import React, { useState } from 'react';
import styles from '../styles.module.scss';

const Select = ({ content, onClick, disabled }) => {

    const [selected, setSelected] = useState();

    return <div
        className={`${styles.bubble} ${styles.bot}`}
        style={{ padding: 0 }}
    >
        <div className={styles.message}
            style={{ border: 'none', padding: 0 }}
        >
            {
                content.map((select, key) => {
                    return <div
                        onClick={() => { setSelected(key); onClick(select.label) }}
                        className={`${styles.select} ${selected === key ? styles.selected : undefined} ${disabled ? 'disabled' : ''}`}>
                        {select.label}
                    </div>
                })
            }
        </div>
    </div>
}

export default Select;
import Unit from './index';

export default class ServerLogUnit extends Unit {

    loadLog = (cbSuccess, cbError) => {
        this.get('/serverLog', cbSuccess, cbError);
    }

}




import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { formatDbDateTime } from '../../helper/functions/formatDate';
import { translate } from '../../helper/multilingual';

import styles from './style.module.scss';

const LogEntry = ({ type, text, creator, createdAt }) => {
    return <div className={`${styles.container}`}>
        <div className={styles.typeContainer}>{translateType(type)}</div>
        {text !== "" && <div className={styles.textContainer}>{text}</div>}
        <div className={styles.footer}>
            <div className={styles.creatorContainer}>
                {creator.type === 'system' && "System"}
                {creator.type === 'admin' && `Afreshed-Admin ${creator.name}`}
                {creator.type === 'customer' && `Kunde ${creator.name}`}
            </div>
            <div className={styles.dateTimeContainer}>{formatDbDateTime(createdAt)}</div>
        </div>
    </div>
}

export default LogEntry;

const translateType = (key) => {
    const types = {
        user_created: "Benutzer erstellt",
    };

    return types[key];
}
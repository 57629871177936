import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Login from './screens/Login';
import { hasPermission, isLoggedIn } from './helper/functions/auth';
import Loading from './helper/screens/Loading';
import UserUnit from './communicator/http/User';
import AdminUsersEdit from './screens/AdminUsers/Add_Edit';
import Cache from './communicator/local';

import { BASENAME, MAINTENANCE } from './config';
import Dashboard from './screens/Dashboard';
import { changeLanguageHandler } from './helper/multilingual';
import PasswordForgot from './screens/PasswordForgot';
import PasswordReset from './screens/PasswordReset';
import Navigation from './elements/navigation';
import Admins from './screens/AdminUsers';
import NotFound from './screens/NotFound';
import wrlog from './helper/functions/wrlog';
import Products from './screens/Products';
import ProductSingle from './screens/Products/Single';
import Customers from './screens/Customers';
import CustomerSingle from './screens/Customers/Single';
import Subscriptions from './screens/Subscriptions';
import Invoices from './screens/Invoices';
import ServerLog from './screens/ServerLog';
import Settings from './screens/Settings';
import SubscriptionSingle from './screens/Subscriptions/Single';
import Ticketing from './screens/Ticketing';
import SubscriptionsProducts from './screens/Subscriptions/Products';
import ProductGroups from './screens/Products/Groups';
import ProductGroupSingle from './screens/Products/Groups/Single';
import Footer from './elements/footer';
import Maintenance from './screens/Maintenance';
import Coupons from './screens/Coupons';
import CouponSingle from './screens/Coupons/Single';
import Makros from './screens/Settings/Makros';
import ProcurementProducts from './screens/Procurement/Products';
import ProcurementProductSingle from './screens/Procurement/Products/Single';
import ProcurementSupplier from './screens/Procurement/Supplier';
import ProcurementSupplierSingle from './screens/Procurement/Supplier/Single';
import Procurement from './screens/Procurement';
import DeliveryNotes from './screens/DeliveryNotes';
import Transactions from './screens/Banking/Transactions';
import Balances from './screens/Banking/Balance';
import BankingOrders from './screens/Banking/Orders';
import TimeTracking from './screens/TimeTracking';
import Vacations from './screens/TimeTracking/vacations';
import Kpis from './screens/Kpis';
import Chatbot from './screens/Chatbot';
import BankingOrderSingle from './screens/Banking/Orders/Single';
import BankingOrdersAutomation from './screens/Banking/OrdersAutomation';
import BankingOrderAutomationSingle from './screens/Banking/OrdersAutomation/Single';
import Accounting from './screens/Banking/Accounting';
import CancelationCohorts from './screens/Statistics/CancelationCohorts';
import Stickyness from './screens/Statistics/Stickyness';
import CancelationReasons from './screens/Statistics/CancelationReasons';
import DownloadCoupons from './screens/Downloads/Coupons';
import ServiceDeskForm from './screens/Ticketing/ServiceDesk';
import CreditCheckForm from './screens/Customers/CreditCheck';
import ServiceDeskArticle from './screens/Ticketing/Articles';
import UploadToPronto from './screens/Pronto';


const App = () => {

    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        changeLanguageHandler();
        const userUnit = new UserUnit();

        isLoggedIn().then((res) => {

            localStorage.setItem('token', res.token);

            userUnit.getPermissions((permissions) => {

                sessionStorage.setItem('permissions', JSON.stringify(permissions));

                setLoggedIn(true);
                setLoading(false);

            }, (err) => wrlog("ERR", err));

        }).catch(() => {
            setLoading(false);
            setLoggedIn(false);
            localStorage.removeItem('token')
        })

        // DELETE ALL CACHES NECCESSARY
        let queryArgsCache = new Cache('subscription.queryArgs', true);
        queryArgsCache.delete();
        queryArgsCache = new Cache('customers.queryArgs', true);
        queryArgsCache.delete();
        queryArgsCache = new Cache('products.queryArgs', true);
        queryArgsCache.delete();

    }, []);

    if (MAINTENANCE) {
        return <Maintenance />;
    }

    if (loading) {
        return <Loading visible={loading} />;
    }

    if (loggedIn) {

        return (
            <Router basename={BASENAME}>
                <div className="wrapper">
                    <Navigation />
                    <div className="content_wrapper">
                        <Routes>
                            <Route path='/' element={<Dashboard />} />
                            <Route path='/timetracking' element={<TimeTracking />} />
                            <Route path='/timetracking/vacation' element={<Vacations />} />
                            {hasPermission('admin', 'can_read') && <Route path='/admins' element={<Admins />} />}
                            {hasPermission('admin', 'can_read') && <Route path='/admins/:id' element={<AdminUsersEdit />} />}

                            {hasPermission('product', 'can_read') && <Route path='/products' element={<Products />} />}
                            {hasPermission('product', 'can_read') && <Route path='/products/:id' element={<ProductSingle />} />}
                            {hasPermission('product', 'can_read') && <Route path='/products/groups' element={<ProductGroups />} />}
                            {hasPermission('product', 'can_read') && <Route path='/products/groups/:id' element={<ProductGroupSingle />} />}

                            {hasPermission('customer', 'can_read') && <Route path='/coupons' element={<Coupons />} />}
                            {hasPermission('customer', 'can_read') && <Route path='/coupons/:id' element={<CouponSingle />} />}

                            {hasPermission('customer', 'can_read') && <Route path='/customers' element={<Customers />} />}
                            {hasPermission('customer', 'can_read') && <Route path='/customers/:id' element={<CustomerSingle />} />}
                            {hasPermission('admin', 'can_read') && <Route path='/customers/creditcheck' element={<CreditCheckForm />} />}

                            {hasPermission('subscription', 'can_read') && <Route path='/subscriptions' element={<Subscriptions />} />}
                            {hasPermission('subscription', 'can_read') && <Route path='/subscriptions/productcount' element={<SubscriptionsProducts />} />}
                            {hasPermission('subscription', 'can_read') && <Route path='/subscriptions/deliverynotes' element={<DeliveryNotes />} />}
                            {hasPermission('subscription', 'can_read') && <Route path='/subscriptions/:id' element={<SubscriptionSingle />} />}

                            {hasPermission('invoice', 'can_read') && <Route path='/invoices' element={<Invoices />} />}
                            {hasPermission('admin', 'can_delete') && <Route path='/logssss' element={<ServerLog />} />}

                            {hasPermission('ticketing', 'can_read') && <Route path='/ticketing/my/*' element={<Ticketing />} />}
                            {hasPermission('ticketing', 'can_read') && <Route path='/ticketing/all/*' element={<Ticketing />} />}
                            {hasPermission('ticketing', 'can_read') && <Route path='/ticketing/open/*' element={<Ticketing />} />}
                            {hasPermission('ticketing', 'can_read') && <Route path='/ticketing/*' element={<Ticketing />} />}
                            {hasPermission('ticketing', 'can_read') && <Route path='/ticketing/cancel/*' element={<Ticketing />} />}
                            {hasPermission('ticketing', 'can_read') && <Route path='/ticketing/pause/*' element={<Ticketing />} />}
                            {hasPermission('ticketing', 'can_read') && <Route path='/ticketing/claim/*' element={<Ticketing />} />}
                            {hasPermission('ticketing', 'can_read') && <Route path='/ticketing/servicedesk' element={<ServiceDeskForm />} />}
                            {hasPermission('ticketing', 'can_read') && <Route path='/ticketing/article/:id' element={<ServiceDeskArticle />} />}

                            {hasPermission('product', 'can_read') && <Route path='/procurement' element={<Procurement />} />}
                            {hasPermission('product', 'can_read') && <Route path='/procurement/:id' element={<Procurement />} />}
                            {hasPermission('product', 'can_read') && <Route path='/procurement/products' element={<ProcurementProducts />} />}
                            {hasPermission('product', 'can_read') && <Route path='/procurement/products/:id' element={<ProcurementProductSingle />} />}
                            {hasPermission('product', 'can_read') && <Route path='/procurement/supplier' element={<ProcurementSupplier />} />}
                            {hasPermission('product', 'can_read') && <Route path='/procurement/supplier/:id' element={<ProcurementSupplierSingle />} />}

                            {hasPermission('banking', 'can_read') && <Route path='/banking/transactions' element={<Transactions />} />}
                            {hasPermission('banking', 'can_read') && <Route path='/banking/liabilities' element={<Balances type="liability" />} />}
                            {hasPermission('banking', 'can_read') && <Route path='/banking/receivables' element={<Balances type="receivable" />} />}
                            {hasPermission('banking', 'can_read') && <Route path='/banking/orders' element={<BankingOrders />} />}
                            {hasPermission('banking', 'can_read') && <Route path='/banking/accounting' element={<Accounting />} />}
                            {(hasPermission('banking', 'can_create') || hasPermission('banking', 'can_update')) && <Route path='/banking/orders/:id' element={<BankingOrderSingle />} />}
                            {hasPermission('banking', 'can_read') && <Route path='/settings/banking-orders-automation' element={<BankingOrdersAutomation />} />}
                            {(hasPermission('banking', 'can_create') || hasPermission('banking', 'can_update')) && <Route path='/settings/banking-orders-automation/:id' element={<BankingOrderAutomationSingle />} />}
                            {(hasPermission('banking', 'can_create') || hasPermission('banking', 'can_update')) && <Route path='/settings/banking-orders-automation/:id' element={<BankingOrderAutomationSingle />} />}


                            {hasPermission('admin', 'can_delete') && <Route path='/settings' element={<Settings />} />}
                            {hasPermission('admin', 'can_delete') && <Route path='/settings/makros' element={<Makros />} />}

                            {hasPermission('admin', 'can_delete') && <Route path='/kpis' element={<Kpis />} />}
                            {hasPermission('admin', 'can_delete') && <Route path='/chatbot' element={<Chatbot />} />}


                            {hasPermission('admin', 'can_read') && <Route path='/statistics' element={<Dashboard />} />}
                            {hasPermission('admin', 'can_read') && <Route path='/statistics/cancelation-cohorts' element={<CancelationCohorts />} />}
                            {hasPermission('admin', 'can_read') && <Route path='/statistics/cancelation-reasons' element={<CancelationReasons />} />}
                            {hasPermission('admin', 'can_read') && <Route path='/statistics/stickyness' element={<Stickyness />} />}


                            {hasPermission('admin', 'can_read') && <Route path='/downloads' element={<DownloadCoupons />} />}
                            {hasPermission('admin', 'can_read') && <Route path='/pronto' element={<UploadToPronto />} />}


                            <Route path='*' element={<NotFound />} />
                        </Routes>
                        <Footer />
                    </div>
                </div>
            </Router>
        );

    } else {
        return (
            <Router basename={BASENAME}>
                <Routes>

                    <Route path='/forgot-password' element={<PasswordForgot />} />
                    <Route path='/reset-password/:key/:secret' element={<PasswordReset />} />
                    <Route path='*' element={<Login />} />
                </Routes>
            </Router>
        );
    }
}

export default App;
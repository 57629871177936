import { Checkbox, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import CustomerUnit from '../../../communicator/http/Customers';
import wrlog from '../../../helper/functions/wrlog';
import { hasPermission } from '../../../helper/functions/auth';

/**
 * 
 * @returns Contact Data Form Items for Customer-Screen
 */
const PaymentMethodForm = ({ customerId, paymentMethod, sendInvoiceHardCopy, setShow, setCustomer, setError }) => {

    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState('');
    const [iban, setIban] = useState('');
    const [invoiceHardCopy, setInvoiceHardCopy] = useState(sendInvoiceHardCopy || false);

    const { handleSubmit, setValue, control, formState: { errors }, watch } = useForm();

    useEffect(() => {
        setMethod(paymentMethod);
    }, []);


    /**
     * Submit userData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const customerUnit = new CustomerUnit();

        setLoading(true);

        let body = {
            method: method,
            provider: 'afreshed'
        };

        if (method === 'sepa') {
            body['payment_data'] = { iban };
            body.provider = 'mollie';
        }

        const cbError = (err) => {
            setLoading(false);
            setError(err);
            setShow(false);
        }

        // Success function for Update User
        const cbSuccessUpdate = (res) => {
            customerUnit.getCustomer(customerId, (res) => {
                setShow(false);
                setLoading(false);
                setCustomer(res);
            }, cbError)

        }

        customerUnit.updatePaymentMethod(customerId, body, cbSuccessUpdate, cbError);
    }

    return (
        <>
            <Container fluid style={{ padding: 0 }}>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <Row style={{ marginTop: 50 }}>
                        <Col md={12}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="paymentMethod">Zahlungsmethode</InputLabel>
                                <Select
                                    onChange={e => setMethod(e.target.value)}
                                    value={method}
                                >
                                    <MenuItem value={"sepa"}>SEPA (Mollie)</MenuItem>
                                    <MenuItem value={"invoice"}>Kauf auf Rechnung</MenuItem>
                                </Select>
                            </FormControl>

                            {
                                method === 'sepa'
                                && <TextField
                                    onChange={e => setIban(e.target.value)}
                                    value={iban}
                                    className={`filled ${iban !== undefined && iban !== null && iban !== '' ? 'valued' : ''}`}
                                    id="standard-basic"
                                    label={'IBAN'}
                                />
                            }
                            {/* {
                                method === 'invoice'
                                && <Checkbox
                                    defaultChecked={invoiceHardCopy}
                                    onChange={(v) => { setInvoiceHardCopy(v.target.checked) }}
                                />
                            } */}

                        </Col>
                    </Row>
                    <div style={{ top: 50, left: 30, position: 'absolute', display: 'inline-block', width: 'max-content' }}>
                        <div
                            onClick={() => setShow(false)}
                            style={{ color: 'var(--red-color)', display: 'inline-block', cursor: 'pointer' }}
                        >
                            {translate('abbrechen')}
                        </div>
                        <input
                            type="submit"
                            style={{ color: 'var(--green-color)', display: 'inline-block', marginLeft: 15, padding: 0, cursor: 'pointer', background: 'none', border: 'none' }}
                            value={translate('save')}
                        />
                    </div>
                </form>
            </Container>
            <Loading visible={loading} containerStyle={{ borderRadius: 15, width: '100%', height: '100%', position: 'absolute' }} />
        </>

    )

}

export default PaymentMethodForm;
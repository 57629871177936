import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import AdminUnit from '../../communicator/http/Admins';
import { translate } from '../../helper/multilingual';
import { hasPermission } from '../../helper/functions/auth';

/**
 * 
 * @returns Admin Overview Page
 */
const Admins = () => {

    const [loading, setLoading] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [selectedAdmins, setSelectedAdmins] = useState([]);
    const [searchNameTerm, setSearchNameTerm] = useState("");

    const adminUnit = new AdminUnit();

    /**
     * initially loads Admin user
     */
    useEffect(() => {
        loadUsers();
    }, []);

    /**
     * Listener on change of searchNameTerm & Filters admins
     */
    useEffect(() => {

        let _admins = [...admins];
        if (searchNameTerm !== "") {
            _admins = admins.filter((admin) => {

                let valid = true;

                searchNameTerm.split(' ').forEach(term => {

                    if (
                        admin.firstName.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                        admin.lastName.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                        admin.email.toLowerCase().indexOf(term.toLowerCase()) > -1
                    ) {

                    } else {
                        valid = false;
                    }
                })

                return valid;
            })
        }

        setSelectedAdmins(_admins)

    }, [searchNameTerm]);

    /**
     * Loads admin users from API
     */
    const loadUsers = () => {

        setLoading(true);

        adminUnit.listAdmins((res) => {

            setLoading(false);

            setAdmins(res);
            setSelectedAdmins(res);

        }, (err) => {
            setLoading(false);
        })
    }

    return <>
        <TopBar title={"Admins"} />
        <Container fluid>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
                <Col md={8}>
                    <TextField id="standard-basic" className="filled" value={searchNameTerm} label="Admins durchsuchen" onChange={value => setSearchNameTerm(value.target.value)} /><br />
                </Col>
                <Col md={4}>
                    {hasPermission('admin', ['can_create']) && <Link to={'/admins/new'} className={'button'}>{translate('create_new_admin')}</Link>}
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nachname</TableCell>
                                <TableCell>Vorname</TableCell>
                                <TableCell>E-Mail</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                selectedAdmins.map((admin, key) => {

                                    return <TableRow key={key}>
                                        <TableCell>
                                            {admin.lastName}
                                        </TableCell>
                                        <TableCell>
                                            {admin.firstName}
                                        </TableCell>
                                        <TableCell>
                                            <a href={`mailto:${admin.email}`}>{admin.email}</a>
                                        </TableCell>
                                        <TableCell style={{textAlign: 'right'}}>
                                            {hasPermission('admin', ['can_update', 'can_delete']) && <Link to={`/admins/${admin.id}`}>bearbeiten</Link>}
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </Col>
            </Row>
        </Container>
        <Loading visible={loading} />
    </>

}

export default Admins;
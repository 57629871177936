import Unit from './index';

export default class ServiceDeskUnit extends Unit {
    listFormFields = (type, cbSuccess, cbError) => {
        this.get(`/devticket/fields/${type}`, cbSuccess, cbError);
    }

    create = (type, body, cbSuccess, cbError) => {
        this.post(`/devticket/${type}`, body, cbSuccess, cbError);
    }

    searchArticles = (search, cbSuccess, cbError) => {
        this.post(`/devticket/articles/search`, { search: search }, cbSuccess, cbError);
    }

    getArticle = (articleId, cbSuccess, cbError) => {
        this.get(`/devticket/articles/${articleId}`, cbSuccess, cbError);
    }

}

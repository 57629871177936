import { Checkbox, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, FormControlLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import { translate } from '../../../../helper/multilingual';
import Message from '../../../../elements/message';
import NotFound from '../../../NotFound';
import { hasPermission } from '../../../../helper/functions/auth';
import TextEditor from '../../../../elements/texteditor';
import ImageInput from '../../../../elements/imageInput';
import { formatMoney } from '../../../../helper/functions/formatPrice';
import wrlog from '../../../../helper/functions/wrlog';
import { isJsonString } from '../../../../helper/functions/isJsonString';
import { formatPercent } from '../../../../helper/functions/formatPercent';
import DatePicker from '../../../../elements/datepicker';
import { Autocomplete } from '@material-ui/lab';
import BankingOrderUnit from '../../../../communicator/http/BankingOrder';
import ProcurementSupplierUnit from '../../../../communicator/http/ProcurementSupplier';


/**
 * 
 * @returns Admin Product Detail Page
 */
const BankingOrderAutomationSingle = () => {

    const params = useParams();

    const orderId = params.id;

    const [loading, setLoading] = useState(orderId !== 'new');
    const [suppliers, setSuppliers] = useState([]);
    const [order, setOrder] = useState({});

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [askDeleteMessageOpen, setAskDeleteMessageOpen] = useState(false);

    const [imageData, setImageData] = useState(null);

    const [is404, setIs404] = useState(false);
    const [selectedProductIds, setSelectedProductIds] = useState([]);

    const { handleSubmit, setValue, control, formState: { errors }, getValues } = useForm();

    const bankingOrderUnit = new BankingOrderUnit();

    /**
     * Inititally check if productId is "new" to check if product is updated or new product is created
     */
    useEffect(() => {


        /**
         * if productId !== set set 404 = true
         */
        if (orderId === undefined || orderId === null || orderId === "") {
            setIs404(true);
            return;
        }

        /**
         * if productId !== new --> update product & load productData
         */
        if (orderId !== 'new') {

            // Check if product can update || delete

            if (!hasPermission('banking', ['can_update', 'can_delete'])) {
                setIs404(true);
                return;
            }

            bankingOrderUnit.getOrderAutomation(orderId, (order) => {

                setLoading(false);

                let postcodes = order.postcodes;
                if(typeof postcodes === 'string'){
                    postcodes = JSON.parse(postcodes);
                }
                
                postcodes.join(', ');

                setValue('title', order.title);
                setValue('supplierId', order.supplierId);
                setValue('postcodes', postcodes);
                setValue('sumNet', formatMoney(order.sumNet, false));
                setValue('sumGross', formatMoney(order.sumGross, false));
                setValue('tax', order.tax);
                setValue('status', order.status);

                setOrder(order);

            }, () => {
                setIs404(true);
            })
        } else {
            if (!hasPermission('banking', ['can_create'])) {
                setIs404(true);
                return;
            }

            setValue('title', '');
            setValue('postcodes', '');
            setValue('supplierId', '');
            setValue('sumNet', '');
            setValue('sumGross', '');
            setValue('tax', '');
            setValue('status', '');
        }


    }, [orderId]);

    useEffect(() => {
        const supplierUnit = new ProcurementSupplierUnit();
        supplierUnit.list('', (response) => {

            setSuppliers(response.results);
        }, () => { })

    }, [])

    /**
     * Submit productData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {

        let postcodes = data.postcodes.split(',');
        postcodes = postcodes.map(p => p.trim());

        let body = {
            title: data.title,
            postcodes: postcodes,
            sum_gross: data.sumGross,
            sum_net: data.sumNet,
            tax: data.tax,
            supplier_id: data.supplierId,
        }
        setLoading(true);

        // Success function for Create Product
        const cbSuccess = (res) => {
            setLoading(false);
            if (res.id !== undefined) {
                window.location.replace(`/settings/banking-orders-automation/${res.id}`); //Replace Location to new created product & reload page
            }
        }

        // Success function for Update Product
        const cbSuccessUpdate = (res) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
            setErrorMessageSmall(true);
            setErrorMessageTitle("Auftrag gespeichert.");
            setErrorMessageBody("");

            setOrder(data);
        }

        const cbError = (err) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        if (orderId === 'new') {
            bankingOrderUnit.createAutomation(body, cbSuccess, cbError);
        } else {
            body.id = orderId;
            bankingOrderUnit.updateAutomation(body, cbSuccessUpdate, cbError);
        }
    }

    /**
     * Delete current product
     */
    const deleteOrder = () => {
        setLoading(true);
        setAskDeleteMessageOpen(false);

        const cbSuccess = (res) => {
            window.location.replace(`/settings/banking-orders-automation`);
        }

        const cbError = (err) => {
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        bankingOrderUnit.updateAutomation({ id: orderId, status: 'deleted' }, cbSuccess, cbError);
    }

    if (is404 === true) {
        return <NotFound />;
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to="/settings/banking-orders-automation">zurück</Link><br /><br />
                        <h1>{orderId !== "new" ? <>{order.title} {translate('edit')}</> : "Auftrag-Vorlage erstellen"} </h1>
                        {orderId !== "new" && hasPermission('banking', ['can_delete']) && <div
                            className='small_button'
                            style={{ color: 'red' }}
                            onClick={() => setAskDeleteMessageOpen(true)}>
                            Auftrag löschen
                        </div>}
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <Row style={{ marginTop: 50 }}>
                        <Col md={7}>
                            <FormController
                                name={"title"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.title}
                                        id="standard-basic"
                                        label={'Titel / Beschreibung'}
                                    />
                                )}
                            />

                            {<FormController
                                name={"supplierId"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    value !== null && value !== undefined && suppliers.length > 0 && <FormControl
                                        className="selectFormControl"
                                    >
                                        <InputLabel id="type">Lieferant</InputLabel>
                                        <Select
                                            onChange={onChange}
                                            value={value}
                                        >
                                            {
                                                suppliers.map(supplier => (
                                                    <MenuItem value={supplier.id}>{supplier.companyTitle}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                )}
                            />}

                            <FormController
                                name={"postcodes"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        multiline={true}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.title}
                                        id="standard-basic"
                                        label={'Postleitzahlen'}
                                    />
                                )}
                            />

                            <FormController
                                name={"sumGross"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.sumGross}
                                        id="standard-basic"
                                        label={'Summe (brutto)'}
                                    />
                                )}
                            />
                            <FormController
                                name={"sumNet"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.sumGross}
                                        id="standard-basic"
                                        label={'Summe (netto)'}
                                    />
                                )}
                            />
                            <FormController
                                name={"tax"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.tax}
                                        id="standard-basic"
                                        label={'Steuer (%)'}
                                    />
                                )}
                            />
                        </Col>
                        <Col md={5}>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 100 }}>
                        <Col md={5}>
                            {
                                ((hasPermission('product', ['can_update']) && params.id !== "new") || (hasPermission('product', ['can_create']) && params.id === "new")) &&
                                <input type="submit" style={{ width: '200px', position: 'fixed', bottom: 20, right: 20 }} className="button" value={params.id !== "new" ? translate('save') : translate('add')} />
                            }
                        </Col>
                        <Col md={7}>
                        </Col>
                    </Row>
                </form>
            </Container >
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />

            <Message
                open={askDeleteMessageOpen}
                type={'success'}
                small={false}
                title={translate('sure_delete_title')}
                body={translate('sure_delete_body')}
                buttonText={translate('yes_delete')}
                buttonAction={() => deleteOrder()}
                buttonTwoText={translate('cancel')}
                buttonTwoAction={() => setAskDeleteMessageOpen(false)}
                buttonTwoStyle={{ color: 'red' }}
            />

            <Loading visible={loading} />
        </>

    )

}

export default BankingOrderAutomationSingle;
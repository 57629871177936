import Unit from './index';

export default class ProcurementContentUnit extends Unit {

    list = (deliveryDate, afProductId, cbSuccess, cbError) => {
        this.post('/procurementcontent/list', { delivery_date: deliveryDate, af_product_id: afProductId }, cbSuccess, cbError);
    }

    getDelivery = (deliveryId, cbSuccess, cbError) => {
        this.get('/procurementcontent/' + deliveryId, cbSuccess, cbError);
    }

    create = (body, cbSuccess, cbError) => {
        this.post('/procurementcontent', body, cbSuccess, cbError);
    }

    update = (data, cbSuccess, cbError) => {
        this.put('/procurementcontent', data, cbSuccess, cbError);
    }

    delete = (data, cbSuccess, cbError) => {
        
    }

}
import Unit from './index';

export default class SettingsUnit extends Unit {

    listSettings = (cbSuccess, cbError) => {
        this.get(`/settings`, cbSuccess, cbError);
    }

    setSettings = (body, cbSuccess, cbError) => {
        this.post(`/settings`, body, cbSuccess, cbError);
    }

}

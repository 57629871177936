import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Message from '../../../elements/message';
import Loading from '../../../helper/screens/Loading';
import TopBar from '../../../elements/topbar';
import { translate } from '../../../helper/multilingual';
import { getUserToken, hasPermission } from '../../../helper/functions/auth';
import ProductUnit from '../../../communicator/http/Products';
import BankingUnit from '../../../communicator/http/Banking';
import Pagination from '../../../elements/pagination';
import wrlog from '../../../helper/functions/wrlog';
import { formatDbDate } from '../../../helper/functions/formatDate';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../../elements/statusLabel';
import ToolTip from '../../../elements/tooltip';
import Cache from '../../../communicator/local';
import DatePicker from '../../../elements/datepicker';
import { formatMoney } from '../../../helper/functions/formatPrice';
import { translateTransactionStatus, translatePaymentType } from '../../../helper/functions/translateTransaction';
import Popup from '../../../elements/popup';
import InvoiceUnit from '../../../communicator/http/Invoices';
import Unit from '../../../communicator/http';
import Dropzone from 'react-dropzone';

import balanceStyles from '../Balance/style.module.scss';
import styles from './style.module.scss';
import BankingOrderUnit from '../../../communicator/http/BankingOrder';
import ProcurementSupplierUnit from '../../../communicator/http/ProcurementSupplier';
import { Link } from 'react-router-dom';


/**
 * 
 * @returns Transactions Overview Page
 */
const BankingOrdersAutomation = (props) => {

    const [loading, setLoading] = useState(false);

    const [orders, setOrders] = useState([]);
    const [filterSupplier, setFilterSupplier] = useState('');
    const [suppliers, setSuppliers] = useState([]);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isInit, setIsInit] = useState(true);

    const perPage = 25;

    const bankingOrderUnit = new BankingOrderUnit();
    const supplierUnit = new ProcurementSupplierUnit();

    const queryArgsCache = new Cache('bankingOrdersAutomation.queryArgs', true);

    /**
     * initially loads products
     */
    useEffect(() => {

        let queryArgs = queryArgsCache.get();

        if (queryArgs !== false) {
            if (queryArgs.supplier !== undefined && props.supplier === undefined) {
                setFilterSupplier(queryArgs.supplier);
            }

            setPage(queryArgs.page);
        }

        supplierUnit.list('', res => {
            setSuppliers(res.results);
        }, err => {

        })

    }, []);


    /**
     * initially loads products
     */
    useEffect(() => {
        let queryArgs = queryArgsCache.get();
        if (page > 0 && (!isInit || queryArgs === false)) {
            setIsInit(false);
            load();
        }
    }, [page]);

    useEffect(() => {
        setIsInit(false);
        setLoading(true);
        setPage(0);
        setTimeout(() => {
            setPage(1);
        }, 100)
    }, [filterSupplier]);

    /**
* loads products by search result
* @param {*} e 
*/
    const doSearch = (e) => {
        e.preventDefault();
        load();
    }


    /**
     * Loads transactions from API
     */
    const load = (cb = () => { }) => {

        setLoading(true);

        const skip = (page - 1) * perPage;

        const body = {
            skip: skip,
            take: perPage,
            supplier: filterSupplier,
        };

        bankingOrderUnit.listAutomation(body, (res) => {

            setLoading(false);
            setOrders(res.results);
            setTotalPages(Math.ceil(res.total / perPage));
            cb();
            body.page = page;
            queryArgsCache.set(body);

        }, (err) => {
            setLoading(false);
        })
    }

    return <>
        <TopBar title={"Aufträge"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <Row>
                        <Col md={6}>
                            <form onSubmit={doSearch}>
                                <FormControl
                                    className="selectFormControl"
                                >
                                    <InputLabel id="type">Lieferanten</InputLabel>
                                    <Select
                                        onChange={(e) => {
                                            setFilterSupplier(e.target.value === '' ? '' : e.target.value)
                                        }}
                                        value={filterSupplier || ""}
                                    >
                                        <MenuItem value={""}>Alle</MenuItem>
                                        {
                                            suppliers.map(su => (
                                                <MenuItem value={su.id}>{su.companyTitle}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>                            </form>
                        </Col>
                        <Col md={3}>
                            <Link to={`/settings/banking-orders-automation/new`} className='button'>hinzufügen</Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Lieferant</TableCell>
                                <TableCell>Titel</TableCell>
                                <TableCell style={{ width: 50 }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                orders.map((order, key) => {

                                    return <TableRow key={key}>
                                        <TableCell>
                                            {order.supplier}
                                        </TableCell>
                                        <TableCell>
                                            {order.title}
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/settings/banking-orders-automation/${order.id}`} className='link'>bearbeiten</Link>
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        pages={totalPages}
                        current={page}
                        pageChange={setPage}
                    />
                </Col>
            </Row>
        </Container>


        <Loading visible={loading} />
    </>

}

export default BankingOrdersAutomation;
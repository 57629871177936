import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import { translate } from '../../helper/multilingual';
import { hasPermission } from '../../helper/functions/auth';
import Cache from '../../communicator/local';
import ProductUnit from '../../communicator/http/Products';
import Pagination from '../../elements/pagination';
import { formatMoney } from '../../helper/functions/formatPrice';

/**
 * 
 * @returns Product Overview Page
 */
const Products = () => {

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [searchNameTerm, setSearchNameTerm] = useState("");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isInit, setIsInit] = useState(true);

    const perPage = 25;

    const productUnit = new ProductUnit();
    const queryArgsCache = new Cache('products.queryArgs', true);

    /**
     * initially loads products
     */
    useEffect(() => {

        let queryArgs = queryArgsCache.get();

        if (page > 0 && (!isInit || queryArgs === false)) {
            loadProducts();
            setIsInit(false);
        }
    }, [page]);

    /**
     * initially loads products
     */
    useEffect(() => {
        let queryArgs = queryArgsCache.get();
        console.log("CACHE", queryArgs)
        if (queryArgs !== false) {
            setLoading(true);
            setSearchNameTerm(queryArgs.search);
            setPage(queryArgs.page);
        }
    }, []);

    useEffect(() => {
        if (isInit) {
            setIsInit(false);
            setLoading(true);
            setPage(0);
            setTimeout(() => {
                setPage(1);
            }, 100)
        }

    }, [searchNameTerm])

    /**
     * loads products by search result
     * @param {*} e 
     */
    const doSearch = (e) => {
        e.preventDefault();
        loadProducts();
    }

    /**
     * Loads admin users from API
     */
    const loadProducts = () => {

        setLoading(true);

        const skip = (page - 1) * perPage;

        productUnit.listProducts({
            skip: skip,
            take: perPage,
            search: searchNameTerm
        }, (res) => {
            setLoading(false);

            setProducts(res.results);
            setTotalPages(Math.ceil(res.total / perPage));

            const queryArgs = {
                page: page,
                search: searchNameTerm
            }

            queryArgsCache.set(queryArgs);
        }, (err) => {
            setLoading(false);
        })
    }

    return <>
        <TopBar title={"Produkte"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={8}>
                    <form onSubmit={doSearch}>
                        <Row>
                            <Col md={6}>
                                <TextField id="standard-basic" className="filled" value={searchNameTerm} label="Produkte durchsuchen" onChange={value => setSearchNameTerm(value.target.value)} /><br />
                            </Col>
                            <Col md={4} >
                                <div
                                    onClick={doSearch}
                                    style={{ paddingTop: 17, paddingBottom: 18 }}
                                    className={`button ${searchNameTerm === '' ? 'disabled' : ''}`}>
                                    suchen
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Col>
                <Col md={4} style={{ textAlign: 'right' }}>
                    {hasPermission('admin', ['can_create']) && <Link
                        style={{ paddingTop: 17, paddingBottom: 18 }}
                        to={'/products/new'} className={'button'}>Neues Produkt hinzufügen</Link>}
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>SKU</TableCell>
                                <TableCell>Produkt</TableCell>
                                <TableCell>Preis</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                products.map((product, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>
                                            {product.sku}
                                        </TableCell>
                                        <TableCell>
                                            {product.title}
                                        </TableCell>
                                        <TableCell>
                                            {product.reducedPrice !== "" && product.reducedPrice < product.price ? <><del>{formatMoney(product.price)}</del> {formatMoney(product.reducedPrice)}</> : formatMoney(product.price)}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>
                                            {hasPermission('product', ['can_update', 'can_delete']) && <Link to={`/products/${product.id}`}>bearbeiten</Link>}
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        pages={totalPages}
                        current={page}
                        pageChange={setPage}
                    />
                </Col>
            </Row>
        </Container>
        <Loading visible={loading} />
    </>

}

export default Products;
import styles from '../styles.module.scss';

const Text = ({ content, sender }) => {
    return <div className={`${styles.bubble} ${styles[sender]}`}>
        <div className={styles.message}>
            {content}
        </div>
    </div>
}

export default Text;
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import wrlog from '../../../helper/functions/wrlog';
import SettingMakroUnit from '../../../communicator/http/SettingMakro';
import cloneArray from '../../../helper/functions/cloneArray';
import { translate } from '../../../helper/multilingual';
import Popup from '../../../elements/popup';
import TopBar from '../../../elements/topbar';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import StatisticsUnit from '../../../communicator/http/Statistics';
import { Autocomplete } from '@material-ui/lab';
import ProductUnit from '../../../communicator/http/Products';
import DatePicker from '../../../elements/datepicker';
import { formatPercent } from '../../../helper/functions/formatPercent';
import DownloadsUnit from '../../../communicator/http/Downloads';
import ReactExport from "react-export-excel-xlsx-fix";
import PDFMerger from 'pdf-merger-js/browser';
import { getUserToken } from '../../../helper/functions/auth';
import { zipCreator } from '../../../helper/functions/zipCreator';
import axios from 'axios';
import JSZip from "jszip"
import { saveAs } from 'file-saver';
import InvoiceUnit from '../../../communicator/http/Invoices';
import ReminderUnit from '../../../communicator/http/Reminders';
import moment from 'moment';
import { decode } from 'iconv-lite';
import { transcode } from 'buffer';
import { v4 as uuidv4 } from 'uuid';
import { BASENAME } from '../../../config';
import { API_ENDPOINT } from '../../../config';
import Unit from '../../../communicator/http';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Downloads = () => {
    const [loading, setLoading] = useState(false);
    const [datePeriodFrom, setDatePeriodFrom] = useState(new Date());
    const [datePeriodTo, setDatePeriodTo] = useState(new Date());
    const [csvData, setCsvData] = useState(null);
    const [csvTitle, setCsvTitle] = useState('');

    useEffect(() => {
        if (csvData !== null) {
            setTimeout(() => setCsvData(null), 1000)
        }
    }, [csvData])

    const buildQueryBody = () => {
        try {
            console.log(datePeriodFrom, datePeriodTo)
            let body = {};
            if (datePeriodFrom && datePeriodFrom !== '') {
                body.from = moment(datePeriodFrom).format('YYYY-MM-DD');
            }
            if (datePeriodTo && datePeriodTo !== '') {
                body.to = moment(datePeriodTo).format('YYYY-MM-DD');
            }

            return body;
        } catch (err) {
            return {}
        }
    }

    const loadCoupons = () => {
        const downloadsUnit = new DownloadsUnit();
        setCsvTitle('Gutscheine');
        setLoading(true);

        downloadsUnit.getUsedCoupons(buildQueryBody(), (res) => {
            setLoading(false);
            setCsvData(res);
        }, () => {
            setLoading(false);
        })
    }

    const loadInvoices = () => {
        const downloadsUnit = new DownloadsUnit();
        setLoading(true);

        const filename = 'invoices-to-print';

        downloadsUnit.listInvoices(buildQueryBody(), async (invoices) => {
            await zipCreator(invoices, downloadInvoice, filename)
            setLoading(false);
        }, () => {
            setLoading(false);
        })
    }

    const downloadInvoice = (id) => {
        return new Promise((resolve, reject) => {
            const invoiceUnit = new InvoiceUnit();
            invoiceUnit.getPdf(id, resolve, reject);
        })
    }

    const loadReminders = () => {
        const downloadsUnit = new DownloadsUnit();
        setLoading(true);

        const body = {
            from: datePeriodFrom,
            to: datePeriodTo
        };

        const filename = 'Mahnungen';

        downloadsUnit.listReminder(buildQueryBody(), async (reminders) => {
            await zipCreator(reminders, downloadReminder, filename)
            setLoading(false);
        }, () => {
            setLoading(false);
        })
    }

    const downloadReminder = (id) => {
        return new Promise((resolve, reject) => {
            const reminderUnit = new ReminderUnit();
            reminderUnit.getPdf(id, resolve, reject);
        })
    }

    const loadFactoringCsv = async () => {
        setLoading(true);

        let unit = new Unit();

        const filename = 'factoring-download-' + uuidv4();
        let options = {
            method: 'POST',
            responseType: 'blob',
            url: `${API_ENDPOINT}/downloads/factoring-bank-export/csv`,
            headers: {
                'Content-Type': 'application/json',
                ...unit.getAuthenticationHeader()
            },
            data: buildQueryBody()
        };

        axios.request(options).then(function (csvResponse) {
            const csv = csvResponse.data;
            options.url = `${API_ENDPOINT}/downloads/factoring-bank-export/invoices`
            saveAs(csv, filename + '.csv');

            axios.request(options).then(function (zipResponse) {
                const zip = zipResponse.data;
                saveAs(zip, filename + '.zip');
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                console.error(error);
            });

        }).catch(function (error) {
            setLoading(false);
            console.error(error);
        });

    }

    return <>
        <TopBar title={"Eingelöste Gutscheine"} />
        <Container fluid>
            <Row>
                <Col md={2}>
                    <DatePicker
                        onChange={(val) => {
                            setDatePeriodFrom(val)
                        }}
                        value={datePeriodFrom}
                        label={"Von"}
                        date={datePeriodFrom}
                    />
                </Col>
                <Col md={2}>
                    <DatePicker
                        onChange={(val) => {
                            setDatePeriodTo(val)
                        }}
                        value={datePeriodTo}
                        label={"Bis"}
                        date={datePeriodTo}
                    />
                </Col>
            </Row>
        </Container>
        <Table style={{ width: '100%', marginTop: 20 }} cellpadding={10}>
            <TableRow>
                <TableCell>
                    Eingelöste Gutscheine
                </TableCell>
                <TableCell style={{ width: 140 }}>
                    <div
                        style={{ paddingTop: 17, paddingBottom: 18 }}
                        className={`button`}
                        onClick={() => loadCoupons()}
                    >Laden</div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    Rechnungen (Zahlung auf Rechnunge)
                </TableCell>
                <TableCell style={{ width: 140 }}>
                    <div
                        style={{ paddingTop: 17, paddingBottom: 18 }}
                        className={`button`}
                        onClick={() => loadInvoices()}
                    >Laden</div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    Mahnungen (3. Stufe und abgelaufen)
                </TableCell>
                <TableCell style={{ width: 140 }}>
                    <div
                        style={{ paddingTop: 17, paddingBottom: 18 }}
                        className={`button`}
                        onClick={() => loadReminders()}
                    >Laden</div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    Factoring Bank Export
                </TableCell>
                <TableCell style={{ width: 140 }}>
                    <div
                        style={{ paddingTop: 17, paddingBottom: 18 }}
                        className={`button`}
                        onClick={() => loadFactoringCsv()}
                    >Laden</div>
                </TableCell>
            </TableRow>
        </Table>

        <Loading visible={loading} />
        {
            csvData !== null && <ExcelFile
                hideElement={true}
            >
                <ExcelSheet data={csvData} name={csvTitle}>
                    {
                        Object.keys(csvData[0]).map((column) => {
                            return <ExcelColumn label={column} value={column} />
                        })
                    }
                </ExcelSheet>
            </ExcelFile>
        }
    </>

}

export default Downloads;
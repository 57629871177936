import React, { Component, useState, useEffect } from 'react';
import styles from './style.module.scss';


const TimeInput = (props) => {

    const [focused, setFocused] = useState(false);
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [value, setValue] = useState('');
    const [status, setStatus] = useState('default');

    useEffect(() => {
        if (props.value !== undefined && props.value !== null) {

            if(props.value === ''){
                setHours('');
                setMinutes('');
                return;
            }

            let split = props.value.split(':');

            if (split[0].length < 2) {
                split[0] = `0${split[0]}`
            }
            if (split[1].length < 2) {
                split[1] = `0${split[1]}`
            }

            setHours(split[0]);
            setMinutes(split[1]);
        }
    }, [props.value]);

    const _onChange = (_value, key) => {

        let output = '';
        let valid = true;

        if (_value.length < 2) {
            _value = `0${_value}`;
        }

        if (key === 'h') {
            if (parseInt(_value) > 23) {
                _value = 23;
            }
            output = `${_value}:${minutes.length === 2 ? minutes : ''}`;
            setHours(_value);
        } else {
            if (parseInt(_value) > 59) {
                _value = 59;
            }
            output = `${hours.length === 2 ? hours : ''}:${_value}`;
            setMinutes(_value);
        }

        if (output.length === 5 && valid) {
            setStatus('valid');
        } else if (!valid) {
            setStatus('error');
        }

        props.onChange(output);
        setValue(output);
    }

    return <div className={`${styles.timeinput} ${styles[status]}`}>
        <input
            type="text"
            value={hours}
            onChange={e => setHours(e.target.value)}
            onBlur={e => _onChange(e.target.value, 'h')}
            onFocus={() => setFocused(true)}
            maxLength={2}
        />
        :
        <input
            type="text"
            value={minutes}
            onChange={e => setMinutes(e.target.value)}
            onBlur={e => _onChange(e.target.value, 'm')}
            onFocus={() => setFocused(true)}
            maxLength={2}
        />
    </div>

}

export default TimeInput;
import React, { useState, useEffect } from 'react';
import { Badge, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { hasPermission } from '../../helper/functions/auth';
import { translate } from '../../helper/multilingual';
import StatusLabel from '../../elements/statusLabel';
import { Link } from 'react-router-dom';
import Sidebar from '../../elements/sidebar';
import Loading from '../../helper/screens/Loading';
import wrlog from '../../helper/functions/wrlog';
import TicketingUnit from '../../communicator/http/Ticketing';


const TicketingSidebar = ({ tickets, selectedTicketId, loading, prefix, loadMore, totalPages, page, acceptedStatuses, doSearch, setSearchNameTerm, searchNameTerm, reload, setDateSort, dateSort }) => {

    const [loadingTakeButton, setLoadingTakeButton] = useState(false);

    const handleScroll = (e) => {
        if (totalPages > page) {
            if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
                loadMore();
            }
        }
    }

    const bulkAssign = () => {

        setLoadingTakeButton(true);

        const ticketingUnit = new TicketingUnit();
        ticketingUnit.bulkAssignAdmin(dateSort, () => {
            setLoadingTakeButton(false);
            reload()
        }, () => {
            setLoadingTakeButton(false);
        });
    }


    return <Sidebar
        onScroll={handleScroll}
    >
        <form onSubmit={doSearch} style={{ position: 'sticky', top: 0 }}>
            <div style={{ display: 'flex' }}>
                <div
                    className={`smallButton ${loadingTakeButton ? 'disabled' : ''}`}
                    style={{ height: 62, width: 62 }}
                    onClick={() => bulkAssign()}
                >{loadingTakeButton ? '...' : '+10'}</div>
                <TextField style={{ width: 'calc(100% - 124px)' }} id="standard-basic" className="filled searchInList" value={searchNameTerm} label="Suche..." onChange={value => setSearchNameTerm(value.target.value)} />
                <div onClick={() => setDateSort(dateSort === 'ASC' ? 'DESC' : 'ASC')} className='smallButton' style={{ height: 62, width: 62 }}>{dateSort === 'ASC' ? <>Alte<br />zuerst</> : <>Neue<br />zuerst</>}</div>
            </div>
        </form>

        <Table className="table_container" style={{
        }}>
            <TableBody>

                {
                    tickets.map((ticket, key) => {

                        if (acceptedStatuses.length > 0 && !acceptedStatuses.includes(ticket.status)) {
                            return null;
                        }

                        return <Link to={`/ticketing${prefix}/${ticket.id}`} style={{wordBreak: 'break-all'}}>
                            <TableRow key={key} className={selectedTicketId === ticket.id ? 'selected' : undefined}>
                                <TableCell style={{ width: '100%' }}>
                                    {ticket.sender.email}<br />
                                    <strong>{ticket.subject}</strong>
                                    {ticket.last_message.body !== undefined ? <small style={{ color: 'grey' }}><br />{ticket.last_message.body}</small> : <small style={{ color: 'grey' }}><br />Keine Nachricht enthalten</small>}
                                </TableCell>
                                <TableCell>
                                    <StatusLabel small status={ticket.status}>{ticket.unread > 0 ? ticket.unread : ''}</StatusLabel>
                                </TableCell>
                            </TableRow>
                        </Link>
                    })
                }
            </TableBody>
        </Table>
        {
            loading &&
            <Loading containerStyle={{ height: 70 }} visible={true} relative />
        }
        {
            totalPages === page && <div style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}><strong>Alle Tickets geladen</strong></div>
        }
    </Sidebar>
}

export default TicketingSidebar;
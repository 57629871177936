import Unit from './index';

export default class TicketingUnit extends Unit {

    getOverview = (cbSuccess, cbError) => {
        this.get(`/tickets/overview`, cbSuccess, cbError);
    }

    listTickets = (body, cbSuccess, cbError) => {
        this.post(`/tickets/list`, body, cbSuccess, cbError);
    }

    getTicket = (ticketId, cbSuccess, cbError) => {
        this.get(`/tickets/${ticketId}`, cbSuccess, cbError);
    }

    updateTicket = (body, cbSuccess, cbError) => {
        this.put('/tickets', body, cbSuccess, cbError);
    }

    assignAdmin = (body, cbSuccess, cbError) => {
        this.put('/tickets/assign', body, cbSuccess, cbError);
    }

    bulkAssignAdmin = (dateSort, cbSuccess, cbError) => {
        this.put('/tickets/take', { sort: dateSort }, cbSuccess, cbError);
    }

    sendMessage = (id, message, cbSuccess, cbError) => {
        this.post(`/tickets/${id}/message`, { message: message }, cbSuccess, cbError);
    }

    addNote = (id, note, cbSuccess, cbError) => {
        this.post(`/tickets/${id}/note`, { note: note }, cbSuccess, cbError);
    }

    getNotes = (ticketId, cbSuccess, cbError) => {
        this.get(`/tickets/${ticketId}/notes`, cbSuccess, cbError);
    }
    
}




const wrlog = (...args) => {

    let environment = 'development';

    if (process.env.NODE_ENV) {
        environment = process.env.NODE_ENV || 'development'; 
    }

    if (environment === 'development' || args[args.length - 1] === 'force') {
        console.log(...args);
    }

}

export default wrlog;
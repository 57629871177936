import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { RichUtils } from 'draft-js';
import { handleNewLine, insertNewUnstyledBlock } from 'draftjs-utils';

import styles from './style.module.scss';
import wrlog from '../../helper/functions/wrlog';

const TextEditor = (props) => {

    const [content, setContent] = useState('');

    useEffect(() => {

        if (props.value === false || props.value === undefined || props.value === null || content !== '') {
            return;
        }

        const contentBlock = htmlToDraft(props.value);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);

            setContent(editorState);
        }

    }, [props.value])

    useEffect(() => {

        if (props.value === false || props.value === undefined || props.value === null) {
            return;
        }

        const contentBlock = htmlToDraft(props.hardValue);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);

            setContent(editorState);
        }

    }, [props.hardValue])


    const onEditorStateChange = (editorState) => {
        setContent(editorState);

        if (props.setValid !== undefined) {
            props.setValid(convertToRaw(editorState.getCurrentContent()).blocks[0].text !== "");
        }

        props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };



    return <>
        <p class={styles.placeholder}>{props.label}</p>
        <Editor
            handleReturn={(event) => {
                // override behavior for enter key
                var newEditorState = null;
                if (event.key === 'Enter' && event.shiftKey) {
                    // with shift, make a new block
                    newEditorState = insertNewUnstyledBlock(content);
                } else if (event.key === 'Enter' && !event.shiftKey) {
                    // without shift, just a normal line break
                    newEditorState = RichUtils.insertSoftNewline(content);
                }
                if (newEditorState) {
                    setContent(newEditorState);
                    return true;
                }
                return false;
            }}
            toolbar={props.toolbar}
            wrapperClassName={styles.wrapper}
            editorClassName={styles.editor}
            toolbarClassName={styles.toolbar}
            editorState={content}
            onEditorStateChange={onEditorStateChange}
        />
    </>

}

export default TextEditor;
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Message from '../../elements/message';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import { translate } from '../../helper/multilingual';
import { getUserToken, hasPermission } from '../../helper/functions/auth';
import ProductUnit from '../../communicator/http/Products';
import DeliveryNoteUnit from '../../communicator/http/DeliveryNote';
import Pagination from '../../elements/pagination';
import wrlog from '../../helper/functions/wrlog';
import { formatDbDate } from '../../helper/functions/formatDate';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../elements/statusLabel';
import ToolTip from '../../elements/tooltip';
import Cache from '../../communicator/local';
import DatePicker from '../../elements/datepicker';
import { formatMoney } from '../../helper/functions/formatPrice';
import { translateDeliveryNoteStatus } from '../../helper/functions/translateDeliveryNote';
import InvoiceUnit from '../../communicator/http/Invoices';

/**
 * 
 * @returns DeliveryNotes Overview Page
 */
const DeliveryNotes = () => {

    const [loading, setLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);

    const [deliveryNotes, setDeliveryNotes] = useState([]);
    const [filterType, setFilterType] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [filterdeliveryDate, setFilterdeliveryDate] = useState({
        from: new Date(),
        to: null
    });
    const [filterProducts, setFilterProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isInit, setIsInit] = useState(true);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [downloadMessageOpen, setDownloadMessageOpen] = useState(false);


    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [pdfData, setPdfData] = useState("");

    const perPage = 25;

    const productUnit = new ProductUnit();
    const deliveryNoteUnit = new DeliveryNoteUnit();

    const queryArgsCache = new Cache('deliveryNotes.queryArgs', true);

    /**
     * initially loads products
     */
    useEffect(() => {

        let queryArgs = queryArgsCache.get();

        if (queryArgs !== false) {
            setFilterStatus(queryArgs.statuses);

            if (queryArgs.delivery_date !== null && queryArgs.delivery_date !== "") {
                setFilterdeliveryDate({
                    from: new Date(queryArgs.delivery_date.from),
                    to: new Date(queryArgs.delivery_date.to),
                })
            }

            setPage(queryArgs.page);
        }

    }, []);


    /**
     * initially loads products
     */
    useEffect(() => {
        let queryArgs = queryArgsCache.get();
        if (page > 0 && (!isInit || queryArgs === false)) {
            setIsInit(false);
            load();
        }
    }, [page]);

    /**
     * initially loads products
     */
    useEffect(() => {
        setIsInit(false);
        setLoading(true);
        setPage(0);
        setTimeout(() => {
            setPage(1);
        }, 100)
    }, [filterType, filterProducts, filterStatus, filterdeliveryDate]);


    useEffect(() => {
        if (pdfLoading) {
            setTimeout(() => {
                setPdfLoading(false);
            }, 1000);
        }
    }, [pdfData]);

    /**
     * Loads deliveryNotes from API
     */
    const load = (cb = () => { }) => {

        setLoading(true);

        const skip = (page - 1) * perPage;

        let deliveryDate = null;
        if (filterdeliveryDate !== undefined && filterdeliveryDate !== null && filterdeliveryDate !== false && filterdeliveryDate.from !== null && filterdeliveryDate.to !== null) {
            deliveryDate = {
                from: filterdeliveryDate.from.toDateString(),
                to: filterdeliveryDate.to.toDateString()
            }
        }

        const body = {
            skip: skip,
            take: perPage,
            status: filterStatus,
            delivery_date: deliveryDate
        };

        deliveryNoteUnit.listDeliveryNotes(body, (res) => {

            wrlog("deliveryNotes", res);

            setLoading(false);
            setDeliveryNotes(res.results);
            setTotalPages(Math.ceil(res.total / perPage));
            cb();
            body.page = page;
            body.products = filterProducts;
            queryArgsCache.set(body);

        }, (err) => {
            wrlog("deliveryNoteserr", err);
            setLoading(false);
        })
    }

    const createInvoices = () => {

        const invoiceUnit = new InvoiceUnit();

        setLoading(true);

        const cbSuccess = (res) => {
            // setLoading(false);
            load()
            wrlog(res);
        }

        const cbError = (err) => {
            setLoading(false);
            wrlog(err);
        }

        let deliveryDate = null;
        if (filterdeliveryDate !== undefined && filterdeliveryDate !== null && filterdeliveryDate !== false && filterdeliveryDate.from !== null && filterdeliveryDate.to !== null) {
            deliveryDate = {
                from: filterdeliveryDate.from.toDateString(),
                to: filterdeliveryDate.to.toDateString()
            }
        }

        let body = {
            status: filterStatus,
            delivery_date: deliveryDate
        }

        invoiceUnit.createFromDeliveryNotesFilter(body, cbSuccess, cbError);

    }

    /**
     * receives the csv file
     * 
     */
    const downloadDeliveryNote = (deliveryNoteId = null) => {

        setLoading(true);
        deliveryNoteUnit.getPdf(deliveryNoteId, (res) => {

            const url = window.URL.createObjectURL(new Blob([Buffer.from(res.base64, 'base64')]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${res.title}.pdf`);
            document.body.appendChild(link);
            link.click();


            setLoading(false);
        }, (err) => {
            setLoading(false);
        })
    }

    return <>
        <TopBar title={"Lieferscheine"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <Row>
                        <Col md={4}>
                        </Col>
                        <Col md={2}>
                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>
                            {/* <div
                                className='button'
                                onClick={downloadDeliveryNotes}>
                                {pdfLoading ? 'PDFs werden generiert...' : 'Auswahl herunterladen'}
                            </div> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Status</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setFilterStatus(e.target.value === '' ? '' : e.target.value)
                                    }}
                                    value={filterStatus || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"sent"}>Gesendet</MenuItem>
                                    <MenuItem value={"charged"}>Verrechnet</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <DatePicker
                                onChange={(val) => {
                                    setFilterdeliveryDate({
                                        from: val[0],
                                        to: val[1]
                                    });
                                }}
                                value={filterdeliveryDate.from}
                                label={"Rechnungsdatum"}
                                startDate={filterdeliveryDate.from}
                                endDate={filterdeliveryDate.to}
                                selectsRange
                            />
                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>
                            <div className='button'
                                onClick={() => { createInvoices() }}>Rechnungen aus Auswahl erstellen</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>

            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Rechnungsdatum</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>E-Mail</TableCell>
                                <TableCell style={{ width: 180 }}>Status</TableCell>
                                <TableCell style={{ width: 50 }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                deliveryNotes.map((deliveryNote, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>
                                            {deliveryNote.number}
                                        </TableCell>
                                        <TableCell>
                                            {formatDbDate(deliveryNote.date)}
                                        </TableCell>
                                        <TableCell>
                                            {deliveryNote.customer.company}
                                        </TableCell>
                                        <TableCell>
                                            {deliveryNote.customer.email}
                                        </TableCell>
                                        <TableCell>
                                            <StatusLabel status={deliveryNote.status}>{translateDeliveryNoteStatus(deliveryNote.status)}</StatusLabel>
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                items={[{
                                                    label: "Zum Kunden",
                                                    to: `/customers/${deliveryNote.customer.id}`,
                                                    params: {
                                                        returnUrl: `/subscriptions/deliverynotes`, initQueryVars: {
                                                            skip: (page - 1) * perPage,
                                                            take: perPage,
                                                            delivery_date: filterdeliveryDate,
                                                            status: filterStatus,
                                                        }
                                                    }
                                                },
                                                {
                                                    label: "herunterladen",
                                                    onClick: () => downloadDeliveryNote(deliveryNote.id),
                                                }]}
                                            ></ToolTip>
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        pages={totalPages}
                        current={page}
                        pageChange={setPage}
                    />
                </Col>
            </Row>
        </Container >
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />

        <Message
            open={downloadMessageOpen}
            type={"success"}
            small={false}
            title={"PDFs werden erstellt"}
            body={"Du bekommst in den nächsten Minuten E-Mails mit Rechnungspaketen zugeschickt."}
            buttonText={"OK"}
            buttonAction={() => setDownloadMessageOpen(false)}
        />

        <Loading visible={loading} />
    </>

}

export default DeliveryNotes;
import Unit from './index';

export default class DeliveryNoteUnit extends Unit {

    listDeliveryNotes = (body, cbSuccess, cbError) => {
        this.post(`/deliverynote/list`, body, cbSuccess, cbError);
    }

    getPdf = (deliveryNoteId, cbSuccess, cbError) => {
        this.get(`/deliverynote/${deliveryNoteId}/pdf`, cbSuccess, cbError);
    }

}

import Unit from './index';

export default class BankingUnit extends Unit {

    listTransactions = (body, cbSuccess, cbError) => {
        this.post(`/banking/list`, body, cbSuccess, cbError);
    }

    updateTransaction = (body, cbSuccess, cbError) => {
        this.put(`/banking/transaction`, body, cbSuccess, cbError);
    }

    uploadTransactionFile = (body, cbSuccess, cbError) => {
        this.put(`/banking/transaction/file`, body, cbSuccess, cbError);
    }

    listBalances = (body, cbSuccess, cbError) => {
        this.post(`/banking/balance/list`, body, cbSuccess, cbError);
    }

    addBalance = (body, cbSuccess, cbError) => {
        this.post(`/banking/balance`, body, cbSuccess, cbError);
    }

    updateBalance = (body, cbSuccess, cbError) => {
        this.put(`/banking/balance`, body, cbSuccess, cbError);
    }

    deleteBalance = (body, cbSuccess, cbError) => {
        this.delete(`/banking/balance`, body, cbSuccess, cbError);
    }

    uploadFile = (id, file, cbSuccess, cbError) => {
        this.post(`/banking/balance/file`, { id: id, file: file }, cbSuccess, cbError);
    }

    assignTransactionToBalance = (body, cbSuccess, cbError) => {
        this.post(`/banking/balance/assign`, body, cbSuccess, cbError);
    }

    getAccountingZip = (month, cbSuccess, cbError) => {
        this.get(`/banking/accounting/monthly/${month}`, cbSuccess, cbError);
    }

}
import { Checkbox, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, FormControlLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import CustomerUnit from '../../../communicator/http/Customers';
import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import NotFound from '../../NotFound';
import { hasPermission } from '../../../helper/functions/auth';
import Card from '../../../elements/card';
import { formatDbDate } from '../../../helper/functions/formatDate';
import SubscriptionUnit from '../../../communicator/http/Subscriptions';
import Popup from '../../../elements/popup';
import { DateRangePicker } from 'react-date-range';
import { de } from 'date-fns/locale'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import StatusLabel from '../../../elements/statusLabel';
import wrlog from '../../../helper/functions/wrlog';

/**
 * 
 * @returns Customer Detail Page
 */
const SubscriptionSingle = (props) => {

    const location = useLocation();

    const params = useParams();

    const subscriptionId = params.id;

    const [loading, setLoading] = useState(subscriptionId !== 'new');
    const [deliveriesLoading, setDeliveriesLoading] = useState(true);

    const [deliveries, setDeliveries] = useState([]);

    const [customer, setCustomer] = useState({});
    const [subscription, setSubscription] = useState({});
    const [subscriptionEdit, setSubscriptionEdit] = useState(false);
    const [addingLogEntry, setAddingLogEntry] = useState(false);
    const [pausePopupOpen, setPausePopupOpen] = useState(false);

    const [logEntries, setLogEntries] = useState([]);

    const [editAddress, setEditAddress] = useState(false);

    const [pauseRange, setPauseRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [askDeleteMessageOpen, setAskDeleteMessageOpen] = useState(false);

    const [is404, setIs404] = useState(false);

    const { handleSubmit, setValue, control, formState: { errors }, getValues, register } = useForm();

    /**
     * Inititally check if customerId is "new" to check if customer is updated or new customer is created
     */
    useEffect(() => {
        /**
         * if customerId !== set set 404 = true
         */
        if (subscriptionId === undefined || subscriptionId === null || subscriptionId === "") {
            setIs404(true);
            return;
        }

        if (!hasPermission('subscription', ['can_create'])) {
            setIs404(true);
            return;
        }

        loadSubscription()
        loadDeliveries()

    }, [subscriptionId]);

    /**
     * Loads the subscriptions of the user
     * @param {Function} cb 
     */
    const loadSubscription = (cb = () => { }) => {
        const subscriptionUnit = new SubscriptionUnit();
        subscriptionUnit.getSubscription(subscriptionId, (subscription) => {
            setSubscription(subscription);

            // Set Address
            setValue('firstName', subscription.address.first_name)
            setValue('lastName', subscription.address.last_name)
            setValue('email', subscription.address.email)
            setValue('phone', subscription.address.phone)
            setValue('address1', subscription.address.address_1)
            setValue('streetNumber', subscription.address.street_number)
            setValue('address2', subscription.address.address_2)
            setValue('postcode', subscription.address.postcode)
            setValue('city', subscription.address.city)
            setValue('note', subscription.address.note)

            setLoading(false);
            cb();
        }, () => {
            setLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle("Unbekannter Fehler");
            setErrorMessageBody("Ein unbekannter Fehler ist aufgetreten bitte laden Sie die Seite neu. Sollte das Problem weiterhin bestehen wenden Sie sich an den Systemadministrator.");
        })
    }

    /**
     * Loads the subscriptions of the user
     * @param {Function} cb 
     */
    const loadDeliveries = (cb = () => { }) => {
        const subscriptionUnit = new SubscriptionUnit();
        subscriptionUnit.getDeliveries(subscriptionId, (deliveries) => {
            setDeliveries(deliveries);

            setDeliveriesLoading(false);
            cb();
        }, () => {
            setDeliveriesLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle("Unbekannter Fehler");
            setErrorMessageBody("Ein unbekannter Fehler ist aufgetreten bitte laden Sie die Seite neu. Sollte das Problem weiterhin bestehen wenden Sie sich an den Systemadministrator.");
        })
    }


    /**
     * Submit address to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const subscriptionUnit = new SubscriptionUnit();

        setLoading(true);

        const body = {
            id: subscriptionId,
            first_name: data.firstName,
            last_name: data.lastName,
            address_1: data.address1,
            street_number: data.streetNumber,
            address_2: data.address2,
            postcode: data.postcode,
            city: data.city,
            email: data.email,
            phone: data.phone,
            note: data.note
        };

        const cbError = (err) => {
            wrlog(err.response.data.exception);
            setLoading(false);
            setErrorMessageOpen(true);
            setErrorMessageType('error');
            setErrorMessageTitle('Unbekannter Fehler');
            setErrorMessageBody('Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.');
            setErrorMessageButtonText('OK');
        }

        // Success function for Update User
        const cbSuccessUpdate = (res) => {
            setErrorMessageOpen(true);
            setErrorMessageType('success');
            setErrorMessageTitle('Daten erfolgreich geändert');
            setErrorMessageBody('');
            setErrorMessageButtonText('OK');

            loadSubscription(() => setEditAddress(false));

        }

        subscriptionUnit.updateSubscription(body, cbSuccessUpdate, cbError);
    }


    /**
     * Delete current customer
     */
    const deleteSubscription = () => {
        const subscriptionUnit = new SubscriptionUnit();

        setLoading(true);
        setAskDeleteMessageOpen(false);

        const cbSuccess = (res) => {
            window.location.replace(`/subscriptions`);
        }

        const cbError = (err) => {
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        subscriptionUnit.deleteSubscription(subscriptionId, cbSuccess, cbError);
    }

    /**
     * Change the status of the subscription
     * 
     * @param string status
     * 
     * @return void
     */
    const changeStatus = (status, subscriptionId) => {
        const subscriptionUnit = new SubscriptionUnit();

        if (status === 'paused') {
            setPausePopupOpen(true);
        } else if (status === 'canceled') {
            subscriptionUnit.cancelSubscription(subscriptionId, (res) => {
                loadSubscription(() => {
                    setLoading(false);
                });
            }, (err) => {
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            })
        } else if (status === 'forceCanceled') {
            subscriptionUnit.forceCancelSubscription(subscriptionId, (res) => {
                loadSubscription(() => {
                    setLoading(false);
                });
            }, (err) => {
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            })
        } else {
            subscriptionUnit.activateSubscription(subscriptionId, (res) => {
                loadSubscription(() => {
                    setLoading(false);
                });
            }, (err) => {
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            })
        }

    }

    const setPause = () => {

        const subscriptionUnit = new SubscriptionUnit();
        const customerUnit = new CustomerUnit();

        setLoading(true);

        let body = {
            id: subscriptionId,
            start: pauseRange.startDate.toDateString(),
            end: pauseRange.endDate.toDateString()
        }

        const cbSuccess = (res) => {
            loadSubscription(() => {
                setPausePopupOpen(false)
            });
        }

        const cbError = (err) => {
            setLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        subscriptionUnit.pauseSubscription(body, cbSuccess, cbError);
    }

    const onChangeDate = (dates) => {
        setPauseRange(dates.selection)
    }

    const renderPausedDetails = (subscription) => {
        return <>
            Pausiert<br />
            Von: {formatDbDate(subscription.pauseStart)}<br />
            Bis: {formatDbDate(subscription.pauseEnd)}
        </>
    }

    const renderCanceledDetails = (subscription) => {
        return <>
            {
                <>Letzte Lieferung: {subscription.lastDeliveryDate !== undefined && subscription.lastDeliveryDate !== null ? formatDbDate(subscription.lastDeliveryDate) : 'Keine'}</>
            }
            <><br />Storniert am {formatDbDate(subscription.canceledAt)}</>
        </>
    }

    if (is404 === true) {
        return <NotFound />;
    }


    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to={location.state?.returnUrl || "/customers"}>zurück</Link><br /><br />
                        <h1>Abonnement bearbeiten</h1>
                        {hasPermission('customer', ['can_delete']) && <div
                            className='small_button'
                            style={{ color: 'red' }}
                            onClick={() => setAskDeleteMessageOpen(true)}>
                            Abonnement löschen
                        </div>}
                    </Col>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Col md={8}>
                        <Card>
                            {
                                editAddress ?
                                    <Container fluid style={{ padding: 0 }}>
                                        <form onSubmit={handleSubmit(doSubmit)}>
                                            <Row>
                                                <Col md={12}>
                                                    <h4>Lieferadresse bearbeiten</h4>
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormController
                                                        name={"firstName"}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                            <TextField
                                                                onChange={onChange}
                                                                value={value}
                                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                                error={errors.firstName}
                                                                id="standard-basic"
                                                                label={translate('firstname')}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <FormController
                                                        name={"lastName"}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                                error={errors.lastName}
                                                                id="standard-basic"
                                                                label={translate('lastname')}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormController
                                                        name={"email"}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                                error={errors.email}
                                                                id="standard-basic"
                                                                label={translate('email')}
                                                                type="email"
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <FormController
                                                        name={"phone"}
                                                        rules={{
                                                            required: false,
                                                        }}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                                error={errors.phone}
                                                                id="standard-basic"
                                                                label={translate('phone')}
                                                                type="phone"
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row style={{ paddingTop: 30 }}>
                                                <Col md={8}>
                                                    <FormController
                                                        name={"address1"}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                                error={errors.address1}
                                                                id="standard-basic"
                                                                label={translate('address')}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <FormController
                                                        name={"streetNumber"}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                                error={errors.streetNumber}
                                                                id="standard-basic"
                                                                label={"Hausnummer"}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FormController
                                                        name={"address2"}
                                                        rules={{
                                                            required: false,
                                                        }}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                                error={errors.address2}
                                                                id="standard-basic"
                                                                label={translate('address_additional')}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormController
                                                        name={"postcode"}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                                error={errors.postcode}
                                                                id="standard-basic"
                                                                label={translate('postcode')}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <FormController
                                                        name={"city"}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                            <TextField
                                                                value={value}
                                                                onChange={onChange}
                                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                                error={errors.city}
                                                                id="standard-basic"
                                                                label={translate('city')}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} style={{ paddingTop: 30 }}>
                                                    <FormController
                                                        name={"note"}
                                                        rules={{
                                                            required: false,
                                                        }}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                            <TextField
                                                                value={value}
                                                                multiline
                                                                onChange={onChange}
                                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                                error={errors.note}
                                                                id="standard-basic"
                                                                label={translate('note')}
                                                                type="note"
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <input
                                                type="submit"
                                                className={"button"}
                                                value={translate('save')}
                                            />
                                            <div style={{ top: 20, right: 30, position: 'absolute', display: 'inline-block', width: 'max-content' }}>
                                                <div
                                                    onClick={() => setEditAddress(false)}
                                                    style={{ color: 'var(--red-color)', display: 'inline-block', cursor: 'pointer' }}
                                                >
                                                    abbrechen
                                                </div>
                                                <input
                                                    type="submit"
                                                    style={{ color: 'var(--green-color)', display: 'inline-block', marginLeft: 15, padding: 0, cursor: 'pointer', background: 'none', border: 'none' }}
                                                    value={'speichern'}
                                                />
                                            </div>
                                        </form>
                                    </Container>
                                    :
                                    <Container fluid style={{ padding: 0 }}>
                                        <Row>
                                            <Col md={12}>
                                                <h4>Lieferadresse</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                {subscription?.address?.first_name} {subscription?.address?.last_name}<br />
                                                <br />
                                                {subscription?.address?.address_1} {subscription?.address?.street_number}<br />
                                                {subscription.address?.address_2 !== '' ? <>{subscription.address?.address_2}<br /></> : ''}
                                                {subscription?.address?.postcode} {subscription?.address?.city}<br />
                                                {subscription?.address?.country}
                                            </Col>
                                            <Col md={6}>
                                                {subscription?.address?.email}<br />
                                                {subscription?.address?.phone}<br /><br />
                                                {subscription?.address?.note}
                                            </Col>
                                        </Row>
                                        <div
                                            onClick={() => setEditAddress(true)}
                                            style={{ color: 'var(--green-color)', position: 'absolute', top: 20, right: 30, cursor: 'pointer' }}>
                                            bearbeiten
                                        </div>
                                    </Container>
                            }
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <h4>Status</h4>
                            {(hasPermission('customer', ['can_update']) || hasPermission('subscription', ['can_update'])) && subscription.status !== undefined ?
                                <Select
                                    className={`statusSelect ${subscription?.status}`}
                                    value={subscription?.status}
                                    onChange={e => changeStatus(e.target.value, subscriptionId)}
                                >
                                    <MenuItem value="active">Aktiv</MenuItem>
                                    <MenuItem value="paused">Pausiert</MenuItem>
                                    <MenuItem value="canceled">Storniert</MenuItem>
                                    <MenuItem value="forceCanceled">Sofort storniert</MenuItem>
                                </Select>
                                : <StatusLabel status={subscription?.status}>{subscription?.status}</StatusLabel>
                            }
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <Card style={{ marginTop: 25 }}>
                            <h4>Lieferungen</h4>
                            <table>
                                <tr>
                                    <td><strong></strong></td>
                                    <td><strong></strong></td>
                                </tr>
                                {deliveries.length < 1 ?
                                    <tr><td colSpan={2}>Keine Lieferungen vorhanden. Das kann daran liegen, dass dieses Abo vor Version 1.2 storniert wurde.</td></tr>
                                    : ''
                                }
                                {
                                    deliveries.map(delivery => {
                                        return <tr>
                                            <td><strong>{formatDbDate(delivery.deliveryDate)}</strong></td>
                                            <td>
                                                {delivery.type === 'last' && "Letzte Lieferung"}
                                                {delivery.status === 'paused' && "Pausiert"}
                                            </td>
                                        </tr>
                                    })
                                }
                            </table>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={{ marginTop: 25 }}>
                            <h4>Nächste Lieferungen</h4>
                            {subscription.nextDeliveryDate !== undefined && subscription.nextDeliveryDate !== null ? <>Nächste Lieferung: {formatDbDate(subscription.nextDeliveryDate)}<br /></> : ''}
                            {
                                subscription.status === 'active' && <></>
                            }
                            {
                                subscription.status === 'paused' && renderPausedDetails(subscription)
                            }
                            {
                                subscription.status === 'canceled' && renderCanceledDetails(subscription)
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Popup
                close={() => setPausePopupOpen(false)}
                open={pausePopupOpen}
                style={{ width: 372, paddingTop: 50 }}
            >
                <DateRangePicker
                    ranges={[pauseRange]}
                    onChange={onChangeDate}
                    minDate={new Date()}
                    locale={de}
                    staticRanges={[]}
                    inputRanges={[]}
                    renderStaticRangeLabel={{ hasCustomRendering: false }}
                />
                <div
                    className="button"
                    onClick={setPause}
                >Pausieren</div>
            </Popup>
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
                escapeAction={() => setErrorMessageOpen(false)}
            />

            <Message
                open={askDeleteMessageOpen}
                type={'success'}
                small={false}
                title={translate('sure_delete_title')}
                body={translate('sure_delete_body')}
                buttonText={translate('yes_delete')}
                buttonAction={() => deleteSubscription()}
                buttonTwoText={translate('cancel')}
                buttonTwoAction={() => setAskDeleteMessageOpen(false)}
                buttonTwoStyle={{ color: 'red' }}
            />

            <Loading visible={loading} />
        </>

    )

}

export default SubscriptionSingle;
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import StatisticsUnit from '../../communicator/http/Statistics';
import Loading from '../../helper/screens/Loading';
import wrlog from '../../helper/functions/wrlog';
import { hasPermission } from '../../helper/functions/auth';

// import "~react-vis/dist/style";
import DatePicker from '../../elements/datepicker';
import { Card } from '@material-ui/core';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, MarkSeries, Hint } from 'react-vis';
import moment from 'moment';
import { formatMoney } from '../../helper/functions/formatPrice';
import SalesKpi from './Elements/SalesKPI';
import CustomerGrowthKpi from './Elements/CustomerFlow';
import NewCustomersSalesOverview from './Elements/NewCustomersSalesOverview';

const Kpis = () => {

    let today = new Date();
    let monthStart = `${today.getFullYear()}-${(today.getMonth() - 3 < 10 ? `0${today.getMonth() - 3}` : today.getMonth() - 3)}-01`;
    let monthEnd = `${today.getFullYear()}-${(today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1)}-30`;

    wrlog(monthStart);
    wrlog(monthEnd);

    if (!hasPermission('admin', 'can_read')) {
        return <></>;
    }

    return <>
        <Container fluid>
        <Row>
                <Col md={4}>
                    <NewCustomersSalesOverview
                        start={monthStart}
                        end={monthEnd}
                    />
                </Col>
                <Col md={7}>
                </Col>
            </Row>
            <Row>
                <Col md={5}>
                    <CustomerGrowthKpi
                        start={monthStart}
                        end={monthEnd}
                    />
                </Col>
                <Col md={7}>
                    <SalesKpi
                        start={monthStart}
                        end={monthEnd}
                    />
                </Col>
            </Row>
        </Container>
    </>

}

export default Kpis;
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * 
 * @returns 404 Page
 */
const NotFound = () => {

    return (
        <>
            <div className="login_wrapper">
                <div className="login_container" style={{ background: 'transparent', boxShadow: 'none' }}>
                    <h1>404 Seite nicht gefunden</h1>
                    <strong>Die Seite, die Sie versuchen zu erreichen existiert nicht.</strong>
                    <br />
                    <br />
                    <br />
                    <Link className="button" to="/">zum Dashboard</Link>
                </div>

            </div>
        </>
    );
}

export default NotFound;
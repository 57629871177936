import { Checkbox, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, FormControlLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import ProductUnit from '../../../communicator/http/Products';
import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import AdminUnit from '../../../communicator/http/Admins';
import NotFound from '../../NotFound';
import { hasPermission } from '../../../helper/functions/auth';
import TextEditor from '../../../elements/texteditor';
import ImageInput from '../../../elements/imageInput';
import { formatMoney } from '../../../helper/functions/formatPrice';
import wrlog from '../../../helper/functions/wrlog';
import { isJsonString } from '../../../helper/functions/isJsonString';
import { formatPercent } from '../../../helper/functions/formatPercent';
import CouponsUnit from '../../../communicator/http/Coupons';
import DatePicker from '../../../elements/datepicker';
import { Autocomplete } from '@material-ui/lab';


/**
 * 
 * @returns Admin Product Detail Page
 */
const CouponSingle = () => {

    const params = useParams();

    const couponId = params.id;

    const [loading, setLoading] = useState(couponId !== 'new');
    const [products, setProducts] = useState([]);
    const [coupon, setCoupon] = useState({});

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [askDeleteMessageOpen, setAskDeleteMessageOpen] = useState(false);

    const [imageData, setImageData] = useState(null);

    const [is404, setIs404] = useState(false);
    const [selectedProductIds, setSelectedProductIds] = useState([]);

    const { handleSubmit, setValue, control, formState: { errors }, getValues } = useForm();

    /**
     * Inititally check if productId is "new" to check if product is updated or new product is created
     */
    useEffect(() => {

        const couponsUnit = new CouponsUnit();

        /**
         * if productId !== set set 404 = true
         */
        if (couponId === undefined || couponId === null || couponId === "") {
            setIs404(true);
            return;
        }

        /**
         * if productId !== new --> update product & load productData
         */
        if (couponId !== 'new') {

            // Check if product can update || delete

            if (!hasPermission('product', ['can_update', 'can_delete'])) {
                setIs404(true);
                return;
            }

            couponsUnit.getCoupon(couponId, (coupon) => {

                setLoading(false);

                setValue('code', coupon.code);
                setValue('amount', coupon.amount);
                setValue('amountType', coupon.amountType);
                setValue('emails', coupon.emails === null || coupon.emails === '' ? coupon.emails.join(',') : '');
                setValue('customerIds', coupon.customerIds === null || coupon.customerIds === '' ? coupon.customerIds.join(',') : '');
                setValue('productIds', coupon.productIds === null || coupon.productIds === '' ? coupon.productIds : '');
                setValue('validFrom', coupon.validFrom === null || coupon.validFrom === '' ? null : new Date(coupon.validFrom));
                setValue('validTo', coupon.validTo === null || coupon.validTo === '' ? null : new Date(coupon.validTo));
                setValue('onlyNewCustomers', coupon.onlyNewCustomers);
                setValue('minCartVolume', coupon.minCartVolume);
                setValue('maxUsageCoupon', coupon.maxUsageCoupon);
                setValue('maxUsageCouponPerCustomer', coupon.maxUsageCouponPerCustomer);

                setCoupon(coupon);

            }, () => {
                setIs404(true);
            })
        } else {
            if (!hasPermission('product', ['can_create'])) {
                setIs404(true);
                return;
            }

            setValue('code', '');
            setValue('amount', '');
            setValue('amountType', 'PERCENT');
            setValue('emails', '');
            setValue('customerIds', '');
            setValue('productIds', []);
            setValue('validFrom', '');
            setValue('validTo', '');
            setValue('onlyNewCustomers', false);
            setValue('minCartVolume', '');
            setValue('maxUsageCoupon', '');
            setValue('maxUsageCouponPerCustomer', '');
        }


    }, [couponId]);

    useEffect(() => {
        const productUnit = new ProductUnit();
        productUnit.listProducts({}, (response) => {

            setProducts(response.results);
        }, () => { })

    }, [])

    useEffect(() => {

        if (coupon.productIds === undefined || products.length < 1) {
            return;
        }

        let selectedProductIds = [];
        if (coupon.productIds !== undefined && coupon.productIds.length > 0 !== undefined) {
            selectedProductIds = products.filter(product => { return coupon.productIds.includes(product.id) })
        }

        setSelectedProductIds(selectedProductIds);

    }, [coupon, products])

    /**
     * Submit productData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const couponsUnit = new CouponsUnit();

        setLoading(true);

        let _productIds = selectedProductIds.map(p => p.id);

        let body = {
            code: data.code,
            amount: data.amount,
            amount_type: data.amountType,
            emails: data.emails,
            customer_ids: data.customerIds !== '' ? JSON.stringify(data.customerIds) : '',
            product_ids: _productIds,
            valid_from: data.validFrom,
            valid_to: data.validTo,
            only_new_customers: data.onlyNewCustomers,
            min_cart_volume: data.minCartVolume,
            max_usage: data.maxUsageCoupon,
            max_usage_per_customer: data.maxUsageCouponPerCustomer,
        }

        // Success function for Create Product
        const cbSuccess = (res) => {
            setLoading(false);
            window.location.replace(`/coupons/${res.id}`); //Replace Location to new created product & reload page
        }

        // Success function for Update Product
        const cbSuccessUpdate = (res) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
            setErrorMessageSmall(true);
            setErrorMessageTitle("Gutschein gespeichert.");
            setErrorMessageBody("");

            setCoupon(data);
        }

        const cbError = (err) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        if (couponId === 'new') {
            couponsUnit.createCoupon(body, cbSuccess, cbError);
        } else {
            body.id = couponId;
            couponsUnit.updateCoupon(body, cbSuccessUpdate, cbError);
        }
    }

    /**
     * Delete current product
     */
    const deleteProduct = () => {
        const productUnit = new ProductUnit();

        setLoading(true);
        setAskDeleteMessageOpen(false);

        const cbSuccess = (res) => {
            if (res[0] === 200) {
                window.location.replace(`/products`);
            }
        }

        const cbError = (err) => {
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        productUnit.deleteProduct(couponId, cbSuccess, cbError);
    }

    /**
     * @param int weekday of delivery
     */
    const handleDeliveryDaysChange = (weekday) => {

        let weekdays = [...getValues('deliveryDays')];

        if (weekdays.includes(weekday)) {
            const position = weekdays.indexOf(weekday);
            weekdays.splice(position, 1);
        } else {
            weekdays.push(weekday);
        }

        wrlog(weekdays);
        setValue('deliveryDays', weekdays);

    }

    if (is404 === true) {
        return <NotFound />;
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to="/coupons">zurück</Link><br /><br />
                        <h1>{params.id !== "new" ? <>{coupon.code} {translate('edit')}</> : "Gutschein erstellen"} </h1>
                        {params.id !== "new" && hasPermission('customers', ['can_delete']) && <div
                            className='small_button'
                            style={{ color: 'red' }}
                            onClick={() => setAskDeleteMessageOpen(true)}>
                            Gutschein löschen
                        </div>}
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <Row style={{ marginTop: 50 }}>
                        <Col md={7}>
                            <FormController
                                name={"code"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.code}
                                        id="standard-basic"
                                        label={'Code'}
                                    />
                                )}
                            />
                            <Container fluid style={{ padding: 0 }}>
                                <Row style={{ marginTop: 50 }}>
                                    <Col md={7}>
                                        <FormController
                                            name={"amount"}
                                            rules={{
                                                required: true,
                                            }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                <TextField
                                                    onChange={onChange}
                                                    value={value}
                                                    className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                    error={errors.amount}
                                                    id="standard-basic"
                                                    label={'Summe'}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <FormController
                                            name={"amountType"}
                                            rules={{
                                                required: true,
                                            }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                value !== null && value !== undefined && value !== '' && <FormControl
                                                    className="selectFormControl"
                                                >
                                                    <InputLabel id="type">Typ</InputLabel>
                                                    <Select
                                                        onChange={onChange}
                                                        value={value}
                                                    >
                                                        <MenuItem value={"PERCENT"}>Prozent</MenuItem>
                                                        <MenuItem value={"EUR"}>Euro</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Container>

                            <FormController
                                name={"emails"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.emails}
                                        id="standard-basic"
                                        label={'E-mails'}
                                        placeholder="Alle E-Mail-Adressen"
                                    />
                                )}
                            />
                            <FormController
                                name={"customerIds"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.customerIds}
                                        placeholder="Alle Kunden"
                                        id="standard-basic"
                                        label={'Kunden-Ids'}
                                    />
                                )}
                            />
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={products}
                                getOptionLabel={(option) => `${option.title} - ${option.sku}`}
                                disabled={products.length < 1}
                                value={selectedProductIds}
                                onChange={(e, v) => {
                                    setSelectedProductIds(v)
                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        disabled={products.length < 1}
                                        label={products.length < 1 ? "Produkte werden geladen..." : "Produkte"}
                                        className="filled"
                                        placeholder="Alle Produkte"
                                    />
                                )}
                            />
                        </Col>
                        <Col md={5}>
                            <FormController
                                name={"validFrom"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <DatePicker
                                        onChange={onChange}
                                        value={value}
                                        label={"Gültig ab"}
                                        date={value}
                                    />
                                )}
                            />
                            <FormController
                                name={"validTo"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <DatePicker
                                        onChange={onChange}
                                        value={value}
                                        label={"Gültig bis"}
                                        date={value}
                                    />
                                )}
                            />

                            <br />
                            <hr />

                            <FormController
                                name={"onlyNewCustomers"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <FormControlLabel
                                        name={"onlyNewCustomers"}
                                        label={'Nur neue Kunden'} control={
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            />
                                        } />
                                )}
                            />

                            <br />
                            <br />

                            <FormController
                                name={"minCartVolume"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.minCartVolume}
                                        id="standard-basic"
                                        placeholder='Kein Mindesbetrag'
                                        label={"Mindestbestellwert"}
                                    />
                                )}
                            />

                            <FormController
                                name={"maxUsageCoupon"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.maxUsageCoupon}
                                        id="standard-basic"
                                        placeholder='Keine Einschränkung'
                                        label={"Nutzungslimit pro Gutschein"}
                                    />
                                )}
                            />

                            <FormController
                                name={"maxUsageCouponPerCustomer"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.maxUsageCouponPerCustomer}
                                        id="standard-basic"
                                        placeholder='Keine Einschränkung'
                                        label={"Nutzungslimit pro Kunde"}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 100 }}>
                        <Col md={5}>
                            {
                                ((hasPermission('product', ['can_update']) && params.id !== "new") || (hasPermission('product', ['can_create']) && params.id === "new")) &&
                                <input type="submit" style={{ width: '200px', position: 'fixed', bottom: 20, right: 20 }} className="button" value={params.id !== "new" ? translate('save') : translate('add')} />
                            }
                        </Col>
                        <Col md={7}>
                        </Col>
                    </Row>
                </form>
            </Container >
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />

            <Message
                open={askDeleteMessageOpen}
                type={'success'}
                small={false}
                title={translate('sure_delete_title')}
                body={translate('sure_delete_body')}
                buttonText={translate('yes_delete')}
                buttonAction={() => deleteProduct()}
                buttonTwoText={translate('cancel')}
                buttonTwoAction={() => setAskDeleteMessageOpen(false)}
                buttonTwoStyle={{ color: 'red' }}
            />

            <Loading visible={loading} />
        </>

    )

}

export default CouponSingle;
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Message from '../../../elements/message';
import Loading from '../../../helper/screens/Loading';
import TopBar from '../../../elements/topbar';
import Pagination from '../../../elements/pagination';
import wrlog from '../../../helper/functions/wrlog';
import DatePicker from '../../../elements/datepicker';
import DeliveryUnit from '../../../communicator/http/Delivery';
import { formatDbDate } from '../../../helper/functions/formatDate';

/**
 * 
 * @returns Subscriptions Overview Page
 */
const SubscriptionsProducts = () => {

    const [loading, setLoading] = useState(false);
    const [csvLoading, setCsvLoading] = useState(false);

    const [products, setProducts] = useState([]);
    const [filterDeliveryDate, setFilterDeliveryDate] = useState(null);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const deliveryUnit = new DeliveryUnit();


    /**
     * Loads subscriptions from API
     */
    const load = (deliveryDate) => {

        try {
            setLoading(true);

            deliveryUnit.getProducts(`${deliveryDate.getFullYear()}-${deliveryDate.getMonth() + 1}-${deliveryDate.getDate()}`, (res) => {
                setLoading(false);
                setProducts(res);
            }, (err) => {
                setLoading(false);
            })
        } catch {
            setProducts([]);
            setLoading(false);
        }
    }

    /**
     * Listener for dateChanges
     * @param {date} dates 
     */
    const onChangeDate = (date) => {
        load(date);
        setFilterDeliveryDate(date)
    }

    return <>
        <TopBar title={"Abonnements"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <DatePicker
                        onChange={onChangeDate}
                        value={filterDeliveryDate}
                        label={"Versanddatum"}
                        id={"filterDeliveryDate"}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    {products.length > 1 ? <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Produkt</TableCell>
                                <TableCell>Anzahl</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                products.map((product, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>
                                            {product.title} ({product.sku})
                                        </TableCell>
                                        <TableCell>
                                            {product.count}
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                        :
                        <>
                            <p>Bitte wähle ein Versanddatum aus, um die Einkaufsliste zu sehen</p>
                        </>
                    }
                </Col>
            </Row>
        </Container>
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />

        <Loading visible={loading} />
    </>

}

export default SubscriptionsProducts;
import Unit from './index';

export default class AdminUnit extends Unit {

    listAdmins = (cbSuccess, cbError) => {
        this.get('/admins', cbSuccess, cbError);
    }

    getAdmin = (adminId, cbSuccess, cbError) => {
        this.get(`/admins/${adminId}`, cbSuccess, cbError);
    }

    createAdmin = (body, cbSuccess, cbError) => {
        this.post('/admins', body, cbSuccess, cbError);
    }

    updateAdmin = (body, cbSuccess, cbError) => {
        this.put('/admins', body, cbSuccess, cbError);
    }

    deleteAdmin = (adminId, cbSuccess, cbError) => {
        this.delete(`/admins`, { id: adminId }, cbSuccess, cbError);
    }


    loadContracts = (adminId, cbSuccess, cbError) => {
        this.post('/employee/contract/list', { admin_id: adminId }, cbSuccess, cbError);
    }

    createContract = (body, cbSuccess, cbError) => {
        this.post('/employee/contract', body, cbSuccess, cbError);
    }

    updateContract = (body, cbSuccess, cbError) => {
        this.put('/employee/contract', body, cbSuccess, cbError);
    }

}




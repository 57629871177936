import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import StatisticsUnit from '../../../communicator/http/Statistics';
import Loading from '../../../helper/screens/Loading';
import wrlog from '../../../helper/functions/wrlog';
import { hasPermission } from '../../../helper/functions/auth';

// import "~react-vis/dist/style";
import DatePicker from '../../../elements/datepicker';
import { Card } from '@material-ui/core';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, MarkSeries, Hint } from 'react-vis';
import moment from 'moment';
import { formatMoney } from '../../../helper/functions/formatPrice';

const SalesKpi = ({ start, end }) => {

    let monthStart = start;
    let monthEnd = end;

    const [loading, setLoading] = useState(true);
    const [filterSalesDate, setFilterSalesDate] = useState({
        from: new Date(monthStart),
        to: new Date(monthEnd)
    });

    const [sales, setSales] = useState([]);
    const [salesMax, setSalesMax] = useState(10000);
    const [selectedSalesHint, setSelectedSalesHint] = useState('');
    const [salesChartWidth, setSalesChartWidth] = useState(400);
    const salesContainerRef = useRef();


    useEffect(() => {

        if (filterSalesDate.from === null || filterSalesDate.to === null) {
            return;
        }

        setLoading(true);
        const statisticsUnit = new StatisticsUnit();
        statisticsUnit.getSales(moment(filterSalesDate.from).format('yyyy-MM-DD'), moment(filterSalesDate.to).format('yyyy-MM-DD'), (res) => {

            let max = res[0].total;

            let processed = res.map((sale, key) => {

                if (max < sale.total) {
                    max = sale.total;
                }

                return {
                    x: key,
                    y: sale.total.toFixed(2),
                    total: sale.total.toFixed(2),
                    week: sale.week,
                    scale: sale.scale,
                    month: sale.month
                }
            })

            max = Math.floor((max + 10000) / 10000) * 10000;

            setSalesMax(max);

            setSales(processed);
            setLoading(false);

        }, () => { })
    }, [filterSalesDate])

    useEffect(() => {
        const handleResize = () => {
            if (salesContainerRef.current !== undefined) {
                setSalesChartWidth(salesContainerRef.current.offsetWidth - 50)
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);

    }, [])

    if (!hasPermission('admin', 'can_read')) {
        return <></>;
    }

    return <>
        <DatePicker
            onChange={(val) => {
                setFilterSalesDate({
                    from: val[0],
                    to: val[1]
                });
            }}
            value={filterSalesDate.from}
            label={"Datum"}
            startDate={filterSalesDate.from}
            endDate={filterSalesDate.to}
            className={loading ? 'disabled' : undefined}
            selectsRange
        />
        <Card style={{ marginTop: 0 }} ref={salesContainerRef}>
            {
                loading ?
                    <Loading visible={loading} relative containerStyle={{ height: 340 }} />
                    :
                    <XYPlot
                        width={salesChartWidth}
                        height={340}
                        yDomain={[0, salesMax > 0 ? salesMax : 10]}
                    >
                        <HorizontalGridLines />
                        <LineSeries
                            curve={'curveMonotoneX'}
                            stroke={'#AEDB82'}
                            strokeWidth={4}
                            style={{}}
                            data={sales}
                        />
                        <XAxis
                            tickValues={sales.map((s, k) => k)}
                            tickFormat={v => { wrlog(sales[v]); return (sales[0].scale === 'weeks' ? sales[v].week : sales[v].month) }}
                            title={sales.length > 0 && sales[0].scale === 'weeks' ? 'Kalenderwoche' : 'Monat'}
                        />
                        <YAxis
                            tickFormat={v => v / 1000 + 'K'}
                            title={'Umsatz'}
                        />
                        {
                            sales.map(value => selectedSalesHint === value.week && <Hint
                                align={{ vertical: 'bottom', horizontal: 'right' }}
                                value={value}
                                style={{
                                    fontSize: 14,
                                    text: {
                                        display: 'none'
                                    },
                                    value: {
                                        color: 'red'
                                    }
                                }}
                            >
                                <div
                                    style={{
                                        padding: 5,
                                        borderRadius: 10,
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        color: 'black',
                                        fontWeight: 900,
                                        boxShadow: '0px 0px 20px rgba(0,0,0,0.08)',
                                        transform: 'translate(-50%, 10px)',
                                        textAlign: 'center'
                                    }}>
                                    {value.scale === 'weeks' ? `KW${value.week}` : value.month} <br />
                                    {formatMoney(value.total)}
                                </div>
                            </Hint>)
                        }
                        <MarkSeries
                            className="mark-series-example"
                            sizeRange={[5, 15]}
                            fill={'#AEDB82'}
                            color={'#AEDB82'}
                            stroke={'white'}
                            strokeWidth={3}
                            size={7}
                            onValueMouseOver={(datapoint, event) => {
                                setSelectedSalesHint(datapoint.week)
                            }}
                            onValueMouseOut={(datapoint, event) => {
                                setSelectedSalesHint('')
                            }}
                            data={sales}
                        />
                    </XYPlot>
            }

        </Card>
    </>

}

export default SalesKpi;
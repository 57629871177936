import { Checkbox, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Message from '../../../elements/message';
import Loading from '../../../helper/screens/Loading';
import TopBar from '../../../elements/topbar';
import { translate } from '../../../helper/multilingual';
import { getUserToken, hasPermission } from '../../../helper/functions/auth';
import ProductUnit from '../../../communicator/http/Products';
import BankingUnit from '../../../communicator/http/Banking';
import Pagination from '../../../elements/pagination';
import wrlog from '../../../helper/functions/wrlog';
import { formatDbDate } from '../../../helper/functions/formatDate';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../../elements/statusLabel';
import ToolTip from '../../../elements/tooltip';
import Cache from '../../../communicator/local';
import DatePicker from '../../../elements/datepicker';
import { formatMoney } from '../../../helper/functions/formatPrice';
import { translateBalanceStatus } from '../../../helper/functions/translateBalance';
import Popup from '../../../elements/popup';
import BalanceDetail from './balanceDetail';
import Transactions from '../Transactions';

// Verbindlichkeiten
/**
 * 
 * @returns Balances Overview Page
 */
const Balances = ({ type }) => {

    const [loading, setLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);

    const [balances, setBalances] = useState([]);
    const [searchNameTerm, setSearchNameTerm] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [paidStatus, setPaidStatus] = useState(null);
    const [bookedStatus, setBookedStatus] = useState(null);
    const [sum, setSum] = useState('');

    const [filterBalanceDate, setfilterBalanceDate] = useState({
        from: new Date(),
        to: null
    });

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isInit, setIsInit] = useState(true);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [downloadMessageOpen, setDownloadMessageOpen] = useState(false);


    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [assignPopupOpen, setAssignPopupOpen] = useState(false);
    const [openDetailPopup, setOpenDetailPopup] = useState(false);
    const [selectedBalance, setSelectedBalance] = useState(null);


    const [pdfData, setPdfData] = useState("");

    const perPage = 25;

    const bankingUnit = new BankingUnit();

    const queryArgsCache = new Cache(`balance_${type}.queryArgs`, true);

    /**
     * initially loads products
     */
    useEffect(() => {

        let queryArgs = queryArgsCache.get();

        if (queryArgs !== false) {
            setSearchNameTerm(queryArgs.search);
            setFilterStatus(queryArgs.status);

            if (queryArgs.date !== null && queryArgs.date !== "") {
                setfilterBalanceDate({
                    from: new Date(queryArgs.date.from),
                    to: new Date(queryArgs.date.to),
                })
            }

            setPage(queryArgs.page);
        }

    }, []);

    /**
     * initially loads products
     */
    useEffect(() => {
        let queryArgs = queryArgsCache.get();
        if (page > 0 && (!isInit || queryArgs === false)) {
            setIsInit(false);
            load();
        }
    }, [page]);

    useEffect(() => {
        setIsInit(false);
        setLoading(true);
        setPage(0);
        setTimeout(() => {
            setPage(1);
        }, 100)
    }, [type, filterStatus, filterBalanceDate, paidStatus, bookedStatus]);


    useEffect(() => {
        if (pdfLoading) {
            setTimeout(() => {
                setPdfLoading(false);
            }, 1000);
        }
    }, [pdfData]);

    /**
    * loads products by search result
    * @param {*} e 
    */
    const doSearch = (e) => {
        e.preventDefault();
        load();
    }


    /**
     * Loads balances from API
     */
    const load = (cb = () => { }) => {

        setLoading(true);

        const skip = (page - 1) * perPage;

        let bookingDate = "";
        if (filterBalanceDate !== undefined && filterBalanceDate !== null && filterBalanceDate !== false && filterBalanceDate.from !== null && filterBalanceDate.to !== null) {
            bookingDate = {
                from: filterBalanceDate.from.toDateString(),
                to: filterBalanceDate.to.toDateString()
            }
        }

        const body = {
            skip: skip,
            take: perPage,
            search: searchNameTerm,
            status: filterStatus,
            paid: paidStatus,
            booked: bookedStatus,
            type: type,
            date: bookingDate
        };

        bankingUnit.listBalances(body, (res) => {

            setLoading(false);
            setBalances(res.results);
            setTotalPages(Math.ceil(res.total / perPage));
            setSum(res.sum)
            cb();

            body.page = page;
            body.type = undefined;

            queryArgsCache.set(body);

        }, (err) => {
            setLoading(false);
        })

    }

    const setCheckbox = (id, key, checked) => {
        let index = balances.findIndex(e => e.id === id);

        let _balances = [...balances];
        _balances[index][key] = checked;

        setBalances(_balances);

        let body = {
            id: id
        }

        body[key] = checked;

        const bankingUnit = new BankingUnit();
        bankingUnit.updateBalance(body)

    }

    const changeStatus = (id, status) => {
        let index = balances.findIndex(e => e.id === id);

        let _balances = [...balances];
        _balances[index]['status'] = status;

        setBalances(_balances);

        let body = {
            id: id,
            status: status
        }

        const bankingUnit = new BankingUnit();
        bankingUnit.updateBalance(body)
    }

    const assignToBalance = (transactionId) => {
        const bankingUnit = new BankingUnit();
        bankingUnit.assignTransactionToBalance({ transaction_id: transactionId, balance_id: selectedBalance.id }, () => {
            load();
            setAssignPopupOpen(false);
        })
    }

    return <>
        <TopBar title={type === 'liability' ? 'Verbindlichkeiten' : 'Forderungen'} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <Row>
                        <Col md={4}>
                            <form onSubmit={doSearch}>
                                <TextField id="standard-basic" className="filled" value={searchNameTerm} label="Durchsuchen" onChange={value => setSearchNameTerm(value.target.value)} /><br />
                            </form>
                        </Col>
                        <Col md={2}>
                            <div
                                onClick={doSearch}
                                style={{ paddingTop: 17, paddingBottom: 18 }}
                                className={`button ${searchNameTerm === '' ? 'disabled' : ''}`}>
                                suchen
                            </div>
                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>
                            <div
                                onClick={() => {
                                    setOpenDetailPopup(true);
                                    setSelectedBalance(null);
                                }}
                                style={{ paddingTop: 17, paddingBottom: 18 }}
                                className={`button`}>
                                Erstellen
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Status</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setFilterStatus(e.target.value === '' ? '' : e.target.value)
                                    }}
                                    value={filterStatus || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"created"}>Erstellt</MenuItem>
                                    <MenuItem value={"needs_checking"}>Braucht Überprüfung</MenuItem>
                                    <MenuItem value={"in_progress"}>In Bearbeitung</MenuItem>
                                    <MenuItem value={"factoring_to_send"}>Factoring ausstehend</MenuItem>
                                    <MenuItem value={"factoring_sent"}>An Factoring gesendet</MenuItem>
                                    <MenuItem value={"done"}>Abgeschlossen</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Verbucht</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setBookedStatus(e.target.value === '' ? '' : e.target.value)
                                    }}
                                    value={bookedStatus || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"true"}>Verbucht</MenuItem>
                                    <MenuItem value={"false"}>Nicht verbucht</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Gezahlt</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setPaidStatus(e.target.value === '' ? '' : e.target.value)
                                    }}
                                    value={paidStatus || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"true"}>Gezahlt</MenuItem>
                                    <MenuItem value={"false"}>Nicht gezahlt</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <DatePicker
                                onChange={(val) => {
                                    setfilterBalanceDate({
                                        from: val[0],
                                        to: val[1]
                                    });
                                }}
                                value={filterBalanceDate.from}
                                label={"Buchungsdatum"}
                                startDate={filterBalanceDate.from}
                                endDate={filterBalanceDate.to}
                                selectsRange
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <strong>Summe: {formatMoney(sum)}</strong>
                </Col>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Datum</TableCell>
                                <TableCell>Betrag</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>IBAN / BBAN</TableCell>
                                <TableCell style={{ width: 250 }}>Status</TableCell>
                                <TableCell style={{ width: 50 }}>Verbucht</TableCell>
                                <TableCell style={{ width: 50 }}>Gezahlt</TableCell>
                                <TableCell>Zahlungsreferenz</TableCell>
                                <TableCell style={{ width: 50 }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                balances.map((balance, key) => {

                                    let tooltip = [];

                                    tooltip.push({
                                        label: "bearbeiten",
                                        onClick: () => {
                                            setSelectedBalance({ ...balance })
                                            setOpenDetailPopup(true);
                                        },
                                    })

                                    if (!balance.connectedToTransaction) {
                                        tooltip.push({
                                            label: "Transaktion auswählen",
                                            onClick: () => {
                                                setSelectedBalance({ ...balance })
                                                setAssignPopupOpen(true);
                                            },
                                        })
                                    }

                                    // if (balance.connected_invoice !== null) {
                                    //     if (balance.connected_invoice_type === 'afreshed_invoice') {
                                    //         tooltip.push({
                                    //             label: "Rechnung anzeigen",
                                    //             onClick: () => {
                                    //                 setOpenDetailPopup(true);
                                    //                 setSelectedBalance({ ...balance })
                                    //             },
                                    //         })
                                    //     }
                                    // }

                                    return <TableRow key={key}>
                                        <TableCell>
                                            {formatDbDate(balance.invoice.date)}
                                        </TableCell>
                                        <TableCell>
                                            {formatMoney(balance.invoice.grossAmount)}
                                        </TableCell>
                                        <TableCell>
                                            {balance.account.name}
                                        </TableCell>
                                        <TableCell>
                                            {balance.account.iban || balance.account.bban}
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                className={`statusSelect ${balance.status}`}
                                                value={balance.status}
                                                onChange={e => changeStatus(balance.id, e.target.value)}
                                                style={{ width: 200 }}
                                            >
                                                <MenuItem value="created">Erstellt</MenuItem>
                                                <MenuItem value="needs_checking">Braucht Überprüfung</MenuItem>
                                                <MenuItem value="in_progress">In Bearbeitung</MenuItem>
                                                <MenuItem value={"factoring_to_send"}>Factoring ausstehend</MenuItem>
                                                <MenuItem value={"factoring_sent"}>An Factoring gesendet</MenuItem>
                                                <MenuItem value="done">Abgeschlossen</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={balance.booked}
                                                onChange={(v) => setCheckbox(balance.id, 'booked', v.target.checked)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={balance.paid}
                                                onChange={(v) => setCheckbox(balance.id, 'paid', v.target.checked)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {balance.reference}
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                items={tooltip}
                                            ></ToolTip>
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        pages={totalPages}
                        current={page}
                        pageChange={setPage}
                    />
                </Col>
            </Row>
        </Container>
        <Popup
            style={{ width: 'calc(100% - 100px)', maxWidth: '100%' }}
            close={() => setOpenDetailPopup(false)}
            open={openDetailPopup}
        >
            <BalanceDetail
                setShow={setOpenDetailPopup}
                reload={load}
                type={type}
                balance={selectedBalance}
            />
        </Popup>
        <Popup
            style={{ width: 'calc(100% - 100px)', maxWidth: '100%' }}
            close={() => setAssignPopupOpen(false)}
            open={assignPopupOpen}
        >
            <Transactions
                assignToBalance={transactionId => assignToBalance(transactionId)}
                type={type === 'liability' ? 'out' : 'in'}
                status={'needs_checking'}
            />
        </Popup>
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />

        <Message
            open={downloadMessageOpen}
            type={"success"}
            small={false}
            title={"PDFs werden erstellt"}
            body={"Du bekommst in den nächsten Minuten E-Mails mit Rechnungspaketen zugeschickt."}
            buttonText={"OK"}
            buttonAction={() => setDownloadMessageOpen(false)}
        />

        <Loading visible={loading} />
    </>

}

export default Balances;
import { TextField } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { translate } from '../../../helper/multilingual';
import styles from '../styles.module.scss';

const Login = ({ login }) => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const doLogin = (data) => {

        data = { email: 'lukas.greul@me.com', password: 'password' }
        login(data);
    }

    return <div
        className={`${styles.card}`}
    >
        <div className={styles.title}>Kundenkonto</div>
        <form onSubmit={handleSubmit(doLogin)}>
            <TextField
                {...register('email', { required: true })}
                error={errors.email}
                id="standard-basic"
                label={translate('email')}
                type="email"
            />
            <TextField
                {...register('password', { required: true })}
                error={errors.password}
                id="standard-basic"
                label={translate('password')}
                type="password"
            />
            <input type="submit" style={{ width: '100%' }} className="button" value={translate('signin')} />
        </form>
    </div>
}

export default Login;
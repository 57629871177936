import { Card, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StatisticsUnit from '../../communicator/http/Statistics';
import ToolTip from '../../elements/tooltip';
import Loading from '../../helper/screens/Loading';
import { translate } from '../../helper/multilingual';
import TopBar from '../../elements/topbar';
import wrlog from '../../helper/functions/wrlog';
import Cache from '../../communicator/local';
import { hasPermission } from '../../helper/functions/auth';
import CustomerGrowthKpi from '../Kpis/Elements/CustomerFlow';
import SalesKpi from '../Kpis/Elements/SalesKPI';
import NewCustomersSalesOverview from '../Kpis/Elements/NewCustomersSalesOverview';


const Dashboard = () => {

    let today = new Date();
    let monthStart = `${today.getFullYear()}-${(today.getMonth() < 10 ? `0${today.getMonth()}` : today.getMonth())}-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;
    if(today.getMonth() === 2 && today.getDate() > 28){
        monthStart = `${today.getFullYear()}-${(today.getMonth() < 10 ? `0${today.getMonth()}` : today.getMonth())}-28`;
    }
    if (today.getMonth() === 0) {
        monthStart = `${today.getFullYear() - 1}-12-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;
    }

    let monthEnd = `${today.getFullYear()}-${(today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1)}-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;

    const [productsCount, setProductsCount] = useState(0);
    const [customersCount, setCustomersCount] = useState(0);
    const [subscriptionsCount, setSubscriptionsCount] = useState({
        total: 0,
        active: 0,
        paused: 0,
        canceled: 0,
    });


    console.log(monthStart, monthEnd)


    useEffect(() => {
        const statisticsUnit = new StatisticsUnit();

        statisticsUnit.products((res) => setProductsCount(res.products), () => { })
        statisticsUnit.customers((res) => setCustomersCount(res.customers), () => { })
        statisticsUnit.subscriptions((res) => setSubscriptionsCount(res), () => { })

    }, [])

    const queryArgsCache = new Cache('subscription.queryArgs', true);

    const setAllSubscriptionCache = () => {
        queryArgsCache.set({
            skip: 0, take: 25, search: "", type: "", postcodes: [], product_statuses: [], products: [], delivery_date: "", page: 1
        });
    }

    const setActiveSubscriptionCache = () => {
        queryArgsCache.set({
            skip: 0, take: 25, search: "", type: "", postcodes: [], product_statuses: ["active"], products: [], delivery_date: "", page: 1
        });
    }

    const setPausedSubscriptionCache = () => {
        queryArgsCache.set({
            skip: 0, take: 25, search: "", type: "", postcodes: [], product_statuses: ["paused"], products: [], delivery_date: "", page: 1
        });
    }

    const setCanceledSubscriptionCache = () => {
        queryArgsCache.set({
            skip: 0, take: 25, search: "", type: "", postcodes: [], product_statuses: ["canceled"], products: [], delivery_date: "", page: 1
        });
    }

    if (!hasPermission('admin', 'can_read')) {
        return <></>;
    }

    return <>
        <Container fluid style={{}}>
            <Row>
                <Col md={4}>
                    <NewCustomersSalesOverview
                        start={monthStart}
                        end={monthEnd}
                    />
                </Col>
                <Col md={2}>
                    <Link to="/subscriptions" style={{ textDecoration: 'none' }}>
                        <Card className='counter' to="/subscriptions" onClick={setAllSubscriptionCache}>
                            <div className={"content"}>
                                <h2>{subscriptionsCount.total}</h2>
                                <span>Alle Abonnements</span>
                            </div>
                        </Card>
                    </Link>
                    <br />
                    <Link to="/products" style={{ textDecoration: 'none' }}>
                        <Card className='counter'>
                            <h2>{productsCount}</h2>
                            <span>Produkte</span>
                        </Card>
                    </Link>
                </Col>
                <Col md={2}>
                    <Link to="/subscriptions" style={{ textDecoration: 'none' }}>
                        <Card className='counter' to="/subscriptions" onClick={setActiveSubscriptionCache}>
                            <div className={"content"}>
                                <h2>{subscriptionsCount.active}</h2>
                                <span>Aktive Abonnements</span>
                            </div>
                        </Card>
                    </Link>
                    <br />
                    <Link to="/customers" style={{ textDecoration: 'none' }}>
                        <Card className='counter'>
                            <h2>{customersCount}</h2>
                            <span>Kunden</span>
                        </Card>
                    </Link>
                </Col>
                <Col md={2}>
                    <Link to="/subscriptions" style={{ textDecoration: 'none' }}>
                        <Card className='counter' to="/subscriptions" onClick={setPausedSubscriptionCache}>
                            <div className={"content"}>
                                <h2>{subscriptionsCount.paused}</h2>
                                <span>Pausierte Abonnements</span>
                            </div>
                        </Card>
                    </Link>
                </Col>
                <Col md={2}>
                    <Link to="/subscriptions" style={{ textDecoration: 'none' }}>
                        <Card className='counter' to="/subscriptions" onClick={setCanceledSubscriptionCache}>
                            <div className={"content"}>
                                <h2>{subscriptionsCount.canceled}</h2>
                                <span>Stornierte Abonnements</span>
                            </div>
                        </Card>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col md={5}>
                    <CustomerGrowthKpi
                        start={monthStart}
                        end={monthEnd}
                    />
                </Col>
                <Col md={7}>
                    <SalesKpi
                        start={monthStart}
                        end={monthEnd}
                    />
                </Col>
            </Row>
        </Container>
    </>

}

export default Dashboard;
import { Checkbox, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import CustomerUnit from '../../../communicator/http/Customers';
import wrlog from '../../../helper/functions/wrlog';
import { hasPermission } from '../../../helper/functions/auth';
import DeliveryUnit from '../../../communicator/http/Delivery';
import { formatDbDate } from '../../../helper/functions/formatDate';
import StatusLabel from '../../../elements/statusLabel';
import { translateDeliveryStatus } from '../../../helper/functions/translateDeliveryStatus';

/**
 * 
 * @returns Contact Data Form Items for Customer-Screen
 */
const DeliveryList = ({ customerId }) => {

    const [loading, setLoading] = useState(false);
    const [deliveries, setDeliveries] = useState([]);

    useEffect(() => {
        if (customerId) {
            load();
        }
    }, [customerId])

    const load = () => {
        const customerUnit = new CustomerUnit();
        customerUnit.listDeliveries(customerId, (res) => {
            setDeliveries(res);
            setLoading(false);
        }, () => { })
    }

    /**
     * Submit userData to API
     * @param {object} data 
     */
    const update = (deliveryIds, status) => {
        const deliveryUnit = new DeliveryUnit();

        setLoading(true);

        const body = {
            status: status,
            customer_id: customerId,
            delivery_ids: deliveryIds
        }

        deliveryUnit.updateDeliveryStatus(body, (res) => {
            setLoading(false);

            let _deliveries = [...deliveries];
            const index = deliveries.findIndex(d => {
                return d.ids[0] === deliveryIds[0]
            })

            if (index > -1) {
                _deliveries[index].status = status;
            }

            setDeliveries(_deliveries);

        }, (err) => {

        });

    }

    return (
        <>
            <h4>Vergangene Lieferungen</h4>
            <table>
                <tr>
                    <td><strong>Lieferdatum<br /><small style={{ fontSize: 10 }}>(Montag der Lieferwoche)</small></strong></td>
                    <td><strong>Produkte</strong></td>
                    <td>Status</td>
                </tr>
                {
                    deliveries?.map(({ ids, date, products, status, editable }) => {
                        return <tr>
                            <td>
                                {formatDbDate(date)}
                            </td>
                            <td>
                                <div style={{ fontSize: 10 }}>{products.join(', ')}</div>
                            </td>
                            <td style={{ width: 200 }}>
                                {(hasPermission('customer', ['can_update']) || hasPermission('subscription', ['can_update'])) && editable && status !== 'succeeded' ?
                                    <Select
                                        className={`statusSelect ${status} ${status === 'succeeded' ? 'disabled' : ''}`}
                                        value={status}
                                        onChange={e => update(ids, e.target.value)}
                                    >
                                        <MenuItem value="to_deliver">Geplant</MenuItem>
                                        <MenuItem value="out_for_delivery">In Zustellung</MenuItem>
                                        <MenuItem value="succeeded">Zugestellt</MenuItem>
                                        <MenuItem value="failed">Fehlgeschlagen</MenuItem>
                                    </Select>
                                    : <StatusLabel status={status}>{translateDeliveryStatus(status)}</StatusLabel>
                                }
                            </td>
                        </tr>
                    })
                }
            </table>
            <Loading visible={loading} containerStyle={{ borderRadius: 15, width: '100%', height: '100%', position: 'absolute' }} />
        </>

    )

}

export default DeliveryList;
import JSZip from "jszip"
import { saveAs } from 'file-saver';

export const zipCreator = async (docs, call, filename = 'download') => {
    const zip = new JSZip()
    const folder = zip.folder(filename)
    await Promise.all(docs.map(async ({ id, file }) => {
        try {
            const response = await call(id);

            let name = response.title || 'file.pdf';
            if (file) {
                name = file.substring(file.lastIndexOf('/'))
            }

            const blob = new Blob([Buffer.from(response.base64, 'base64')]);
            folder.file(name, blob)
        } catch (err) {
        }
    }))

    zip.generateAsync({ type: "blob" })
        .then(blob => saveAs(blob, filename))
        .catch(e => console.log(e));
}
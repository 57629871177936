import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import UserUnit from '../../communicator/http/User';
import Loading from '../../helper/screens/Loading';
import { useForm } from "react-hook-form";
import Message from '../../elements/message';
import { translate } from '../../helper/multilingual';
import { Link } from 'react-router-dom';

import Logo from '../../assets/logo.png';

/**
 * 
 * @returns Login Page
 */
const Login = () => {

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const userUnit = new UserUnit();

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");

    /**
     * Logs in the User
     * @param {object} data 
     * 
     * @returns void
     */
    const doLogin = (data) => {

        setLoading(true);

        let cbSuccess = (res) => {
            localStorage.setItem('token', res.token);
            window.location.reload();
        }

        let cbError = (err) => {
            setLoading(false);

            setErrorMessageTitle(translate('login_error'));
            setErrorMessageBody(translate('login_error_body'));
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
        }

        userUnit.login(data.email, data.password, cbSuccess, cbError);

    }

    return (
        <>
            <div className="login_wrapper">
                <img src={Logo} />
                <div className="login_container">
                    <h1>{translate('signin')}</h1>
                    <br />
                    <form onSubmit={handleSubmit(doLogin)}>
                        <TextField
                            {...register('email', { required: true })}
                            error={errors.email}
                            id="standard-basic"
                            label={translate('email')}
                            type="email"
                        />
                        <TextField
                            {...register('password', { required: true })}
                            error={errors.password}
                            id="standard-basic"
                            label={translate('password')}
                            type="password"
                        />
                        <input type="submit" style={{ width: '100%' }} className="button" value={translate('signin')} />
                    </form>
                    <br/>
                    <Link to="/forgot-password" style={{alignSelf: 'center'}}>{translate('forgot_password')}</Link>
                </div>

            </div>
            <Loading visible={loading} />
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />
        </>
    );
}

export default Login;
import Unit from './index';

export default class ProcurementDeliveryUnit extends Unit {

    list = (deliveryDate, afProductId, cbSuccess, cbError) => {
        this.post('/procurementdelivery/list', { delivery_date: deliveryDate, af_product_id: afProductId }, cbSuccess, cbError);
    }

    getDelivery = (deliveryId, cbSuccess, cbError) => {
        this.get('/procurementdelivery/' + deliveryId, cbSuccess, cbError);
    }

    create = (body, cbSuccess, cbError) => {
        this.post('/procurementdelivery', body, cbSuccess, cbError);
    }

    update = (data, cbSuccess, cbError) => {
        this.put('/procurementdelivery', data, cbSuccess, cbError);
    }

}
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import wrlog from '../../../helper/functions/wrlog';
import SettingMakroUnit from '../../../communicator/http/SettingMakro';
import cloneArray from '../../../helper/functions/cloneArray';
import { translate } from '../../../helper/multilingual';
import Popup from '../../../elements/popup';
import TopBar from '../../../elements/topbar';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import StatisticsUnit from '../../../communicator/http/Statistics';
import { Autocomplete } from '@material-ui/lab';
import ProductUnit from '../../../communicator/http/Products';
import DatePicker from '../../../elements/datepicker';


const CancelationCohorts = () => {

    const [loading, setLoading] = useState(false);
    const [datePeriodFrom, setDatePeriodFrom] = useState(null);
    const [datePeriodTo, setDatePeriodTo] = useState(null);
    const [productId, setProductId] = useState(null);
    const [combineKey, setCombineKey] = useState(null);
    const [intervalOfDelivery, setIntervalOfDelivery] = useState(null);
    const [postcode, setPostcode] = useState(null);
    const [reason, setReason] = useState(null);
    const [minDeliveries, setMinDeliveries] = useState(null);
    const [maxDeliveries, setMaxDeliveries] = useState(null);
    const [showPeriod, setShowPeriod] = useState('week');

    const [products, setProducts] = useState([]);

    const [data, setData] = useState([]);

    const intervalOfDeliveryArray = [
        {
            label: "jede Woche",
            value: "1_week"
        },
        {
            label: "alle 2 Wochen",
            value: "2_weeks"
        },
        {
            label: "alle 4 Wochen",
            value: "4_weeks"
        }
    ];



    const cancelationReasons = [
        {
            label: "Zu teuer",
            value: "price"
        },
        {
            label: "Logistik hat nicht geklappt",
            value: "logistic"
        },
        {
            label: "Qualitätsprobleme",
            value: "quality"
        },
        {
            label: "Zu wenig Auswahl",
            value: "to_little_choice"
        },
        {
            label: "Zu oft der gleiche Inhalt",
            value: "same_content"
        },
        {
            label: "Zu viel Inhalt für mich",
            value: "too_much_content"
        },
        {
            label: "Zu wenig Inhalt für mich",
            value: "too_little_content"
        },
        {
            label: "Ich wollte es nur probieren",
            value: "just_wanted_to_try"
        },
        {
            label: "Anderer Grund",
            value: "other"
        }
    ];

    const combineKeyArray = [
        {
            label: "Obst-Klein",
            value: "obst-klein"
        },
        {
            label: "Obst-Mittel",
            value: "obst-mittel"
        },
        {
            label: "Obst-Groß",
            value: "obst-gross"
        },
        {
            label: "Gemüse-Klein",
            value: "gemuese-klein"
        },
        {
            label: "Gemüse-Mittel",
            value: "gemuese-mittel"
        },
        {
            label: "Gemüse-Groß",
            value: "gemuese-gross"
        },
        {
            label: "Mix-Klein",
            value: "mix-klein"
        },
        {
            label: "Mix-Mittel",
            value: "mix-mittel"
        },
        {
            label: "Mix-Groß",
            value: "mix-gross"
        }
    ];

    useEffect(() => {
        const productUnit = new ProductUnit();

        productUnit.listProducts({
            skip: 0,
            take: 10000,
            search: ""
        }, (res) => {
            let _products = [];
            res.results.forEach(_product => {
                _products.push({
                    title: _product.title + ' (' + _product.sku + ')',
                    id: _product.id,
                });
            });

            setProducts(_products);
        }, (err) => {
            setLoading(false);
        })

    }, [])

    const load = () => {
        const statisticsUnit = new StatisticsUnit();
        setLoading(true);

        const body = {
            show_period: showPeriod,
            from: datePeriodFrom,
            to: datePeriodTo,
            delivery_interval: intervalOfDelivery,
            combination_key: combineKey,
            product_id: productId,
            postcode: postcode,
            reason: reason,
            min_deliveries: minDeliveries,
            max_deliveries: maxDeliveries
        };

        statisticsUnit.getCancelationCohorts(body, (res) => {
            setLoading(false);
            setData(res);
        }, () => {
            setLoading(false);
        })
    }

    return <>
        <TopBar title={"Kündigungskohorten"} />
        <Container fluid>
            <Row>
                <Col md={3}>
                    <FormControl
                        className="selectFormControl"
                    >
                        <InputLabel id="type">Interval der Lieferung</InputLabel>
                        <Select
                            onChange={v => { setIntervalOfDelivery(v.target.value); }}
                            defaultValue={intervalOfDelivery}
                        >
                            <MenuItem value={null}>Alle</MenuItem>
                            {intervalOfDeliveryArray.map((i) => <MenuItem value={i.value}>{i.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Col>
                <Col md={3}>
                    <FormControl
                        className="selectFormControl"
                    >
                        <InputLabel id="type">Produkt-Kategorie</InputLabel>
                        <Select
                            onChange={v => { setCombineKey(v.target.value); }}
                            defaultValue={combineKey}
                        >
                            <MenuItem value={null}>Alle</MenuItem>
                            {combineKeyArray.map((i) => <MenuItem value={i.value}>{i.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Col>
                <Col md={3}>
                    <Autocomplete
                        id="tags-outlined"
                        options={products}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, newValue) => {
                            if (newValue !== null) {
                                setProductId(newValue.id);
                            } else {
                                setProductId(newValue);
                            }
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Produkte"
                                className="filled"
                            />
                        )}
                    />
                </Col>
                <Col md={3}>
                    <FormControl
                        className="selectFormControl"
                    >
                        <InputLabel id="type">Kündigungsgrund</InputLabel>
                        <Select
                            onChange={v => { setReason(v.target.value) }}
                            defaultValue={cancelationReasons}
                        >
                            <MenuItem value={null}>Alle</MenuItem>
                            {cancelationReasons.map((i) => <MenuItem value={i.value}>{i.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Col>
            </Row>

            <Row>
                <Col md={2}>
                    <DatePicker
                        onChange={(val) => {
                            setDatePeriodFrom(val)
                        }}
                        value={datePeriodFrom}
                        label={"Von"}
                        date={datePeriodFrom}
                    />
                </Col>
                <Col md={2}>
                    <DatePicker
                        onChange={(val) => {
                            setDatePeriodTo(val)
                        }}
                        value={datePeriodTo}
                        label={"Bis"}
                        date={datePeriodTo}
                    />
                </Col>
                <Col md={2}>
                    <TextField
                        onChange={(val) => {
                            setMinDeliveries(val.target.value);
                        }}
                        value={minDeliveries}
                        className={`filled`}
                        id="standard-basic"
                        label={'Min. Lieferungen'}
                    />
                </Col>
                <Col md={2}>
                    <TextField
                        onChange={(val) => {
                            setMaxDeliveries(val.target.value);
                        }}
                        value={maxDeliveries}
                        className={`filled`}
                        id="standard-basic"
                        label={'Max. Lieferungen'}
                    />
                </Col>
                <Col md={2}>
                    <FormControl
                        className="selectFormControl"
                    >
                        <InputLabel id="type">Anzeige</InputLabel>
                        <Select
                            onChange={v => { setShowPeriod(v.target.value) }}
                            defaultValue={showPeriod}
                        >
                            <MenuItem value={'week'}>Woche</MenuItem>
                            <MenuItem value={'month'}>Monat</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
                <Col md={2} style={{ float: 'right' }}>
                    <div
                        style={{ paddingTop: 17, paddingBottom: 18 }}
                        className={`button`}
                        onClick={() => load()}
                    >Laden</div>
                </Col>
            </Row>
        </Container>
        {data.length > 0 && <Table style={{ width: '100%', marginTop: 20 }} cellpadding={10}>
            <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={data[0].canceled.length + 3}>
                    Kündigung
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    Vertrags-Beginn
                </TableCell>
                {
                    data[0].canceled.map(canceled => {
                        return <TableCell>
                            {canceled.period}
                        </TableCell>
                    })
                }
                <TableCell>Verträge</TableCell>
                <TableCell>Kündigungen</TableCell>
                <TableCell>aktive Verträge</TableCell>
            </TableRow>
            {
                data.map(created => {
                    return <>
                        <TableRow>
                            <TableCell>{created.period}</TableCell>

                            {created.canceled.map(canceled => {
                                return <TableCell>
                                    {canceled.count}
                                </TableCell>
                            })}

                            <TableCell>{created.sum_created}</TableCell>
                            <TableCell>{created.sum_canceled}</TableCell>
                            <TableCell>{created.sum_created - created.sum_canceled}</TableCell>
                        </TableRow>
                    </>
                })
            }
        </Table>
        }
        <Loading visible={loading} />

    </>

}

export default CancelationCohorts;
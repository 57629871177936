import moment from 'moment';
export const format_time_from_db = (datetime) => {

    let date = moment(datetime);
    return {
        date: date.format('DD.MM.YYYY'),
        time: date.format('HH:mm'),
    };

}

export const format_datetime = (datetime) => { 

    if (typeof datetime === 'string') {

        datetime = datetime.replace(' ', 'T');
        datetime = new Date(datetime);

    }

    return moment(datetime).format('DD.MM.YYYY HH:mm')
}

export const format_time_to_unix = (datetime) => {

    if (typeof datetime === 'string') {

        datetime = datetime.replace(' ', 'T');
        datetime = new Date(datetime);

    }

    return datetime.getTime();
}
export const get_current_unix = () => {
    return new Date().getTime();
}

export const format_datetime_for_db = (datetime) => {
    if(typeof datetime === 'string'){
        datetime = new Date(datetime);
    }
    return moment(datetime).format('YYYY-MM-DD HH:mm:00');
}

export const format_datetime_for_db_simple = (datetime) => {
    return moment(datetime).format('YYYY-MM-DD HH:mm:00');
}

export const current_date_time = () => {

    return moment().format('DD.MM.YYYY HH:mm')

}
export const calculate_local_timezone = (date) => {


    if (typeof date === 'string') {

        date = date.replace(' ', 'T');
        date = new Date(date);

    }

    let offset = date.getTimezoneOffset();
    let newDate = new Date(date.getTime() - (offset * 60 * 1000));
    return moment(newDate).format('DD.MM.YYYY - HH:mm')

}
export const calculate_utc_timezone = (date) => {

    let offset = date.getTimezoneOffset();
    offset = 0;
    let newDate = new Date(date.getTime() - (offset * 60 * 1000));

    return moment(newDate).format('YYYY-MM-DDTHH:mm:00') + 'Z'

}
export const removeA = (arr, needle) => {
    let ax = arr.indexOf(needle)
    if (ax !== -1) {
        arr.splice(ax, 1);
    }
    return arr;
}



export const format_db_datetime = (timestamp) => {

    var date = new Date(timestamp);

    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    var hours = date.getHours();
    var minutes = date.getMinutes();

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;


    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';

}


import Unit from './index';

export default class SettingMakroUnit extends Unit {

    listMakros = (cbSuccess, cbError) => {
        this.get(`/settingmakro`, cbSuccess, cbError);
    }

    createMakro = (body, cbSuccess, cbError) => {
        this.post(`/settingmakro`, body, cbSuccess, cbError);
    }

    updateMakro = (body, cbSuccess, cbError) => {
        this.put(`/settingmakro`, body, cbSuccess, cbError);
    }

    deleteMakro = (body, cbSuccess, cbError) => {
        this.delete(`/settingmakro`, body, cbSuccess, cbError);
    }

}

import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import wrlog from '../../../helper/functions/wrlog';
import Message from '../../../elements/message';
import ServiceDeskUnit from '../../../communicator/http/ServiceDesk';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate, useParams } from 'react-router-dom';
import NotFound from '../../NotFound';

import styles from './styles.module.scss';

const ServiceDeskArticle = () => {

    const [loading, setLoading] = useState(true);

    const [article, setArticle] = useState([]);
    const [is404, setIs404] = useState(false);

    const serviceDeskUnit = new ServiceDeskUnit();
    const params = useParams();

    /**
     * initially loads tickets
     */
    useEffect(() => {
        if (params.id) {
            serviceDeskUnit.getArticle(
                params.id,
                (res) => {
                    setArticle(res)
                    setLoading(false);

                    res.attachments.map(attachment => {
                        serviceDeskUnit.getAttachment(
                            attachment.download,
                            (res) => {
                            },
                            (err) => {
                            }
                        )
                    })


                },
                (err) => {
                    setIs404(true)
                }
            )
        }
    }, [params.id]);

    if (is404 === true) {
        return <NotFound />;
    }

    return <>
        <Container className={styles.confluenceContainer} style={{ padding: 0, marginTop: 40, marginBottom: 20 }}>
            <Row style={{}}>
                <Col sm={12}>
                    <h1>{ReactHtmlParser(article?.title)}</h1>
                    {ReactHtmlParser(article?.view)}
                </Col>
            </Row>
        </Container>

        {
            loading
            && <Loading visible={true} />
        }
    </>

}

export default ServiceDeskArticle;
import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import wrlog from '../../helper/functions/wrlog';
import SettingsUnit from '../../communicator/http/Settings';
import cloneArray from '../../helper/functions/cloneArray';
import { translate } from '../../helper/multilingual';


const Settings = () => {

    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        const settingsUnit = new SettingsUnit();
        settingsUnit.listSettings((res) => {
            wrlog("RES", res)
            setSettings(res);
        }, () => { })
    }, []);

    const onChange = (key, value) => {

        wrlog(key, value);

        let _settings = cloneArray(settings);

        _settings.forEach((setting, index) => {
            if (setting.key === key) {
                _settings[index].value = value;
            }
        })

        setSettings(_settings);
    }

    const save = () => {
        setLoading(true);
        const settingsUnit = new SettingsUnit();
        settingsUnit.setSettings({ settings: settings }, (res) => {
            setLoading(false);
        }, () => { })
    }

    return <>
        <Container className='equalHeight'>
            {settings.map(setting => {
                return <Row>
                    <Col md={4}>
                        {setting.title}
                    </Col>
                    <Col md={8}>
                        <TextField
                            multiline
                            value={setting.value}
                            onChange={e => onChange(setting.key, e.target.value)}
                            className={`filled ${setting.value !== undefined && setting.value !== null && setting.value !== '' ? 'valued' : ''}`}
                            id="standard-basic"
                            label={setting.title}
                        />
                    </Col>
                </Row>
            })}

        </Container>
        <div onClick={save} style={{ position: 'fixed', bottom: 20, right: 20 }} className="button">{translate('save')}</div>
        <Loading visible={loading} />
    </>

}

export default Settings;
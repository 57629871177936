import Unit from './index';

export default class CustomerUnit extends Unit {

    listCustomers = (body, cbSuccess, cbError) => {
        this.post(`/customers/list`, body, cbSuccess, cbError);
    }

    getCustomer = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}`, cbSuccess, cbError);
    }

    getSubscriptions = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}/subscriptions`, cbSuccess, cbError);
    }

    getReviews = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}/reviews`, cbSuccess, cbError);
    }

    getLog = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}/log`, cbSuccess, cbError);
    }

    addLogEntry = (customerId, body, cbSuccess, cbError) => {
        this.post(`/customers/${customerId}/log`, body, cbSuccess, cbError);
    }

    createCustomer = (body, cbSuccess, cbError) => {
        this.post('/customers', body, cbSuccess, cbError);
    }

    updateCustomer = (body, cbSuccess, cbError) => {
        this.put('/customers', body, cbSuccess, cbError);
    }

    deleteCustomer = (customerId, cbSuccess, cbError) => {
        this.delete(`/customers`, { id: customerId }, cbSuccess, cbError);
    }

    updatePaymentMethod = (customerId, body, cbSuccess, cbError) => {
        this.put(`/customers/${customerId}/paymentmethod`, body, cbSuccess, cbError);
    }

    listDiscounts = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}/discounts`, cbSuccess, cbError);
    }

    listDeliveryNotes = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}/deliverynotes`, cbSuccess, cbError);
    }

    listInvoices = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}/invoices`, cbSuccess, cbError);
    }

    listOrders = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}/orders`, cbSuccess, cbError);
    }

    getOrderPaymentStatus = (orderId, cbSuccess, cbError) => {
        this.get(`/order/${orderId}/paymentstatus`, cbSuccess, cbError);
    }

    creditCheck = (body, cbSuccess, cbError) => {
        this.post(`/customers/creditcheck`, body, cbSuccess, cbError);
    }

    listDeliveries = (customerId, cbSuccess, cbError) => {
        this.get(`/customers/${customerId}/deliveries`, cbSuccess, cbError);
    }

}
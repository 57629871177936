import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import { translate } from '../../helper/multilingual';
import { hasPermission } from '../../helper/functions/auth';
import Cache from '../../communicator/local';
import Pagination from '../../elements/pagination';
import wrlog from '../../helper/functions/wrlog';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../elements/statusLabel';
import Popup from '../../elements/popup';
import Message from '../../elements/message';
import TicketingUnit from '../../communicator/http/Ticketing';
import TicketingSidebar from './Sidebar';
import TicketingDetail from './detail';
import cloneArray from '../../helper/functions/cloneArray';

/**
 * 
 * @returns Customers Overview Page
 */
const Ticketing = (props) => {

    const [loading, setLoading] = useState(false);
    const [ticketLoading, setTicketLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [ticket, setTicket] = useState({});
    const [searchNameTerm, setSearchNameTerm] = useState("");
    const [filterStatus, setFilterStatus] = useState([]);
    const [filterType, setFilterType] = useState("");
    const [getAssigned, setGetAssigned] = useState(false);
    const [page, setPage] = useState(1);
    const [prefix, setPrefix] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [acceptedStatuses, setAcceptedStatuses] = useState([]);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [dateSort, setDateSort] = useState('ASC');


    const [createPopupOpen, setCreatePopupOpen] = useState(false);
    const [isInit, setIsInit] = useState(true);

    const perPage = 20;

    const ticketingUnit = new TicketingUnit();
    const queryArgsCache = new Cache('ticketing.queryArgs', true);

    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        // If param * not is empty --> Load ticket
        if (params['*'] !== '' && ticket.id !== params['*']) {
            loadSingleTicket(params['*']);
        }
    }, [params])

    useEffect(() => {

        // If param * not is empty --> Load ticket
        if (location.pathname.includes('my') && prefix !== '/my') {
            wrlog('IS MY');
            setPrefix('/my');
            setAcceptedStatuses(['open', 'processing'])
            load({
                search: searchNameTerm,
                status: ['open', 'processing'],
                type: '',
                assigned: true,
                page: 1
            });
        }
        else if (location.pathname.includes('open') && prefix !== '/open') {
            wrlog('IS OPEN')
            setPrefix('/open');
            setAcceptedStatuses(['open'])
            load({
                search: searchNameTerm,
                status: ['open'],
                type: '',
                assigned: false,
                page: 1
            });
        }
        else if (location.pathname.includes('all') && prefix !== '/all') {
            wrlog('IS ALL')
            setPrefix('/all');
            setAcceptedStatuses([])
            load({
                search: searchNameTerm,
                status: [],
                type: '',
                assigned: false,
                page: 1
            });
        }
        else if (location.pathname.includes('cancel') && prefix !== '/cancel') {
            wrlog('IS ALL')
            setPrefix('/cancel');
            setAcceptedStatuses([])
            load({
                search: searchNameTerm,
                status: ['open', 'processing'],
                type: 'cancel',
                assigned: false,
                page: 1
            });
        }
        else if (location.pathname.includes('pause') && prefix !== '/pause') {
            setPrefix('/pause');
            setAcceptedStatuses([])
            load({
                search: searchNameTerm,
                status: ['open', 'processing'],
                type: 'pause',
                assigned: false,
                page: 1
            });
        }
        else if (location.pathname.includes('claim') && prefix !== '/claim') {
            wrlog('IS ALL')
            setPrefix('/claim');
            setAcceptedStatuses([])
            load({
                search: searchNameTerm,
                status: ['open', 'processing'],
                type: 'claim',
                assigned: false,
                page: 1
            });
        }
    }, [location])

    /**
     * initially loads tickets
     */
    useEffect(() => {

        let queryArgs = queryArgsCache.get();
        if (queryArgs !== false) {
            setSearchNameTerm(queryArgs.search);
            setFilterType(queryArgs.type);
            setFilterStatus(queryArgs.status);
            setGetAssigned(queryArgs.assigned);
            setPage(queryArgs.page || 1);
        }

        // ticketingUnit.listTickets({
        //     skip: 0,
        //     take: 25,
        //     search: ""
        // }, (res) => {
        //     setTickets(res.results);
        // }, (err) => {
        // })
    }, []);

    const loadMore = () => {

        let queryArgs = queryArgsCache.get();

        if (page > 0 && (!isInit || queryArgs === false)) {
            setIsInit(false);
            load({
                search: searchNameTerm,
                status: filterStatus,
                assigned: getAssigned,
                dateSort: dateSort,
                page: page + 1
            });
        }

        setPage(prev => {
            return (prev + 1)
        });

    }

    const reload = () => {
        load({
            search: searchNameTerm,
            status: filterStatus,
            assigned: getAssigned,
            dateSort: dateSort,
            page: 1
        });
    }

    /**
     * loads products by search result
     * @param {*} e 
     */
    const doSearch = (e) => {
        e.preventDefault();
        load({
            search: searchNameTerm,
            page: 1,
            status: filterStatus,
            assigned: getAssigned,
            dateSort: dateSort,
        });
    }

    /**
     * loads products by search result
     * @param {*} e 
     */
    const changeDateSort = (sort) => {

        setDateSort(sort);
        setPage(1);

        load({
            search: searchNameTerm,
            dateSort: sort,
            page: 1,
            status: filterStatus,
            assigned: getAssigned,
        });
    }

    const loadSingleTicket = (ticketId) => {
        setTicketLoading(true);
        ticketingUnit.getTicket(ticketId, (response) => {
            setTicket(response);
            setTicketLoading(false);
            if (response.unread > 0) {
                updateInList(ticketId, { unread: 0 })
            }
        }, (err) => {

        })
    }

    const reloadTicket = () => {
        loadSingleTicket(ticket.id);
    }

    const updateInList = (id, data) => {

        let _tickets = cloneArray(tickets);

        wrlog(_tickets, id, data);

        _tickets.forEach((_ticket, index) => {
            if (_ticket.id === id) {
                Object.keys(data).forEach(key => {
                    _tickets[index][key] = data[key];
                })
            }
        })

        setTickets(_tickets);
    }

    /**
     * Loads admin users from API
     */
    const load = (props) => {

        setLoading(true);

        const skip = (props.page - 1) * perPage;

        if (props.status !== undefined) {
            setFilterStatus(props.status);
            setPage(1);
        }

        if (props.search !== undefined) {
            setSearchNameTerm(props.search)
            setPage(1);
        }

        if (props.assigned !== undefined) {
            setGetAssigned(props.assigned)
            setPage(1);
        }

        if (props.type !== undefined) {
            setFilterType(props.type)
        }

        // if (props.dateSort !== undefined) {
        //     setPage(1);
        // }

        if (skip === 0) {
            setTickets([]);
        }

        const body = {
            skip: skip,
            take: perPage,
            search: props.search,
            type: props.type,
            status: props.status,
            assigned: props.assigned,
            date_sort: props.dateSort
        };

        ticketingUnit.listTickets(body, (res) => {
            setLoading(false);

            setIsInit(false);

            queryArgsCache.set(body);

            if (props.page > 1) {
                setTickets(prev => [...prev, ...res.results]);
            } else {
                setTickets(res.results);
            }

            setTotalPages(Math.ceil(res.total / perPage));

        }, (err) => {
            setLoading(false);
        })
    }

    return <>

        <Container fluid style={{ padding: 0, marginTop: -30, marginBottom: -30, marginLeft: -30, width: 'calc(100% + 30px)', maxHeight: '100vh' }}>
            <Row style={{}}>
                <Col sm={4}>
                    <TicketingSidebar
                        tickets={tickets}
                        selectedTicketId={ticket.id}
                        loading={loading}
                        prefix={prefix}
                        loadMore={loadMore}
                        page={page || 1}
                        totalPages={totalPages}
                        acceptedStatuses={acceptedStatuses}
                        doSearch={doSearch}
                        searchNameTerm={searchNameTerm}
                        setSearchNameTerm={setSearchNameTerm}
                        reload={reload}
                        setDateSort={changeDateSort}
                        dateSort={dateSort}
                    />
                </Col>
                <Col sm={8} style={{ overflow: 'scroll' }}>
                    {
                        ticketLoading ?
                            <Loading visible={true} relative />
                            :
                            <TicketingDetail
                                ticket={ticket}
                                reload={reloadTicket}
                                reloadList={reload}
                                updateInList={updateInList}
                                setLoading={setLoading}
                            />
                    }

                </Col>
            </Row>
        </Container>

        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />
    </>

}

export default Ticketing;
import Unit from './index';

export default class ProcurementUnit extends Unit {

    downloadProcurementList = (deliveryDate, cbSuccess, cbError) => {
        this.post('/procurement/download-list', { delivery_date: deliveryDate }, cbSuccess, cbError);
    }

    downloadProcurementPickingLists = (deliveryDate, cbSuccess, cbError) => {
        this.post('/procurement/download-picking-lists', { delivery_date: deliveryDate }, cbSuccess, cbError);
    }

    downloadProcurementPickingListsBusiness = (deliveryDate, cbSuccess, cbError) => {
        this.post('/procurement/download-picking-lists/business', { delivery_date: deliveryDate, type: 'b2b' }, cbSuccess, cbError);
    }

    downloadProcurementLabels = (deliveryDate, cbSuccess, cbError) => {
        this.post('/procurement/download-labels', { delivery_date: deliveryDate }, cbSuccess, cbError);
    }


}
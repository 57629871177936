import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Message from '../../../elements/message';
import Loading from '../../../helper/screens/Loading';
import TopBar from '../../../elements/topbar';
import { translate } from '../../../helper/multilingual';
import { getUserToken, hasPermission } from '../../../helper/functions/auth';
import ProductUnit from '../../../communicator/http/Products';
import BankingUnit from '../../../communicator/http/Banking';
import Pagination from '../../../elements/pagination';
import wrlog from '../../../helper/functions/wrlog';
import { formatDbDate } from '../../../helper/functions/formatDate';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../../elements/statusLabel';
import ToolTip from '../../../elements/tooltip';
import Cache from '../../../communicator/local';
import DatePicker from '../../../elements/datepicker';
import { formatMoney } from '../../../helper/functions/formatPrice';
import { translateTransactionStatus, translatePaymentType } from '../../../helper/functions/translateTransaction';
import Popup from '../../../elements/popup';
import InvoiceUnit from '../../../communicator/http/Invoices';
import Unit from '../../../communicator/http';
import Dropzone from 'react-dropzone';

import balanceStyles from '../Balance/style.module.scss';
import styles from './style.module.scss';


/**
 * 
 * @returns Transactions Overview Page
 */
const Transactions = (props) => {

    const [loading, setLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);

    const [transactions, setTransactions] = useState([]);
    const [searchNameTerm, setSearchNameTerm] = useState("");
    const [filterType, setFilterType] = useState(props.type || '');
    const [filterStatus, setFilterStatus] = useState(props.status || '');
    const [filterTransactionDate, setfilterTransactionDate] = useState({
        from: new Date(),
        to: null
    });

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isInit, setIsInit] = useState(true);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [downloadMessageOpen, setDownloadMessageOpen] = useState(false);
    const [disabledTransactions, setDisabledTransactions] = useState([]);


    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [openDetailPopup, setOpenDetailPopup] = useState(false);
    const [selectedTransaction, setSelectedTransactions] = useState({});
    const [selectedInvoice, setSelectedInvoice] = useState('');
    const [selectedInvoices, setSelectedInvoices] = useState('');

    const [uploadInvoicePopupOpen, setUploadInvoicePopupOpen] = useState(false);
    const [fileUploadRunning, setFileUploadRunning] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});

    const [pdfData, setPdfData] = useState("");

    const perPage = 25;

    const bankingUnit = new BankingUnit();

    const queryArgsCache = new Cache('transactions.queryArgs', true);

    /**
     * initially loads products
     */
    useEffect(() => {

        let queryArgs = queryArgsCache.get();

        if (queryArgs !== false) {
            setSearchNameTerm(queryArgs.search);
            if (queryArgs.type !== undefined && props.type === undefined) {
                setFilterType(queryArgs.type);
            }
            if (queryArgs.status !== undefined && props.status === undefined) {
                setFilterStatus(queryArgs.status);
            }

            if (queryArgs.date !== null && queryArgs.date !== "") {
                setfilterTransactionDate({
                    from: new Date(queryArgs.date.from),
                    to: new Date(queryArgs.date.to),
                })
            }

            setPage(queryArgs.page);
        }

    }, []);


    /**
     * initially loads products
     */
    useEffect(() => {
        let queryArgs = queryArgsCache.get();
        if (page > 0 && (!isInit || queryArgs === false)) {
            setIsInit(false);
            load();
        }
    }, [page]);

    useEffect(() => {
        setIsInit(false);
        setLoading(true);
        setPage(0);
        setTimeout(() => {
            setPage(1);
        }, 100)
    }, [filterType, filterStatus, filterTransactionDate]);


    useEffect(() => {
        if (pdfLoading) {
            setTimeout(() => {
                setPdfLoading(false);
            }, 1000);
        }
    }, [pdfData]);

    /**
* loads products by search result
* @param {*} e 
*/
    const doSearch = (e) => {
        e.preventDefault();
        load();
    }


    /**
     * Loads transactions from API
     */
    const load = (cb = () => { }) => {

        setLoading(true);

        const skip = (page - 1) * perPage;

        let bookingDate = "";
        if (filterTransactionDate !== undefined && filterTransactionDate !== null && filterTransactionDate !== false && filterTransactionDate.from !== null && filterTransactionDate.to !== null) {
            bookingDate = {
                from: filterTransactionDate.from.toDateString(),
                to: filterTransactionDate.to.toDateString()
            }
        }

        const body = {
            skip: skip,
            take: perPage,
            search: searchNameTerm,
            status: filterStatus,
            type: filterType,
            date: bookingDate
        };

        bankingUnit.listTransactions(body, (res) => {

            setLoading(false);
            setTransactions(res.results);
            setTotalPages(Math.ceil(res.total / perPage));
            cb();
            body.page = page;
            queryArgsCache.set(body);

        }, (err) => {
            setLoading(false);
        })
    }

    const updateTransactionStatus = (transactionId, status) => {

        let body = {
            id: transactionId,
            status: status
        }

        let _disabledTransactions = [...disabledTransactions];


        if (_disabledTransactions.findIndex(t => t === transactionId) < 0) {
            _disabledTransactions.push(transactionId);
        }

        setDisabledTransactions(_disabledTransactions);

        bankingUnit.updateTransaction(body, (res) => {
            let _disabledTransactions = [...disabledTransactions];
            let index = _disabledTransactions.findIndex(t => t === transactionId);
            _disabledTransactions.splice(index, 1);
            setDisabledTransactions(_disabledTransactions);

            let _transactions = [...transactions];

            index = _transactions.findIndex(t => t.id === transactionId, 1);
            _transactions[index].status = status;

            setTransactions(_transactions);
        }, (err) => {
        })
    }

    const selectFile = (file) => {

        let mimeType = file.type;
        let title = file.name;

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result

            let _file = { ...selectedFile };
            _file.file = base64String;
            _file.fileMimeType = mimeType;
            _file.fileTitle = title;

            setSelectedFile(_file);

        };

        reader.readAsDataURL(file);
    }

    const uploadFile = () => {
        setFileUploadRunning(true);
        bankingUnit.uploadTransactionFile({ id: selectedFile.transactionId, base64: selectedFile.file, mime_type: selectedFile.fileMimeType, title: selectedFile.fileTitle }, () => {
            setFileUploadRunning(false);
            load()
        });
    }


    return <>
        <TopBar title={"Transaktionen"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <Row>
                        <Col md={4}>
                            <form onSubmit={doSearch}>
                                <TextField id="standard-basic" className="filled" value={searchNameTerm} label="Durchsuchen" onChange={value => setSearchNameTerm(value.target.value)} /><br />
                            </form>
                        </Col>
                        <Col md={2}>
                            <div
                                onClick={doSearch}
                                style={{ paddingTop: 17, paddingBottom: 18 }}
                                className={`button ${searchNameTerm === '' ? 'disabled' : ''}`}>
                                suchen
                            </div>
                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Status</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setFilterStatus(e.target.value === '' ? '' : e.target.value)
                                    }}
                                    value={filterStatus || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"created"}>Erstellt</MenuItem>
                                    <MenuItem value={"booked"}>Verbucht</MenuItem>
                                    <MenuItem value={"needs_checking"}>Braucht überprüfung</MenuItem>
                                    <MenuItem value={"factoring_to_send"}>Factoring ausstehend</MenuItem>
                                    <MenuItem value={"factoring_sent"}>An Factoring gesendet</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Typ</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setFilterType(e.target.value === '' ? '' : e.target.value)
                                    }}
                                    value={filterType || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"in"}>Eingangsbuchung</MenuItem>
                                    <MenuItem value={"out"}>Ausgangsbuchung</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <DatePicker
                                onChange={(val) => {
                                    setfilterTransactionDate({
                                        from: val[0],
                                        to: val[1]
                                    });
                                }}
                                value={filterTransactionDate.from}
                                label={"Buchungsdatum"}
                                startDate={filterTransactionDate.from}
                                endDate={filterTransactionDate.to}
                                selectsRange
                            />
                        </Col>
                        <Col md={3}>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Datum</TableCell>
                                <TableCell>Betrag</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>IBAN / BBAN</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Zahlungsreferenz</TableCell>
                                <TableCell style={{ width: 50 }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                transactions.map((transaction, key) => {

                                    let tooltip = [];
                                    if (transaction.connected_invoice !== null) {
                                        if (transaction.connected_invoice_type === 'afreshed_invoice') {
                                            tooltip.push({
                                                label: "Rechnung anzeigen",
                                                onClick: () => {
                                                    setOpenDetailPopup(true);
                                                    setSelectedTransactions({ ...transaction })
                                                    setSelectedInvoice('');
                                                    setSelectedInvoices('');

                                                    let invoiceUnit = new InvoiceUnit();
                                                    invoiceUnit.getPdf(transaction.connected_invoice, ({ base64 }) => {
                                                        setSelectedInvoice(`data:application/pdf;base64,${base64}`);
                                                    })
                                                },
                                            })
                                        } else if (transaction.connected_invoice_type === 'transaction_attached_invoice') {
                                            tooltip.push({
                                                label: "Rechnung anzeigen",
                                                onClick: () => {
                                                    setOpenDetailPopup(true);
                                                    setSelectedTransactions({ ...transaction })
                                                    setSelectedInvoice('');
                                                    setSelectedInvoices('');

                                                    setSelectedInvoice(transaction.connected_invoice);
                                                },
                                            })
                                        } else if (transaction.connected_invoice_type === 'balance_attached_invoices') {
                                            tooltip.push({
                                                label: "Rechnungen anzeigen",
                                                onClick: () => {
                                                    setOpenDetailPopup(true);
                                                    setSelectedTransactions({ ...transaction })
                                                    setSelectedInvoice(transaction.connected_invoice[0]);

                                                    setSelectedInvoices(transaction.connected_invoice);
                                                },
                                            })
                                        } else {
                                            tooltip.push({
                                                label: "Rechnung/Beleg hochladen",
                                                onClick: () => {
                                                    setSelectedFile({ transactionId: transaction.id })
                                                    setUploadInvoicePopupOpen(true)
                                                },
                                            })
                                        }
                                    } else {
                                        tooltip.push({
                                            label: "Rechnung/Beleg hochladen",
                                            onClick: () => {
                                                setSelectedFile({ transactionId: transaction.id })
                                                setUploadInvoicePopupOpen(true)
                                            },
                                        })
                                    }

                                    if (props.assignToBalance !== undefined) {
                                        tooltip.push({
                                            label: "zuweisen",
                                            onClick: () => {
                                                props.assignToBalance(transaction.id)
                                            },
                                        })
                                    }

                                    return <TableRow className={disabledTransactions.includes(transaction.id) ? 'disabled' : undefined} key={key}>
                                        <TableCell>
                                            {formatDbDate(transaction.date)}
                                        </TableCell>
                                        <TableCell>
                                            {formatMoney(transaction.amount)}
                                        </TableCell>
                                        <TableCell>
                                            {transaction.account.name}
                                        </TableCell>
                                        <TableCell>
                                            {transaction.account.iban || transaction.account.bban}
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                className={`statusSelect ${transaction.status}`}
                                                value={transaction.status}
                                                style={{ width: 230 }}
                                                onChange={e => updateTransactionStatus(transaction.id, e.target.value)}
                                            >
                                                <MenuItem value={"created"}>Erstellt</MenuItem>
                                                <MenuItem value={"booked"}>Verbucht</MenuItem>
                                                <MenuItem value={"needs_checking"}>Braucht überprüfung</MenuItem>
                                                <MenuItem value={"factoring_to_send"}>Factoring ausstehend</MenuItem>
                                                <MenuItem value={"factoring_sent"}>An Factoring gesendet</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            {transaction.reference}
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                items={tooltip}
                                            ></ToolTip>
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        pages={totalPages}
                        current={page}
                        pageChange={setPage}
                    />
                </Col>
            </Row>
        </Container>

        <Popup
            style={{ width: '100%', maxWidth: 800 }}
            close={() => setOpenDetailPopup(false)}
            open={openDetailPopup}
        >
            {selectedInvoices !== '' ? <div className={styles.invoiceSelectContainer}>
                <div className={styles.left}>
                    {
                        selectedInvoices.map((invoice, key) => {
                            return <div
                                className={`${styles.button} ${invoice === selectedInvoice ? styles.selected : undefined}`}
                                onClick={() => setSelectedInvoice(invoice)}
                            >
                                {key + 1}</div>
                        })
                    }
                </div>
                <div className={styles.right}>
                    {selectedInvoice !== '' && <iframe className='iframe' style={{ position: 'sticky', top: 0, left: 0, width: '100%', height: 'calc(100vh - 130px)' }} src={selectedInvoice}></iframe>}
                </div>
            </div>
                : <iframe className='iframe' style={{ position: 'sticky', top: 0, left: 0, width: '100%', height: 'calc(100vh - 130px)' }} src={selectedInvoice}></iframe>
            }
        </Popup>

        <Popup
            style={{ width: '100%', maxWidth: 800, overflow: 'scroll' }}
            close={() => {
                setUploadInvoicePopupOpen(false);
                setSelectedFile({});
            }}
            open={uploadInvoicePopupOpen}
        >
            {
                selectedFile.file === undefined ? <Dropzone
                    multiple={false}
                    onDrop={acceptedFiles => selectFile(acceptedFiles[0])}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div className={`${balanceStyles.dragndropContainer} ${fileUploadRunning ? 'disabled' : ''}`}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} id="asdasdasd" />
                                <p>Drag 'n' drop oder klicken um eine Datei hochzuladen</p>
                            </div>
                        </div>
                    )}
                </Dropzone>
                    :
                    <><iframe className='iframe' style={{ width: '100%', height: 'calc(100vh - 130px)' }} src={selectedFile.file}></iframe>
                        <div
                            onClick={uploadFile}
                            style={{ paddingTop: 17, paddingBottom: 18 }}
                            className={`button`}>
                            Hochladen
                        </div>
                        <div
                            className='small_button'
                            style={{ color: 'red', float: 'right', marginTop: 15 }}
                            onClick={() => setSelectedFile({})}>
                            {'löschen'}
                        </div>
                    </>
            }

        </Popup>



        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />

        <Message
            open={downloadMessageOpen}
            type={"success"}
            small={false}
            title={"PDFs werden erstellt"}
            body={"Du bekommst in den nächsten Minuten E-Mails mit Rechnungspaketen zugeschickt."}
            buttonText={"OK"}
            buttonAction={() => setDownloadMessageOpen(false)}
        />

        <Loading visible={loading} />
    </>

}

export default Transactions;
import { Checkbox, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import CustomerUnit from '../../../communicator/http/Customers';
import wrlog from '../../../helper/functions/wrlog';
import { hasPermission } from '../../../helper/functions/auth';
import SubscriptionUnit from '../../../communicator/http/Subscriptions';
import DiscountsUnit from '../../../communicator/http/Discount';

/**
 * 
 * @returns Contact Data Form Items for Customer-Screen
 */
const AddDiscountForm = ({ subscriptionId, reload, setShow }) => {

    const params = useParams();

    const userId = params.id;

    const [loading, setLoading] = useState(false);
    const [selectedType, setSelectedType] = useState('onetime');

    const { handleSubmit, setValue, control, formState: { errors }, watch } = useForm();

    useEffect(() => {
        setValue('type', 'onetime');
        setValue('amount', 0);
    }, []);


    watch(({ type }) => {
        setSelectedType(type);
    })

    /**
     * Submit userData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const discountUnit = new DiscountsUnit();
        setShow(false);
        setLoading(true);

        let body = {
            type: data.type,
            amount: data.amount,
            subscription_id: subscriptionId
        }

        const cbError = (err) => {
            setLoading(false)
        }

        // Success function for Creating User
        const cbSuccess = (res) => {
            reload();
        }

        discountUnit.addDiscount(body, cbSuccess, cbError);
    }

    return (
        <form onSubmit={handleSubmit(doSubmit)}>
            <FormController
                name={"type"}
                rules={{
                    required: true,
                }}
                control={control}
                render={({ field: { onChange, onBlur, value, onFocus } }) => {

                    if (value == undefined || value == null) {
                        return <></>
                    }

                    return <FormControl
                        className="selectFormControl"
                    >
                        <InputLabel id="type">Art</InputLabel>
                        <Select
                            onChange={onChange}
                            value={value}
                        >
                            <MenuItem value={"once"}>Einmalig</MenuItem>
                            <MenuItem value={"infinite"}>Unendlich</MenuItem>
                        </Select>
                    </FormControl>
                }}
            />
            <FormController
                name={"amount"}
                rules={{
                    required: true,
                }}
                control={control}
                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                    <TextField
                        onChange={onChange}
                        value={value}
                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                        error={errors.amount}
                        id="standard-basic"
                        label={'Prozent' + '*'}
                    />
                )}
            />
            <input
                type="submit"
                className='button'
                value={translate('add')}
            />
            <Loading visible={loading} />
        </form>
    )

}

export default AddDiscountForm;
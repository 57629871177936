import Unit from './index';

export default class ProductUnit extends Unit {

    listProducts = (body, cbSuccess, cbError) => {
        this.post(`/products/list`, body, cbSuccess, cbError);
    }

    getProductCombineKeys = (cbSuccess, cbError) => {
        this.get(`/products/combinekeys`, cbSuccess, cbError);
    }
    

    getProduct = (productId, cbSuccess, cbError) => {
        this.get(`/products/${productId}`, cbSuccess, cbError);
    }

    createProduct = (body, cbSuccess, cbError) => {
        this.post('/products', body, cbSuccess, cbError);
    }

    updateProduct = (body, cbSuccess, cbError) => {
        this.put('/products', body, cbSuccess, cbError);
    }

    deleteProduct = (productId, cbSuccess, cbError) => {
        this.delete(`/products`, { id: productId }, cbSuccess, cbError);
    }

    // Add group support
    listGroups = (cbSuccess, cbError) => {
        this.get(`/products/groups`, cbSuccess, cbError);
    }

    getGroup = (groupId, cbSuccess, cbError) => {
        this.get(`/products/groups/${groupId}`, cbSuccess, cbError);
    }

    createGroup = (body, cbSuccess, cbError) => {
        this.post(`/products/groups`, body, cbSuccess, cbError);
    }

    updateGroup = (body, cbSuccess, cbError) => {
        this.put(`/products/groups`, body, cbSuccess, cbError);
    }

    deleteGroup = (productGroupId, cbSuccess, cbError) => {
        this.delete(`/products/groups`, { id: productGroupId }, cbSuccess, cbError);
    }
}

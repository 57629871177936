import Unit from './index';

export default class DownloadsUnit extends Unit {

    getUsedCoupons = (body, cbSuccess, cbError) => {
        this.post('/downloads/coupons', body, cbSuccess, cbError);
    }

    listInvoices = (body, cbSuccess, cbError) => {
        this.post('/downloads/invoices-to-print', body, cbSuccess, cbError);
    }

    listReminder = (body, cbSuccess, cbError) => {
        this.post('/downloads/reminders-to-print', body, cbSuccess, cbError);
    }

    listFactoringCsv = (body, cbSuccess, cbError) => {
        this.post('/downloads/factoring-bank-export/csv', body, cbSuccess, cbError);
    }

    listFactoringInvoices = (body, cbSuccess, cbError) => {
        this.post('/downloads/factoring-bank-export/invoices', body, cbSuccess, cbError);
    }

}




export const translateDiscountTypes = (key) => {
    const translations = {
        once: 'Einmalig',
        infinite: 'Unendlich'
    }

    return translations[key]
}

export const translateDiscountStatus = (key) => {
    const translations = {
        active: 'Aktiv',
        used: 'Eingelöst',
        revoked: 'Zurückgezogen',
    }

    return translations[key]
}
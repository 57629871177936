import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from '../../helper/screens/Loading';
import wrlog from '../../helper/functions/wrlog';
import SettingMakroUnit from '../../communicator/http/SettingMakro';
import cloneArray from '../../helper/functions/cloneArray';
import { translate } from '../../helper/multilingual';
import Popup from '../../elements/popup';
import TopBar from '../../elements/topbar';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import StatisticsUnit from '../../communicator/http/Statistics';
import { Autocomplete } from '@material-ui/lab';
import ProductUnit from '../../communicator/http/Products';
import DatePicker from '../../elements/datepicker';
import { formatPercent } from '../../helper/functions/formatPercent';
import DownloadsUnit from '../../communicator/http/Downloads';
import ReactExport from "react-export-excel-xlsx-fix";
import PDFMerger from 'pdf-merger-js/browser';
import { getUserToken } from '../../helper/functions/auth';
import { zipCreator } from '../../helper/functions/zipCreator';
import axios from 'axios';
import JSZip from "jszip"
import { saveAs } from 'file-saver';
import InvoiceUnit from '../../communicator/http/Invoices';
import ReminderUnit from '../../communicator/http/Reminders';
import moment from 'moment';
import ProntoUnit from '../../communicator/http/Pronto';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const UploadToPronto = () => {
    const [loading, setLoading] = useState(false);

    const uploadToPronto = () => {
        const prontoUnit = new ProntoUnit();

        let monday = new Date();
        monday.setDate(monday.getDate() + (1 + 7 - monday.getDay()) % 7);

        prontoUnit.upload(moment(monday).format('YYYY-MM-DD'), (res) => {
            setLoading(false);
        }, () => {
            setLoading(false);
        })
    }

    return <>
        <TopBar title={"In Pronto hochladen"} />
        <p>Vorsicht! Diese Funktion importiert alle Stops des nächsten Montags in Pronto. Dieser Prozess kann nicht aufgehalten werden und die Stopps können nachträglich nicht einfach aus Pronto gelöscht werden! <br/><br/>Einmal auf den Button klicken, warten bis der Ladebalken weg ist (kann bis zu 5min dauern) Wenn danach noch der Ladebalken da ist oder in Pronto unter Imports kein Import ersichtlich ist einen Dev benachrichtigen, der in der DB nachschaut!</p>
        <Table style={{ width: '100%', marginTop: 20 }} cellpadding={10}>
            <TableRow>
                <TableCell>
                    In Pronto hochladen
                </TableCell>
                <TableCell style={{ width: 140 }}>
                    <div
                        style={{ paddingTop: 17, paddingBottom: 18 }}
                        className={`button`}
                        onClick={() => uploadToPronto()}
                    >updload</div>
                </TableCell>
            </TableRow>
        </Table>

        <Loading visible={loading} />
    </>

}

export default UploadToPronto;
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import wrlog from '../../../helper/functions/wrlog';
import SettingMakroUnit from '../../../communicator/http/SettingMakro';
import cloneArray from '../../../helper/functions/cloneArray';
import { translate } from '../../../helper/multilingual';
import Popup from '../../../elements/popup';
import TopBar from '../../../elements/topbar';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';


const Makros = () => {

    const [loading, setLoading] = useState(false);
    const [makros, setMakros] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedMakroKey, setSelectedMakroKey] = useState('new');
    const [selectedMakro, setSelectedMakro] = useState({});
    const { handleSubmit, setValue, control, formState: { errors } } = useForm();

    useEffect(() => {
        load()
    }, []);

    const load = () => {
        const settingMakroUnit = new SettingMakroUnit();
        settingMakroUnit.listMakros((res) => {
            setMakros(res);
            setPopupOpen(false);
            setLoading(false);
        }, () => { })
    }

    const onChange = (key, value) => {
        wrlog(key, value);

        let makro = cloneArray(selectedMakro);
        makro[key] = value;
        setSelectedMakro(makro);
    }

    const save = () => {
        setLoading(true);
        const settingMakroUnit = new SettingMakroUnit();

        if (selectedMakroKey === 'new') {
            settingMakroUnit.createMakro(selectedMakro, (res) => {
                load()
            }, () => { })
        } else {
            settingMakroUnit.updateMakro(selectedMakro, (res) => {
                load()
            }, () => { })
        }
    }

    return <>
        <TopBar title={"Makros"} />
        <div onClick={() => {
            setSelectedMakroKey('new');
            setSelectedMakro({});
            setPopupOpen(true);
        }} className="button">Makro erstellen</div>
        <Table style={{ width: '100%', marginTop: 20 }} cellpadding={10}>
            <TableBody>
                {makros.map((makro, key) => {
                    return <TableRow>
                        <TableCell>
                            {makro.title}
                        </TableCell>
                        <TableCell>
                            {makro.text}
                        </TableCell>
                        <TableCell style={{ width: 150 }}>
                            <Link to={'#'} className='link' onClick={() => {
                                setSelectedMakro({ ...makro });
                                setSelectedMakroKey(key);
                                setPopupOpen(true);
                            }}>bearbeiten</Link>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
        <Loading visible={loading} />
        <Popup
            close={() => setPopupOpen(false)}
            open={popupOpen}
            style={{ paddingTop: 60 }}
        >
            <form onSubmit={handleSubmit(save)}>

                <TextField
                    value={selectedMakro.title}
                    onChange={e => onChange('title', e.target.value)}
                    className={`filled ${selectedMakro.title !== undefined && selectedMakro.title !== null && selectedMakro.title !== '' ? 'valued' : ''}`}
                    id="standard-basic"
                    label={'Titel'}
                />
                <TextField
                    multiline
                    value={selectedMakro.text}
                    onChange={e => onChange('text', e.target.value)}
                    className={`filled ${selectedMakro.text !== undefined && selectedMakro.text !== null && selectedMakro.text !== '' ? 'valued' : ''}`}
                    id="standard-basic"
                    label={'Text'}
                />
                <FormControl
                    className="selectFormControl"
                >
                    <InputLabel id="type">Tag</InputLabel>
                    <Select
                        onChange={(e) => {
                            onChange('tag', e.target.value === '' ? '' : e.target.value)
                        }}
                        value={selectedMakro.tag || ""}
                    >
                        <MenuItem value={""}>kein Tag ausgewählt</MenuItem>
                        <MenuItem value={"cancel"}>Stornierung</MenuItem>
                        <MenuItem value={"pause"}>Pause</MenuItem>
                        <MenuItem value={"claim"}>Reklamation</MenuItem>
                    </Select>
                </FormControl>
                <input type="submit" style={{ width: '200px', float: 'right', bottom: 20, right: 20 }} className="button" value={selectedMakroKey !== "new" ? translate('save') : translate('add')} />
            </form>
        </Popup>
    </>

}

export default Makros;
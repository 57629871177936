import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Message from '../../elements/message';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import { translate } from '../../helper/multilingual';
import { getUserToken, hasPermission } from '../../helper/functions/auth';
import ProductUnit from '../../communicator/http/Products';
import InvoiceUnit from '../../communicator/http/Invoices';
import Pagination from '../../elements/pagination';
import wrlog from '../../helper/functions/wrlog';
import { formatDbDate } from '../../helper/functions/formatDate';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../elements/statusLabel';
import ToolTip from '../../elements/tooltip';
import Cache from '../../communicator/local';
import DatePicker from '../../elements/datepicker';
import { formatMoney } from '../../helper/functions/formatPrice';
import { translateInvoiceStatus, translatePaymentType } from '../../helper/functions/translateInvoice';
import TimeTrackingUnit from '../../communicator/http/TimeTracking';
import { translateVacationStatus } from '../../helper/functions/translateVacation';
import Popup from '../../elements/popup';
import moment from 'moment';

/**
 * 
 * @returns Invoices Overview Page
 */
const Vacations = (props) => {

    const [loading, setLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);

    const [vacations, setVacations] = useState([]);
    const [createVacationPopupOpen, setCreateVacationPopupOpen] = useState(false);
    const [newVacationFrom, setNewVacationFrom] = useState(null);
    const [newVacationTo, setNewVacationTo] = useState(null);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const timetrackingUnit = new TimeTrackingUnit();

    /**
     * initially loads products
     */
    useEffect(() => {
        load();
    }, []);

    /**
     * Loads invoices from API
     */
    const load = (cb = () => { }) => {

        setLoading(true);

        let body = {};

        if (props.userId) {
            body.admin_id = props.userId;
        }

        timetrackingUnit.listVacations(body, (res) => {
            setLoading(false);
            setVacations(res);
        }, (err) => {
            setLoading(false);
        })
    }


    const deleteVacation = (vacationId) => {
        setLoading(true);

        const body = {
            id: vacationId,
            status: 'deleted'
        };

        timetrackingUnit.updateVacation(body, (res) => {
            setLoading(false);
            load();
        }, (err) => {
            setLoading(false);
        })
    }

    const createVacation = () => {
        setLoading(true);

        let body = {
            from: moment(newVacationFrom).format('YYYY-MM-DD 00:00:00'),
            to: moment(newVacationTo).format('YYYY-MM-DD 23:59:59'),
        };

        if (props.userId) {
            body.admin_id = props.userId;
        }

        timetrackingUnit.createVacation(body, (res) => {
            setLoading(false);
            setCreateVacationPopupOpen(false);
            load();
        }, (err) => {
            setLoading(false);
        })
    }

    const updateVacation = (status, vacationId) => {
        if (!hasPermission('admin', ['can_update'])) {
            return;
        }

        setLoading(true);

        let body = {
            id: vacationId,
            status: status
        };

        timetrackingUnit.updateVacation(body, (res) => {
            setLoading(false);
            load();
        }, (err) => {
            setLoading(false);
        })
    }


    return <>
        <TopBar title={"Urlaub"} />
        <Container fluid>
            <Row style={{ marginTop: 0 }}>
                <Col md={12} style={{ textAlign: 'right' }}>
                    <div className='button' onClick={() => setCreateVacationPopupOpen(true)}>Beantragen</div>
                </Col>
            </Row>
        </Container>
        <Container fluid>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Von</TableCell>
                                <TableCell>Bis</TableCell>
                                <TableCell>Urlaubstage</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell style={{ width: 50 }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                vacations.map((vacation, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>
                                            {formatDbDate(vacation.from)}
                                        </TableCell>
                                        <TableCell>
                                            {formatDbDate(vacation.to)}
                                        </TableCell>
                                        <TableCell>
                                            {vacation.days} {parseInt(vacation.days) === 1 ? 'Tag' : 'Tage'}
                                        </TableCell>
                                        <TableCell>
                                            {(hasPermission('admin', ['can_update'])) ?
                                                <Select
                                                    className={`statusSelect ${vacation.status}`}
                                                    value={vacation.status}
                                                    style={{ width: 250 }}
                                                    onChange={e => updateVacation(e.target.value, vacation.id)}
                                                >
                                                    <MenuItem value="issued">Offen</MenuItem>
                                                    <MenuItem value="accepted">Akzeptiert</MenuItem>
                                                    <MenuItem value="declined">Abgelehnt</MenuItem>
                                                </Select>
                                                : <StatusLabel status={vacation.status}>{translateVacationStatus(vacation.status)}</StatusLabel>

                                            }
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                items={[
                                                    {
                                                        label: "löschen",
                                                        onClick: () => deleteVacation(vacation.id),
                                                    }]}
                                            ></ToolTip>
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </Col>
            </Row>
        </Container>
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />

        <Popup
            close={() => setCreateVacationPopupOpen(false)}
            open={createVacationPopupOpen}
            style={{ minHeight: 350 }}
        >
            <DatePicker
                onChange={(val) => {
                    console.log(val)
                    setNewVacationFrom(val);
                }}
                value={newVacationFrom}
                label={"Von"}
                date={newVacationFrom}
            />
            <DatePicker
                onChange={(val) => {
                    setNewVacationTo(val);
                }}
                value={newVacationTo}
                label={"Bis"}
                date={newVacationTo}
            />

            <div
                className="button"
                style={{ marginRight: 20 }}
                onClick={() => createVacation()}
            >beantragen</div>
        </Popup>

        <Loading visible={loading} />
    </>

}

export default Vacations;
import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import ProductUnit from '../../../../communicator/http/Products';
import { translate } from '../../../../helper/multilingual';
import Message from '../../../../elements/message';
import NotFound from '../../../NotFound';
import { hasPermission } from '../../../../helper/functions/auth';
import ImageInput from '../../../../elements/imageInput';
import { formatMoney } from '../../../../helper/functions/formatPrice';
import wrlog from '../../../../helper/functions/wrlog';
import { isJsonString } from '../../../../helper/functions/isJsonString';
import { formatPercent } from '../../../../helper/functions/formatPercent';


/**
 * 
 * @returns Admin Product Detail Page
 */
const ProductGroupSingle = () => {

    const params = useParams();

    const productGroupId = params.id;

    const [loading, setLoading] = useState(productGroupId !== 'new');
    const [productGroup, setProductGroup] = useState(true);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [askDeleteMessageOpen, setAskDeleteMessageOpen] = useState(false);
    const [deliveryDatesUpdateMessageOpen, setDeliveryDatesUpdateMessageOpen] = useState(false);
    const [deliveryDaysDefault, setDeliveryDaysDefault] = useState([]);

    const [imageData, setImageData] = useState(null);

    const [is404, setIs404] = useState(false);

    const { handleSubmit, setValue, control, formState: { errors }, getValues } = useForm();

    /**
     * Inititally check if productGroupId is "new" to check if product is updated or new product is created
     */
    useEffect(() => {

        const productUnit = new ProductUnit();

        /**
         * if productGroupId !== set set 404 = true
         */
        if (productGroupId === undefined || productGroupId === null || productGroupId === "") {
            setIs404(true);
            return;
        }

        /**
         * if productGroupId !== new --> update product & load productData
         */
        if (productGroupId !== 'new') {

            // Check if product can update || delete

            if (!hasPermission('product', ['can_update', 'can_delete'])) {
                setIs404(true);
                return;
            }

            productUnit.getGroup(productGroupId, (product) => {

                setLoading(false);

                setValue('sku', product.sku);
                setValue('title', product.title);
                setValue('description', product.description);
                setValue('futurePurchasesInterval', product.futurePurchasesInterval);
                setValue('intervalOfDelivery', product.intervalOfDelivery);
                setValue('deliveryDays', product.deliveryDays !== undefined && isJsonString(product.deliveryDays) ? JSON.parse(product.deliveryDays) : []);
                setValue('maxPauseTime', product.maxPauseTime);
                setValue('cutOffDate', product.cutOffDate);
                setValue('price', formatMoney(product.price, false));
                setValue('reducedPrice', formatMoney(product.reducedPrice, false));
                setValue('taxRate', formatPercent(product.taxRate, true));

                setDeliveryDaysDefault(product.deliveryDays !== undefined && isJsonString(product.deliveryDays) ? JSON.parse(product.deliveryDays) : []);

                setProductGroup(product);

            }, () => {
                setIs404(true);
            })
        } else {
            if (!hasPermission('product', ['can_create'])) {
                setIs404(true);
                return;
            }

            setValue('intervalOfDelivery', "");
            setValue('maxPauseTime', "");
            setValue('cutOffDate', "");
            setValue('deliveryDays', []);

        }


    }, [productGroupId]);

    /**
     * Submit productData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const productUnit = new ProductUnit();

        let deliveryDaysChanges = false;
        if (data.deliveryDays.length !== deliveryDaysDefault.length) {
            deliveryDaysChanges = true;
        }

        deliveryDaysDefault.forEach(deliveryDay => {
            if (!data.deliveryDays.includes(deliveryDay)) {
                deliveryDaysChanges = true;
            }
        })
        data.deliveryDays.forEach(deliveryDay => {
            if (!deliveryDaysDefault.includes(deliveryDay)) {
                deliveryDaysChanges = true;
            }
        })

        setLoading(true);

        let body = {
            title: data.title,
        }

        if (imageData !== null) {
            body.image = {
                base64: imageData.base64,
                title: imageData.title,
                mime_type: imageData.mimeType
            }
        }


        // Success function for Create Product
        const cbSuccess = (res) => {
            setLoading(false);
            window.location.replace(`/products/groups/${res.id}`); //Replace Location to new created product & reload page
        }

        // Success function for Update Product
        const cbSuccessUpdate = (res) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
            setErrorMessageSmall(true);
            setErrorMessageTitle("Änderung erfolgreich");
            setErrorMessageBody("");

            if (deliveryDaysChanges) {
                setDeliveryDatesUpdateMessageOpen(true);
            }

            setProductGroup(data);
        }

        const cbError = (err) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        if (productGroupId === 'new') {
            productUnit.createGroup(body, cbSuccess, cbError);
        } else {
            body.id = productGroupId;
            productUnit.updateGroup(body, cbSuccessUpdate, cbError);
        }
    }

    /**
     * Delete current product
     */
    const deleteProductGroup = () => {
        const productUnit = new ProductUnit();

        setLoading(true);
        setAskDeleteMessageOpen(false);

        const cbSuccess = (res) => {
            window.location.replace(`/products/groups`);
        }

        const cbError = (err) => {
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        productUnit.deleteGroup(productGroupId, cbSuccess, cbError);
    }

    if (is404 === true) {
        return <NotFound />;
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to="/products/groups">zurück</Link><br /><br />
                        <h1>{params.id !== "new" ? <>{productGroup.title} {translate('edit')}</> : "Neues Produkt hinzufügen"} </h1>
                        {params.id !== "new" && hasPermission('product', ['can_delete']) && <div
                            className='small_button'
                            style={{ color: 'red' }}
                            onClick={() => setAskDeleteMessageOpen(true)}>
                            Produktgruppe löschen
                        </div>}
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <Row style={{ marginTop: 50 }}>
                        <Col md={7}>
                            <FormController
                                name={"title"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.title}
                                        id="standard-basic"
                                        label={translate('title') + '*'}
                                    />
                                )}
                            />
                        </Col>
                        <Col md={5}>
                            <ImageInput
                                value={imageData?.base64}
                                imageUrl={productGroup.imageUrl}
                                onChange={setImageData}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 100 }}>
                        <Col md={5}>
                            {
                                ((hasPermission('product', ['can_update']) && params.id !== "new") || (hasPermission('product', ['can_create']) && params.id === "new")) &&
                                <input type="submit" style={{ width: '200px', position: 'fixed', bottom: 20, right: 20 }} className="button" value={params.id !== "new" ? translate('save') : translate('add')} />
                            }
                        </Col>
                        <Col md={7}>
                        </Col>
                    </Row>
                </form>
            </Container>
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />

            <Message
                open={askDeleteMessageOpen}
                type={'success'}
                small={false}
                title={translate('sure_delete_title')}
                body={translate('sure_delete_body')}
                buttonText={translate('yes_delete')}
                buttonAction={() => deleteProductGroup()}
                buttonTwoText={translate('cancel')}
                buttonTwoAction={() => setAskDeleteMessageOpen(false)}
                buttonTwoStyle={{ color: 'red' }}
            />

            <Message
                open={deliveryDatesUpdateMessageOpen}
                type={'info'}
                small={false}
                title={"Wichtiger Hinweis"}
                body={"Es wird einige Minuten dauern, bis alle Versanddaten umgestellt sind."}
                buttonText={"OK"}
                buttonAction={() => setDeliveryDatesUpdateMessageOpen(false)}
            />

            <Loading visible={loading} />
        </>

    )

}

export default ProductGroupSingle;
import Unit from './index';

export default class PermissionUnit extends Unit {

    load = (userId, cbSuccess, cbError) => {
        this.get('/permissions/'+userId, cbSuccess, cbError);
    }

    set = (data, cbSuccess, cbError) => {
        this.post('/permissions', data, cbSuccess, cbError);
    }

}




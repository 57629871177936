import Unit from './index';

export default class ProcurementSupplierUnit extends Unit {

    list = (search, cbSuccess, cbError) => {
        this.post('/procurementsupplier/list', { search: search }, cbSuccess, cbError);
    }

    getSupplier = (supplierId, cbSuccess, cbError) => {
        this.get('/procurementsupplier/'+supplierId, cbSuccess, cbError);
    }

    create = (body, cbSuccess, cbError) => {
        this.post('/procurementsupplier', body, cbSuccess, cbError);
    }

    update = (data, cbSuccess, cbError) => {
        this.put('/procurementsupplier', data, cbSuccess, cbError);
    }

}




export const translateVacationStatus = (status) => {
    const translations = {
        issued: 'offen',
        accepted: 'accepted',
        declined: 'abgelehnt',
        deleted: 'gelöscht'
    };

    if (translations[status] === undefined) {
        return 'offen';
    }
    return translations[status];
}

import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './style.module.scss';

const Sidebar = ({ children, onScroll }) => {

    return <div onScroll={onScroll} className={styles.container}>
        {children}
    </div>

}

export default Sidebar;
import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import TopBar from '../../../elements/topbar';
import { translate } from '../../../helper/multilingual';
import { hasPermission } from '../../../helper/functions/auth';
import Cache from '../../../communicator/local';
import ProductUnit from '../../../communicator/http/Products';
import Pagination from '../../../elements/pagination';
import { formatMoney } from '../../../helper/functions/formatPrice';

/**
 * 
 * @returns Product Groups Overview Page
 */
const ProductGroups = () => {

    const [loading, setLoading] = useState(false);
    const [productGroups, setProductGroups] = useState([]);

    const productUnit = new ProductUnit();

    /**
     * initially loads products
     */
    useEffect(() => {
        loadProductGroups();
    }, []);

    /**
     * Loads admin users from API
     */
    const loadProductGroups = () => {

        setLoading(true);

        productUnit.listGroups((res) => {
            setLoading(false);
            setProductGroups(res);
        }, (err) => {
            setLoading(false);
        })
    }

    return <>
        <TopBar title={"Produktgruppen"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={8}></Col>
                <Col md={4} style={{ textAlign: 'right' }}>
                    {hasPermission('admin', ['can_create']) && <Link
                        style={{ paddingTop: 17, paddingBottom: 18 }}
                        to={'/products/groups/new'} className={'button'}>Neue Gruppe hinzufügen</Link>}
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Titel</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                productGroups.map((group, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>
                                            {group.title}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>
                                            {hasPermission('product', ['can_update', 'can_delete']) && <Link to={`/products/groups/${group.id}`}>bearbeiten</Link>}
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </Col>
            </Row>
        </Container>
        <Loading visible={loading} />
    </>

}

export default ProductGroups;
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './style.module.scss';

const Footer = () => {

    const location = useLocation();

    if(location.pathname.includes('/ticketing')){
        return null;
    }

    return <div className={styles.container}>
        afreshed admin dashboard - Version 1.3.1 - © afreshed GmbH - All rights reserved
    </div>

}

export default Footer;
import Unit from './index';

export default class TimeTrackingUnit extends Unit {

    list = (body, cbSuccess, cbError) => {
        this.post(`/employee/timeslot/list`, body, cbSuccess, cbError);
    }

    overview = (body, cbSuccess, cbError) => {
        this.post(`/employee/timeslot/overview`, body, cbSuccess, cbError);
    }

    deleteEntry = (timeslotId, cbSuccess, cbError) => {
        this.delete(`/employee/timeslot`, { id: timeslotId }, cbSuccess, cbError);
    }

    create = (body, cbSuccess, cbError) => {
        this.post(`/employee/timeslot`, body, cbSuccess, cbError);
    }

    // VACATIONS

    listVacations = (body, cbSuccess, cbError) => {
        this.post(`/employee/vacations/list`, body, cbSuccess, cbError);
    }
    createVacation = (body, cbSuccess, cbError) => {
        this.post(`/employee/vacation`, body, cbSuccess, cbError);
    }

    updateVacation = (body, cbSuccess, cbError) => {
        this.put(`/employee/vacation`, body, cbSuccess, cbError);
    }

}




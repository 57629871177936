import { Checkbox, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import CustomerUnit from '../../../communicator/http/Customers';
import wrlog from '../../../helper/functions/wrlog';
import { hasPermission } from '../../../helper/functions/auth';

/**
 * 
 * @returns Contact Data Form Items for Customer-Screen
 */
const ContactDataForm = ({ customerId, phone, firstName, lastName, company, address, streetNumber, billing, email, note, type, vatNumber, setShow, setCustomer, isCreate, setError }) => {

    const params = useParams();

    const userId = params.id;

    const [loading, setLoading] = useState(false);
    const [selectedType, setSelectedType] = useState(type);

    const { handleSubmit, setValue, control, formState: { errors }, watch } = useForm();

    useEffect(() => {
        if (!isCreate) {
            setValue('type', type);
            setValue('vatNumber', vatNumber);
            setValue('firstName', firstName);
            setValue('lastName', lastName);
            setValue('company', company);
            setValue('address1', address.address1);
            setValue('streetNumber', address.streetNumber);
            setValue('address2', address.address2);
            setValue('postcode', address.postcode);
            setValue('city', address.city);
            setValue('country', address.country);
            setValue('email', email);
            setValue('phone', phone);
            setValue('note', note);

            setValue('billingAddress1', billing.address1);
            setValue('billingAddress2', billing.address2);
            setValue('billingPostcode', billing.postcode);
            setValue('billingCity', billing.city);
            setValue('billingCountry', billing.country);

        } else {
            setValue('type', "");
        }
    }, [firstName]);


    watch(({ type }) => {
        setSelectedType(type);
    })

    /**
     * Submit userData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const customerUnit = new CustomerUnit();

        wrlog("DATA", data);

        setLoading(true);

        let body = {
            type: data.type,
            first_name: data.firstName,
            last_name: data.lastName,
            address_1: data.address1,
            street_number: data.streetNumber,
            address_2: data.address2,
            postcode: data.postcode,
            city: data.city,
            country: data.country,
            company: data.company,
            vat_number: data.vatNumber,
            billing_address_1: data.billingAddress1,
            billing_address_2: data.billingAddress2,
            billing_postcode: data.billingPostcode,
            billing_city: data.billingCity,
            email: data.email,
            phone: data.phone,
            note: data.note,
            legal_text: true,
        };

        const cbError = (err) => {
            setLoading(false)
            setError(err)
        }

        // Success function for Update User
        const cbSuccessUpdate = (res) => {

            customerUnit.getCustomer(customerId, (res) => {
                setCustomer(res);
                setShow(false);
                setLoading(false);
            }, cbError)

        }

        // Success function for Creating User
        const cbSuccessCreate = (res) => {
            setLoading(false);
            window.location.replace(`/customers/${res.id}`); // Replace Location to new created customer & reload page
        }

        if (isCreate) {
            body.password = data.password;
            customerUnit.createCustomer(body, cbSuccessCreate, cbError);
        } else {
            body.id = customerId;
            customerUnit.updateCustomer(body, cbSuccessUpdate, cbError);
        }
    }

    return (
        <>
            <Container fluid style={{ padding: 0 }}>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <Row style={{ marginTop: 50 }}>
                        <Col md={6}>
                            <FormController
                                name={"type"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => {

                                    if (value == undefined || value == null) {
                                        return <></>
                                    }

                                    return <FormControl
                                        className="selectFormControl"
                                    >
                                        <InputLabel id="type">Kundentyp</InputLabel>
                                        <Select
                                            onChange={onChange}
                                            value={value}
                                        >
                                            <MenuItem value={"b2b"}>Businesskunde</MenuItem>
                                            <MenuItem value={"b2c"}>Privatkunde</MenuItem>
                                        </Select>
                                    </FormControl>
                                }}
                            />
                            <FormController
                                name={"firstName"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.firstName}
                                        id="standard-basic"
                                        label={translate('firstname') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"lastName"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.lastName}
                                        id="standard-basic"
                                        label={translate('lastname') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"company"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.company}
                                        id="standard-basic"
                                        label={translate('company') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"vatNumber"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.vatNumber}
                                        id="standard-basic"
                                        label={"UID-Nummber"}
                                    />
                                )}
                            />
                            <h4>Lieferadresse</h4>
                            <Container fluid style={{ padding: 0 }}>
                                <Row>
                                    <Col md={8}>
                                        <FormController
                                            name={"address1"}
                                            rules={{
                                                required: true,
                                            }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                <TextField
                                                    value={value}
                                                    onChange={onChange}
                                                    className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                    error={errors.address1}
                                                    id="standard-basic"
                                                    label={translate('address') + '*'}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <FormController
                                            name={"streetNumber"}
                                            rules={{
                                                required: true,
                                            }}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                                <TextField
                                                    value={value}
                                                    onChange={onChange}
                                                    className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                    error={errors.streetNumber}
                                                    id="standard-basic"
                                                    label={"Hausnummer" + '*'}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Container>


                            <FormController
                                name={"address2"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.address2}
                                        id="standard-basic"
                                        label={translate('address_additional')}
                                    />
                                )}
                            />
                            <FormController
                                name={"postcode"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.postcode}
                                        id="standard-basic"
                                        label={translate('postcode') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"city"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.city}
                                        id="standard-basic"
                                        label={translate('city') + '*'}
                                    />
                                )}
                            />

                            <h4>Rechnungsadresse</h4>
                            <FormController
                                name={"billingAddress1"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.billingAddress1}
                                        id="standard-basic"
                                        label={translate('address') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"billingAddress2"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.billingAddress2}
                                        id="standard-basic"
                                        label={translate('address_additional')}
                                    />
                                )}
                            />
                            <FormController
                                name={"billingPostcode"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.billingPostcode}
                                        id="standard-basic"
                                        label={translate('postcode') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"billingCity"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.billingCity}
                                        id="standard-basic"
                                        label={translate('city') + '*'}
                                    />
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <FormController
                                name={"email"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.email}
                                        id="standard-basic"
                                        label={translate('email') + '*'}
                                        type="email"
                                    />
                                )}
                            />
                            <FormController
                                name={"phone"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.email}
                                        id="standard-basic"
                                        label={translate('phone')}
                                        type="phone"
                                    />
                                )}
                            />
                            {
                                isCreate && <FormController
                                    name={"password"}
                                    rules={{
                                        required: true,
                                    }}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                            error={errors.password}
                                            id="standard-basic"
                                            label={translate('password') + '*'}
                                            type="password"
                                        />
                                    )}
                                />

                            }

                            <FormController
                                name={"note"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        multiline
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.email}
                                        id="standard-basic"
                                        label={translate('note')}
                                        type="note"
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                    {isCreate ?
                        hasPermission('customer', ['can_create']) &&
                        <input
                            type="submit"
                            className='button'
                            value={translate('add')}
                        />

                        :
                        hasPermission('customer', ['can_update']) &&
                        <div style={{ top: 20, right: 30, position: 'absolute', display: 'inline-block', width: 'max-content' }}>
                            <div
                                onClick={() => setShow(false)}
                                style={{ color: 'var(--red-color)', display: 'inline-block', cursor: 'pointer' }}
                            >
                                {translate('abbrechen')}
                            </div>
                            <input
                                type="submit"
                                style={{ color: 'var(--green-color)', display: 'inline-block', marginLeft: 15, padding: 0, cursor: 'pointer', background: 'none', border: 'none' }}
                                value={translate('save')}
                            />
                        </div>
                    }
                </form>
            </Container>
            <Loading visible={loading} containerStyle={{ borderRadius: 15, width: '100%', height: '100%', position: 'absolute' }} />
        </>

    )

}

export default ContactDataForm;
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import { translate } from '../../helper/multilingual';
import { getUserToken, hasPermission } from '../../helper/functions/auth';
import Cache from '../../communicator/local';
import ProductUnit from '../../communicator/http/Products';
import Pagination from '../../elements/pagination';
import { formatMoney } from '../../helper/functions/formatPrice';
import { CalendarContainer } from 'react-datepicker';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import 'moment/locale/de'
import ProcurementUnit from '../../communicator/http/Procurement.js';
import ProcurementDeliveryUnit from '../../communicator/http/ProcurementDelivery';
import ProcurementProductUnit from '../../communicator/http/ProcurementProduct';
import ProcurementContentUnit from '../../communicator/http/ProcurementContent';
import { Autocomplete } from '@material-ui/lab';
import wrlog from '../../helper/functions/wrlog';
import ProcurementSupplierUnit from '../../communicator/http/ProcurementSupplier';
import { API_ENDPOINT } from '../../config';

moment.locale('de')
/**
 * 
 * @returns Product Overview Page
 */
const Procurement = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [combinesProducts, setCombinesProducts] = useState([]);
    const [disabledNewRow, setDisabledNewRow] = useState(false);
    const [reloading, setReloading] = useState(false);

    const [selectedDates, setSelectedDates] = useState([new Date()]);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [selectedMonday, setSelectedMonday] = useState(null);
    const [deliveries, setDeliveries] = useState([]);
    const [delivery, setDelivery] = useState({});
    const [selectedCombineKey, setSelectedCombineKey] = useState({});
    const [isInit, setIsInit] = useState(true);

    const productUnit = new ProductUnit();
    const procurementProductUnit = new ProcurementProductUnit();
    const procurementDeliveryUnit = new ProcurementDeliveryUnit();
    const procurementContentUnit = new ProcurementContentUnit();
    const procurementSupplierUnit = new ProcurementSupplierUnit();
    const procurementUnit = new ProcurementUnit()

    const params = useParams();

    /**
     * initially loads products
     */
    useEffect(() => {
        loadCombinedProducts();
        loadProducts();
        loadSuppliers();
        handleDateChange(new Date(), false);

    }, []);

    useEffect(() => {
        setLoading(true);
        if (isInit) {
            setIsInit(false);
            return;
        }
        if (params.id !== 'new') {
            loadDelivery();
        } else {
            createDelivery();
        }
    }, [params, selectedCombineKey]);

    useEffect(() => {
        if (selectedWeek !== null) {
            loadDeliveries();
        }
    }, [selectedDates])

    /**
     * Loads admin users from API
     */
    const loadCombinedProducts = () => {

        setLoading(false);

        productUnit.getProductCombineKeys((res) => {
            setLoading(false);
            setCombinesProducts(res);
        }, (err) => {
            setLoading(false);
        })
    }

    /**
     * Loads admin users from API
     */
    const loadProducts = () => {
        procurementProductUnit.list('', (res) => {
            setLoading(false);
            console.log(res.results)
            setProducts(res.results);
        }, (err) => {
            setLoading(false);
        })
    }

    /**
         * Loads admin users from API
         */
    const loadSuppliers = () => {
        procurementSupplierUnit.list('', (res) => {
            setSuppliers(res.results);
        }, (err) => {
        })
    }

    /**
     * Loads admin users from API
     */
    const loadDelivery = () => {

        setLoading(true);

        procurementDeliveryUnit.getDelivery(params.id, (res) => {
            console.log('res', res)
            setDisabledNewRow(false);
            setLoading(false);
            setDelivery(res);
            setReloading(true);
            setSelectedCombineKey(res.afProductId);
            setSelectedMonday(res.deliveryDate);

            let monday = new Date(res.deliveryDate);
            monday.setDate(monday.getDate() + 6);

            setSelectedDates([new Date(res.deliveryDate), monday]);
            setTimeout(() => {
                setReloading(false)
            }, 100)
        }, (err) => {
            setLoading(false);
        })
    }

    /**
     * Loads admin users from API
     */
    const loadDeliveries = () => {

        setLoading(false);

        let d = new Date(selectedDates[0]);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        let monday = new Date(d.setDate(diff));
        monday = moment(monday.toString()).startOf('week').format('YYYY-MM-DD').toString()

        procurementDeliveryUnit.list(monday, null, (res) => {
            setLoading(false);
            setSelectedMonday(monday);

            setDeliveries(res);
        }, (err) => {
            setLoading(false);
        })
    }

    const handleDateChange = (value) => {
        let start = new Date(moment(value).startOf('week').format('YYYY-MM-DD').toString());
        let end = new Date(moment(value).endOf('week').format('YYYY-MM-DD').toString());

        let dates = [start, end];


        let d = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number

        setSelectedDates(dates)
        setSelectedWeek(weekNo)
        navigate(`/procurement`);
    }

    const createDelivery = () => {

        const body = {
            title: `${selectedCombineKey}-KW${selectedWeek}`,
            delivery_date: selectedMonday,
            af_product_id: selectedCombineKey
        };

        procurementDeliveryUnit.create(body, res => {
            loadDeliveries();
            navigate(`/procurement/${res.id}`);
        }, err => {

        })
    }

    const changeContent = (value, key, id) => {
        wrlog("ID", id);
        if (id === undefined) {
            addContent(value, key);
            return;
        }

        let body = {
            id: id,
        }
        body[key] = value;

        procurementContentUnit.update(body, res => {
            wrlog(res.id);
        }, err => {

        });
    }

    const deleteRow = (id) => {
        procurementContentUnit.update({ id: id, status: 'deleted' }, res => {
            loadDelivery();
        }, err => {

        });
    }

    const addContent = (value, key) => {
        setDisabledNewRow(true);

        let body = {
            delivery_id: delivery.id
        }
        body[key] = value;
        procurementContentUnit.create(body, res => {
            loadDelivery();
        }, err => {

        });
    }

    const downloadProcurementList = () => {
        setLoading(true);

        procurementUnit.downloadProcurementList(selectedMonday, res => {
            setLoading(false)
        }, err => {

        });
    }

    const downloadPickingList = () => {
        setLoading(true);

        procurementUnit.downloadProcurementPickingLists(selectedMonday, res => {
            setLoading(false)
        }, err => {

        });
    }
    const downloadPickingListBusiness = () => {
        setLoading(true);

        procurementUnit.downloadProcurementPickingListsBusiness(selectedMonday, res => {
            setLoading(false)
        }, err => {

        });
    }



    const contentRow = (item = {}) => {

        if (reloading) {
            return null;
        }

        let defaultProduct = {};
        let defaultSupplier = {};

        products.forEach(product => {
            if (product.id === item?.productId) {
                defaultProduct = { ...product };
            }
        })

        suppliers.forEach(supplier => {
            if (supplier.id === item?.supplierId) {
                defaultSupplier = { ...supplier };
            }
        })

        return <TableRow className={disabledNewRow && item.id === undefined ? 'disabled' : ''}>
            <TableCell
                style={{ paddingLeft: 3, paddingRight: 3 }}
            >
                <Autocomplete
                    options={products}
                    getOptionLabel={(option) => option.title}
                    defaultValue={defaultProduct}
                    disabled={products.length < 1}
                    onChange={(event, value) => {
                        if (value !== null) {
                            changeContent(value.id, 'product_id', item.id)
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            disabled={products.length < 1}
                            label={products.length < 1 ? "Produkte werden geladen..." : "Produkte"}
                            className="filled noMargin small"
                        />
                    )}
                />
            </TableCell>
            <TableCell
                style={{ paddingLeft: 3, paddingRight: 3 }}
            >
                <TextField
                    style={{ margin: 0 }}
                    defaultValue={item.price}
                    onBlur={e => changeContent(e.target.value, 'price', item.id)}
                    label={'€ / kg'}
                    className="filled noMargin small"
                />
            </TableCell>
            <TableCell
                style={{ paddingLeft: 3, paddingRight: 3 }}
            >
                <TextField
                    style={{ margin: 0 }}
                    defaultValue={item.quantity}
                    onBlur={e => changeContent(e.target.value, 'quantity', item.id)}
                    label={'Menge'}
                    className="filled noMargin small"
                />
            </TableCell>
            <TableCell
                style={{ paddingLeft: 3, paddingRight: 3 }}
            >
                <FormControl
                    className="selectFormControl noMargin small"
                >
                    <InputLabel id="type">Einheit</InputLabel>
                    <Select
                        onChange={e => changeContent(e.target.value, 'quantity_unit', item.id)}
                        defaultValue={item.quantityUnit}
                        className=''
                    >
                        <MenuItem value={"kg"}>kg</MenuItem>
                        <MenuItem value={"stk"}>Stk.</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell
                style={{ paddingLeft: 3, paddingRight: 3 }}
            >
                <Autocomplete
                    options={suppliers}
                    getOptionLabel={(option) => option.companyTitle}
                    disabled={suppliers.length < 1}
                    defaultValue={defaultSupplier}
                    onChange={(event, value) => {
                        if (value !== null) {
                            changeContent(value.id, 'supplier_id', item.id)
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            disabled={suppliers.length < 1}
                            label={suppliers.length < 1 ? "Lieferanten werden geladen..." : "Lieferanten"}
                            className="filled small"
                        />
                    )}
                />
            </TableCell>
            <TableCell
                style={{ paddingLeft: 3, paddingRight: 3 }}
            >
                <div
                    onClick={() => deleteRow(item.id)}
                    className='button' style={{ padding: '3px 10px' }}>x</div>
            </TableCell>
        </TableRow>
    }

    return <>
        <TopBar title={"Lieferungen"} />
        <Container fluid>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={4}>
                    <Calendar
                        style={{ width: '100%' }}
                        showWeekNumbers={true}
                        onChange={e => handleDateChange(e)}
                        value={selectedDates}
                    />
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Produkt</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                combinesProducts.map((product, key) => {
                                    let deliveryId = null;
                                    let contentCount = 0;
                                    deliveries.forEach((delivery) => {
                                        if (delivery.afProductId === product.title) {
                                            deliveryId = delivery.id;
                                            contentCount = delivery.count;
                                        }
                                    })
                                    return <TableRow style={deliveryId === null ? { backgroundColor: '#FCC8C8' } : {}} key={key}>
                                        <TableCell>
                                            {product.title}
                                        </TableCell>
                                        <TableCell>
                                            {deliveryId !== null ? 'Erledigt' : 'Inhalt fehlt'}
                                        </TableCell>
                                        <TableCell>
                                            {contentCount}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>
                                            {hasPermission('product', ['can_update', 'can_delete']) && <Link
                                                onClick={() => {
                                                    setSelectedCombineKey(product.title)
                                                }}
                                                to={`/procurement/${deliveryId === null ? 'new' : deliveryId}`}>{deliveryId === null ? 'hinzufügen' : 'bearbeiten'}</Link>}
                                        </TableCell>
                                    </TableRow>

                                })
                            }
                        </TableBody>
                    </Table>
                    <div
                        className='button'
                        style={{ marginTop: 20 }}
                        onClick={downloadProcurementList}
                    >Einkaufsliste herunterladen</div>
                    <div
                        className='button'
                        style={{ marginTop: 20 }}
                        onClick={downloadPickingList}

                    >Packlisten B2C herunterladen</div>
                    <div
                        className='button'
                        style={{ marginTop: 20 }}
                        onClick={downloadPickingListBusiness}

                    >Packlisten B2B herunterladen</div>


                    <a
                        href={`${API_ENDPOINT}/procurement/download-picking-labels/${selectedMonday}/${window.btoa(getUserToken())}`}
                        target={'_blank'}
                        className='button'
                        style={{ marginTop: 20 }}

                    >Etiketten herunterladen</a>
                </Col>
                <Col md={8}>
                    {
                        delivery.title === undefined ?
                            '' :
                            <>
                                <h4>{delivery.title}</h4>
                                <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Produkt</TableCell>
                                            <TableCell style={{ width: 100 }}>Preis</TableCell>
                                            <TableCell style={{ width: 100 }}>Menge</TableCell>
                                            <TableCell style={{ width: 140 }}>Einheit</TableCell>
                                            <TableCell style={{ width: 150 }}>Lieferant</TableCell>
                                            <TableCell style={{ width: 37 }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            delivery.content?.map(item => {
                                                return contentRow(item)
                                            })
                                        }
                                        {contentRow()}
                                    </TableBody>
                                </Table>
                            </>
                    }
                </Col>
            </Row>
        </Container>
        <Loading visible={loading} />
    </>

}

export default Procurement;
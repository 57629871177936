import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import StatisticsUnit from '../../../communicator/http/Statistics';
import Loading from '../../../helper/screens/Loading';
import wrlog from '../../../helper/functions/wrlog';
import { hasPermission } from '../../../helper/functions/auth';

// import "~react-vis/dist/style";
import DatePicker from '../../../elements/datepicker';
import { Card } from '@material-ui/core';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, MarkSeries, Hint } from 'react-vis';
import moment from 'moment';
import { formatMoney } from '../../../helper/functions/formatPrice';

const NewCustomersSalesOverview = ({ start, end }) => {

    let monthStart = start;
    let monthEnd = end;

    const [loading, setLoading] = useState(false);
    const [filterSalesDate, setFilterSalesDate] = useState({
        from: new Date(monthStart),
        to: new Date(monthEnd)
    });

    const [sales, setSales] = useState(0);
    const [openReceivables, setOpenReceivables] = useState(0);
    const [newCustomers, setNewCustomers] = useState(0);


    useEffect(async () => {

        if (filterSalesDate.from === null || filterSalesDate.to === null) {
            return;
        }

        const statisticsUnit = new StatisticsUnit();
        statisticsUnit.getSales(moment(filterSalesDate.from).format('yyyy-MM-DD'), moment(filterSalesDate.to).format('yyyy-MM-DD'), (res) => {
            let total = 0;
            res.forEach((sale, key) => {
                total += sale.total;
            })

            setSales(total);
        }, () => { })

        statisticsUnit.getReceivables(moment(filterSalesDate.from).format('yyyy-MM-DD'), moment(filterSalesDate.to).format('yyyy-MM-DD'), (res) => {
            setOpenReceivables(res.total);
        }, () => { })

        statisticsUnit.getNewCustomers(moment(filterSalesDate.from).format('yyyy-MM-DD'), moment(filterSalesDate.to).format('yyyy-MM-DD'), (res) => {
            setNewCustomers(res.total);
        }, () => { })
    }, [filterSalesDate])

    if (!hasPermission('admin', 'can_read')) {
        return <></>;
    }

    return <>
        <DatePicker
            onChange={(val) => {
                setFilterSalesDate({
                    from: val[0],
                    to: val[1]
                });
            }}
            value={filterSalesDate.from}
            label={"Datum"}
            startDate={filterSalesDate.from}
            endDate={filterSalesDate.to}
            className={loading ? 'disabled' : undefined}
            selectsRange
        />
        <Card style={{ marginTop: 0, marginBottom: 50, height: 215 }}>
            {
                loading ?
                    <Loading visible={loading} relative containerStyle={{ height: 340 }} />
                    :
                    <Container fluid style={{ padding: 20 }}>
                        <Row>
                            <Col md={12}
                                style={{ paddingBottom: 20 }}
                            >
                                <h2>
                                    {formatMoney(sales)}
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                    <div style={{}}>
                                        <strong>Offene Forderungen</strong>
                                        <h3>{formatMoney(openReceivables)}</h3>
                                    </div>
                                    <div style={{ width: 1, backgroundColor: 'black', height: 70, display: 'block' }}></div>
                                    <div style={{}}>
                                        <strong>Neue Kunden</strong>
                                        <h3>{newCustomers}</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }

        </Card>
    </>

}

export default NewCustomersSalesOverview;
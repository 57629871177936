import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ServerLogUnit from '../../communicator/http/ServerLog';
import { translate } from '../../helper/multilingual';
import TopBar from '../../elements/topbar';
import ReactHtmlParser from 'react-html-parser';


const ServerLog = () => {

    const [serverLog, setServerLog] = useState("");

    useEffect(() => {
        
        load();
        setInterval(load, 1000);

    }, [])

    const load = () => {
        const serverLogUnit = new ServerLogUnit();
        serverLogUnit.loadLog((res) => {

            let _res = res.replace(/(?:\r\n|\r|\n)/g, '<br />')
            setServerLog(_res);
        }, () => { })
    }


    return <>
        <Container >
            <Row>
                <Col md={12}>
                    {ReactHtmlParser(serverLog)}
                </Col>
            </Row>
        </Container>
    </>

}

export default ServerLog;
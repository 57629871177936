import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Message from '../../elements/message';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import { translate } from '../../helper/multilingual';
import { hasPermission } from '../../helper/functions/auth';
import ProductUnit from '../../communicator/http/Products';
import SubscriptionUnit from '../../communicator/http/Subscriptions';
import Pagination from '../../elements/pagination';
import wrlog from '../../helper/functions/wrlog';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../elements/statusLabel';
import ToolTip from '../../elements/tooltip';
import Cache from '../../communicator/local';
import Popup from '../../elements/popup';
import DatePicker from '../../elements/datepicker';
import { DateRangePicker, Calendar } from 'react-date-range';
import { de } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { CSVLink } from "react-csv";

/**
 * 
 * @returns Subscriptions Overview Page
 */
const Subscriptions = () => {

    const [loading, setLoading] = useState(false);
    const [csvLoading, setCsvLoading] = useState(false);

    const [subscriptions, setSubscriptions] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchNameTerm, setSearchNameTerm] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterPostcode, setFilterPostcode] = useState("");
    const [filterStatuses, setFilterStatuses] = useState("");
    const [filterDeliveryDate, setFilterDeliveryDate] = useState(null);
    const [filterProducts, setFilterProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isInit, setIsInit] = useState(true);
    const [pausePopupOpen, setPausePopupOpen] = useState(false);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
    const [pauseRange, setPauseRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const perPage = 25;

    const productUnit = new ProductUnit();
    const subscriptionUnit = new SubscriptionUnit();

    const queryArgsCache = new Cache('subscription.queryArgs', true);

    /**
     * initially loads products
     */
    useEffect(() => {

        let queryArgs = queryArgsCache.get();

        if (queryArgs !== false) {
            setSearchNameTerm(queryArgs.search);
            setFilterType(queryArgs.type);
            setFilterPostcode(queryArgs.postcodes.join(','));
            setFilterStatuses(queryArgs.product_statuses);
            setFilterProducts(queryArgs.products);

            if (queryArgs.delivery_date !== null && queryArgs.delivery_date !== "") {
                setFilterDeliveryDate(new Date(queryArgs.delivery_date));
            }

            setPage(queryArgs.page);
        }

        productUnit.listProducts({
            skip: 0,
            take: 10000,
            search: ""
        }, (res) => {

            let _products = [];
            res.results.forEach(_product => {
                _products.push({
                    title: _product.title,
                    id: _product.id,
                });
            });

            setProducts(_products);
        }, (err) => {
        })
    }, []);


    /**
     * initially loads products
     */
    useEffect(() => {
        let queryArgs = queryArgsCache.get();
        if (page > 0 && (!isInit || queryArgs === false)) {
            setIsInit(false);
            load();
        }
    }, [page]);

    /**
     * initially loads products
     */
    useEffect(() => {

        wrlog("filterDeliveryDate", filterDeliveryDate)

        setIsInit(false);
        setLoading(true);
        setPage(0);
        setTimeout(() => {
            setPage(1);
        }, 100)
    }, [filterType, filterProducts, filterStatuses, filterDeliveryDate]);



    /**
    * loads products by search result
    * @param {*} e 
    */
    const doSearch = (e) => {
        e.preventDefault();
        load();
    }


    /**
     * Loads subscriptions from API
     */
    const load = (cb = () => { }) => {

        setLoading(true);

        const skip = (page - 1) * perPage;
        let postcodes = [];
        if (filterPostcode !== "") {
            filterPostcode.split(',').forEach((postcode, index) => {

                if (parseInt(postcode) >= 1000 && parseInt(postcode) <= 9999) {
                    postcodes.push(parseInt(postcode));
                }
            })
        }

        let productIds = [];

        filterProducts.forEach(value => {
            productIds.push(value.id);
        });

        let deliveryDate = "";
        if (filterDeliveryDate !== undefined && filterDeliveryDate !== null && filterDeliveryDate !== false && filterDeliveryDate !== "") {
            deliveryDate = filterDeliveryDate.toDateString()
        }

        const body = {
            skip: skip,
            take: perPage,
            search: searchNameTerm,
            type: filterType,
            postcodes: postcodes,
            product_statuses: filterStatuses,
            products: productIds,
            delivery_date: deliveryDate
        };

        subscriptionUnit.listSubscriptions(body, (res) => {

            setLoading(false);
            setSubscriptions(res.results);
            setTotalPages(Math.ceil(res.total / perPage));
            cb();
            body.page = page;
            body.products = filterProducts;
            queryArgsCache.set(body);

        }, (err) => {
            setLoading(false);
        })
    }

    /**
     * Change the status of the subscription
     * 
     * @param string status
     * 
     * @return void
     */
    const changeStatus = (status, subscriptionId) => {
        const subscriptionUnit = new SubscriptionUnit();

        let body = {
            status: status,
            id: subscriptionId
        }

        setSelectedSubscriptionId(subscriptionId)

        if (status === 'paused') {
            setPausePopupOpen(true);
        } else if (status === 'canceled') {
            subscriptionUnit.cancelSubscription(subscriptionId, (res) => {
                load();
            }, (err) => {
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            })
        } else {
            subscriptionUnit.activateSubscription(subscriptionId, (res) => {
                load();
            }, (err) => {
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            })
        }

    }

    /**
     * sets the pause
     */
    const setPause = () => {

        const subscriptionUnit = new SubscriptionUnit();

        setLoading(true);

        let body = {
            id: selectedSubscriptionId,
            start: pauseRange.startDate.toDateString(),
            end: pauseRange.endDate.toDateString()
        }

        const cbSuccess = (res) => {
            load(() => {
                setPausePopupOpen(false)
            });
        }

        const cbError = (err) => {
            setLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        subscriptionUnit.pauseSubscription(body, cbSuccess, cbError);
    }

    /**
     * Listener for dateChanges
     * @param {date} dates 
     */
    const onChangeDate = (dates) => {
        setPauseRange(dates.selection)
    }

    /**
     * receives the csv file
     * 
     */
    const getCsv = () => {

        setCsvLoading(true);

        let postcodes = [];
        if (filterPostcode !== "") {
            filterPostcode.split(',').forEach((postcode, index) => {
                if (parseInt(postcode) >= 1000 && parseInt(postcode) <= 9999) {
                    postcodes.push(parseInt(postcode));
                }
            })
        }

        let productIds = [];

        filterProducts.forEach(value => {
            productIds.push(value.id);
        });

        let deliveryDate = "";
        if (filterDeliveryDate !== undefined && filterDeliveryDate !== null && filterDeliveryDate !== false && filterDeliveryDate !== "") {
            deliveryDate = filterDeliveryDate.toDateString()
        }


        const body = {
            search: searchNameTerm,
            type: filterType,
            postcodes: postcodes,
            product_statuses: filterStatuses,
            products: productIds,
            delivery_date: deliveryDate,
            check_weekly: true
        };

        subscriptionUnit.getCsv(body, (res) => {
            setCsvLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle('CSV wird erstellt');
            setErrorMessageBody('Das CSV-Dokument wird gerade generiert und wird dir per E-Mail zugeschickt. Dies kann einige Minuten dauern.');

        }, (err) => {
            setCsvLoading(false);
        })
    }

    return <>
        <TopBar title={"Abonnements"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <Row>
                        <Col md={4}>
                            <form onSubmit={doSearch}>
                                <TextField id="standard-basic" className="filled" value={searchNameTerm} label="Kunden durchsuchen" onChange={value => setSearchNameTerm(value.target.value)} /><br />
                            </form>
                        </Col>
                        <Col md={2}>
                            <div
                                onClick={doSearch}
                                style={{ paddingTop: 17, paddingBottom: 18 }}
                                className={`button ${searchNameTerm === '' ? 'disabled' : ''}`}>
                                suchen
                            </div>
                        </Col>
                        <Col md={3}>
                            <DatePicker
                                onChange={setFilterDeliveryDate}
                                value={filterDeliveryDate}
                                label={"Versanddatum"}
                                id={"filterDeliveryDate"}
                            />
                        </Col>
                        <Col md={3} style={{ textAlign: 'right' }}>
                            <div
                                className="button"
                                onClick={getCsv}
                            >{csvLoading ? 'wird generiert...' : 'CSV herunterladen'}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Abo-Status</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setFilterStatuses(e.target.value === '' ? '' : [e.target.value])
                                    }}
                                    value={filterStatuses[0] || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"active"}>Aktiv</MenuItem>
                                    <MenuItem value={"paused"}>Pausiert</MenuItem>
                                    <MenuItem value={"canceled"}>Storniert</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Kunden</InputLabel>
                                <Select
                                    onChange={(e) => setFilterType(e.target.value)}
                                    value={filterType || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"b2b"}>Businesskunden</MenuItem>
                                    <MenuItem value={"b2c"}>Privatkunden</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={products}
                                getOptionLabel={(option) => option.title}
                                value={filterProducts}
                                onChange={(event, newValue) => {
                                    setFilterProducts(newValue);

                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Produkte"
                                        className="filled"
                                    />
                                )}
                            />
                        </Col>
                        <Col md={3}>
                            <form onSubmit={doSearch}>
                                <TextField id="standard-basic" className="filled" value={filterPostcode} label="Postleitzahlen" onChange={value => setFilterPostcode(value.target.value)} /><br />
                            </form>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Produkt</TableCell>
                                <TableCell>E-Mail</TableCell>
                                <TableCell>PLZ</TableCell>
                                <TableCell style={{ width: 180 }}>Abo-Status</TableCell>
                                <TableCell style={{ width: 50 }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                subscriptions.map((subscription, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>
                                            {subscription.customer.firstName} {subscription.customer.lastName}
                                        </TableCell>
                                        <TableCell>
                                            {subscription.product.title}
                                        </TableCell>
                                        <TableCell>
                                            {subscription.customer.email}
                                        </TableCell>
                                        <TableCell>
                                            {subscription.postcode}
                                        </TableCell>
                                        <TableCell>
                                            {(hasPermission('customer', ['can_update']) || hasPermission('subscription', ['can_update'])) ?

                                                <Select
                                                    className={`statusSelect ${subscription.status}`}
                                                    value={subscription.status}
                                                    onChange={e => changeStatus(e.target.value, subscription.id)}
                                                >
                                                    <MenuItem value="active">Aktiv</MenuItem>
                                                    <MenuItem value="paused">Pausiert</MenuItem>
                                                    <MenuItem value="canceled">Storniert</MenuItem>
                                                </Select>
                                                : <StatusLabel status={subscription.status}>{subscription.status}</StatusLabel>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                items={[
                                                    {
                                                        label: "Zum Abonnement",
                                                        to: `/subscriptions/${subscription.id}`,
                                                        params: {
                                                            returnUrl: `/subscriptions`, initQueryVars: {
                                                                skip: (page - 1) * perPage,
                                                                take: perPage,
                                                                search: searchNameTerm,
                                                                type: filterType,
                                                                postcodes: filterPostcode,
                                                                product_statuses: filterStatuses,
                                                                delivery_date: filterDeliveryDate,
                                                                products: filterProducts,
                                                            }
                                                        }
                                                    },
                                                    {
                                                        label: "Zum Kunden",
                                                        to: `/customers/${subscription.customer.id}`,
                                                        params: {
                                                            returnUrl: `/subscriptions`, initQueryVars: {
                                                                skip: (page - 1) * perPage,
                                                                take: perPage,
                                                                search: searchNameTerm,
                                                                type: filterType,
                                                                postcodes: filterPostcode,
                                                                product_statuses: filterStatuses,
                                                                delivery_date: filterDeliveryDate,
                                                                products: filterProducts,
                                                            }
                                                        }
                                                    }
                                                ]}
                                            ></ToolTip>
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        pages={totalPages}
                        current={page}
                        pageChange={setPage}
                    />
                </Col>
            </Row>
        </Container>
        <Popup
            close={() => setPausePopupOpen(false)}
            open={pausePopupOpen}
            style={{ width: 372, paddingTop: 50 }}
        >
            <DateRangePicker
                ranges={[pauseRange]}
                onChange={onChangeDate}
                minDate={new Date()}
                locale={de}
                staticRanges={[]}
                inputRanges={[]}
                renderStaticRangeLabel={{ hasCustomRendering: false }}
                timezome
            />
            <div
                className="button"
                onClick={setPause}
            >Pausieren</div>
        </Popup>
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />

        <Loading visible={loading} />
    </>

}

export default Subscriptions;
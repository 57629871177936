export default class Cache {

    constructor(key, useSession = false) {
        this.key = key;
        this.useSession = useSession;
    }

    set = (value) => {

        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }

        if (this.useSession) {
            sessionStorage.setItem(this.key, value);
        } else {
            localStorage.setItem(this.key, value);
        }

    }

    get = () => {
        let data = [];

        if (this.useSession) {
            data = sessionStorage.getItem(this.key);
        } else {
            data = localStorage.getItem(this.key);
        }

        if (data === null) return false;

        try {
            return JSON.parse(data);
        } catch {
            return data;
        }
    }

    delete = () => {
        if (this.useSession) {
            sessionStorage.removeItem(this.key);
        } else {
            localStorage.removeItem(this.key);
        }
    }

}
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import wrlog from '../../../helper/functions/wrlog';
import Message from '../../../elements/message';
import ServiceDeskUnit from '../../../communicator/http/ServiceDesk';
import CustomerUnit from '../../../communicator/http/Customers';
import moment from 'moment';
import DatePicker from '../../../elements/datepicker';

const CreditCheckForm = () => {

    const [loading, setLoading] = useState(false);
    const [body, setBody] = useState({
        "first_name": "",
        "last_name": "",
        "birthdate": "",
        "address": "",
        "street_number": "",
        "postcode": "",
        "city": ""
    });

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const customerUnit = new CustomerUnit();
    /**
     * initially loads tickets
     */

    const changeValue = (key, value) => {
        let formValues = { ...body };
        formValues[key] = value;
        setBody(formValues);
    }

    const send = () => {
        let valid = true;
        let _body = {}
        Object.keys({ ...body }).forEach(key => {
            if (body[key] === '') {
                valid = false;
            }

            _body[key] = body[key];

            if (key === 'birthdate') {
                _body[key] = moment(_body[key]).format('YYYYMMDD');
            }
        })

        if (!valid) {
            setErrorMessageOpen(true);
            setErrorMessageType('error');
            setErrorMessageTitle('Felder nicht richtig ausgefüllt.')
            setErrorMessageBody('Bitte fülle alle Felder aus.');
            setErrorMessageButtonText('OK');
            setErrorMessageSmall(false);
            return false;
        }

        setLoading(true);
        customerUnit.creditCheck(_body, (res) => {

            if (res?.status === 'accepted') {
                setErrorMessageOpen(true);
                setErrorMessageType('success');
                setErrorMessageTitle('Bestanden')
                setErrorMessageBody('Der Kunde wurde als kreditwürdig eingestuft.');
                setErrorMessageButtonText('OK');
                setErrorMessageSmall(false);
            } else {
                setErrorMessageOpen(true);
                setErrorMessageType('error');
                setErrorMessageTitle('Abgelehnt')
                setErrorMessageBody('Crif hat den Kunden als NICHT kreditwürdig eingestuft. Er sollte die Möglichkeit Kauf auf Rechnung NICHT erhalten.');
                setErrorMessageButtonText('OK');
                setErrorMessageSmall(false);
            }
            setLoading(false);

        }, () => {
            setErrorMessageOpen(true);
            setErrorMessageType('error');
            setErrorMessageTitle('Abgelehnt')
            setErrorMessageBody('Crif hat den Kunden als NICHT kreditwürdig eingestuft. Er sollte die Möglichkeit Kauf auf Rechnung NICHT erhalten.');
            setErrorMessageButtonText('OK');
            setErrorMessageSmall(false);
            setLoading(false);
        });

    }

    return <>

        <Container style={{ padding: 0, marginTop: 20, marginBottom: 0 }}>
            <Row style={{}}>
                <Col sm={12}>
                    <TextField
                        value={body.first_name}
                        onChange={e => changeValue('first_name', e.target.value)}
                        className={`filled ${body.first_name !== undefined && body.first_name !== null && body.first_name !== '' ? 'valued' : ''}`}
                        error={body.first_name === ''}
                        required={true}
                        id="standard-basic"
                        label={"Vorname"}
                    />
                    <TextField
                        value={body.last_name}
                        onChange={e => changeValue('last_name', e.target.value)}
                        className={`filled ${body.last_name !== undefined && body.last_name !== null && body.last_name !== '' ? 'valued' : ''}`}
                        error={body.last_name === ''}
                        required={true}
                        id="standard-basic"
                        label={"Nachname"}
                    />

                    <DatePicker
                        onChange={(val) => changeValue('birthdate', val)}
                        value={body.birthdate}
                        required
                        error={!body.birthdate}
                        label={"Geburtsdatum"}
                        date={body.birthdate}
                    />

                    <Container fluid style={{ padding: 0, marginTop: 20, marginBottom: 0 }}>
                        <Row style={{}}>
                            <Col sm={8}>
                                <TextField
                                    value={body.address}
                                    onChange={e => changeValue('address', e.target.value)}
                                    className={`filled ${body.address !== undefined && body.address !== null && body.address !== '' ? 'valued' : ''}`}
                                    error={body.address === ''}
                                    required={true}
                                    id="standard-basic"
                                    label={"Adresse"}
                                />
                            </Col>
                            <Col sm={4}>
                                <TextField
                                    value={body.street_number}
                                    onChange={e => changeValue('street_number', e.target.value)}
                                    className={`filled ${body.street_number !== undefined && body.street_number !== null && body.street_number !== '' ? 'valued' : ''}`}
                                    error={body.street_number === ''}
                                    required={true}
                                    id="standard-basic"
                                    label={"Hausnummer"}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid style={{ padding: 0, marginTop: 20, marginBottom: 0 }}>
                        <Row style={{}}>
                            <Col sm={6}>
                                <TextField
                                    value={body.postcode}
                                    onChange={e => changeValue('postcode', e.target.value)}
                                    className={`filled ${body.postcode !== undefined && body.postcode !== null && body.postcode !== '' ? 'valued' : ''}`}
                                    error={body.postcode === ''}
                                    required={true}
                                    id="standard-basic"
                                    label={"PLZ"}
                                />
                            </Col>
                            <Col sm={6}>
                                <TextField
                                    value={body.city}
                                    onChange={e => changeValue('city', e.target.value)}
                                    className={`filled ${body.city !== undefined && body.city !== null && body.city !== '' ? 'valued' : ''}`}
                                    error={body.city === ''}
                                    required={true}
                                    id="standard-basic"
                                    label={"Ort"}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row style={{}}>
                <Col sm={12}>
                    <br /><br /><div
                        className="button"
                        onClick={() => send()}
                        style={{ marginTop: 30 }}
                    >senden</div>
                </Col>
            </Row>

        </Container>



        {loading
            && <Loading visible={true} />
        }

        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />
    </>

}

export default CreditCheckForm;
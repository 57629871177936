import React, { useState, useEffect, useRef } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { hasPermission } from '../../helper/functions/auth';
import { translate } from '../../helper/multilingual';
import StatusLabel from '../../elements/statusLabel';
import { Link } from 'react-router-dom';
import Sidebar from '../../elements/sidebar';
import Card from '../../elements/card';
import { Col, Container, Row } from 'react-bootstrap';
import TicketingUnit from '../../communicator/http/Ticketing';
import styles from './style.module.scss';
import ReactHtmlParser from 'react-html-parser';
import nl2br from '../../helper/functions/nl2br';
import { useForm, Controller as FormController } from "react-hook-form";
import TextEditor from '../../elements/texteditor';
import wrlog from '../../helper/functions/wrlog';
import { format_datetime } from '../../helper/misc';
import AdminUnit from '../../communicator/http/Admins';
import SettingMakroUnit from '../../communicator/http/SettingMakro';

import noteIcon from '../../assets/icons/note.png';
import Popup from '../../elements/popup';
import LogNoteForm from './logNoteForm';
import LogEntry from '../../elements/logEntry';
import BottomBar from '../../elements/bottombar';

const TicketingDetail = ({ ticket, reload, updateInList, setLoading, reloadList }) => {

    const ticketigUnit = new TicketingUnit();
    const adminUnit = new AdminUnit();
    const settingMakroUnit = new SettingMakroUnit();

    const { handleSubmit, setValue, control, formState: { errors }, getValues } = useForm();

    const [buttonValid, setButtonValid] = useState(false);
    const [admins, setAdmins] = useState([]);

    const [noteLoading, setNoteLoading] = useState(false);
    const [notes, setNotes] = useState([]);
    const [notePopupOpen, setNotePopupOpen] = useState(false);
    const [addingNote, setAddingNote] = useState(false);
    const [makros, setMakros] = useState([]);
    const [message, setMessage] = useState('Hallo <br/><br/>');
    const [salutation, setSalutation] = useState('Hallo <br/><br/>');

    const lastMessageRef = useRef();

    useEffect(() => {
        if (lastMessageRef.current !== undefined) {
            lastMessageRef.current.scrollIntoView();
        }
        updateInList(ticket.id, { unread: 0 });

        if (ticket.sender !== undefined && ticket.sender.firstName !== undefined && ticket.sender.firstName !== '') {
            setSalutation(`Hallo ${ticket.sender.firstName} <br/><br/>`)
            setMessage(`Hallo ${ticket.sender.firstName} <br/><br/>`)
        }

    }, [ticket])

    useEffect(() => {
        wrlog('message', message);
        setValue('message', message);
    }, [message, setValue])

    useEffect(() => {
        adminUnit.listAdmins((admins) => {
            setAdmins(admins)
        }, () => {
        })

        settingMakroUnit.listMakros((makros) => {
            setMakros(makros);
        }, () => {
        })

    }, [])

    const types = [
        {
            id: 'cancel',
            label: 'Stornierung',
        },
        {
            id: 'pause',
            label: 'Pausierung',
        }
    ];

    if (ticket.id === undefined) {
        return <></>
    }
    const renderMessages = () => {
        return ticket.messages.map((message, key) => {
            return <div
                ref={key === ticket.messages.length - 1 ? lastMessageRef : undefined}
                className={`${styles.chatContainer} ${message.sender_is_support ? styles.self : undefined}`}>
                <div className={styles.chatBubble}>
                    {ReactHtmlParser(message.body)}
                </div>
                <div className={styles.detailContainer}>
                    <div>
                        {message.sender}
                    </div>
                    <div>
                        {format_datetime(message.created)}
                    </div>
                </div>
                {
                    message.attachments !== undefined && message.attachments.map(attachment => (
                        <div className={styles.detailContainer}>
                            <div>
                                <a target='_blank' href={attachment.link}>{attachment.title}</a>
                            </div>
                        </div>
                    ))
                }
            </div>
        });
    }

    const changeStatus = (status, ticketId) => {
        setLoading(true);
        const action = () => {
            ticketigUnit.updateTicket({
                id: ticketId,
                status: status
            }, (res) => {
                reload();
                updateInList(ticketId, { status: status });
            }, () => { })
        }

        if (ticket.assignedAdmin === null) {
            ticketigUnit.assignAdmin({
                id: ticketId,
            }, () => {
                action();
            }, () => {
                setLoading(false);
            })
        } else {
            action();
        }

    }

    const changeType = (type, ticketId) => {
        setLoading(true);
        ticketigUnit.updateTicket({
            id: ticketId,
            type: type
        }, () => {
            reload();
        }, () => { })
    }

    const assignAdmin = (ticketId, adminId = null) => {
        setLoading(true);

        let body = {
            id: ticketId,
        }

        if (adminId !== null) {
            body.assigned_admin = adminId
        }

        ticketigUnit.assignAdmin(body, () => {
            reload();
            if (adminId !== null) {
                reloadList()
            }
        }, () => { })
    }

    const sendMessage = (data) => {
        setLoading(true);
        ticketigUnit.sendMessage(ticket.id, data.message, () => {
            reload();
            updateInList(ticket.id, { status: 'processing' });
        }, () => {
        })

    }

    const assignedContainer = () => {
        if (ticket.assignedAdmin !== null) {

            if (admins.length > 0) {
                return <Select
                    className={`statusSelect done`}
                    value={ticket.assignedAdminId}
                    style={{ width: 200 }}
                    onChange={e => {
                        assignAdmin(ticket.id, e.target.value);
                    }}
                >
                    {
                        admins.map(admin => (
                            <MenuItem value={admin.id}>{admin.firstName} {admin.lastName}</MenuItem>
                        ))
                    }
                </Select>
            }

            return <></>


        } else {
            return <div className='button' onClick={() => assignAdmin(ticket?.id)}>Übernehmen</div>;
        }
    }

    const getNotes = () => {
        setNoteLoading(true)
        ticketigUnit.getNotes(ticket?.id, (results) => {
            setNoteLoading(false)

            let notes = [];

            results.forEach(note => {

                let creator = '';

                admins.forEach(admin => {
                    if (admin.id === note.adminId) {
                        creator = { type: 'admin', name: `${admin.firstName} ${admin.lastName}` }
                    }
                })

                notes.push({
                    type: '',
                    text: note.message,
                    creator: creator,
                    createdAt: note.created,
                })
            })

            setNotes(notes);
        }, () => {
            setNoteLoading(false)
            setNotes([]);
        })
    }

    const insertMakroText = (makroId) => {

        let makroText = '';
        makros.forEach(makro => {
            if (makro.id === makroId) {
                makroText = makro.text;
            }
        })

        wrlog('MESSAGES', getValues());

        setMessage(salutation+makroText);
    }

    return <div className={styles.contentWrapper}>
        <div className={styles.header}>
            <Select
                className={`statusSelect ${ticket?.status}`}
                value={ticket?.status}
                style={{ width: 'fit-content' }}
                onChange={e => changeStatus(e.target.value, ticket.id)}
            >
                <MenuItem value="open">Offen</MenuItem>
                <MenuItem value="processing">In Bearbeitung</MenuItem>
                <MenuItem value="done">Erledigt</MenuItem>
            </Select>
            <Select
                className={`statusSelect ${ticket?.type}`}
                value={ticket?.type}
                style={{ width: 'fit-content' }}
                onChange={e => changeType(e.target.value, ticket.id)}
            >
                <MenuItem value={'default'}>nicht ausgewählt</MenuItem>
                {types.map(type => <MenuItem value={type.id}>{type.label}</MenuItem>)}
            </Select>
            <div className='iconButton button simple' onClick={() => {
                getNotes();
                setNotePopupOpen(true)
            }} style={{
                width: 50, height: 50, padding: 20
            }}>
                <div className='badge'>{ticket.notes}</div>
                <img src={noteIcon} style={{ width: 30 }} />
            </div>
            {ticket.customer !== null ? <a target='_blank' href={`/customers/${ticket.customer.id}`}>{ticket.sender.email}</a> : ticket.sender.email}

            {assignedContainer()}

        </div>
        <div style={{ display: 'inline-block', width: '100%' }}>
            {renderMessages()}
        </div>
        <br />
        <form className={styles.editor} onSubmit={handleSubmit(sendMessage)}>
            <FormController
                name={"message"}
                rules={{
                    required: true,
                }}
                control={control}
                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                    <TextEditor
                        value={value}
                        hardValue={message}
                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                        onChange={(val) => {
                            onChange(val);
                        }}
                        error={errors.message}
                        setValid={setButtonValid}
                        toolbar={
                            {
                                options: ['inline', 'list', 'link', 'emoji'],
                                inline: {
                                    inDropdown: false,
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['bold', 'italic'],
                                },
                                list: {
                                    inDropdown: false,
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['unordered', 'ordered'],
                                },
                                link: {
                                    inDropdown: false,
                                    className: undefined,
                                    component: undefined,
                                    popupClassName: undefined,
                                    dropdownClassName: undefined,
                                    showOpenOptionOnHover: true,
                                    defaultTargetOption: '_self',
                                    options: ['link', 'unlink'],
                                    linkCallback: undefined
                                },
                                emoji: {
                                    className: undefined,
                                    component: undefined,
                                    popupClassName: undefined,
                                    emojis: [
                                        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                        '✅', '❎', '💯',
                                    ],
                                },
                            }
                        }
                    />
                )}
            />
            <BottomBar>
                <Container fluid style={{ padding: 0 }}>
                    <Row>
                        <Col md={9}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Makros</InputLabel>
                                <Select
                                    onChange={e => {
                                        setButtonValid(true)
                                        insertMakroText(e.target.value)
                                    }}
                                >
                                    {
                                        makros.map(makro => (
                                            <MenuItem value={makro.id}>{makro.title}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <input type="submit" value="Senden" className={`button ${!buttonValid ? 'disabled' : ''}`} style={{ float: 'right' }} />
                        </Col>
                    </Row>
                </Container>
            </BottomBar>
        </form>
        <Popup
            close={() => setNotePopupOpen(false)}
            open={notePopupOpen}
            style={{ paddingTop: 50 }}
        >
            {addingNote ?
                <>
                    <LogNoteForm
                        setShow={setAddingNote}
                        reload={getNotes}
                        ticketId={ticket.id}
                    />
                </>
                :
                <>
                    {hasPermission('ticketing', ['can_update']) &&
                        <div
                            onClick={() => setAddingNote(true)}
                            style={{ color: 'var(--green-color)', position: 'absolute', top: 15, left: 20, cursor: 'pointer' }}>Notiz hinzufügen</div>
                    }
                    {
                        notes.map(note => {
                            return <LogEntry {...note} />;
                        })
                    }
                </>
            }
        </Popup>
    </div>
}

export default TicketingDetail;
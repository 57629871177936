import React from 'react';

import styles from './style.module.scss';

const TopBar = ({ title }) => {

    return <div className={styles.container}>
        <h2>{title}</h2>
    </div>

}

export default TopBar;
import { Checkbox, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import CustomerUnit from '../../../communicator/http/Customers';
import wrlog from '../../../helper/functions/wrlog';
import { hasPermission } from '../../../helper/functions/auth';

/**
 * 
 * @returns Contact Data Form Items for Customer-Screen
 */
const OrderDetail = ({ order, orderId, setShow, setMessage }) => {

    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [paymentStatus, setPaymentStatus] = useState('')

    useEffect(() => {
        if (orderId !== null) {
            load();
        }
    }, [orderId]);

    /**
     * Submit userData to API
     * @param {object} data 
     */
    const load = () => {
        const customerUnit = new CustomerUnit();

        setLoading(true);

        const cbError = (err) => {
            setLoading(false);
            setShow(false);
            // setMessage(err);
        }

        // Success function for Update User
        const success = (res) => {
            setPaymentDetails(res);
            setLoading(false);

            if (res?.status === 'canceled') {
                setPaymentStatus('Kunde hat abgebrochen');
            } else if (res?.status === 'success') {
                setPaymentStatus('Zahlung erfolgreich');
            } else if (res?.status === 'failed') {
                setPaymentStatus('Zahlung fehlgeschlagen');
            }

        }

        customerUnit.getOrderPaymentStatus(orderId, success, cbError);
    }

    return (
        <>
            <Container fluid style={{ padding: 0 }}>
                <Row style={{ marginTop: 0 }}>
                    <Col md={12}>
                        <strong>Status: {paymentStatus}</strong><br />
                        {paymentDetails?.provider === 'afreshed'
                            && <>
                                <strong>Methode: Kauf auf Rechnung</strong><br />
                                <p>{paymentDetails?.details?.failureMessage}</p>
                            </>
                        }
                        {paymentDetails?.provider === 'mollie'
                            && <>
                                <strong>Methode: {paymentDetails?.type}</strong><br />
                                {paymentDetails?.details?.cardLabel && <>{`Karte: ${paymentDetails?.details?.cardLabel}`}<br /></>}
                                {paymentDetails?.details?.cardNumber && <>{`Kartennummer: **** **** **** ${paymentDetails?.details?.cardNumber}`}<br /></>}
                                <p>{paymentDetails?.details?.failureMessage}</p>
                            </>
                        }
                    </Col>
                </Row>
            </Container>
            <Loading visible={loading} containerStyle={{ borderRadius: 15, width: '100%', height: '100%', position: 'absolute' }} />
        </>

    )

}

export default OrderDetail;
export const translateDeliveryStatus = (status) => {
    switch (status) {
        case 'to_deliver':
            return 'Geplant';
        case 'out_for_delivery':
            return 'In Auslieferung';
        case 'succeeded':
            return 'Zugestellt';
        case 'failed':
            return 'Fehlgeschlgen';
        default:
            return status;
    }

    return status;
}

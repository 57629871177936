export const translateInvoiceStatus = (status) => {
    const translations = {
        issued: 'offen',
        processing: 'ausstehend',
        paid: 'bezahlt',
        canceled: 'storniert',
        failed: 'fehlgeschlagen'
    };

    if (translations[status] === undefined) {
        return 'offen';
    }
    return translations[status];
}

export const translatePaymentType = (paymentType) => {
    const translations = {
        card: 'Kreditkarte',
        sepa_debit: 'SEPA',
        paypal: 'PayPal',
        klarna: 'Klarna - Rechnung',
        bank_transfer: 'Bank-Überweisung'
    };

    if (translations[paymentType] === undefined) {
        return paymentType;
    }
    return translations[paymentType];
}
import React, { Component, useState, useEffect } from 'react';
import styles from './style.module.scss';

import PlaceholderImage from '../../assets/icons/placeholder.png';
import wrlog from '../../helper/functions/wrlog';

const ImageInput = (props) => {

    const [imageData, setImageData] = useState({
        base64: '',
        title: '',
        mimeType: ''
    });

    const [imageUrl, setImageUrl] = useState("");
    const [showOverlay, setShowOverlay] = useState(false);

    const [imageSet, setImageSet] = useState(false);

    const onChange = (evt) => {

        let reader = new FileReader();
        let file = evt.target.files[0];

        reader.onload = function (upload) {

            props.onChange({
                base64: upload.target.result,
                title: file.name,
                mimeType: file.type
            });

            setImageData({
                base64: upload.target.result,
                title: file.name,
                mimeType: file.type
            });

            setImageSet(true);

        };

        reader.readAsDataURL(file);

    }

    const doDelete = () => {

        setImageUrl('');

        setImageData({
            base64: '',
            title: '',
            mimeType: ''
        });

        setImageSet(false);

        props.onChange({
            base64: '',
            title: '',
            mimeType: ''
        });
    }

    useEffect(() => {
        if (props.imageUrl !== undefined && props.imageUrl !== '') {
            setImageUrl(props.imageUrl);
            setImageSet(true);
        }
    }, [props.imageUrl]);

    return <div className={styles.container}>
        <div className={styles.imageWrapper}>
            <label>
                <img
                    src={imageSet ? imageData.base64 || imageUrl : PlaceholderImage}
                    onMouseEnter={() => setShowOverlay(true)}
                    onMouseLeave={() => setShowOverlay(false)}
                    className={`${!imageSet ? styles.widthBorderRadiusBottom : undefined}`}
                />
                <input type="file" accept="image/*" onChange={onChange} />
                <div
                    onMouseEnter={() => setShowOverlay(true)}
                    onMouseLeave={() => setShowOverlay(false)}
                    className={`${styles.editOverlay} ${showOverlay ? styles.show : undefined} ${!imageSet ? styles.widthBorderRadiusBottom : undefined}`}

                >
                    ändern
                </div>
            </label>
        </div>
        {
            imageData.base64 || imageUrl ? <div
                onClick={doDelete}
                className={styles.delete}>löschen</div>
                : null
        }

    </div>

}

export default ImageInput;
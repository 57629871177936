import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import CustomerUnit from '../../../communicator/http/Customers';
import wrlog from '../../../helper/functions/wrlog';
import { hasPermission } from '../../../helper/functions/auth';

/**
 * 
 * @returns Contact Data Form Items for Customer-Screen
 */
const LogNoteForm = ({ customerId, setShow, setLogEntries }) => {

    const params = useParams();

    const [loading, setLoading] = useState(false);

    const { handleSubmit, setValue, control, formState: { errors } } = useForm();

    /**
     * Submit userData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const customerUnit = new CustomerUnit();

        wrlog("DATA", data);

        setLoading(true);

        let body = {
            type: "note",
            text: data.note
        };

        const cbError = (err) => {
            setLoading(false)
        }

        // Success function for Update User
        const cbSuccess = (res) => {

            customerUnit.getLog(customerId, (res) => {
                setLogEntries(res);
                setShow(false);
                setLoading(false);
            }, cbError)



        }

        customerUnit.addLogEntry(customerId, body, cbSuccess, cbError);

    }

    return (
        <>
            <form onSubmit={handleSubmit(doSubmit)}>

                <FormController
                    name={"note"}
                    rules={{
                        required: true,
                    }}
                    control={control}
                    render={({ field: { onChange, onBlur, value, onFocus } }) => (
                        <TextField
                            value={value}
                            multiline
                            onChange={onChange}
                            className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                            error={errors.email}
                            id="standard-basic"
                            label={translate('note')}
                            type="note"
                        />
                    )}
                />
                {
                    hasPermission('customer', ['can_update']) &&
                    <div style={{ top: 20, right: 30, position: 'absolute', display: 'inline-block', width: 'max-content' }}>
                        <div
                            onClick={() => setShow(false)}
                            style={{ color: 'var(--red-color)', display: 'inline-block', cursor: 'pointer' }}
                        >
                            {translate('abbrechen')}
                        </div>
                        <input
                            type="submit"
                            style={{ color: 'var(--green-color)', display: 'inline-block', marginLeft: 15, padding: 0, cursor: 'pointer', background: 'none', border: 'none' }}
                            value={translate('save')}
                        />
                    </div>
                }
            </form>
            <Loading visible={loading} containerStyle={{ borderRadius: 15, width: '100%', height: '100%', position: 'absolute' }} />
        </>

    )

}

export default LogNoteForm;
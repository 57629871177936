import { Checkbox, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, FormControlLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import { translate } from '../../../../helper/multilingual';
import Message from '../../../../elements/message';
import AdminUnit from '../../../../communicator/http/Admins';
import NotFound from '../../../NotFound';
import { hasPermission } from '../../../../helper/functions/auth';
import TextEditor from '../../../../elements/texteditor';
import ImageInput from '../../../../elements/imageInput';
import { formatMoney } from '../../../../helper/functions/formatPrice';
import wrlog from '../../../../helper/functions/wrlog';
import { isJsonString } from '../../../../helper/functions/isJsonString';
import { formatPercent } from '../../../../helper/functions/formatPercent';
import ProcurementSupplierUnit from '../../../../communicator/http/ProcurementSupplier';


/**
 * 
 * @returns Admin Supplier Detail Page
 */
const ProcurementSupplierSingle = () => {

    const params = useParams();

    const supplierId = params.id;

    const [loading, setLoading] = useState(supplierId !== 'new');
    const [supplier, setSupplier] = useState(true);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [imageData, setImageData] = useState(null);

    const [is404, setIs404] = useState(false);

    const { handleSubmit, setValue, control, formState: { errors }, getValues } = useForm();

    /**
     * Inititally check if supplierId is "new" to check if supplier is updated or new supplier is created
     */
    useEffect(() => {

        const supplierUnit = new ProcurementSupplierUnit();

        /**
         * if supplierId !== set set 404 = true
         */
        if (supplierId === undefined || supplierId === null || supplierId === "") {
            setIs404(true);
            return;
        }

        /**
         * if supplierId !== new --> update supplier & load supplierData
         */
        if (supplierId !== 'new') {

            // Check if supplier can update || delete

            if (!hasPermission('product', ['can_update', 'can_delete'])) {
                setIs404(true);
                return;
            }

            supplierUnit.getSupplier(supplierId, (supplier) => {

                setLoading(false);
                setValue('companyTitle', supplier.companyTitle);
                setValue('address', supplier.address);
                setValue('postcode', supplier.postcode);
                setValue('city', supplier.city);
                setValue('country', supplier.country);
                setValue('email', supplier.email);
                setValue('phone', supplier.phone);
                setValue('contactPerson', supplier.contactPerson);
                setValue('externalId', supplier.externalId);
                setValue('iban', supplier.iban);
                setValue('accountNumber', supplier.accountNumber);

                setSupplier(supplier);

            }, () => {
                setIs404(true);
            })
        } else {
            if (!hasPermission('product', ['can_create'])) {
                setIs404(true);
                return;
            }
        }


    }, [supplierId]);

    /**
     * Submit supplierData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const supplierUnit = new ProcurementSupplierUnit();

        setLoading(true);

        setValue('companyTitle', supplier.companyTitle);
        setValue('address', supplier.address);
        setValue('postcode', supplier.postcode);
        setValue('city', supplier.city);
        setValue('country', supplier.country);
        setValue('email', supplier.email);
        setValue('phone', supplier.phone);
        setValue('contactPerson', supplier.contactPerson);
        setValue('externalId', supplier.externalId);
        setValue('iban', supplier.iban);
        setValue('accountNumber', supplier.accountNumber);

        let body = {
            company_title: data.companyTitle,
            address: data.address,
            postcode: data.postcode,
            city: data.city,
            country: data.country,
            email: data.email,
            phone: data.phone,
            contact_person: data.contactPerson,
            external_id: data.externalId,
            iban: data.iban,
            account_number: data.accountNumber,
        }

        // Success function for Create Supplier
        const cbSuccess = (res) => {
            setLoading(false);
            window.location.replace(`/procurement/supplier/${res.id}`); //Replace Location to new created supplier & reload page
        }

        // Success function for Update Supplier
        const cbSuccessUpdate = (res) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
            setErrorMessageSmall(true);
            setErrorMessageTitle(res.exception.message);
            setErrorMessageBody("");

            setSupplier(data);
        }

        const cbError = (err) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        if (supplierId === 'new') {
            supplierUnit.create(body, cbSuccess, cbError);
        } else {
            body.id = supplierId;
            supplierUnit.update(body, cbSuccessUpdate, cbError);
        }
    }

    if (is404 === true) {
        return <NotFound />;
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to="/procurement/supplier">zurück</Link><br /><br />
                        <h1>{params.id !== "new" ? <>{supplier.companyTitle} {translate('edit')}</> : "Neues Lieferant hinzufügen"} </h1>
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <Row style={{ marginTop: 50 }}>
                        <Col md={12}>
                            <FormController
                                name={"companyTitle"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.companyTitle}
                                        id="standard-basic"
                                        label={translate('title') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"address"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.address}
                                        id="standard-basic"
                                        label={translate('address') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"postcode"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.postcode}
                                        id="standard-basic"
                                        label={translate('postcode') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"city"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.city}
                                        id="standard-basic"
                                        label={translate('city') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"country"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.country}
                                        id="standard-basic"
                                        inputProps={{ maxLength: 2, minLength: 2 }}
                                        label={translate('country') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"contactPerson"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.contactPerson}
                                        id="standard-basic"
                                        label={'Kontaktperson *'}
                                    />
                                )}
                            />
                            <FormController
                                name={"email"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.email}
                                        id="standard-basic"
                                        label={translate('email') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"phone"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.phone}
                                        id="standard-basic"
                                        label={translate('phone') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"externalId"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.externalId}
                                        id="standard-basic"
                                        label={'Externe ID'}
                                    />
                                )}
                            />
                            <FormController
                                name={"iban"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.iban}
                                        id="standard-basic"
                                        label={'IBAN'}
                                    />
                                )}
                            />
                            <FormController
                                name={"accountNumber"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.accountNumber}
                                        id="standard-basic"
                                        label={'Kontonummer (Buchhaltung)'}
                                    />
                                )}
                            />
                            {
                                ((hasPermission('product', ['can_update']) && params.id !== "new") || (hasPermission('product', ['can_create']) && params.id === "new")) &&
                                <input type="submit" style={{ width: '200px', marginTop: 20, right: 20 }} className="button" value={params.id !== "new" ? translate('save') : translate('add')} />
                            }
                        </Col>
                    </Row>
                </form>
            </Container>
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />

            <Loading visible={loading} />
        </>

    )

}

export default ProcurementSupplierSingle;
import Unit from './index';

export default class ReminderUnit extends Unit {

    listReminders = (body, cbSuccess, cbError) => {
        this.post(`/financialreminder/list`, body, cbSuccess, cbError);
    }

    getReminder = (reminderId, cbSuccess, cbError) => {
        this.get(`/financialreminder/${reminderId}`, cbSuccess, cbError);
    }

    getPdf = (reminderId, cbSuccess, cbError) => {
        this.get(`/financialreminder/${reminderId}/pdf`, cbSuccess, cbError);
    }

    updateReminder = (body, cbSuccess, cbError) => {
        this.put('/financialreminder', body, cbSuccess, cbError);
    }

}

import Unit from './index';

export default class UserUnit extends Unit {

    login = (email, password, cbSuccess, cbError) => {

        let body = {
            email: email,
            password: password,
            admin: true,
        }

        this.post('/signin', body, cbSuccess, cbError);
    }

    validateToken = (cbSuccess, cbError) => {
        let body = {
            admin: true,
        }
        this.post('/validatetoken', body, cbSuccess, cbError);
    }

    getPermissions = (cbSuccess, cbError) => {
        this.get('/permissions', cbSuccess, cbError);
    }

    create = (body, cbSuccess, cbError) => {

        this.post('/users', body, cbSuccess, cbError);

    }

    getUserData = (userId, cbSuccess, cbError) => {

        if (userId === null) {
            this.get(`/users`, cbSuccess, cbError);
        } else {
            this.get(`/users/${userId}`, cbSuccess, cbError);
        }

    }

    update = (body, cbSuccess, cbError) => {
        this.put('/customers', body, cbSuccess, cbError);
    }

    list = (cbSuccess, cbError) => {
        this.get('/customers', cbSuccess, cbError, 'admin_users_list');
    }

    deleteUser = (userId, cbSuccess, cbError) => {
        this.delete(`/customers`, { id: userId }, cbSuccess, cbError);
    }

    forgotPassword = (email, cbSuccess, cbError) => {
        this.post(`/lostpassword`, { email: email, admin: true }, cbSuccess, cbError);
    }

    resetPassword = (password, key, secret, cbSuccess, cbError) => {
        this.post(`/resetpassword`, {
            password: password,
            key: key,
            secret: secret,
            admin: true
        }, cbSuccess, cbError);
    }

}




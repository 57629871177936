import { Checkbox, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import CustomerUnit from '../../../communicator/http/Customers';
import wrlog from '../../../helper/functions/wrlog';
import { hasPermission } from '../../../helper/functions/auth';
import SubscriptionUnit from '../../../communicator/http/Subscriptions';
import DiscountsUnit from '../../../communicator/http/Discount';
import Dropzone from 'react-dropzone';
import { Document, Page } from 'react-pdf';

// const axios = require('axios');
// const fs = require("fs");
// const FormData = require('form-data')

import styles from './style.module.scss';
import axios from 'axios';
import fs from 'fs';
import ProcurementSupplierUnit from '../../../communicator/http/ProcurementSupplier';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import BankingUnit from '../../../communicator/http/Banking';
const filter = createFilterOptions();

/**
 * 
 * @returns Contact Data Form Items for Customer-Screen
 */
const BalanceDetail = (props) => {

    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [fileUploadRunning, setFileUploadRunning] = useState(false);
    const [balance, setBalance] = useState({ ...props.balance });
    const [selectedType, setSelectedType] = useState('onetime');

    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [fileChanged, setFileChanged] = useState(false);

    const [accountName, setAccountName] = useState('');
    const [accountIban, setAccountIban] = useState('');
    const [accountingAccount, setAccountingAccount] = useState('');
    const [account, setAccount] = useState(null);

    const { handleSubmit, setValue, control, formState: { errors }, watch } = useForm();

    useEffect(() => {
        if (balance.id !== undefined && balance.id !== null) {
            wrlog("balance", balance);

            setValue('date', balance.invoice.date);
            setValue('dueDate', balance.paymentGoal);
            setValue('invoiceNumber', balance.invoice.number);
            setValue('taxRate', balance.invoice.taxRate);
            setValue('grossAmount', balance.invoice.grossAmount);
            setValue('type', balance.type);
            setValue('accountBban', balance.accountBban);

            setAccountName(balance.account.name);
            setAccountIban(balance.account.iban);
            setAccountingAccount(balance.clearingAccount);
            setAccount({ id: balance.account.id });

        }
    }, [balance]);

    useEffect(() => {
        if (balance.account !== undefined && balance.account.id !== undefined && balance.account.id !== null && suppliers.length > 0) {
            suppliers.forEach(supplier => {
                if (supplier.id === balance.account.id) {
                    setAccount(supplier);
                }
            })

        }
    }, [balance, suppliers])

    useEffect(() => {
        let procurementSupplierUnit = new ProcurementSupplierUnit()
        procurementSupplierUnit.list('', (res) => {
            setSuppliers(res.results);
        }, (err) => { })
    }, []);

    /**
     * Submit userData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {

        let body = {
            type: props.type,
            account_name: accountName,
            account_type: account !== null && account.id !== undefined && account.id !== null ? 'afreshed_supplier' : 'undefined',
            account_id: account !== null && account.id !== undefined && account.id !== null ? account.id : null,
            account_iban: accountIban,
            clearing_account: accountingAccount,
            invoice_number: data.invoiceNumber,
            gross_amount: data.grossAmount,
            tax_rate: data.taxRate,
            invoice_date: data.date,
            payment_goal: data.dueDate,
        }

        let bankingUnit = new BankingUnit();

        if (balance.id !== undefined && balance.id !== null) {
            body.id = balance.id;
            bankingUnit.updateBalance(body, (res) => {
                wrlog(res);
                uploadFile(balance.id);
            }, (err) => {
                wrlog(err)
            })
        } else {
            bankingUnit.addBalance(body, (res) => {
                uploadFile(res.id)
                wrlog(res);
            }, (err) => {
                wrlog(err)
            })
        }



    }

    const uploadFile = (id) => {
        let bankingUnit = new BankingUnit();
        if (fileChanged && balance.file !== undefined && balance.file !== null) {
            bankingUnit.uploadFile(id, { base64: balance.file, mime_type: balance.fileMimeType, title: balance.fileTitle }, () => props.reload());
        } else {
            props.reload()
        }
    }

    const deleteBalance = () => {
        setLoading(true);
        let bankingUnit = new BankingUnit();

        let body = {
            id: balance.id,
            status: 'deleted'
        }

        bankingUnit.updateBalance(body, (res) => {
            setLoading(false);
            props.setShow(false);
            props.reload()
        }, (err) => {
            wrlog(err)
        })

    }

    const doOCR = async (file) => {

        let data = new FormData();

        setFileUploadRunning(true);

        data.append("document", file);

        const config = {
            method: 'POST',
            url: 'https://api.mindee.net/v1/products/mindee/invoices/v3/predict',
            headers: {
                'Authorization': 'Token 137b5fde6edb30a970d9d774134b3258',
                'Content-Type': 'multipart/form-data'
            },
            data
        }

        try {
            let response = await axios(config)

            console.log(response.data);

            const resData = response.data.document.inference.prediction;

            let type = 'liability';
            let accountName = '';
            if (resData.supplier !== undefined && resData.supplier.value !== undefined && resData.supplier.value !== null && resData.supplier.value.toLowerCase().includes('afreshed')) {
                type = 'receivable';
                accountName = resData.customer?.value; // Empfänger
            } else {
                accountName = resData.supplier?.value; // Supplier
            }

            let res = {
                date: resData.date.value,
                dueDate: resData.due_date.value,
                invoiceNumber: resData?.invoice_number?.value,
                accountIban: resData?.payment_details[0]?.iban,
                accountBban: resData?.payment_details[0]?.account_number,
                accountBic: resData?.payment_details[0]?.swift,
                accountName: accountName,
                taxRate: resData?.taxes[0]?.rate,
                grossAmount: resData?.total_incl.value,
                type: type
            }

            setValue('date', res.date);
            setValue('dueDate', res.dueDate);
            setValue('invoiceNumber', res.invoiceNumber);
            setValue('taxRate', res.taxRate);
            setValue('grossAmount', res.grossAmount);
            setValue('type', res.type);
            setValue('accountBban', res.accountBban);

            setAccountName(res.accountName);
            setAccountIban(res.accountIban);

            suppliers.forEach(supplier => {
                if (supplier.iban !== null) {
                    let iban = supplier.iban.replace(/ /g, '').toLowerCase();
                    if (iban === res.accountIban.toLowerCase()) {
                        setAccountingAccount(supplier.accountNumber);
                        setAccount(supplier);
                    }
                }
            })

            showFile(file);
            console.log(res);

        } catch (error) {
            console.log(error)
            setFileUploadRunning(false);
        }

    }

    // useEffect(() => {
    //     const fileInput = document.querySelector('input#asdasdasd');
    //     fileInput.addEventListener('change', (e) => {
    //         // Get a reference to the file
    //         const file = e.target.files[0];

    //         // Encode the file using the FileReader API
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             // Use a regex to remove data url part
    //             const base64String = reader.result
    //             // .replace('data:', '')
    //             // .replace(/^.+,/, '');

    //             setBalance({ file: base64String });

    //         };
    //         reader.readAsDataURL(file);
    //     });
    // }, [])

    const selectAccount = (accountData) => {
        setAccountIban(accountData.iban || '');
        setAccount(accountData);
        setAccountName(accountData.companyTitle || '');
        setAccountingAccount(accountData.accountNumber || '');
    }

    const setFile = (file) => {
        if (balance.id === undefined || balance.id === null) {
            doOCR(file)
        } else {
            showFile(file);
        }
    }

    const showFile = (file) => {
        // Encode the file using the FileReader API

        let mimeType = file.type;
        let title = file.name;

        const reader = new FileReader();
        reader.onloadend = () => {
            // Use a regex to remove data url part
            const base64String = reader.result
            // .replace('data:', '')
            // .replace(/^.+,/, '');

            let _balance = { ...balance };
            _balance.file = base64String;
            _balance.fileMimeType = mimeType;
            _balance.fileTitle = title;

            setFileChanged(true);
            setBalance(_balance);

            setFileUploadRunning(false);
        };

        reader.readAsDataURL(file);
    }

    return (
        <Container fluid>
            <Row>
                <Col md={6}>
                    {
                        fileUploadRunning || balance === null || balance.file === undefined || balance.file === null || balance.file === '' ?
                            <Dropzone
                                multiple={false}
                                onDrop={acceptedFiles => setFile(acceptedFiles[0])}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div className={`${styles.dragndropContainer} ${fileUploadRunning ? 'disabled' : ''}`}>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} id="asdasdasd" />
                                            <p>Drag 'n' drop oder klicken um eine Datei hochzuladen</p>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            :

                            <>
                                <iframe className='iframe' style={{ position: 'sticky', top: 0, left: 0, width: '100%', height: 'calc(100vh - 130px)' }} src={balance.file}></iframe>
                            </>

                    }

                </Col>
                <Col md={6} style={{ paddingTop: 30 }}>
                    <form onSubmit={handleSubmit(doSubmit)}>
                        <FormController
                            name={"type"}
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field: { onChange, onBlur, value, onFocus } }) => {

                                if (value == undefined || value == null) {
                                    return <></>
                                }

                                return <FormControl
                                    className="selectFormControl"
                                >
                                    <InputLabel id="type">Art</InputLabel>
                                    <Select
                                        onChange={onChange}
                                        value={value}
                                    >
                                        <MenuItem value={"receivable"}>Forderung</MenuItem>
                                        <MenuItem value={"liability"}>Verbindlichkeit</MenuItem>
                                    </Select>
                                </FormControl>
                            }}
                        />

                        <FormController
                            name={"invoiceNumber"}
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                    error={errors.invoiceNumber}
                                    id="standard-basic"
                                    label={'Rechnungsnummer'}
                                />
                            )}
                        />

                        <FormController
                            name={"date"}
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                    error={errors.date}
                                    id="standard-basic"
                                    label={'Rechnungsdatum'}
                                />
                            )}
                        />

                        <FormController
                            name={"dueDate"}
                            rules={{
                                required: false,
                            }}
                            control={control}
                            render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                    error={errors.dueDate}
                                    id="standard-basic"
                                    label={'Zahlungsziel (Datum)'}
                                />
                            )}
                        />

                        <FormController
                            name={"grossAmount"}
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                    error={errors.grossAmount}
                                    id="standard-basic"
                                    label={'Summe Brutto'}
                                />
                            )}
                        />

                        <FormController
                            name={"taxRate"}
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                    error={errors.taxRate}
                                    id="standard-basic"
                                    label={'Steuersatz in %'}
                                />
                            )}
                        />

                        {props.type === 'liability' ? <Autocomplete
                            id="tags-outlined"
                            options={suppliers}
                            // getOptionLabel={(option) => option.companyTitle}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.companyTitle;
                            }}
                            disabled={suppliers.length < 1}
                            value={account}
                            // onChange={(e, value) => { wrlog(e.target.value, value); selectAccount(value) }}
                            filterSelectedOptions
                            freeSolo
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    selectAccount({
                                        id: null,
                                        companyTitle: newValue,
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    selectAccount({
                                        id: null,
                                        companyTitle: newValue.inputValue,
                                    });
                                } else {
                                    selectAccount(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.companyTitle);

                                console.log("inputValue")

                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        title: `Add "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            style={{ marginBottom: 20 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    disabled={suppliers.length < 1}
                                    label={suppliers.length < 1 ? "Lieferanten werden geladen..." : "Lieferant"}
                                    className="filled"
                                    placeholder="Alle Lieferanten"
                                />
                            )}
                        /> : <TextField
                            onChange={e => setAccountName(e.target.value)}
                            value={accountName}
                            className={`filled ${accountName !== undefined && accountName !== null && accountName !== '' ? 'valued' : ''}`}
                            id="standard-basic"
                            label={'Name'}
                        />
                        }

                        <TextField
                            onChange={e => setAccountIban(e.target.value)}
                            value={accountIban}
                            className={`filled ${accountIban !== undefined && accountIban !== null && accountIban !== '' ? 'valued' : ''}`}
                            id="standard-basic"
                            label={'IBAN'}
                        />



                        <FormController
                            name={"accountBban"}
                            rules={{
                                required: false,
                            }}
                            control={control}
                            render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                <TextField
                                    onChange={onChange}
                                    value={value}
                                    className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                    error={errors.accountBban}
                                    id="standard-basic"
                                    label={'BBAN'}
                                />
                            )}
                        />

                        <TextField
                            onChange={e => setAccountingAccount(e.target.value)}
                            value={accountingAccount}
                            className={`filled ${accountingAccount !== undefined && accountingAccount !== null && accountingAccount !== '' ? 'valued' : ''}`}
                            id="standard-basic"
                            label={'Kontorahmen (Buchhaltung)'}
                        />

                        <input
                            type="submit"
                            className='button'
                            value={balance.id !== undefined && balance.id !== null ? translate('save') : translate('add')}
                        />
                        <div
                            className='small_button'
                            style={{ color: 'red', float: 'right', marginTop: 15 }}
                            onClick={() => deleteBalance()}>
                            {'löschen'}
                        </div>
                        <Loading visible={loading} />
                    </form>
                </Col>
            </Row>
        </Container >
    )

}

export default BalanceDetail;
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { translate } from '../../helper/multilingual';

import Container from './container.js';

import styles from './style.module.scss';

const Card = ({ children, style, counter, to, onClick }) => {

    if (to !== undefined && to !== '') {
        return <Link onClick={onClick} to={to} style={style} className={`${styles.container} ${counter !== undefined ? styles.counter : undefined}`}>{children}</Link>
    }
    return <div onClick={onClick} style={style} className={`${styles.container} ${counter !== undefined ? styles.counter : undefined}`}>{children}</div>
}

export default Card;

export const CardContainer = Container;
/**
 * Formats the date from the server
 * @param object dateTime
 * 
 * @return string din-formatted date
 */
export const formatDbDate = (dateTime) => {

    try {
        let dateObject = new Date();

        if (typeof dateTime === 'string') {
            dateObject = new Date(dateTime);
        } else {
            let dateTimeParts = dateTime.date.split(/[- :]/);
            dateTimeParts[1]--;

            dateObject = new Date(...dateTimeParts);
        }

        let day = dateObject.getDate();
        if (day < 10) day = '0' + day;

        let month = dateObject.getMonth() + 1;
        if (month < 10) month = '0' + month;

        return `${day}.${month}.${dateObject.getFullYear()}`;
    } catch {
        return '';
    }
}


/**
 * Formats the date and time from the server
 * @param object dateTime
 * 
 * @return string din-formatted date
 */
export const formatDbDateTime = (dateTime) => {

    try {
        let dateObject = new Date();

        if (typeof dateTime === 'string') {
            dateObject = new Date(dateTime);
        } else {
            let dateTimeParts = dateTime.date.split(/[- :]/);
            dateTimeParts[1]--;

            dateObject = new Date(...dateTimeParts);
        }

        let day = dateObject.getDate();
        if (day < 10) day = '0' + day;

        let month = dateObject.getMonth() + 1;
        if (month < 10) month = '0' + month;

        let hours = dateObject.getHours();
        if (hours < 10) hours = '0' + hours;

        let minutes = dateObject.getMinutes();
        if (minutes < 10) minutes = '0' + minutes;

        let seconds = dateObject.getSeconds();
        if (seconds < 10) seconds = '0' + seconds;

        return `${day}.${month}.${dateObject.getFullYear()} ${hours}:${minutes}:${seconds}`;
    } catch {
        return '';
    }
}

/**
 * Formats the date and time from the server
 * @param object dateTime
 * 
 * @return string din-formatted date
 */
export const formatDbTime = (dateTime) => {

    try {
        let dateObject = new Date();

        if (typeof dateTime === 'string') {
            dateObject = new Date(dateTime);
        } else {
            let dateTimeParts = dateTime.date.split(/[- :]/);
            dateTimeParts[1]--;

            dateObject = new Date(...dateTimeParts);
        }

        let hours = dateObject.getHours();
        if (hours < 10) hours = '0' + hours;

        let minutes = dateObject.getMinutes();
        if (minutes < 10) minutes = '0' + minutes;

        return `${hours}:${minutes}`;
    } catch {
        return '';
    }
}
import Unit from './index';

export default class DeliveryUnit extends Unit {

    getProducts = (deliveryDate, cbSuccess, cbError) => {
        this.post('/delivery/products', { delivery_date: deliveryDate }, cbSuccess, cbError);
    }

    updateDeliveryStatus = (body, cbSuccess, cbError) => {
        this.put('/delivery/bulk', body, cbSuccess, cbError);
    }
}




import { createSlice } from '@reduxjs/toolkit'
import TicketingUnit from '../../../communicator/http/Ticketing'
import wrlog from '../../functions/wrlog'
const ticketingUnit = new TicketingUnit()

export const ticketCountSlice = createSlice({
    name: 'counter',
    initialState: {
        my: 0,
        open: 0
    },
    reducers: {
        setTicketCount: (state, action) => {
            state.my = action.payload.my
            state.open = action.payload.open
        },
    },
})

// Action creators are generated for each case reducer function
export const { setTicketCount, getTicketCount } = ticketCountSlice.actions

export default ticketCountSlice.reducer
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Message from '../../../elements/message';
import Loading from '../../../helper/screens/Loading';
import TopBar from '../../../elements/topbar';
import BankingUnit from '../../../communicator/http/Banking';
import Pagination from '../../../elements/pagination';
import { formatDbDate } from '../../../helper/functions/formatDate';
import ToolTip from '../../../elements/tooltip';
import Cache from '../../../communicator/local';
import DatePicker from '../../../elements/datepicker';
import { formatMoney } from '../../../helper/functions/formatPrice';
import Popup from '../../../elements/popup';
import Dropzone from 'react-dropzone';

import balanceStyles from '../Balance/style.module.scss';
import styles from './style.module.scss';
import moment from 'moment';


/**
 * 
 * @returns Transactions Overview Page
 */
const Accounting = (props) => {

    const [loading, setLoading] = useState(false);

    const [date, setDate] = useState(new Date());

    const [url, setUrl] = useState('');

    const bankingUnit = new BankingUnit();

    const loadZip = () => {

        if (date === null) {
            return;
        }

        setLoading(true);

        bankingUnit.getAccountingZip(moment(date).format('YYYY-MM'), (res) => {
            setLoading(false);
            setUrl(res.url);

        }, (err) => {
            setLoading(false);
        })
    }

    return <>
        <TopBar title={"Transaktionen"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <Row>
                        <Col md={3}>
                            <DatePicker
                                onChange={(val) => {
                                    setDate(val);
                                }}
                                label={"Monat"}
                                value={date}
                            />
                        </Col>
                        <Col md={3}>
                            <div
                                className={`button`}
                                onClick={() => loadZip()}
                            >Zip-Laden</div>
                        </Col>
                        <Col md={3}>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
                <Col>
                    {url !== '' && <a target={'_blank'} href={url} onClick={() => setTimeout(() => setUrl(''), 500)}>herunterladen</a>}
                </Col>
            </Row>
        </Container>

        <Loading visible={loading} />
    </>

}

export default Accounting;
import { CircularProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const Loading = (props) => {

    if (props.visible) {

        if (props.skeleton !== undefined) {

            if (props.skeleton === 'table') {

            }

            return <Skeleton animation="wave" />
        } else {
            return (
                <div className={"loading_container " + (props.relative !== undefined ? 'relative' : '')} style={props.containerStyle !== undefined ? props.containerStyle : {}}>
                    <CircularProgress color="secondary" className="loadingSpinner" style={props.style !== undefined ? props.style : {}} />
                </div>
            );
        }


    } else {
        return null;
    }

}

export default Loading;
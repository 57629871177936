import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import { translate } from '../../helper/multilingual';
import { hasPermission } from '../../helper/functions/auth';
import Cache from '../../communicator/local';
import Pagination from '../../elements/pagination';
import wrlog from '../../helper/functions/wrlog';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../elements/statusLabel';
import Popup from '../../elements/popup';
import Message from '../../elements/message';
import CouponsUnit from '../../communicator/http/Coupons';

/**
 * 
 * @returns Customers Overview Page
 */
const Coupons = () => {

    const [loading, setLoading] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [searchNameTerm, setSearchNameTerm] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [isInit, setIsInit] = useState(true);

    const perPage = 25;

    const couponsUnit = new CouponsUnit();
    const queryArgsCache = new Cache('coupons.queryArgs', true);

    /**
     * initially loads products
     */
    useEffect(() => {
        let queryArgs = queryArgsCache.get();
        if (page > 0 && (!isInit || queryArgs === false)) {
            setIsInit(false);
            load();
        }
    }, [page]);

    /**
     * initially loads products
     */
    useEffect(() => {
        setIsInit(false);
        setLoading(true);
        setPage(0);
        setTimeout(() => {
            setPage(1);
        }, 100)
    }, [filterStatus]);

    /**
     * loads products by search result
     * @param {*} e 
     */
    const doSearch = (e) => {
        e.preventDefault();
        load();
    }

    /**
     * Loads admin users from API
     */
    const load = () => {

        setLoading(true);

        const skip = (page - 1) * perPage;

        const body = {
            skip: skip,
            take: perPage,
            code: searchNameTerm,
            status: filterStatus,
        };

        couponsUnit.listCoupons(body, (res) => {

            setLoading(false);

            body.page = page;
            queryArgsCache.set(body);

            setCoupons(res.results);
            setTotalPages(Math.ceil(res.total / perPage));
        }, (err) => {
            setLoading(false);
        })
    }

    return <>
        <TopBar title={"Gutscheine"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <form onSubmit={doSearch}>
                        <Row>
                            <Col md={6}>
                                <TextField id="standard-basic" className="filled" value={searchNameTerm} label="Gutscheine durchsuchen" onChange={value => setSearchNameTerm(value.target.value)} /><br />
                            </Col>
                            <Col md={2} >
                                <div
                                    onClick={doSearch}
                                    style={{ paddingTop: 17, paddingBottom: 18 }}
                                    className={`button ${searchNameTerm === '' ? 'disabled' : ''}`}>
                                    suchen
                                </div>
                            </Col>
                            <Col md={4} style={{ textAlign: 'right' }}>
                                {hasPermission('customer', ['can_create']) && <Link
                                    to={`/coupons/new`}
                                    style={{ paddingTop: 17, paddingBottom: 18 }}
                                    className={'button'}>Gutschein erstellen</Link>}
                            </Col>
                        </Row>
                    </form>
                    <Row>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Status</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setFilterStatus(e.target.value === '' ? '' : e.target.value)
                                    }}
                                    value={filterStatus || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"active"}>Aktiv</MenuItem>
                                    <MenuItem value={"expired"}>Abgelaufen</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <form onSubmit={doSearch}>
                            </form>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Typ</TableCell>
                                <TableCell style={{ width: 280 }}>Status</TableCell>
                                <TableCell style={{ width: 110 }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                coupons.map((coupon, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>
                                            {coupon.code}
                                        </TableCell>
                                        <TableCell>
                                            {coupon.type}
                                        </TableCell>
                                        <TableCell>
                                            <StatusLabel status={coupon.status}>{translate(coupon.status)}</StatusLabel>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>
                                            {hasPermission('customer', ['can_update', 'can_delete']) && <Link to={`/coupons/${coupon.id}`}>bearbeiten</Link>}
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        pages={totalPages}
                        current={page}
                        pageChange={setPage}
                    />
                </Col>
            </Row>
        </Container>

        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />

        <Loading visible={loading} />
    </>

}

export default Coupons;
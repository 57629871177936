import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from '../../../helper/screens/Loading';
import wrlog from '../../../helper/functions/wrlog';
import Message from '../../../elements/message';
import ServiceDeskUnit from '../../../communicator/http/ServiceDesk';
import ReactHtmlParser from 'react-html-parser';

const ServiceDeskForm = () => {

    const [loading, setLoading] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [body, setBody] = useState({});
    const [type, setType] = useState('bug');

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [confluenceSearchValue, setConfluenceSearchValue] = useState('');
    const [articles, setArticles] = useState([]);

    const serviceDeskUnit = new ServiceDeskUnit();

    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const urlRegex = new RegExp(expression);

    /**
     * initially loads tickets
     */
    useEffect(() => {
        setLoading(true);
        serviceDeskUnit.listFormFields(
            type,
            (res) => {
                setLoading(false);
                setFormFields(res);
                setBody({});
            },
            (err) => {
                setFormFields([]);
            }
        )
    }, [type]);

    /**
     * initially loads tickets
     */
    useEffect(() => {
        if (confluenceSearchValue.length > 5) {
            serviceDeskUnit.searchArticles(
                confluenceSearchValue,
                (res) => {
                    console.log(res);
                    setArticles(res)
                },
                (err) => {
                    console.log(err);
                }
            )
        }
    }, [confluenceSearchValue]);

    const changeValue = (key, value) => {
        let formValues = { ...body };
        formValues[key] = value;
        setBody(formValues);
    }

    const send = () => {
        let valid = true;

        let _body = { ...body };

        formFields.forEach(({ fieldId, required, jiraSchema }) => {

            // Check required fields
            if (required
                && (
                    body[fieldId] === undefined
                    || body[fieldId] === null
                    || body[fieldId] === ''
                )
            ) {
                valid = false;
            }

            // Check URL Format
            if (
                body[fieldId] !== undefined
                && body[fieldId] !== null
                && body[fieldId] !== ''
                && jiraSchema?.custom?.includes('customfieldtypes:url')
                && !body[fieldId].match(urlRegex)) {
                valid = false;
            }

            // Check description length
            if (jiraSchema?.system === 'description' && required && body[fieldId] !== undefined && body[fieldId] !== null && body[fieldId].length < 50) {
                valid = false;
            }

            // Format option value
            if (_body[fieldId] && (jiraSchema?.type === 'option' || jiraSchema?.type === 'priority')) {
                _body[fieldId] = { id: _body[fieldId] }
            }

        })

        if (!valid) {
            setErrorMessageOpen(true);
            setErrorMessageType('error');
            setErrorMessageTitle('Felder nicht richtig ausgefüllt.')
            setErrorMessageBody('Bitte fülle alle mit Stern (*) gekennzeichneten Felder aus.');
            setErrorMessageButtonText('OK');
            setErrorMessageSmall(false);
            return false;
        }

        setLoading(true);
        serviceDeskUnit.create(type, _body, (res) => {

            if (res?.issueId) {
                setErrorMessageOpen(true);
                setErrorMessageType('success');
                setErrorMessageTitle('Dein Ticket wurde erstellt')
                setErrorMessageBody('Dein Ticket wurde erstellt und wird so schnell wie möglich bearbeitet. Du bekommst auch eine Bestätigungsmail von uns. Wenn du das erste Ticket schreibst, kann es sein, dass du eine Einladungs-Email von "Jira" bekommst. Bitte folge den Schritten in dieser E-Mail.');
                setErrorMessageButtonText('OK');
                setErrorMessageSmall(false);
            } else {
                setErrorMessageOpen(true);
                setErrorMessageType('error');
                setErrorMessageTitle('Ein Fehler ist aufgetreten.')
                setErrorMessageBody('Ein unbekannter Fehler ist aufgetreten, bitte kontrolliere alle Felder und versuche es noch einmal.');
                setErrorMessageButtonText('OK');
                setErrorMessageSmall(false);
            }

            setLoading(false);
        }, () => {
            setErrorMessageOpen(true);
            setErrorMessageType('error');
            setErrorMessageTitle('Ein Fehler ist aufgetreten.');
            setErrorMessageBody('Ein unbekannter Fehler ist aufgetreten, bitte kontrolliere alle Felder und versuche es noch einmal.');
            setErrorMessageButtonText('OK');
            setErrorMessageSmall(false);
        });

    }

    return <>
        <Container style={{ padding: 0, marginTop: 20, marginBottom: 0 }}>
            <Row style={{}}>
                <Col sm={8}>
                    <FormControl
                        className="selectFormControl"
                    >
                        <InputLabel id="type">Typ</InputLabel>
                        <Select
                            onChange={(e) => {
                                setType(e.target.value)
                            }}
                            value={type}
                        >
                            <MenuItem value={"bug"}>Bug/Problem</MenuItem>
                            <MenuItem value={"feature-request"}>Feature-Request</MenuItem>
                        </Select>
                    </FormControl>
                    {type === 'bug'
                        && <p>Eine Funktion funktioniert nicht, nur teilweise oder falsch</p>
                    }
                    {type === 'feature-request'
                        && <p>Du hast eine Idee oder einen Wunsch für eine Funktion? Beschreibe es hier.</p>
                    }
                    {
                        formFields.map((field, key) => {

                            if (!field?.visible) {
                                return null;
                            }

                            if (field?.jiraSchema?.type === 'option' || field?.jiraSchema?.type === 'priority') {
                                return <>
                                    <FormControl
                                        key={key}
                                        className="selectFormControl"
                                    >
                                        <InputLabel id="type">{field?.name}</InputLabel>
                                        <Select
                                            onChange={(e) => changeValue(field?.fieldId, e.target.value)}
                                            value={body[field?.fieldId] || ''}
                                        >
                                            {
                                                field?.validValues?.map((option, optionKey) => (
                                                    <MenuItem key={optionKey} value={option.value}>{option.label}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    {field?.description
                                        && <small style={{ fontSize: 12, marginTop: -20, position: 'relative', top: -20, left: 3 }}>{field?.description}</small>
                                    }
                                </>
                            } else if ( // IF TExtfield
                                field?.jiraSchema?.type === 'string'
                            ) {
                                return <><TextField
                                    key={key}
                                    value={body[field?.fieldId] || ''}
                                    onChange={e => changeValue(field?.fieldId, e.target.value)}
                                    multiline={field?.jiraSchema?.system !== 'summary'}
                                    className={`filled ${body[field?.fieldId] !== undefined && body[field?.fieldId] !== null && body[field?.fieldId] !== '' ? 'valued' : ''}`}
                                    error={
                                        (
                                            body[field?.fieldId] === ''
                                            && field?.required
                                        )
                                        || (
                                            field?.required
                                            && field.jiraSchema.system === 'description'
                                            && body[field?.fieldId] !== undefined
                                            && body[field?.fieldId] !== null
                                            && body[field?.fieldId].length < 50
                                        )
                                        || (
                                            body[field?.fieldId] !== undefined
                                            && body[field?.fieldId] !== ''
                                            && field?.jiraSchema?.custom?.includes('customfieldtypes:url')
                                            && !body[field?.fieldId].match(urlRegex)
                                        )
                                    }
                                    required={field?.required}
                                    id="standard-basic"
                                    label={field?.name + (field?.required && field.jiraSchema.system === 'description' ? 'min. 50 Zeichen' : '')}
                                />
                                    {field?.description
                                        && <small style={{ fontSize: 12, marginTop: -20, position: 'relative', top: -20, left: 3 }}>{field?.description}</small>
                                    }
                                </>
                            }
                        })
                    }
                    <br /><br /><div
                        className="button"
                        onClick={() => send()}
                        style={{ marginTop: 30 }}
                    >senden</div>
                </Col>
                <Col md={4} style={{ backgroundColor: 'white', position: 'sticky', top: 0, left: 0, height: '100vh', marginTop: -50, marginBottom: -50, paddingTop: 30 }}>
                    <TextField
                        value={confluenceSearchValue}
                        onChange={e => setConfluenceSearchValue(e.target.value)}
                        className={`filled ${confluenceSearchValue !== undefined && confluenceSearchValue !== null && confluenceSearchValue !== '' ? 'valued' : ''}`}
                        id="standard-basic"
                        label={'Suche nach Problem'}
                    />
                    <small style={{ fontSize: 12, marginTop: -20, position: 'relative', top: -20, left: 3 }}>Gib hier eine Kurze Beschreibung des Problems ein. Vielleicht haben wir bereits eine Lösung dafür.</small>

                    {
                        articles
                        && articles.map(({ title, excerpt, id }, key) => {
                            return <a key={key} className='' href={`/ticketing/article/${id}`}>
                                <div className='' style={{ padding: 10, borderBottom: '1px solid grey', marginBottom: 10 }}>
                                    <h5>{ReactHtmlParser(title)}</h5>
                                    <small>{ReactHtmlParser(excerpt)}</small>
                                </div>
                            </a>
                        })
                    }

                </Col>
            </Row>
        </Container>

        {
            loading
            && <Loading visible={true} />
        }

        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />
    </>

}

export default ServiceDeskForm;
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import StatisticsUnit from '../../../communicator/http/Statistics';
import Loading from '../../../helper/screens/Loading';
import wrlog from '../../../helper/functions/wrlog';
import { hasPermission } from '../../../helper/functions/auth';

// import "~react-vis/dist/style";
import DatePicker from '../../../elements/datepicker';
import { Card } from '@material-ui/core';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, MarkSeries, Hint } from 'react-vis';
import moment from 'moment';
import { formatMoney } from '../../../helper/functions/formatPrice';

const CustomerGrowthKpi = ({ start, end }) => {

    let monthStart = start;
    let monthEnd = end;

    const [loading, setLoading] = useState(false);
    const [filterDate, setDataDate] = useState({
        from: new Date(monthStart),
        to: new Date(monthEnd)
    });

    const [data, setData] = useState([]);
    const [dataMax, setDataMax] = useState(10000);
    const [dataMin, setDataMin] = useState(0);

    const [selecteddataHint, setSelecteddataHint] = useState('');
    const [dataChartWidth, setDataChartWidth] = useState(400);
    const dataContainerRef = useRef();


    useEffect(() => {
        if (filterDate.from === null || filterDate.to === null) {
            return;
        }

        setLoading(true);

        const statisticsUnit = new StatisticsUnit();
        statisticsUnit.getCustomerFlow(moment(filterDate.from).format('yyyy-MM-DD'), moment(filterDate.to).format('yyyy-MM-DD'), (res) => {

            let max = res[0].total;
            let min = res[0].total;

            let processed = res.map((result, key) => {

                if (max < result.total) {
                    max = result.total;
                }
                if (min > result.total) {
                    min = result.total;
                }

                return {
                    ...result,
                    x: key,
                    y: parseInt(result.total),
                    total: result.total,
                }
            })

            setDataMax(max);
            setDataMin(min);

            setData(processed);
            setLoading(false);

        }, () => { })
    }, [filterDate])

    useEffect(() => {
        const handleResize = () => {
            if (dataContainerRef.current !== undefined) {
                setDataChartWidth(dataContainerRef.current.offsetWidth - 50)
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);

    }, [])

    if (!hasPermission('admin', 'can_read')) {
        return <></>;
    }

    return <>
        <DatePicker
            onChange={(val) => {
                setDataDate({
                    from: val[0],
                    to: val[1]
                });
            }}
            value={filterDate.from}
            label={"Datum"}
            startDate={filterDate.from}
            endDate={filterDate.to}
            className={loading ? 'disabled' : undefined}
            selectsRange
        />
        <Card style={{ marginTop: 0 }} ref={dataContainerRef}>
            {
                loading ?
                    <Loading visible={loading} relative containerStyle={{ height: 340 }} />
                    :
                    <XYPlot
                        width={dataChartWidth}
                        height={340}
                        yDomain={[dataMin, dataMax > 0 ? dataMax : 10]}
                    >
                        <HorizontalGridLines />
                        <LineSeries
                            curve={'curveMonotoneX'}
                            stroke={'#AEDB82'}
                            strokeWidth={4}
                            style={{}}
                            data={data}
                        />
                        <XAxis
                            tickValues={data.map((s, k) => k)}
                            tickFormat={v => {

                                if (data.length > 0) {


                                    if (data[0].scale === 'days') {
                                        moment(data[v].from).format('DD.MM.yyyy')
                                    } else if (data[0].scale === 'weeks') {
                                        return data[v].week;
                                    } else {
                                        return data[v].month
                                    }
                                }

                            }}
                            title={data.length > 0 && data[0].scale === 'weeks' ? 'Kalenderwoche' : (data.length > 0 && data[0].scale === 'months' ? 'Monat' : 'Tage')}
                        />
                        <YAxis
                            tickFormat={v => v}
                            title={'Wachstum'}
                        />
                        {
                            data.map(value => selecteddataHint === value.from && <Hint
                                align={{ vertical: 'auto', horizontal: 'right' }}
                                value={value}
                                style={{
                                    fontSize: 14,
                                    text: {
                                        display: 'none'
                                    },
                                    value: {
                                        color: 'red'
                                    }
                                }}
                            >
                                <div
                                    style={{
                                        padding: 5,
                                        borderRadius: 10,
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        color: 'black',
                                        fontWeight: 900,
                                        boxShadow: '0px 0px 20px rgba(0,0,0,0.08)',
                                        transform: 'translate(-50%, 10px)',
                                        textAlign: 'center'
                                    }}>
                                    {value.scale === 'days' && moment(value.from).format('DD.MM.yyyy')}
                                    {value.scale === 'weeks' && `KW${value.week}`}
                                    {value.scale === 'months' && value.month}
                                    <br />
                                    {value.total > 0 && '+'}{value.total} Personen
                                </div>
                            </Hint>)
                        }
                        <MarkSeries
                            className="mark-series-example"
                            sizeRange={[5, 15]}
                            fill={'#AEDB82'}
                            color={'#AEDB82'}
                            stroke={'white'}
                            strokeWidth={3}
                            size={7}
                            onValueMouseOver={(datapoint, event) => {
                                setSelecteddataHint(datapoint.from)
                            }}
                            onValueMouseOut={(datapoint, event) => {
                                setSelecteddataHint('')
                            }}
                            data={data}
                        />
                    </XYPlot>
            }

        </Card>
    </>

}

export default CustomerGrowthKpi;
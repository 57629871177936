import Unit from './index';

export default class StatisticsUnit extends Unit {

    products = (cbSuccess, cbError) => {
        this.get('/statistics/products', cbSuccess, cbError);
    }

    customers = (cbSuccess, cbError) => {
        this.get('/statistics/customers', cbSuccess, cbError);
    }

    subscriptions = (cbSuccess, cbError) => {
        this.get('/statistics/subscriptions', cbSuccess, cbError);
    }

    getSales = (from, to, cbSuccess, cbError) => {
        this.post('/statistics/sales', { from: from, to: to }, cbSuccess, cbError);
    }

    getCustomerFlow = (from, to, cbSuccess, cbError) => {
        this.post('/statistics/customer-growth', { from: from, to: to }, cbSuccess, cbError);
    }

    getNewCustomers = (from, to, cbSuccess, cbError) => {
        this.post('/statistics/new-customers', { from: from, to: to }, cbSuccess, cbError);
    }

    getReceivables = (from, to, cbSuccess, cbError) => {
        this.post('/statistics/receivables', { from: from, to: to }, cbSuccess, cbError);
    }

    getCancelationCohorts = (body, cbSuccess, cbError) => {
        this.post('/statistics/cancelation-cohorts', body, cbSuccess, cbError);
    }

    getCancelationReasons = (body, cbSuccess, cbError) => {
        this.post('/statistics/cancelation-reasons', body, cbSuccess, cbError);
    }

}




import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import PermissionUnit from '../../../communicator/http/Permission';
import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import AdminUnit from '../../../communicator/http/Admins';
import NotFound from '../../NotFound';
import { hasPermission } from '../../../helper/functions/auth';
import wrlog from '../../../helper/functions/wrlog';
import { formatDbDate, formatDbDateTime } from '../../../helper/functions/formatDate';
import Popup from '../../../elements/popup';
import DatePicker from '../../../elements/datepicker';
import cloneArray from '../../../helper/functions/cloneArray';
import moment from 'moment';
import TimeTracking from '../../TimeTracking';
import Vacations from '../../TimeTracking/vacations';

/**
 * 
 * @returns Admin User Detail Page
 */
const AdminUsersEdit = () => {

    const permissionUnit = new PermissionUnit();

    const params = useParams();

    const userId = params.id;

    const [loading, setLoading] = useState(userId !== 'new');
    const [user, setUser] = useState(true);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [askDeleteMessageOpen, setAskDeleteMessageOpen] = useState(false);

    const [permissions, setPermissions] = useState([]);
    const [is404, setIs404] = useState(false);

    const [contracts, setContracts] = useState([]);
    const [contractPopupOpen, setContractPopupOpen] = useState(false);
    const [selectedContract, setSelectedContract] = useState({});
    const [showTimeTrackingPopup, setShowTimeTrackingPopup] = useState(false);
    const [showVacationPopup, setShowVacationPopup] = useState(false);

    const { handleSubmit, setValue, control, formState: { errors } } = useForm();

    /**
     * Inititally check if userId is "new" to check if user is updated or new user is created
     */
    useEffect(() => {

        const adminUnit = new AdminUnit();

        /**
         * if userId !== set set 404 = true
         */
        if (userId === undefined || userId === null || userId === "") {
            setIs404(true);
            return;
        }

        /**
         * if userId !== new --> update user & load userData
         */
        if (userId !== 'new') {

            // Check if user can update || delete

            if (!hasPermission('admin', ['can_update', 'can_delete'])) {
                setIs404(true);
                return;
            }

            adminUnit.getAdmin(userId, (user) => {

                setLoading(false);

                setValue('firstName', user.firstName);
                setValue('lastName', user.lastName);
                setValue('email', user.email);

                setUser(user);
                loadContracts();

                setPermissions(user.permissions)
            }, () => {
                setIs404(true);
            })
        } else {
            if (!hasPermission('admin', ['can_create'])) {
                setIs404(true);
                return;
            }
        }


    }, [userId, setValue]);

    /**
     * Submit userData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const adminUnit = new AdminUnit();

        wrlog("DATA", data)

        setLoading(true);

        let body = {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
        };

        if (data.password) {
            body.password = data.password;
        }

        // Success function for Create User
        const cbSuccess = (res) => {
            setLoading(false)
            window.location.replace(`/admins/${res.id}`); // Rplace Location to new created user & reload page
        }

        // Success function for Update User
        const cbSuccessUpdate = (res) => {
            savePermissions();
            setUser(data);
        }

        const cbError = (err) => {
            setLoading(false)
        }

        if (userId === 'new') {
            adminUnit.createAdmin(body, cbSuccess, cbError);
        } else {
            body.id = userId;
            adminUnit.updateAdmin(body, cbSuccessUpdate, cbError);
        }
    }

    /**
     * Save the permission of the user
     */
    const savePermissions = () => {

        let body = {
            user_id: userId,
            permissions: permissions
        }

        permissionUnit.set(body, (response) => {

            setLoading(false);

            setErrorMessageTitle(translate('saved_text'));
            setErrorMessageBody(translate('saved_body'));
            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
            setErrorMessageSmall(true);

        }, (err) => {
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle('Fehler');
            setErrorMessageBody(err.response.data.exception.message);
        })

    }

    /**
     * Delete current user
     */
    const deleteUser = () => {
        const adminUnit = new AdminUnit();

        setLoading(true);
        setAskDeleteMessageOpen(false);

        const cbSuccess = (res) => {
            setLoading(false);
            if (res[0] === 200) {
                window.location.replace(`/admins`);
            }
        }

        const cbError = (err) => {
            setLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        adminUnit.deleteAdmin(userId, cbSuccess, cbError);
    }

    const loadContracts = () => {
        if (userId === 'new') {
            return;
        }
        const adminUnit = new AdminUnit();

        adminUnit.loadContracts(userId, (res) => {
            setContracts(res)
            setLoading(false);
        }, err => {
            console.log("ERR", err)
        });
    }

    const selectContract = (contractId) => {

        if (contractId !== 'new') {
            contracts.forEach(contract => {
                if (contract.id === contractId) {
                    let _contract = cloneArray(contract);
                    _contract.from = new Date(contract.from);
                    if (contract.to !== '' && contract.to !== null) {
                        _contract.to = new Date(contract.to);
                    }
                    setSelectedContract(_contract)
                }
            })
        } else {
            setSelectedContract({
                from: new Date(),
                to: null,
                daysVacation: '',
                monday: 8,
                tuesday: 8,
                wednesday: 8,
                thursday: 8,
                friday: 6.5,
                saturday: 0,
                sunday: 0,
            })
        }


        setContractPopupOpen(true);
    }

    const changeContractValue = (value, key) => {
        let _contract = cloneArray(selectedContract);
        _contract.from = new Date(_contract.from);
        if (_contract.to !== '') {
            _contract.to = new Date(_contract.to);
        }

        if (typeof value === 'object' && key !== 'from' && key !== 'to') {
            value = value.target.value;
        }

        _contract[key] = value;
        setSelectedContract(_contract)
    }

    const saveContract = () => {
        setLoading(true);
        const adminUnit = new AdminUnit();

        let body = {
            admin_id: userId,
            from: selectedContract.from,
            to: selectedContract.to,
            days_vacation: selectedContract.daysVacation,
            monday: selectedContract.monday,
            tuesday: selectedContract.tuesday,
            wednesday: selectedContract.wednesday,
            thursday: selectedContract.thursday,
            friday: selectedContract.friday,
            saturday: selectedContract.saturday,
            sunday: selectedContract.sunday,
        }

        body.from = moment(new Date(body.from)).format('YYYY-MM-DD');

        if (body.to !== '' && body.to !== null) {
            body.to = moment(new Date(body.to)).format('YYYY-MM-DD');
        }

        if (selectedContract.id !== undefined) {
            body.id = selectedContract.id;

            adminUnit.updateContract(body, (res) => {
                loadContracts()
                setContractPopupOpen(false);
            }, err => {
                console.log("ERR", err)
            });
        } else {
            adminUnit.createContract(body, (res) => {
                setContractPopupOpen(false);
                loadContracts()
            }, err => {
                console.log("ERR", err)
            });
        }

    }



    if (is404 === true) {
        return <NotFound />;
    }

    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to="/admins">zurück</Link><br /><br />
                        <h1>{params.id !== "new" ? <>{user.firstName} {user.lastName} {translate('edit')}</> : translate('add_new_user')}</h1>
                        {params.id !== "new" && hasPermission('admin', ['can_delete']) && <div
                            className='small_button'
                            style={{ color: 'red' }}
                            onClick={() => setAskDeleteMessageOpen(true)}>
                            {translate('delete_account')}
                        </div>}
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <Row style={{ marginTop: 50 }}>
                        <Col md={7}>
                            <FormController
                                name={"firstName"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.firstName}
                                        id="standard-basic"
                                        label={translate('firstname') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"lastName"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.lastName}
                                        id="standard-basic"
                                        label={translate('lastname') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"email"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.email}
                                        id="standard-basic"
                                        label={translate('email') + '*'}
                                        type="email"
                                    />
                                )}
                            />
                            <FormController
                                name={"password"}
                                rules={{
                                    required: userId === 'new',
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        error={errors.password}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        id="standard-basic"
                                        label={translate('password')}
                                        type="password"
                                    />
                                )}
                            />
                        </Col>
                        <Col md={5}>
                            <div
                                className="button"
                                onClick={() => setShowTimeTrackingPopup(true)}
                            >Zeitaufzeichnungen</div>
                            <br />
                            <br />
                            <div
                                className="button"
                                onClick={() => setShowVacationPopup(true)}
                            >Urlaub</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {params.id !== "new" &&
                                <>
                                    <br />
                                    <br />
                                    <h4>{translate('permissions')}</h4>
                                    <Table className="table_container">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{translate('permission')}</TableCell>
                                                <TableCell>{translate('permission_read')}</TableCell>
                                                <TableCell>{translate('permission_create')}</TableCell>
                                                <TableCell>{translate('permission_update')}</TableCell>
                                                <TableCell>{translate('permission_delete')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                Object.keys(permissions).map((permissionKey) => {

                                                    const permission = permissions[permissionKey];

                                                    return <TableRow key={permissionKey}>
                                                        <TableCell>
                                                            {translate('permission_' + permissionKey)}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                defaultChecked={permission.can_read}
                                                                onChange={(v) => {
                                                                    setPermissions(prev => {
                                                                        prev[permissionKey].can_read = !prev[permissionKey].can_read
                                                                        return prev;
                                                                    })
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                defaultChecked={permission.can_create}
                                                                onChange={(v) => {
                                                                    setPermissions(prev => {
                                                                        prev[permissionKey].can_create = !prev[permissionKey].can_create
                                                                        return prev;
                                                                    })
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                defaultChecked={permission.can_update}
                                                                onChange={(v) => {
                                                                    setPermissions(prev => {
                                                                        prev[permissionKey].can_update = !prev[permissionKey].can_update
                                                                        return prev;
                                                                    })
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                defaultChecked={permission.can_delete}
                                                                onChange={(v) => {
                                                                    setPermissions(prev => {
                                                                        prev[permissionKey].can_delete = !prev[permissionKey].can_delete
                                                                        return prev;
                                                                    })
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {params.id !== "new" &&
                                <>
                                    <br />
                                    <br />
                                    <h4>Dienstverträge</h4>
                                    <Table className="table_container">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Von</TableCell>
                                                <TableCell>Bis</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                contracts.map((contract, key) => {

                                                    return <TableRow key={key}>
                                                        <TableCell>
                                                            {formatDbDate(contract.from)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {contract.to !== '' ? formatDbDate(contract.to) : 'aktuell'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div
                                                                className="button secondary"
                                                                onClick={() => selectContract(contract.id)}
                                                            >bearbeiten</div>
                                                        </TableCell>
                                                    </TableRow>
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                    <div
                                        className="button"
                                        onClick={() => selectContract('new')}
                                    >Vertrag hinzufügen</div>
                                </>
                            }
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 100 }}>
                        <Col md={5}>
                            {
                                ((hasPermission('admin', ['can_update']) && params.id !== "new") || (hasPermission('admin', ['can_create']) && params.id === "new")) &&
                                <input type="submit" style={{ width: '200px', position: 'fixed', bottom: 20, right: 20 }} className="button" value={params.id !== "new" ? translate('save') : translate('add')} />
                            }
                        </Col>
                        <Col md={7}>
                        </Col>
                    </Row>
                </form>
            </Container>

            <Popup
                close={() => setContractPopupOpen(false)}
                open={contractPopupOpen}
            >
                <Container fluid style={{ padding: 0 }}>
                    <Row>
                        <Col md={6}>
                            <DatePicker
                                onChange={(val) => {
                                    console.log(val)
                                    changeContractValue(val, 'from');
                                }}
                                value={selectedContract.from}
                                label={"Von"}
                                date={selectedContract.from}
                            />
                        </Col>
                        <Col md={6}>
                            <DatePicker
                                onChange={(val) => {
                                    changeContractValue(val, 'to');
                                }}
                                value={selectedContract.to}
                                label={"Bis"}
                                date={selectedContract.to}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <TextField
                                onChange={(val) => {
                                    changeContractValue(val, 'daysVacation');
                                }}
                                value={selectedContract.daysVacation}
                                className={`filled ${selectedContract.daysVacation !== undefined && selectedContract.daysVacation !== null && selectedContract.daysVacation !== '' ? 'valued' : ''}`}
                                id="standard-basic"
                                label={'Urlaubstage / Jahr'}
                            />
                        </Col>
                        <Col md={6}>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <TextField
                                onChange={(val) => {
                                    changeContractValue(val, 'monday');
                                }}
                                value={selectedContract.monday}
                                className={`filled ${selectedContract.monday !== undefined && selectedContract.monday !== null && selectedContract.monday !== '' ? 'valued' : ''}`}
                                id="standard-basic"
                                label={'Montag'}
                            />
                            <TextField
                                onChange={(val) => {
                                    changeContractValue(val, 'tuesday');
                                }}
                                value={selectedContract.tuesday}
                                className={`filled ${selectedContract.tuesday !== undefined && selectedContract.tuesday !== null && selectedContract.tuesday !== '' ? 'valued' : ''}`}
                                id="standard-basic"
                                label={'Dienstag'}
                            />
                            <TextField
                                onChange={(val) => {
                                    changeContractValue(val, 'wednesday');
                                }}
                                value={selectedContract.wednesday}
                                className={`filled ${selectedContract.wednesday !== undefined && selectedContract.wednesday !== null && selectedContract.wednesday !== '' ? 'valued' : ''}`}
                                id="standard-basic"
                                label={'Mittwoch'}
                            />
                            <TextField
                                onChange={(val) => {
                                    changeContractValue(val, 'thursday');
                                }}
                                value={selectedContract.thursday}
                                className={`filled ${selectedContract.thursday !== undefined && selectedContract.thursday !== null && selectedContract.thursday !== '' ? 'valued' : ''}`}
                                id="standard-basic"
                                label={'Donnerstag'}
                            />
                            <TextField
                                onChange={(val) => {
                                    changeContractValue(val, 'friday');
                                }}
                                value={selectedContract.friday}
                                className={`filled ${selectedContract.friday !== undefined && selectedContract.friday !== null && selectedContract.friday !== '' ? 'valued' : ''}`}
                                id="standard-basic"
                                label={'Freitag'}
                            />
                            <TextField
                                onChange={(val) => {
                                    changeContractValue(val, 'saturday');
                                }}
                                value={selectedContract.saturday}
                                className={`filled ${selectedContract.saturday !== undefined && selectedContract.saturday !== null && selectedContract.saturday !== '' ? 'valued' : ''}`}
                                id="standard-basic"
                                label={'Samstag'}
                            />
                            <TextField
                                onChange={(val) => {
                                    changeContractValue(val, 'sunday');
                                }}
                                value={selectedContract.sunday}
                                className={`filled ${selectedContract.sunday !== undefined && selectedContract.sunday !== null && selectedContract.sunday !== '' ? 'valued' : ''}`}
                                id="standard-basic"
                                label={'Sonntag'}
                            />
                        </Col>
                    </Row>
                </Container>
                <div
                    className="button"
                    onClick={() => saveContract()}
                >speichern</div>
            </Popup>

            <Popup
                close={() => setShowTimeTrackingPopup(false)}
                open={showTimeTrackingPopup}
                style={{ width: 'calc(100% - 100px)', maxWidth: '100%' }}
            >
                <TimeTracking userId={userId} />
            </Popup>
            <Popup
                close={() => setShowVacationPopup(false)}
                open={showVacationPopup}
                style={{ width: 'calc(100% - 100px)', maxWidth: '100%' }}
            >
                <Vacations userId={userId} />
            </Popup>

            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />

            <Message
                open={askDeleteMessageOpen}
                type={'success'}
                small={false}
                title={translate('sure_delete_title')}
                body={translate('sure_delete_body')}
                buttonText={translate('yes_delete')}
                buttonAction={() => deleteUser()}
                buttonTwoText={translate('cancel')}
                buttonTwoAction={() => setAskDeleteMessageOpen(false)}
                buttonTwoStyle={{ color: 'red' }}
            />

            <Loading visible={loading} />
        </>

    )

}

export default AdminUsersEdit;
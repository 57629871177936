import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { hasPermission } from '../../helper/functions/auth';
import Cache from '../../communicator/local';

import styles from './style.module.scss';
import Logo from '../../assets/logo.png';
import wrlog from '../../helper/functions/wrlog';
import TicketingUnit from '../../communicator/http/Ticketing';
import { setTicketCount } from '../../helper/store/features/counter'

const Navigation = () => {

    const [ticketingOverview, setTicketingOverview] = useState(null);

    const dispatch = useDispatch();
    const ticketCount = useSelector((state) => state.counter)
    const ticketingUnit = new TicketingUnit()

    useEffect(() => {
        if (hasPermission('ticketing', ['can_read'])) {
            loadTicketingOverview();
            setInterval(() => {
                // loadTicketingOverview()
            }, 10000)
        }
    }, []);

    const loadTicketingOverview = () => {
        ticketingUnit.getOverview((res) => {
            wrlog("RELOADED", res)
            dispatch(setTicketCount({
                my: res.my,
                open: res.open,
            }));
        }, () => { })
    }



    const location = useLocation();

    const deleteSubscriptionCache = () => {
        const queryArgsCache = new Cache('subscription.queryArgs', true);
        queryArgsCache.delete();
    }

    const deleteCustomersCache = () => {
        const queryArgsCache = new Cache('customers.queryArgs', true);
        queryArgsCache.delete();
    }

    const deleteProductsCache = () => {
        const queryArgsCache = new Cache('products.queryArgs', true);
        queryArgsCache.delete();
    }

    const deleteInvoiceCache = () => {
        const queryArgsCache = new Cache('invoices.queryArgs', true);
        queryArgsCache.delete();
    }

    return <div className={styles.container}>
        <img src={Logo} className={styles.logo} />

        <div className={`${styles.buttonContainer} ${location.pathname.includes('/statistics/') || location.pathname === '/' ? styles.current : undefined}`}>
            <Link className={`${styles.button} ${location.pathname === '/' ? styles.current : undefined}`} to="/">Dashboard</Link>
            {hasPermission('admin', 'can_read') && (location.pathname.includes('/statistics/') || location.pathname === '/') &&
                <>
                    <Link className={`${styles.smallButton} ${location.pathname.includes('/statistics/cancelation-cohorts') ? styles.current : undefined}`} to="/statistics/cancelation-cohorts">Kündig.-kohorten</Link>
                    <Link className={`${styles.smallButton} ${location.pathname.includes('/statistics/stickyness') ? styles.current : undefined}`} to="/statistics/stickyness">Stickyness</Link>
                    <Link className={`${styles.smallButton} ${location.pathname.includes('/statistics/cancelation-reasons') ? styles.current : undefined}`} to="/statistics/cancelation-reasons">Kündig.-gründe</Link>
                </>
            }
        </div>


        <div className={`${styles.buttonContainer} ${location.pathname.includes('/timetracking') ? styles.current : undefined}`}>
            <Link className={`${styles.button} ${location.pathname.includes('/timetracking') ? styles.current : undefined}`} to="/timetracking">Zeitaufzeichnung</Link>
            {location.pathname.includes('/timetracking') &&
                <>
                    <Link className={`${styles.smallButton} ${location.pathname.includes('/timetracking') && !location.pathname.includes('/vacation') ? styles.current : undefined}`} to="/timetracking">Zeitaufzeichnung</Link>
                    <Link className={`${styles.smallButton} ${location.pathname.includes('/timetracking/vacation') ? styles.current : undefined}`} to="/timetracking/vacation">Urlaub</Link>
                </>
            }
        </div>

        {hasPermission('admin', ['can_read', 'can_create', 'can_update', 'can_delete']) && <Link className={`${styles.button} ${location.pathname.includes('/admins') ? styles.current : undefined}`} to="/admins">Admins</Link>}
        {
            hasPermission('ticketing', ['can_read']) && <div className={`${styles.buttonContainer} ${location.pathname.includes('/ticketing') ? styles.current : undefined}`}>
                <Link className={`${styles.button} ${location.pathname.includes('/ticketing') ? styles.current : undefined}`} to="/ticketing/my">Ticketing</Link>
                {location.pathname.includes('/ticketing') &&
                    <>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/ticketing/my') ? styles.current : undefined}`} to="/ticketing/my"><div>Meine Tickets</div> {ticketCount !== undefined && <div className={styles.badge}>{ticketCount.my}</div>}</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/ticketing/open') ? styles.current : undefined}`} to="/ticketing/open"><div>Offene Tickets</div> {ticketCount !== undefined && <div className={styles.badge}>{ticketCount.open}</div>}</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/ticketing/all') ? styles.current : undefined}`} to="/ticketing/all">Alle Tickets</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/ticketing/cancel') ? styles.current : undefined}`} to="/ticketing/cancel">Stornieren</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/ticketing/pause') ? styles.current : undefined}`} to="/ticketing/pause">Pausieren</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/ticketing/claim') ? styles.current : undefined}`} to="/ticketing/claim">Reklamation</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/ticketing/servicedesk') ? styles.current : undefined}`} to="/ticketing/servicedesk">Interner IT-Service-Desk</Link>
                    </>
                }
            </div>
        }

        {
            hasPermission('product', ['can_read', 'can_create', 'can_update', 'can_delete']) && <div className={`${styles.buttonContainer} ${(location.pathname.includes('/products') && !location.pathname.includes('/procurement')) ? styles.current : undefined}`}>
                <Link onClick={deleteProductsCache} className={`${styles.button} ${(location.pathname.includes('/products') && !location.pathname.includes('/procurement')) ? styles.current : undefined}`} to="/products">Produkte</Link>
                {((location.pathname.includes('/products') && !location.pathname.includes('/procurement')) || location.pathname.includes('/coupons')) &&
                    <>
                        <Link className={`${styles.smallButton} ${!location.pathname.includes('/products/groups') && !location.pathname.includes('/coupons') ? styles.current : undefined}`} to="/products">Alle Produkte</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/products/groups') ? styles.current : undefined}`} to="/products/groups">Produktgruppen</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/coupons') ? styles.current : undefined}`} to="/coupons">Gutscheine</Link>
                    </>
                }
            </div>
        }

        {
            hasPermission('customer', ['can_read', 'can_create', 'can_update', 'can_delete']) && <div className={`${styles.buttonContainer} ${location.pathname.includes('/subscriptions') ? styles.current : undefined}`}>
                <Link onClick={deleteCustomersCache} className={`${styles.button} ${location.pathname.includes('/customers') ? styles.current : undefined}`} to="/customers">Kunden</Link>
                {location.pathname.includes('/customers') &&
                    <>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/customers') && !location.pathname.includes('/creditcheck') ? styles.current : undefined}`} to="/customers">Alle Kunden</Link>
                        {
                            hasPermission('admin', ['can_read'])
                            && <Link className={`${styles.smallButton} ${location.pathname.includes('/customers/creditcheck') ? styles.current : undefined}`} to="/customers/creditcheck">Kredit-Check</Link>
                        }
                    </>
                }
            </div>
        }

        {
            hasPermission('subscription', ['can_read', 'can_create', 'can_update', 'can_delete']) && <div className={`${styles.buttonContainer} ${location.pathname.includes('/subscriptions') ? styles.current : undefined}`}>
                <Link className={`${styles.button} ${location.pathname.includes('/subscriptions') ? styles.current : undefined}`} to="/subscriptions/productcount">Abonnements</Link>
                {location.pathname.includes('/subscriptions') &&
                    <>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/subscriptions') && !location.pathname.includes('/productcount') ? styles.current : undefined}`} to="/subscriptions">Alle Abonnements</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/subscriptions/productcount') ? styles.current : undefined}`} to="/subscriptions/productcount">Einkauf</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/subscriptions/deliverynotes') ? styles.current : undefined}`} to="/subscriptions/deliverynotes">Lieferscheine</Link>
                    </>
                }
            </div>
        }

        {
            hasPermission('product', ['can_read', 'can_create', 'can_update', 'can_delete']) && <div className={`${styles.buttonContainer} ${location.pathname.includes('/procurement') ? styles.current : undefined}`}>
                <Link className={`${styles.button} ${location.pathname.includes('/procurement') ? styles.current : undefined}`} to="/procurement">Einkauf</Link>
                {location.pathname.includes('/procurement') &&
                    <>
                        <Link className={`${styles.smallButton} ${location.pathname === '/procurement' ? styles.current : undefined}`} to="/procurement">Lieferungen</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/procurement/supplier') ? styles.current : undefined}`} to="/procurement/supplier">Lieferanten</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/procurement/products') ? styles.current : undefined}`} to="/procurement/products">Produkte</Link>
                    </>
                }
            </div>
        }

        {/* {hasPermission('subscription', ['can_read', 'can_create', 'can_update', 'can_delete']) && <Link onClick={deleteSubscriptionCache} className={`${styles.button} ${location.pathname.includes('/subscriptions') ? styles.current : undefined}`} to="/subscriptions">Abonnements</Link>} */}
        {hasPermission('invoice', ['can_read', 'can_create', 'can_update', 'can_delete']) && <Link onClick={deleteInvoiceCache} className={`${styles.button} ${location.pathname.includes('/invoices') ? styles.current : undefined}`} to="/invoices">Rechnungen</Link>}

        {
            (hasPermission('admin', ['can_delete']) || hasPermission('ticketing', ['can_read'])) && <div className={`${styles.buttonContainer} ${location.pathname.includes('/settings') ? styles.current : undefined}`}>
                <Link className={`${styles.button} ${location.pathname.includes('/settings') ? styles.current : undefined}`} to={hasPermission('admin', ['can_delete']) ? "/settings" : "/settings/makros"}>Einstellungen</Link>
                {location.pathname.includes('/settings') &&
                    <>
                        {hasPermission('admin', ['can_delete']) && <Link className={`${styles.smallButton} ${location.pathname.includes('/settings') && !location.pathname.includes('/makros') && !location.pathname.includes('/banking-orders-automation') ? styles.current : undefined}`} to="/settings">Generelle Einstellungen</Link>}
                        {hasPermission('admin', ['can_delete']) && <Link className={`${styles.smallButton} ${location.pathname.includes('/settings/banking-orders-automation') ? styles.current : undefined}`} to="/settings/banking-orders-automation">Auftrag-Vorlagen</Link>}

                        <Link className={`${styles.smallButton} ${location.pathname.includes('/settings/makros') ? styles.current : undefined}`} to="/settings/makros">Ticketing-Makros</Link>
                    </>
                }
            </div>
        }

        {
            (hasPermission('admin', ['can_delete'])) && <div className={`${styles.buttonContainer} ${location.pathname.includes('/banking/') ? styles.current : undefined}`}>
                <Link className={`${styles.button} ${location.pathname.includes('/banking/transactions') ? styles.current : undefined}`} to={"/banking/transactions"}>Banking</Link>
                {location.pathname.includes('/banking/') &&
                    <>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/banking/transactions') ? styles.current : undefined}`} to="/banking/transactions">Transaktionen</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/banking/liabilities') ? styles.current : undefined}`} to="/banking/liabilities">Verbindlichkeiten</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/banking/receivables') ? styles.current : undefined}`} to="/banking/receivables">Forderungen</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/banking/orders') ? styles.current : undefined}`} to="/banking/orders">Aufträge</Link>
                        <Link className={`${styles.smallButton} ${location.pathname.includes('/banking/accounting') ? styles.current : undefined}`} to="/banking/accounting">Buchhaltung</Link>

                    </>
                }
            </div>
        }


        {hasPermission('admin', ['can_delete']) && <Link className={`${styles.button} ${location.pathname === '/downloads' ? styles.current : undefined}`} to="/downloads">Downloads</Link>}
        {hasPermission('admin', ['can_delete']) && <Link className={`${styles.button} ${location.pathname === '/pronto' ? styles.current : undefined}`} to="/pronto">Pronto</Link>}


        <div className={`${styles.logoutButton} ${styles.button}`} onClick={() => {
            localStorage.removeItem('token');
            localStorage.removeItem('isAdmin');
            window.location.reload();
        }}>Logout</div>
    </div >

}

export default Navigation;
import Unit from './index';

export default class DiscountsUnit extends Unit {

    listDiscounts = (customerId, cbSuccess, cbError) => {
        this.post(`/discounts/list`, { customer_id: customerId }, cbSuccess, cbError);
    }

    addDiscount = (body, cbSuccess, cbError) => {
        this.post(`/discounts`, body, cbSuccess, cbError);
    }

    revokeDiscount = (discountId, cbSuccess, cbError) => {
        this.put(`/discounts`, { discount_id: discountId, status: 'revoked' }, cbSuccess, cbError);
    }

}
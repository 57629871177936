import Unit from './index';

export default class CouponsUnit extends Unit {

    listCoupons = (body, cbSuccess, cbError) => {
        this.post(`/coupon/list`, body, cbSuccess, cbError);
    }

    getCoupon = (couponId, cbSuccess, cbError) => {
        this.get(`/coupon/${couponId}`, cbSuccess, cbError);
    }

    createCoupon = (body, cbSuccess, cbError) => {
        this.post(`/coupon`, body, cbSuccess, cbError);
    }

    updateCoupon = (body, cbSuccess, cbError) => {
        this.put(`/coupon`, body, cbSuccess, cbError);
    }

    deleteCoupon = (body, cbSuccess, cbError) => {
        this.delete(`/coupon`, body, cbSuccess, cbError);
    }

}
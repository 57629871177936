import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import UserUnit from '../../communicator/http/User';
import Loading from '../../helper/screens/Loading';
import { useForm } from "react-hook-form";
import Message from '../../elements/message';
import { translate } from '../../helper/multilingual';
import { Link } from 'react-router-dom';

import Logo from '../../assets/logo.png';

/**
 * 
 * @returns Login Page
 */
const Maintenance = () => {

    return (
        <>
            <div className="login_wrapper">
                <img src={Logo} />
                <div className="login_container">
                    <h1>Wir sind in wenigen Minuten wieder für dich da.</h1>
                    <br />
                    <p>Wir arbeiten ständig daran, unsere Produkte für dich noch besser zu machen. Deshalb arbeiten wir gerade an unserer Website.</p>
                    <p>Wir sind in wenigen Minuten wieder für dich da um gemeinsam mit dir Lebensmittel zu retten.</p>
                    <a href="https://afreshed.at" className='button' style={{textAlign: 'center', marginTop: 50}}>zu afreshed.at</a>
                </div>

            </div>
        </>
    );
}

export default Maintenance;
import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import wrlog from '../../helper/functions/wrlog';
import { nanoid } from 'nanoid';
import { io } from 'socket.io-client';
import axios from "axios";
import Text from './elements/text';
import Login from './elements/login';
import Select from './elements/select';

const url = 'dev-api.afreshed.at:8080/setup.php';
const wsUrl = `ws://${url}`;
const httpUrl = `http://${url}`;
const botId = `afreshed`;

let socket;

/**
 * 
 * @returns Invoices Overview Page
 */
const Chatbot = () => {


    const [loading, setLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [messages, setMessages] = useState([]);
    const [messageContent, setMessageContent] = useState('');
    const [conversationId, setConversationId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [chatOpen, setChatOpen] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(false);

    const textAreaRef = useRef();
    const contentRef = useRef();

    useEffect(() => {
        const userId = nanoid(24);
        setUserId(userId);

        if (socket) {
            socket.off('event', dispatchSocketEvent)
            socket.off('connect', sendVisit)

            socket.disconnect()
        }

        socket = io(`${wsUrl}`, {
            query: {
                // visitorId: userId
            },
            // transports: ['websocket', 'polling'],
            autoConnect: false,
            path: '/socket.php'
        });

        socket.connect();

        socket.on('connect', sendVisit);
        socket.on('disconnect', function (reason) {
            console.log('DISCONNECTED BECAUSE ' + reason);
        });
        socket.on('event', dispatchSocketEvent)

        // var conn = new WebSocket(wsUrl);
        // conn.onopen = function(e) {
        //     console.log("Connection established!");
        // };

        // conn.onmessage = function(e) {
        //     console.log(e.data);
        // };
    }, []);

    useEffect(() => {

        textAreaRef.current.addEventListener('keypress', onEnterPressed);

        return () => {
            textAreaRef.current.removeEventListener('keypress', onEnterPressed);
        }

    }, [textAreaRef])

    useEffect(() => {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
        wrlog("messages", messages)
    }, [messages])

    const onEnterPressed = (e) => {
        if (e.code.toLowerCase() === 'enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage(e.target.value)
        }
    }

    const sendMessage = async (text) => {
        await axios.post(`${httpUrl}/api/v1/bots/${botId}/mod/channel-web/messages?__ts=${Date.now()}`,
            {
                webSessionId: socket.id,
                conversationId: conversationId,
                payload: {
                    type: 'text',
                    text: text
                }
            }
        )

        setMessageContent('');
    }

    const sendData = async (data) => {

        await axios.post(`${httpUrl}/api/v1/bots/${botId}/mod/channel-web/messages?__ts=${Date.now()}`,
            {
                webSessionId: socket.id,
                conversationId: conversationId,
                payload: {
                    type: 'text',
                    text: JSON.stringify(data),
                }
            }
        )
    }

    const sendVisit = async () => {
        // await axios.post(`${httpUrl}/api/v1/bots/${botId}/mod/channel-web/messages?__ts=${Date.now()}`, {
        //     webSessionId: socket.id,
        //     payload: {
        //         type: 'visit',
        //         text: 'User visit',
        //         timezone: 0,
        //         language: 'de'
        //     }
        // })

        // await sendMessage('INIT');
        console.log("CONNECTED!!!");
    }

    const dispatchSocketEvent = event => {
        wrlog('event', event)
        if (event.name === 'guest.webchat.message') {
            setIsTyping(false);

            if (event?.data?.payload?.type === 'visit') {
                setConversationId(event.data.conversationId);
            } else if (event?.data?.payload?.type === 'text') {
                if (event?.data?.payload.text === 'INIT') {
                    return;
                }
                setMessages(prev => [...prev, { type: 'text', content: event?.data?.payload.text, sender: (event?.data?.authorId !== undefined ? 'customer' : 'bot') }]);
            } else if (event?.data?.payload?.type === 'dropdown') {
                setMessages(prev => [...prev, { type: 'select', content: event?.data?.payload.options, sender: 'bot' }]);
            }

        } else if (event.name === 'guest.webchat.typing') {
            setIsTyping(true);
        } else if (event.name === 'guest.webchat.data') {
            setIsTyping(false)
            console.log("event.data", event.data)
            if (event.data.data === 'login') {
                setMessages(prev => [...prev, { type: 'login' }]);
            }

        } else {
            setIsTyping(false)
        }
    }

    const doLogin = ({ email, password }) => {
        sendMessage('');
        sendData({ email, password })
    }

    const renderChat = () => {
        if (messages.length < 1) {
            return null;
        }

        let currentSender = messages[0].sender;

        return messages.map((message, key) => {

            let out = [];
            if (currentSender !== message.sender) {
                currentSender = message.sender;
                out.push(<div className={styles.seperator}></div>)
            }

            switch (message.type) {
                case 'text':
                    out.push(<Text content={message.content} sender={message.sender} />)
                    break;
                case 'login':
                    out.push(<Login login={doLogin} />)
                    break;
                case 'select':
                    out.push(<Select content={message.content} onClick={sendMessage} disabled={key !== (messages.length - 1)} />)
                    break;
            }
            out.push()

            return out;
        })

    }

    return <div className={styles.container}>
        <div className={styles.header}>
            afreshed Support
            <div
                className={styles.close}
                onClick={() => setChatOpen(false)}
            ></div>
        </div>

        <div className={styles.content} ref={contentRef}>
            {isTyping && <div className={`${styles.bubble} ${styles.bot}`}>
                <div className={styles.message}>
                    ...
                </div>
            </div>}
            {
                renderChat()
            }
        </div>
        <div className={styles.footer}>
            <textarea
                placeholder='Wie können wir dir helfen?'
                onChange={e => { setMessageContent(e.target.value); wrlog("CAHNGE", e.target.value) }}
                value={messageContent}
                ref={textAreaRef}
            ></textarea>
            <div
                className={styles.button}
                onClick={() => sendMessage(messageContent)}>senden</div>
        </div>


    </div >

}

export default Chatbot;
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import { translate } from '../../helper/multilingual';
import { hasPermission } from '../../helper/functions/auth';
import Cache from '../../communicator/local';
import ProductUnit from '../../communicator/http/Products';
import CustomerUnit from '../../communicator/http/Customers';
import Pagination from '../../elements/pagination';
import wrlog from '../../helper/functions/wrlog';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../elements/statusLabel';
import Popup from '../../elements/popup';
import ContactDataForm from './Single/contactDataForm';
import Message from '../../elements/message';

/**
 * 
 * @returns Customers Overview Page
 */
const Customers = () => {

    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchNameTerm, setSearchNameTerm] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterPostcode, setFilterPostcode] = useState("");
    const [filterStatuses, setFilterStatuses] = useState("");
    const [filterProducts, setFilterProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [createPopupOpen, setCreatePopupOpen] = useState(false);
    const [isInit, setIsInit] = useState(true);

    const perPage = 25;

    const productUnit = new ProductUnit();
    const customerUnit = new CustomerUnit();
    const queryArgsCache = new Cache('customers.queryArgs', true);

    /**
     * initially loads products
     */
    useEffect(() => {

        let queryArgs = queryArgsCache.get();
        if (queryArgs !== false) {
            setSearchNameTerm(queryArgs.search);
            setFilterType(queryArgs.type);
            setFilterPostcode(queryArgs.postcodes.join(','));
            setFilterStatuses(queryArgs.product_statuses);
            setFilterProducts(queryArgs.products);
            setPage(queryArgs.page);
        }

        productUnit.listProducts({
            skip: 0,
            take: 10000,
            search: ""
        }, (res) => {

            let _products = [];
            res.results.forEach(_product => {
                _products.push({
                    title: _product.title,
                    id: _product.id,
                });
            });

            setProducts(_products);
        }, (err) => {
        })
    }, []);


    /**
     * initially loads products
     */
    useEffect(() => {
        let queryArgs = queryArgsCache.get();
        if (page > 0 && (!isInit || queryArgs === false)) {
            setIsInit(false);
            load();
        }
    }, [page]);

    /**
     * initially loads products
     */
    useEffect(() => {
        setIsInit(false);
        setLoading(true);
        setPage(0);
        setTimeout(() => {
            setPage(1);
        }, 100)
    }, [filterType, filterProducts, filterStatuses]);

    /**
     * loads products by search result
     * @param {*} e 
     */
    const doSearch = (e) => {
        e.preventDefault();
        load();
    }

    /**
     * Loads admin users from API
     */
    const load = () => {

        setLoading(true);

        const skip = (page - 1) * perPage;
        let postcodes = [];
        if (filterPostcode !== "") {
            filterPostcode.split(',').forEach((postcode, index) => {

                if (parseInt(postcode) >= 1000 && parseInt(postcode) <= 9999) {
                    postcodes.push(parseInt(postcode));
                }
            })
        }

        let productIds = [];

        filterProducts.forEach(value => {
            productIds.push(value.id);
        });

        const body = {
            skip: skip,
            take: perPage,
            search: searchNameTerm,
            type: filterType,
            postcodes: postcodes,
            product_statuses: filterStatuses,
            products: productIds,
        };

        customerUnit.listCustomers(body, (res) => {

            setLoading(false);

            body.page = page;
            body.products = filterProducts;
            queryArgsCache.set(body);

            setCustomers(res.results);
            setTotalPages(Math.ceil(res.total / perPage));
        }, (err) => {
            setLoading(false);
        })
    }

    return <>
        <TopBar title={"Kunden"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <form onSubmit={doSearch}>
                        <Row>
                            <Col md={6}>
                                <TextField id="standard-basic" className="filled" value={searchNameTerm} label="Kunden durchsuchen" onChange={value => setSearchNameTerm(value.target.value)} /><br />
                            </Col>
                            <Col md={2} >
                                <div
                                    onClick={doSearch}
                                    style={{ paddingTop: 17, paddingBottom: 18 }}
                                    className={`button ${searchNameTerm === '' ? 'disabled' : ''}`}>
                                    suchen
                                </div>
                            </Col>
                            <Col md={4} style={{ textAlign: 'right' }}>
                                {hasPermission('customer', ['can_create']) && <div
                                    style={{ paddingTop: 17, paddingBottom: 18 }}
                                    onClick={() => setCreatePopupOpen(true)} className={'button'}>Neuen Kunden hinzufügen</div>}
                            </Col>
                        </Row>
                    </form>
                    <Row>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Abo-Status</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setFilterStatuses(e.target.value === '' ? '' : [e.target.value])
                                    }}
                                    value={filterStatuses[0] || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"active"}>Aktiv</MenuItem>
                                    <MenuItem value={"paused"}>Pausiert</MenuItem>
                                    <MenuItem value={"canceled"}>Storniert</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <FormControl
                                className="selectFormControl"
                            >
                                <InputLabel id="type">Kunden</InputLabel>
                                <Select
                                    onChange={(e) => setFilterType(e.target.value)}
                                    value={filterType || ""}
                                >
                                    <MenuItem value={""}>Alle</MenuItem>
                                    <MenuItem value={"b2b"}>Businesskunden</MenuItem>
                                    <MenuItem value={"b2c"}>Privatkunden</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col md={3}>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={products}
                                getOptionLabel={(option) => option.title}
                                value={filterProducts}
                                onChange={(event, newValue) => {
                                    setFilterProducts(newValue);

                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Produkte"
                                        className="filled"
                                    />
                                )}
                            />
                        </Col>
                        <Col md={3}>
                            <form onSubmit={doSearch}>
                                <TextField id="standard-basic" className="filled" value={filterPostcode} label="Postleitzahlen" onChange={value => setFilterPostcode(value.target.value)} /><br />
                            </form>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20, marginBottom: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nachname</TableCell>
                                <TableCell>Vorname</TableCell>
                                <TableCell>E-Mail</TableCell>
                                <TableCell>PLZ</TableCell>
                                <TableCell style={{ width: 280 }}>Abo-Status</TableCell>
                                <TableCell style={{ width: 110 }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                customers.map((customer, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>
                                            {customer.lastName}
                                        </TableCell>
                                        <TableCell>
                                            {customer.firstName}
                                        </TableCell>
                                        <TableCell>
                                            {customer.email}
                                        </TableCell>
                                        <TableCell>
                                            {customer.postcode}
                                        </TableCell>
                                        <TableCell>
                                            {customer.status.map(status => (
                                                <StatusLabel status={status}>{translate(status)}</StatusLabel>
                                            ))}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>
                                            {hasPermission('customer', ['can_update', 'can_delete']) && <Link to={`/customers/${customer.id}`}>bearbeiten</Link>}
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        pages={totalPages}
                        current={page}
                        pageChange={setPage}
                    />
                </Col>
            </Row>
        </Container>
        <Popup
            close={() => setCreatePopupOpen(false)}
            open={createPopupOpen}
        >
            <ContactDataForm
                isCreate={true}
                setError={err => {
                    setErrorMessageButtonText("OK");
                    setErrorMessageType("error");
                    setErrorMessageOpen(true);
                    setErrorMessageSmall(false);
                    setErrorMessageTitle(err.response.data.exception.title);
                    setErrorMessageBody(err.response.data.exception.message);
                }}
            />
        </Popup>

        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />

        <Loading visible={loading} />
    </>

}

export default Customers;
import Unit from './index';

export default class BankingOrderUnit extends Unit {

    list = (body, cbSuccess, cbError) => {
        this.post(`/bankingorder/list`, body, cbSuccess, cbError);
    }

    create = (body, cbSuccess, cbError) => {
        this.post(`/bankingorder`, body, cbSuccess, cbError);
    }

    update = (body, cbSuccess, cbError) => {
        this.put(`/bankingorder`, body, cbSuccess, cbError);
    }

    getOrder = (id, cbSuccess, cbError) => {
        this.get(`/bankingorder/${id}`, cbSuccess, cbError);
    }

    listAutomation = (body, cbSuccess, cbError) => {
        this.post(`/bankingorder/automation/list`, body, cbSuccess, cbError);
    }

    createAutomation = (body, cbSuccess, cbError) => {
        this.post(`/bankingorder/automation`, body, cbSuccess, cbError);
    }

    updateAutomation = (body, cbSuccess, cbError) => {
        this.put(`/bankingorder/automation`, body, cbSuccess, cbError);
    }

    getOrderAutomation = (id, cbSuccess, cbError) => {
        this.get(`/bankingorder/automation/${id}`, cbSuccess, cbError);
    }

}
import { Checkbox, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, FormControlLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import { translate } from '../../../../helper/multilingual';
import Message from '../../../../elements/message';
import AdminUnit from '../../../../communicator/http/Admins';
import NotFound from '../../../NotFound';
import { hasPermission } from '../../../../helper/functions/auth';
import TextEditor from '../../../../elements/texteditor';
import ImageInput from '../../../../elements/imageInput';
import { formatMoney } from '../../../../helper/functions/formatPrice';
import wrlog from '../../../../helper/functions/wrlog';
import { isJsonString } from '../../../../helper/functions/isJsonString';
import { formatPercent } from '../../../../helper/functions/formatPercent';
import ProcurementProductUnit from '../../../../communicator/http/ProcurementProduct';


/**
 * 
 * @returns Admin Product Detail Page
 */
const ProcurementProductSingle = () => {

    const params = useParams();

    const productId = params.id;

    const [loading, setLoading] = useState(productId !== 'new');
    const [product, setProduct] = useState(true);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [imageData, setImageData] = useState(null);

    const [is404, setIs404] = useState(false);

    const { handleSubmit, setValue, control, formState: { errors }, getValues } = useForm();

    /**
     * Inititally check if productId is "new" to check if product is updated or new product is created
     */
    useEffect(() => {

        const productUnit = new ProcurementProductUnit();

        /**
         * if productId !== set set 404 = true
         */
        if (productId === undefined || productId === null || productId === "") {
            setIs404(true);
            return;
        }

        /**
         * if productId !== new --> update product & load productData
         */
        if (productId !== 'new') {

            // Check if product can update || delete

            if (!hasPermission('product', ['can_update', 'can_delete'])) {
                setIs404(true);
                return;
            }

            productUnit.getProduct(productId, (product) => {

                setLoading(false);
                setValue('title', product.title);
                setValue('italianTitle', product.italianTitle);
                setProduct(product);

            }, () => {
                setIs404(true);
            })
        } else {
            if (!hasPermission('product', ['can_create'])) {
                setIs404(true);
                return;
            }
        }


    }, [productId]);

    /**
     * Submit productData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const productUnit = new ProcurementProductUnit();

        setLoading(true);

        let body = {
            title: data.title,
            italian_title: data.italianTitle,
        }

        if (imageData !== null) {
            body.image = {
                base64: imageData.base64,
                title: imageData.title,
                mime_type: imageData.mimeType
            }
        }


        // Success function for Create Product
        const cbSuccess = (res) => {
            setLoading(false);
            window.location.replace(`/procurement/products/${res.id}`); //Replace Location to new created product & reload page
        }

        // Success function for Update Product
        const cbSuccessUpdate = (res) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
            setErrorMessageSmall(true);
            setErrorMessageTitle(res.exception.message);
            setErrorMessageBody("");

            setProduct(data);
        }

        const cbError = (err) => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        if (productId === 'new') {
            productUnit.create(body, cbSuccess, cbError);
        } else {
            body.id = productId;
            productUnit.update(body, cbSuccessUpdate, cbError);
        }
    }

    /**
     * Delete current product
     */
    // const deleteProduct = () => {
    //     const productUnit = new ProductUnit();

    //     setLoading(true);
    //     setAskDeleteMessageOpen(false);

    //     const cbSuccess = (res) => {
    //         if (res[0] === 200) {
    //             window.location.replace(`/products`);
    //         }
    //     }

    //     const cbError = (err) => {
    //         setErrorMessageButtonText("OK");
    //         setErrorMessageType("error");
    //         setErrorMessageOpen(true);
    //         setErrorMessageSmall(false);
    //         setErrorMessageTitle(err.response.data.exception.title);
    //         setErrorMessageBody(err.response.data.exception.message);
    //     }

    //     productUnit.deleteProduct(productId, cbSuccess, cbError);
    // }

    if (is404 === true) {
        return <NotFound />;
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to="/procurement/products">zurück</Link><br /><br />
                        <h1>{params.id !== "new" ? <>{product.title} {translate('edit')}</> : "Neues Produkt hinzufügen"} </h1>
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <Row style={{ marginTop: 50 }}>
                        <Col md={7}>
                            <FormController
                                name={"title"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.title}
                                        id="standard-basic"
                                        label={translate('title') + '*'}
                                    />
                                )}
                            />
                            <FormController
                                name={"italianTitle"}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.italianTitle}
                                        id="standard-basic"
                                        label={'Italienisch *'}
                                    />
                                )}
                            />
                        </Col>
                        <Col md={5}>
                            <ImageInput
                                value={imageData?.base64}
                                imageUrl={product.imageUrl}
                                onChange={setImageData}
                            />
                            {
                                ((hasPermission('product', ['can_update']) && params.id !== "new") || (hasPermission('product', ['can_create']) && params.id === "new")) &&
                                <input type="submit" style={{ width: '200px', marginTop: 20, right: 20 }} className="button" value={params.id !== "new" ? translate('save') : translate('add')} />
                            }
                        </Col>
                    </Row>
                </form>
            </Container>
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />

            <Loading visible={loading} />
        </>

    )

}

export default ProcurementProductSingle;
import { FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Message from '../../elements/message';
import Loading from '../../helper/screens/Loading';
import TopBar from '../../elements/topbar';
import { translate } from '../../helper/multilingual';
import { getUserToken, hasPermission } from '../../helper/functions/auth';
import ProductUnit from '../../communicator/http/Products';
import InvoiceUnit from '../../communicator/http/Invoices';
import Pagination from '../../elements/pagination';
import wrlog from '../../helper/functions/wrlog';
import { formatDbDate, formatDbDateTime, formatDbTime } from '../../helper/functions/formatDate';
import { Autocomplete } from '@material-ui/lab';
import StatusLabel from '../../elements/statusLabel';
import ToolTip from '../../elements/tooltip';
import Cache from '../../communicator/local';
import DatePicker from '../../elements/datepicker';
import { formatMoney } from '../../helper/functions/formatPrice';
import { translateInvoiceStatus, translatePaymentType } from '../../helper/functions/translateInvoice';
import TimeTrackingUnit from '../../communicator/http/TimeTracking';
import moment from 'moment';

import styles from './style.module.scss';
import formatHoursMinutes from '../../helper/functions/formatHoursMinutes';
import TimeInput from '../../elements/timefield';

/**
 * 
 * @returns Invoices Overview Page
 */
const TimeTracking = (props) => {

    const month_start = new Date();
    month_start.setDate(1);
    const month_end = new Date();

    const [loading, setLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);

    const [timeslots, setTimeslots] = useState([]);
    const [overview, setOverview] = useState([]);
    const [filterDate, setFilterDate] = useState({
        from: month_start,
        to: month_end
    });

    const [startDate, setStartDate] = useState(month_start);

    const [currentDay, setCurrentDay] = useState(new Date());
    const [newTimeFrom, setNewTimeFrom] = useState('');
    const [newTimeTo, setNewTimeTo] = useState('');
    const [newTimeType, setNewTimeType] = useState('work');
    const [newTimeNote, setNewTimeNote] = useState('');

    const [isInit, setIsInit] = useState(true);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [downloadMessageOpen, setDownloadMessageOpen] = useState(false);


    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [pdfData, setPdfData] = useState("");

    const timetrackingUnit = new TimeTrackingUnit();

    const queryArgsCache = new Cache('timeslots.queryArgs', true);

    /**
     * initially loads products
     */
    useEffect(() => {

        let queryArgs = queryArgsCache.get();

        if (queryArgs !== false) {
            if (queryArgs.from !== null && queryArgs.from !== "") {
                setFilterDate({
                    from: new Date(queryArgs.from),
                    to: new Date(queryArgs.to),
                })
            }
        }

    }, []);


    /**
     * initially loads products
     */
    useEffect(() => {
        setLoading(true);
        load();
    }, [startDate]);

    /**
     * initially loads products
     */
    useEffect(() => {
        if (currentDay === null) {
            return;
        }
        loadTimeslots();
    }, [currentDay]);


    useEffect(() => {
        if (pdfLoading) {
            setTimeout(() => {
                setPdfLoading(false);
            }, 1000);
        }
    }, [pdfData]);

    /**
     * Loads invoices from API
     */
    const load = (cb = () => { }) => {
        setLoading(true);

        let today = new Date();
        let body = {
            from: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
            to: moment(today).format('YYYY-MM-DDTHH:mm:ss'),
        }

        if (props.userId) {
            body.admin_id = props.userId;
        }

        timetrackingUnit.overview(body, (res) => {
            setLoading(false);
            setOverview(res);
            cb();
            queryArgsCache.set(body);
        }, (err) => {
            setLoading(false);
        })
    }

    const loadTimeslots = () => {
        setLoading(true);
        setTimeslots([])

        let from = new Date(currentDay.toString());
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        from.setMilliseconds(0);

        let to = new Date(from.toUTCString());
        to.setDate(to.getDate() + 1);

        let body = {
            from: moment(from).format('YYYY-MM-DDTHH:mm:ss'),
            to: moment(to).format('YYYY-MM-DDTHH:mm:ss'),
        }

        timetrackingUnit.list(body, (res) => {
            setLoading(false);
            setTimeslots(res);

            if (res.length > 0) {
                setNewTimeFrom(moment(new Date(res[res.length - 1].to)).format('HH:mm'));
            }

            queryArgsCache.set(body);
        }, (err) => {
            setLoading(false);
        })
    }

    const downloadInvoices = () => {

        setPdfLoading(true);

        let date = "";
        if (filterDate !== undefined && filterDate !== null && filterDate !== false && filterDate.from !== null && filterDate.to !== null) {
            date = {
                from: filterDate.from.toUTCString(),
                to: filterDate.to.toUTCString()
            }
        }


        const body = {
            date: date
        };

        timetrackingUnit.getPdfs(body, (res) => {

            let urlEncodedToken = Buffer.from(getUserToken()).toString('base64');
            setDownloadUrl(`${res.returnUrl}/${urlEncodedToken}`);
            setDownloadMessageOpen(true);

            setPdfLoading(false);
        }, (err) => {
            setPdfLoading(false);
        })
    }

    const openEditTimeslotPopup = (timeslotId) => {

    }

    const deleteEntry = (timeslotId) => {
        setLoading(true)
        timetrackingUnit.deleteEntry(timeslotId, (res) => {
            load();
            loadTimeslots();
        }, (err) => {
            setLoading(false)
        })
    }

    const openAddForm = (date) => {
        setCurrentDay(date);
    }

    useEffect(() => {
        console.log(newTimeFrom);
    }, [newTimeFrom]);


    const save = () => {
        setLoading(true);

        let _currentDay = new Date(currentDay);

        try {
            let from = new Date(_currentDay.toString());
            let to = new Date(_currentDay.toString());
            let _to = newTimeTo;

            let split = newTimeFrom.split(':');
            from.setHours(parseInt(split[0]));
            from.setMinutes(parseInt(split[1]));
            from.setSeconds(0);
            from.setMilliseconds(0);

            split = newTimeTo.split(':');
            to.setHours(parseInt(split[0]));
            to.setMinutes(parseInt(split[1]));
            to.setSeconds(0);
            to.setMilliseconds(0);

            let body = {
                from: moment(from).format('YYYY-MM-DDTHH:mm:ss'),
                to: moment(to).format('YYYY-MM-DDTHH:mm:ss'),
                note: newTimeNote,
                type: newTimeType
            }

            if (props.userId !== undefined) {
                body.admin_id = props.userId;
            }

            timetrackingUnit.create(body, (res) => {
                load();
                loadTimeslots();
                setNewTimeType('work');
                setNewTimeFrom(_to);
                setNewTimeTo('');
            }, (err) => {
                setLoading(false);
                setErrorMessageOpen(true);
                setErrorMessageTitle(err.response.data.exception.title)
                setErrorMessageBody(err.response.data.exception.message)
            })


        } catch (e) {
            setLoading(false);
            console.log(e);
            alert("ASD");
        }

    }

    const renderList = () => {
        let day = new Date();
        day.setHours(0);
        day.setMinutes(0);
        day.setSeconds(0);
        day.setMilliseconds(0);

        let weekNumber = null;
        let month = null;

        if (overview.days === undefined) {
            return null;
        }

        let _days = overview.days;
        _days.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        })

        return _days.map(_day => {
            let display = [];

            let day = new Date(_day.date);

            // let worktimeslots = timeslots.filter(slot => {
            //     let slotDate = new Date(slot.from);
            //     slotDate.setHours(0);
            //     slotDate.setMinutes(0);
            //     slotDate.setSeconds(0);
            //     return slotDate.toISOString() === day.toISOString();
            // });

            if (day.getMonth() !== month) {
                display.push(<div style={month !== null ? { marginTop: 30 } : {}}>
                    <strong>{moment(day).format('MMMM')} {moment(day).format('YYYY')}</strong>
                </div>)

                month = day.getMonth();
            }

            if (day.getWeek() !== weekNumber) {
                weekNumber = day.getWeek();
                display.push(<div style={{ marginTop: 10 }}>
                    Woche {weekNumber}
                </div>)
            }

            display.push(<div className={`${styles.dayWrapper}`} style={{ marginTop: 10 }}>
                <div className={`${styles.dayContainer} ${!_day.workday ? styles.noWorkday : undefined}`}>
                    <div className={styles.dateContainer}>
                        {moment(day).format('dddd')}<br />{moment(day).format('DD')}.{moment(day).format('MM')}.{moment(day).format('Y')}
                    </div>
                    <div className={styles.dateContainer}>
                        {formatHoursMinutes(_day.worked)}
                    </div>
                    <div className={styles.dateContainer}>
                        {formatHoursMinutes(_day.should)}
                    </div>
                    <div className='iconButton button simple' onClick={() => {
                        openAddForm(_day.date);
                    }} style={{
                        width: 30, height: 30, padding: 20, paddingTop: 17, marginLeft: 20
                    }}>+</div>
                </div>

                <div className={`${styles.accordion} ${currentDay === _day.date ? styles.open : undefined}`}>
                    {
                        timeslots.map(timeslot => {
                            return <div className={`${styles.dayContainer} ${!_day.workday ? styles.noWorkday : undefined}`}>
                                <div className={styles.dateContainer}>
                                    {formatDbTime(timeslot.from)}
                                </div>
                                <div className={styles.dateContainer}>
                                    {formatDbTime(timeslot.to)}
                                </div>
                                <div className={styles.dateContainer}>
                                    {formatHoursMinutes(timeslot.duration)}
                                </div>
                                <div className={styles.dateContainer} style={{ width: 100, textAlign: 'left' }}>
                                    {timeslot.type}
                                </div>
                                <div className={styles.dateContainer} style={{ width: 300, textAlign: 'left' }}>
                                    {timeslot.note}
                                </div>
                                <div className={styles.dateContainer}>
                                    <div className='smallButton' style={{ backgroundColor: 'red', borderRadius: 10 }} onClick={() => deleteEntry(timeslot.id)}>löschen</div>
                                </div>
                            </div>
                        })
                    }
                    <div className={`${styles.input}`}>
                        {newTimeType !== 'vacation' &&
                            <>
                                <TimeInput
                                    onChange={value => setNewTimeFrom(value)}
                                    value={newTimeFrom}
                                />
                                &nbsp;&nbsp;-&nbsp;&nbsp;
                                <TimeInput
                                    onChange={value => setNewTimeTo(value)}
                                    value={newTimeTo}
                                />
                            </>
                        }
                        <FormControl
                            className="selectFormControl"
                        >
                            <InputLabel id="type">Typ</InputLabel>
                            <Select
                                onChange={(e) => {
                                    setNewTimeType(e.target.value)
                                }}
                                value={newTimeType}
                            >
                                <MenuItem value={"work"}>Arbeit</MenuItem>
                                <MenuItem value={"pause"}>Pause</MenuItem>
                                <MenuItem value={"compensation"}>Zeitausgleich</MenuItem>
                                <MenuItem value={"vacation"}>Urlaub</MenuItem>
                                <MenuItem value={"sickleave"}>Krankenstand</MenuItem>
                            </Select>
                        </FormControl>
                        {newTimeType === 'work' &&
                            <TextField
                                value={newTimeNote}
                                onChange={e => setNewTimeNote(e.target.value)}
                                className={`filled ${newTimeNote !== undefined && newTimeNote !== null && newTimeNote !== '' ? 'valued' : ''}`}
                                id="standard-basic"
                                label={'Kommentar'}
                            />
                        }
                        <div>
                            <div
                                className='smallButton'
                                onClick={() => {
                                    save();
                                }}
                                style={{
                                    borderRadius: 5, marginBottom: 5
                                }}
                            >speichern</div>
                            <div
                                className='smallButton'
                                onClick={() => {
                                    setCurrentDay(null);
                                }}
                                style={{
                                    borderRadius: 5, marginBottom: 5, backgroundColor: 'grey', transform: 'scale(0.8)'
                                }}
                            >abbrechen</div>
                        </div>
                    </div>
                </div>
            </div>);

            return display;
        })

    }

    Date.prototype.getWeek = function (dowOffset) {
        /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

        dowOffset = typeof (dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
        var newYear = new Date(this.getFullYear(), 0, 1);
        var day = newYear.getDay() - dowOffset; //the day of week the year begins on
        day = (day >= 0 ? day : day + 7);
        var daynum = Math.floor((this.getTime() - newYear.getTime() -
            (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
        var weeknum;
        //if the year starts before the middle of a week
        if (day < 4) {
            weeknum = Math.floor((daynum + day - 1) / 7) + 1;
            if (weeknum > 52) {
                let nYear = new Date(this.getFullYear() + 1, 0, 1);
                let nday = nYear.getDay() - dowOffset;
                nday = nday >= 0 ? nday : nday + 7;
                /*if the next year starts before the middle of
                  the week, it is week #1 of that year*/
                weeknum = nday < 4 ? 1 : 53;
            }
        }
        else {
            weeknum = Math.floor((daynum + day - 1) / 7);
        }
        return weeknum;
    };

    return <>
        <TopBar title={"Zeitaufzeichnung"} />
        <Container fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <Row>
                        <Col md={6}>

                        </Col>
                        <Col md={3}>
                            {/* <div
                                className='button'
                                onClick={downloadInvoices}>
                                {pdfLoading ? 'PDFs werden generiert...' : 'Auswahl herunterladen'}
                            </div> */}
                        </Col>
                        <Col md={3}>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 44 }}>
                <Col md={3}>
                    <h2>{overview.worked !== undefined && formatHoursMinutes(overview.worked - overview.need)}</h2>
                    <span>Stundenstand</span>
                </Col>
                <Col md={3}>
                    <h2>{overview.worked !== undefined && formatHoursMinutes(overview.overtime - overview.compensation_used)}</h2>
                    <span>Überstunden</span>
                </Col>
                <Col md={3}>
                    <h2>{overview.vacation_open !== undefined && `${overview.vacation_open} ${overview.vacation_open !== 1 ? 'Tage' : 'Tag'} / ${overview.vacation_all}`}</h2>
                    <span>Urlaub offen</span>
                </Col>
            </Row>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    {
                        renderList()
                    }
                </Col>
            </Row>
            <Row style={{ marginTop: 20, textAlign: 'center' }}>
                <Col md={12}>
                    <div className='button' onClick={() => {
                        let _startDate = new Date(startDate);
                        _startDate.setMonth(_startDate.getMonth() - 1);
                        setStartDate(_startDate)
                    }}>
                        mehr laden
                    </div>
                </Col>
            </Row>
        </Container>
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />

        <Message
            open={downloadMessageOpen}
            type={"success"}
            small={false}
            title={"PDFs werden erstellt"}
            body={"Du bekommst in den nächsten Minuten E-Mails mit Rechnungspaketen zugeschickt."}
            buttonText={"OK"}
            buttonAction={() => setDownloadMessageOpen(false)}
        />

        <Loading visible={loading} />
    </>

}

export default TimeTracking;
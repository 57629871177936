const formatHoursMinutes = (minutes) => {
    let hours = minutes / 60;
    hours = Math.floor(hours);
    minutes = minutes - (hours * 60);

    let out = ``;

    if (hours !== 0) {
        out += `${hours}h `;
    }
    if (minutes !== 0) {
        out += `${minutes}min`;
    }

    if (out === '') {
        out = '0h 0min';
    }

    return out;
}

export default formatHoursMinutes;
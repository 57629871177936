import { Checkbox, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, FormControlLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import Loading from '../../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";

import CustomerUnit from '../../../communicator/http/Customers';
import { translate } from '../../../helper/multilingual';
import Message from '../../../elements/message';
import NotFound from '../../NotFound';
import { hasPermission } from '../../../helper/functions/auth';
import Card from '../../../elements/card';
import { formatDbDate, formatDbDateTime } from '../../../helper/functions/formatDate';
import ContactDataForm from './contactDataForm';
import SubscriptionUnit from '../../../communicator/http/Subscriptions';
import Popup from '../../../elements/popup';
import { DateRangePicker } from 'react-date-range';
import { de } from 'date-fns/locale'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import LogEntry from '../../../elements/logEntry';
import LogNoteForm from './logNoteForm';
import { Autocomplete } from '@material-ui/lab';
import ProductUnit from '../../../communicator/http/Products';
import StatusLabel from '../../../elements/statusLabel';
import wrlog from '../../../helper/functions/wrlog';
import ToolTip from '../../../elements/tooltip';
import styles from './styles.module.scss';
import AddDiscountForm from './addDiscountForm';
import DiscountsUnit from '../../../communicator/http/Discount';
import { translateDiscountTypes, translateDiscountStatus } from '../../../helper/functions/translateDiscountTypes';
import ProcurementProductUnit from '../../../communicator/http/ProcurementProduct';
import InvoiceUnit from '../../../communicator/http/Invoices';

import { translateInvoiceStatus } from '../../../helper/functions/translateInvoice';
import { translateDeliveryNoteStatus } from '../../../helper/functions/translateDeliveryNote';
import { formatMoney } from '../../../helper/functions/formatPrice';
import DeliveryNoteUnit from '../../../communicator/http/DeliveryNote';
import PaymentMethodForm from './paymentMethodForm';
import moment from 'moment';
import OrderDetail from './orderDetail';
import DeliveryList from './deliveryList';

/**
 * 
 * @returns Customer Detail Page
 */
const CustomerSingle = (props) => {

    const location = useLocation();

    // wrlog("PROPS", location.state.returnUrl);

    const params = useParams();

    const customerId = params.id;

    const [loading, setLoading] = useState(customerId !== 'new');
    const [customer, setCustomer] = useState({});
    const [subscriptions, setSubscriptions] = useState([]);
    const [reviews, setReviews] = useState({});
    const [discounts, setDiscounts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [deliveryNotes, setDeliveryNotes] = useState([]);
    const [showAllDeliveryNotes, setShowAllDeliveryNotes] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [showAllInvoices, setShowAllInvoices] = useState(false);
    const [deliveryNoteLoading, setDeliveryNoteLoading] = useState(true);
    const [invoiceLoading, setInvoiceLoading] = useState(true);

    const [customerEdit, setCustomerEdit] = useState(false);
    const [editPaymentMethod, setEditPaymentMethod] = useState(false);

    const [addingLogEntry, setAddingLogEntry] = useState(false);
    const [pausePopupOpen, setPausePopupOpen] = useState(false);
    const [addSubscriptionPopupOpen, setAddSubscriptionPopupOpen] = useState(false);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
    const [selectedSubscription, setSelectedSubscription] = useState({});

    const [switchProductPopupOpen, setSwitchProductPopupOpen] = useState(false);
    const [addDiscountPopupOpen, setAddDiscountPopupOpen] = useState(false);
    const [isBusinessProduct, setIsBusinessProduct] = useState(false)
    const [businessProductContent, setBusinessProductContent] = useState([])
    const [procurementProducts, setProcurementProducts] = useState([])
    const [businessProductContentShow, setBusinessProductContentShow] = useState(true);
    const [businessProductQuantity, setBusinessProductQuantity] = useState(1);
    const [businessProductDeliveryDay, setBusinessProductDeliveryDay] = useState(1);
    const [businessProductSku, setBusinessProductSku] = useState('');
    const [businessProductTitle, setBusinessProductTitle] = useState('');
    const [businessProductPrice, setBusinessProductPrice] = useState('');
    const [businessProductPriceType, setBusinessProductPriceType] = useState('whole');
    const [businessIntervalOfDelivery, setBusinessIntervalOfDelivery] = useState('1_week');

    const [selectedDeliveryNotes, setSelectedDeliveryNotes] = useState([]);

    const [showOrderDetailPopupOpen, setShowOrderDetailPopupOpen] = useState(false);
    const [currentOrderId, setCurrentOrderId] = useState(null);


    const [logEntries, setLogEntries] = useState([]);
    const [products, setProducts] = useState([]);
    const [newProduct, setNewProduct] = useState(null);


    const [pauseRange, setPauseRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [askDeleteMessageOpen, setAskDeleteMessageOpen] = useState(false);

    const [is404, setIs404] = useState(false);

    const { handleSubmit, setValue, control, formState: { errors }, getValues, register } = useForm();

    const months = [
        '',
        'Jänner',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ];

    let intervalOfDeliveryArray = [
        {
            label: "jede Woche",
            value: "1_week"
        },
        {
            label: "alle 2 Wochen",
            value: "2_weeks"
        },
        {
            label: "alle 4 Wochen",
            value: "4_weeks"
        }
    ];

    /**
     * Inititally check if customerId is "new" to check if customer is updated or new customer is created
     */
    useEffect(() => {

        const customerUnit = new CustomerUnit();

        /**
         * if customerId !== set set 404 = true
         */
        if (customerId === undefined || customerId === null || customerId === "") {
            setIs404(true);
            return;
        }

        /**
         * if customerId !== new --> update customer & load customerData
         */
        if (customerId !== 'new') {

            // Check if customer can update || delete

            if (!hasPermission('customer', ['can_update', 'can_delete', 'can_read'])) {
                setIs404(true);
                return;
            }

            customerUnit.getCustomer(customerId, (customer) => {

                setLoading(false);
                setIsBusinessProduct(customer.type === 'b2b');
                setCustomer(customer);

            }, () => {
                setIs404(true);
            })

            loadSubscriptions();
            loadDiscounts();
            loadReviews();
            loadDeliveryNotes();
            loadInvoices();
            loadOrders();

        } else {
            if (!hasPermission('customer', ['can_create'])) {
                setIs404(true);
                return;
            }

            setValue('intervalOfDelivery', "");
            setValue('maxPauseTime', "");
            setValue('cutOffDate', "");
            setValue('deliveryDays', []);

        }


    }, [customerId]);

    /**
     * initially loads products
     */
    useEffect(() => {

        const productUnit = new ProductUnit();
        const procurementProductUnit = new ProcurementProductUnit();

        productUnit.listProducts({
            skip: 0,
            take: 10000,
            search: ""
        }, (res) => {

            let _products = [];
            res.results.forEach(_product => {
                _products.push({
                    title: _product.title + ' (' + _product.sku + ')',
                    id: _product.id,
                });
            });

            setProducts(_products);
        }, (err) => {
            setLoading(false);
        })

        /**
         * Loads admin users from API
         */
        procurementProductUnit.list('', (res) => {
            setProcurementProducts(res.results);
        }, (err) => {
            setLoading(false);
        })

    }, []);

    const loadBusinessSubsctiption = (subscriptionId) => {
        const subscriptionUnit = new SubscriptionUnit();
        setLoading(true);
        subscriptionUnit.getSubscription(subscriptionId, (_subscriptionData) => {
            setLoading(false);
            console.log("_subscriptionData", _subscriptionData);

            setBusinessProductContent(_subscriptionData.content);
            setBusinessProductQuantity(_subscriptionData.quantity);
            setBusinessProductDeliveryDay(_subscriptionData.deliveryDay);
            setBusinessProductSku(_subscriptionData.productSku);
            setBusinessProductTitle(_subscriptionData.productTitle);
            setBusinessProductPrice(_subscriptionData.productPrice);
            setBusinessProductPriceType(_subscriptionData.productPriceType);
            setBusinessIntervalOfDelivery(_subscriptionData.productIntervalOfDelivery);

            setIsBusinessProduct(true);
            setAddSubscriptionPopupOpen(true);

        }, () => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle("Unbekannter Fehler");
            setErrorMessageBody("Ein unbekannter Fehler ist aufgetreten bitte laden Sie die Seite neu. Sollte das Problem weiterhin bestehen wenden Sie sich an den Systemadministrator.");
        })
    }

    /**
     * Loads the subscriptions of the user
     * @param {Function} cb 
     */
    const loadSubscriptions = (cb = () => { }) => {
        const customerUnit = new CustomerUnit();
        customerUnit.getSubscriptions(customerId, (_subscriptions) => {
            setLoading(false);
            setSubscriptions(_subscriptions);
            loadLog();
            cb();
        }, () => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle("Unbekannter Fehler");
            setErrorMessageBody("Ein unbekannter Fehler ist aufgetreten bitte laden Sie die Seite neu. Sollte das Problem weiterhin bestehen wenden Sie sich an den Systemadministrator.");
        })
    }

    const loadReviews = () => {
        const customerUnit = new CustomerUnit();
        customerUnit.getReviews(customerId, (_reviews) => {
            setReviews(_reviews)
        })
    }

    const loadDiscounts = (cb = () => { }) => {
        const discountsUnit = new DiscountsUnit();
        discountsUnit.listDiscounts(customerId, (_discounts) => {
            setDiscounts(_discounts)
            cb()
        })
    }

    const loadOrders = () => {
        const customerUnit = new CustomerUnit();
        customerUnit.listOrders(customerId, (_orders) => {
            setOrders(_orders)
        })
    }

    const loadDeliveryNotes = () => {
        setDeliveryNoteLoading(true);
        const customerUnit = new CustomerUnit();
        customerUnit.listDeliveryNotes(customerId, (_deliveryNotes) => {
            setDeliveryNoteLoading(false);
            setDeliveryNotes(_deliveryNotes)
        })
    }

    const loadInvoices = () => {
        setInvoiceLoading(true);
        const customerUnit = new CustomerUnit();
        customerUnit.listInvoices(customerId, (_invoices) => {
            setInvoices(_invoices)
            setInvoiceLoading(false);
        })
    }

    const showOrderDetails = (orderId) => {
        setShowOrderDetailPopupOpen(true);
        setCurrentOrderId(orderId);
    }


    /**
 * Loads the log of the user
 * @param {Function} cb 
 */
    const loadLog = (cb = () => { }) => {
        const customerUnit = new CustomerUnit();
        customerUnit.getLog(customerId, (log) => {
            setLoading(false);
            wrlog("LOG", log)
            setLogEntries(log);
            cb();
        }, () => {
            setLoading(false);

            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle("Unbekannter Fehler");
            setErrorMessageBody("Ein unbekannter Fehler ist aufgetreten bitte laden Sie die Seite neu. Sollte das Problem weiterhin bestehen wenden Sie sich an den Systemadministrator.");
        })
    }



    /**
     * Delete current customer
     */
    const deleteCustomer = () => {
        const customerUnit = new CustomerUnit();

        setLoading(true);
        setAskDeleteMessageOpen(false);

        const cbSuccess = (res) => {
            window.location.replace(`/customers`);
        }

        const cbError = (err) => {
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        customerUnit.deleteCustomer(customerId, cbSuccess, cbError);
    }

    /**
     * Change the status of the subscription
     * 
     * @param string status
     * 
     * @return void
     */
    const changeStatus = (status, subscriptionId) => {
        const subscriptionUnit = new SubscriptionUnit();

        setSelectedSubscriptionId(subscriptionId)

        if (status === 'paused') {
            setPausePopupOpen(true);
        } else if (status === 'canceled') {
            subscriptionUnit.cancelSubscription(subscriptionId, (res) => {
                loadSubscriptions(() => {
                    setLoading(false);
                });
            }, (err) => {
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            })
        } else if (status === 'forceCanceled') {
            subscriptionUnit.forceCancelSubscription(subscriptionId, (res) => {
                loadSubscriptions(() => {
                    setLoading(false);
                });
            }, (err) => {
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            })
        } else {
            subscriptionUnit.activateSubscription(subscriptionId, (res) => {
                loadSubscriptions(() => {
                    setLoading(false);
                });
            }, (err) => {
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            })
        }

    }

    const changeSwitchedProduct = (productId) => {
        const prev = { ...selectedSubscription };
        prev.productId = productId;
        setSelectedSubscription(prev);
    }

    const switchProduct = (force = false) => {
        const subscriptionUnit = new SubscriptionUnit();

        setLoading(true);

        let body = {
            id: selectedSubscription.id,
            product_id: selectedSubscription.productId,
        }

        if (force === true) {
            body.force = true;
        }

        const cbSuccess = (res) => {
            loadSubscriptions();
            setSwitchProductPopupOpen(false);
        }

        const cbError = (err) => {
            setLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        subscriptionUnit.switchProduct(body, cbSuccess, cbError);
    }

    const setPause = () => {

        const subscriptionUnit = new SubscriptionUnit();
        const customerUnit = new CustomerUnit();

        setLoading(true);

        let body = {
            id: selectedSubscriptionId,
            start: pauseRange.startDate.toDateString(),
            end: pauseRange.endDate.toDateString()
        }

        const cbSuccess = (res) => {
            loadSubscriptions(() => {
                setPausePopupOpen(false)
            });
        }

        const cbError = (err) => {
            setLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        subscriptionUnit.pauseSubscription(body, cbSuccess, cbError);
    }

    const onChangeDate = (dates) => {
        setPauseRange(dates.selection)
    }

    const createBusinessSubscription = () => {
        let _businessProductContent = [...businessProductContent];
        let subscriptionUnit = new SubscriptionUnit();

        setLoading(true);

        console.log("CHECK NEW BODY 1", businessProductContent)

        businessProductContent.forEach((product, index) => {
            if (procurementProducts.findIndex(e => e.id === product.item_id) > -1) {
                _businessProductContent[index].type = 'product';
            } else {
                _businessProductContent[index].type = 'div';
            }

            if (product.quantity === undefined || product.quantity === '') {
                _businessProductContent[index]['quantity'] = 0;
            }
        })

        let body = {
            customer_id: customerId,
            content: _businessProductContent,
            quantity: businessProductQuantity,
            price: businessProductPrice,
            price_type: businessProductPriceType,
            delivery_day: businessProductDeliveryDay,
            interval_of_delivery: businessIntervalOfDelivery,
            title: businessProductTitle,
            sku: businessProductSku
        }

        console.log("CHECK NEW BODY", body)

        if (selectedSubscription.id === undefined) {
            subscriptionUnit.createBusinessSubscription(body, (res) => {
                loadSubscriptions();
                setAddSubscriptionPopupOpen(false);
            }, (err) => {
                wrlog(err)
            })
        } else {
            body.id = selectedSubscription.id;

            subscriptionUnit.updateBusinessSubscription(body, (res) => {
                setAddSubscriptionPopupOpen(false);
                setLoading(false);
            }, (err) => {
                wrlog(err)
            })
        }

        wrlog(businessProductContent);

    }

    const createSubscription = () => {

        if (isBusinessProduct) {

            createBusinessSubscription();

            return;
        }

        setLoading(true);

        const cbSuccess = (res) => {
            loadSubscriptions(() => {
                setLoading(false);
                setAddSubscriptionPopupOpen(false);
            });
        }
        const cbError = (err) => {
            setAddSubscriptionPopupOpen(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }
        const body = {
            customer_id: customerId,
            product_id: newProduct,
        }

        const subscriptionUnit = new SubscriptionUnit();
        subscriptionUnit.createSubscription(body, cbSuccess, cbError);
    }

    const renderPausedDetails = (subscription) => {
        return <>
            Pausiert<br />
            Von: {formatDbDate(subscription.pauseStart)}<br />
            Bis: {formatDbDate(subscription.pauseEnd)}
        </>
    }

    const renderCanceledDetails = (subscription) => {
        return <>
            Storniert am {formatDbDate(subscription.canceledAt)}
            {
                <><br />Letzte Lieferung: {subscription.lastDeliveryDate !== undefined && subscription.lastDeliveryDate !== null ? formatDbDate(subscription.lastDeliveryDate) : 'Keine'}</>
            }
        </>
    }

    const deleteDiscount = (discountId) => {

        setLoading(true);

        const cbSuccess = (res) => {
            loadDiscounts(() => {
                setLoading(false);
            });
        }
        const cbError = (err) => {
            setLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        const discountsUnit = new DiscountsUnit();
        discountsUnit.revokeDiscount(discountId, cbSuccess, cbError);
    }

    const downloadDeliveryNote = (deliveryNoteId) => {
        const deliveryNoteUnit = new DeliveryNoteUnit();
        setDeliveryNoteLoading(true);
        deliveryNoteUnit.getPdf(deliveryNoteId, (res) => {

            const url = window.URL.createObjectURL(new Blob([Buffer.from(res.base64, 'base64')]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${res.title}.pdf`);
            document.body.appendChild(link);
            link.click();

            setDeliveryNoteLoading(false);
        }, (err) => {
            setDeliveryNoteLoading(false);
        })

    }

    const combineToInvoice = () => {

        if (selectedDeliveryNotes.length < 1) {
            return false;
        }

        const invoiceUnit = new InvoiceUnit();

        setDeliveryNoteLoading(true);

        const cbSuccess = (res) => {
            wrlog(res);
            loadDeliveryNotes();
            loadInvoices();
            loadDiscounts();
        }

        const cbError = (err) => {
            wrlog(err);
        }

        invoiceUnit.createFromDeliveryNotes(customerId, selectedDeliveryNotes, cbSuccess, cbError);

    }

    const selectDeliveryNote = (deliveryNoteId) => {
        let _selectedDeliveryNotes = [...selectedDeliveryNotes];

        let index = _selectedDeliveryNotes.findIndex(e => e === deliveryNoteId);

        if (index < 0) {
            _selectedDeliveryNotes.push(deliveryNoteId);
        } else {
            _selectedDeliveryNotes.splice(index, 1);
        }

        setSelectedDeliveryNotes(_selectedDeliveryNotes);
    }

    const downloadInvoice = (invoiceId = null) => {
        const invoiceUnit = new InvoiceUnit();
        setInvoiceLoading(true);
        invoiceUnit.getPdf(invoiceId, (res) => {

            const url = window.URL.createObjectURL(new Blob([Buffer.from(res.base64, 'base64')]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${res.title}.pdf`);
            document.body.appendChild(link);
            link.click();

            setInvoiceLoading(false);
        }, (err) => {
            setInvoiceLoading(false);
        })
    }

    const changeContent = (value, key, id) => {
        let _businessProductContent = [...businessProductContent];

        let index = _businessProductContent.findIndex(product => product.item_id === id)
        let isNew = false;
        if (index < 0) {
            isNew = true;
            index = _businessProductContent.length;
            _businessProductContent.push({ item_id: id })
        }
        _businessProductContent[index][key] = value;

        setBusinessProductContent(_businessProductContent)
        if (isNew) {
            setBusinessProductContentShow(false);
            setTimeout(() => setBusinessProductContentShow(true), 10)
        }
    }

    const changeInvoiceStatus = (invoiceId, status) => {
        const invoiceUnit = new InvoiceUnit();
        invoiceUnit.updateInvoice({ id: invoiceId, status: status });

        let _invoices = [...invoices];
        let index = invoices.findIndex(e => e.id === invoiceId);

        _invoices[index].status = status;

        setInvoices(_invoices);

    }

    const businessProductContentRow = (id = null, title = null) => {
        let product = businessProductContent.filter(product => product.item_id === id);
        if (product === null) {
            product = { item_id: '' };
        } else {
            product = { ...product[0] }
        }

        let preVal = procurementProducts[procurementProducts.findIndex(e => e.id === id)] || '';
        return <tr>
            <td>{title !== null ? title :
                <Autocomplete
                    options={procurementProducts}
                    getOptionLabel={(option) => option.title}
                    value={preVal}
                    disabled={procurementProducts.length < 1}
                    onChange={(event, value) => {
                        if (value !== null) {
                            changeContent(value.id, 'item_id', id)
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            disabled={products.length < 1}
                            label={products.length < 1 ? "Produkte werden geladen..." : "Produkte"}
                            className="filled noMargin small"
                        />
                    )}
                />
            }</td>
            <td style={{ width: '20% ' }}>
                <TextField
                    label={"€ / kg"}
                    className="filled noMargin small"
                    value={product.price || ''}
                    onChange={e => changeContent(e.target.value, 'price', id)}
                />
            </td>
            <td style={{ width: '20%' }}>
                {id !== null && <TextField
                    style={{ margin: 0 }}
                    value={product.quantity || ''}
                    onChange={e => changeContent(e.target.value, 'quantity', id)}
                    label={'Menge (kg)'}
                    className="filled noMargin small"
                />}
            </td>
        </tr>
    }

    if (is404 === true) {
        return <NotFound />;
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Link className="go_back_button" to={location.state?.returnUrl || "/customers"}>zurück</Link><br /><br />
                        <h1>{`${customer.firstName} ${customer.lastName} ${translate('edit')}`}</h1>
                        {hasPermission('customer', ['can_delete']) && <div
                            className='small_button'
                            style={{ color: 'red' }}
                            onClick={() => setAskDeleteMessageOpen(true)}>
                            Kunde löschen
                        </div>}
                    </Col>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Col md={8}>
                        <Card style={{ marginBottom: 40 }}>
                            <h4>Kontaktdaten</h4>
                            {customerEdit ?
                                <ContactDataForm
                                    {...customer}
                                    customerId={customerId}
                                    setShow={setCustomerEdit}
                                    setCustomer={setCustomer}
                                    isCreate={false}
                                />
                                :
                                <Container fluid style={{ padding: 0, marginTop: 10 }}>
                                    <Row>
                                        <Col md={6}>
                                            {customer.type === 'b2b' ? "Businesskunde" : "Privatkunde"}<br /><br />
                                            {customer.company !== '' ? <>{customer.company}<br /></> : ''}
                                            {customer?.vatNumber !== '' ? <>{customer?.vatNumber} (VAT)<br /></> : ''}

                                            <br />
                                            <strong>Lieferadresse</strong><br />
                                            {customer.firstName} {customer.lastName}<br />
                                            {customer?.address?.address1} {customer?.address?.streetNumber}<br />
                                            {customer.address?.address2 !== '' ? <>{customer.address?.address2}<br /></> : ''}
                                            {customer?.address?.postcode} {customer?.address?.city}<br />
                                            {customer?.address?.country}

                                            <br />
                                            <br />
                                            <strong>Rechnungsadresse</strong>
                                            <br />
                                            {customer?.billing?.firstName} {customer?.billing?.lastName}<br />
                                            {customer?.billing?.address1}
                                            <br />
                                            {customer.billing?.address2 !== '' ? <>{customer.billing?.address2}<br /></> : ''}
                                            {customer?.billing?.postcode} {customer?.billing?.city}<br />
                                            {customer.billing?.country !== '' ? <>{customer.billing?.country}</> : ''}

                                        </Col>
                                        <Col md={6}>
                                            <a href={`mailto:${customer.email}`}>{customer.email}</a><br />
                                            <a href={`tel:${customer.phone}`}>{customer.phone}</a><br /><br />
                                            {customer.note}
                                        </Col>
                                    </Row>
                                    {hasPermission('customer', ['can_update']) &&
                                        <div
                                            onClick={() => setCustomerEdit(true)}
                                            style={{ color: 'var(--green-color)', position: 'absolute', top: 20, right: 30, cursor: 'pointer' }}>
                                            bearbeiten
                                        </div>
                                    }
                                </Container>
                            }


                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={{ marginBottom: 40 }}>
                            {hasPermission('customer', ['can_update'])
                                && !editPaymentMethod
                                && <div
                                    onClick={() => setEditPaymentMethod(true)}
                                    style={{ color: 'var(--green-color)', position: 'absolute', top: 20, right: 30, cursor: 'pointer' }}>
                                    bearbeiten
                                </div>
                            }

                            <h4>Zahlungsmethode</h4>
                            {
                                editPaymentMethod

                                    ? <>
                                        <PaymentMethodForm
                                            setShow={setEditPaymentMethod}
                                            customerId={customerId}
                                            paymentMethod={customer?.payment?.payment_method}
                                            sendInvoiceHardCopy={customer?.payment?.send_invoice_hard_copy}
                                            setCustomer={setCustomer}
                                            setError={(err) => {
                                                setErrorMessageButtonText("OK");
                                                setErrorMessageType("error");
                                                setErrorMessageOpen(true);
                                                setErrorMessageSmall(false);
                                                setErrorMessageTitle(err.response.data.exception.title);
                                                setErrorMessageBody(err.response.data.exception.message);
                                            }}
                                        />
                                    </>

                                    : customer.payment !== undefined && customer.payment.provider !== undefined && <>
                                        {
                                            customer.payment.provider === 'mollie' && customer.payment.details !== null && <>
                                                {
                                                    customer.payment.details.type === 'creditcard' &&
                                                    <div className={styles.paymentTypeContainer}>
                                                        <div className={`${styles.iconContainer} ${styles[customer.payment.details.brand]}`}></div>
                                                        <div className={styles.textContainer}>
                                                            <span className={styles.dots}></span> {customer.payment.details.last4} |
                                                            Gültig bis {customer.payment.details.expiryDate}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    customer.payment.details.type === 'directdebit' &&
                                                    <div className={styles.paymentTypeContainer}>
                                                        <div className={`${styles.iconContainer} ${styles.sepa}`}></div>
                                                        <div className={styles.textContainer}>
                                                            SEPA-Lastschriftverfahren
                                                            <span style={{ marginLeft: 20 }} className={styles.dots}></span> {customer.payment.details.last4}<br />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    customer.payment.details.type === 'paypal' &&
                                                    <div className={styles.paymentTypeContainer}>
                                                        <div className={`${styles.iconContainer} ${styles.paypal}`}></div>
                                                        <div className={styles.textContainer}>
                                                            Paypal<br />
                                                            {customer.payment.details.account_name} - {customer.payment.details.account_email}
                                                        </div>
                                                    </div>
                                                }
                                                <br /><a target="_blank" href={`https://my.mollie.com/dashboard/org_15652757/customers/${customer.payment.customer_id}`}>Zum Mollie-Konto</a>
                                            </>
                                        }
                                        {
                                            customer.payment.provider === 'stripe' && <>
                                                {
                                                    customer.payment.details.type === 'card' &&
                                                    <div className={styles.paymentTypeContainer}>
                                                        <div className={`${styles.iconContainer} ${styles[customer.payment.details.brand]}`}></div>
                                                        <div className={styles.textContainer}>
                                                            <span className={styles.dots}></span> {customer.payment.details.last4}<br />
                                                            Läuft ab im {months[customer.payment.details.exp_month]} {customer.payment.details.exp_year}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    customer.payment.details.type === 'sepa_debit' &&
                                                    <div className={styles.paymentTypeContainer}>
                                                        <div className={`${styles.iconContainer} ${styles.sepa}`}></div>
                                                        <div className={styles.textContainer}>
                                                            SEPA-Lastschriftverfahren<br />
                                                            <span style={{ marginLeft: 20 }} className={styles.dots}></span> {customer.payment.details.last4}<br />
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                        {
                                            customer.payment.provider === 'paypal' && <>
                                                <div className={styles.paymentTypeContainer}>
                                                    <div className={`${styles.iconContainer} ${styles.paypal}`}></div>
                                                    <div className={styles.textContainer}>
                                                        Paypal
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            customer.payment.provider === 'klarna' && <>
                                                <div className={styles.paymentTypeContainer}>
                                                    <div className={`${styles.iconContainer} ${styles.klarna}`}></div>
                                                    <div className={styles.textContainer}>
                                                        Klarna Kauf auf Rechnung
                                                    </div>
                                                </div>

                                            </>
                                        }
                                        {
                                            customer.payment.provider === 'afreshed' && <>
                                                <div className={styles.paymentTypeContainer}>
                                                    <div className={`${styles.iconContainer} ${styles.afreshed}`}></div>
                                                    <div className={styles.textContainer}>
                                                        Kauf auf Rechnung
                                                        <br /><small>
                                                            {
                                                                customer.payment.send_invoice_hard_copy
                                                                    ? "E-Mail & Post-Versand"
                                                                    : "Nur E-Mail-Versand"
                                                            }
                                                        </small>
                                                    </div>
                                                </div>

                                            </>
                                        }
                                    </>
                            }
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card style={{ marginBottom: 40 }}>
                            <h4>Abonnements</h4>
                            <table>
                                <tr>
                                    <td><strong>Produkt</strong></td>
                                    <td><strong>nächste Lieferung</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {
                                    subscriptions.map(subscription => {

                                        return <tr>
                                            <td>
                                                <div>{subscription.productTitle}</div>
                                                <div style={{ fontSize: 10 }}>{subscription.productSku}</div>

                                                {subscription.toSwitch !== undefined && <div style={{ fontSize: 10 }}>Produktänderung: {subscription.toSwitch.title} am {formatDbDate(subscription.toSwitch.date)}</div>}
                                            </td>
                                            <td>{subscription.nextDeliveryDate !== undefined && subscription.nextDeliveryDate !== null ? formatDbDate(subscription.nextDeliveryDate) : 'Keine'}</td>
                                            <td>
                                                {
                                                    subscription.status === 'active' && <></>
                                                }
                                                {
                                                    subscription.status === 'paused' && renderPausedDetails(subscription)
                                                }
                                                {
                                                    subscription.status === 'canceled' && renderCanceledDetails(subscription)
                                                }
                                            </td>
                                            <td style={{ width: 130 }}>
                                                {(hasPermission('customer', ['can_update']) || hasPermission('subscription', ['can_update'])) ?

                                                    <Select
                                                        className={`statusSelect ${subscription.status}`}
                                                        value={subscription.status}
                                                        onChange={e => changeStatus(e.target.value, subscription.id)}
                                                    >
                                                        <MenuItem value="active">Aktiv</MenuItem>
                                                        <MenuItem value="paused">Pausiert</MenuItem>
                                                        <MenuItem value="canceled">Storniert</MenuItem>
                                                        <MenuItem value="forceCanceled">Sofort storniert</MenuItem>
                                                    </Select>
                                                    : <StatusLabel status={subscription.status}>{subscription.status}</StatusLabel>
                                                }
                                            </td>
                                            <td style={{ width: 40 }}>
                                                <ToolTip
                                                    items={[
                                                        {
                                                            label: "Zum Abonnement",
                                                            to: `/subscriptions/${subscription.id}`,
                                                            params: {
                                                                returnUrl: `/customers/${customerId}`, initQueryVars: {}
                                                            }
                                                        },
                                                        {
                                                            label: "Rabatt hinzufügen",
                                                            onClick: () => {
                                                                setSelectedSubscription({ ...subscription });
                                                                setAddDiscountPopupOpen(true);
                                                            }
                                                        },
                                                        {
                                                            label: "Produkt ändern",
                                                            onClick: () => {
                                                                setSelectedSubscription({ ...subscription });

                                                                if (subscription.type === 'b2c') {
                                                                    setIsBusinessProduct(false);
                                                                    setSwitchProductPopupOpen(true);
                                                                } else {
                                                                    loadBusinessSubsctiption(subscription.id);
                                                                }

                                                            }
                                                        }
                                                    ]}
                                                ></ToolTip>
                                            </td>
                                        </tr>
                                    })
                                }
                            </table>
                            {(hasPermission('customer', ['can_update']) || hasPermission('subscription', ['can_update', 'can_create'])) &&
                                <div
                                    onClick={() => {
                                        setSelectedSubscription({});
                                        setAddSubscriptionPopupOpen(true)
                                    }}
                                    style={{ color: 'var(--green-color)', position: 'absolute', top: 20, right: 30, cursor: 'pointer' }}
                                >
                                    Abonnement hinzufügen
                                </div>
                            }
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        {customer.type === "b2b" &&
                            <Card style={{ marginBottom: 40 }}>
                                <h4>Lieferscheine</h4>
                                {deliveryNoteLoading ? <Loading relative visible={true} containerStyle={{ height: 70 }} /> :
                                    <>

                                        {deliveryNotes.length > 0 ? <>
                                            <div
                                                onClick={() => combineToInvoice()}
                                                style={{ color: 'var(--green-color)', position: 'absolute', top: 20, right: 30, cursor: 'pointer' }}>
                                                Zu Rechnung zusammenfassen
                                            </div>
                                            <table>
                                                <tr>
                                                    <td style={{ width: 50 }}></td>
                                                    <td><strong>Datum</strong></td>
                                                    <td><strong>Nummer</strong></td>
                                                    <td><strong>Status</strong></td>
                                                    <td></td>
                                                </tr>
                                                {
                                                    deliveryNotes !== undefined && deliveryNotes.map((deliveryNote, key) => {
                                                        if (!showAllDeliveryNotes && key > 5) {
                                                            return null;
                                                        }
                                                        return <tr>
                                                            <td>
                                                                <Checkbox
                                                                    onChange={(v) => {
                                                                        selectDeliveryNote(deliveryNote.id)
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{formatDbDate(deliveryNote.date)}</td>
                                                            <td>#{deliveryNote.number}</td>
                                                            <td><StatusLabel status={deliveryNote.status}>{translateDeliveryNoteStatus(deliveryNote.status)}</StatusLabel></td>
                                                            <td style={{ width: 40 }}>
                                                                <ToolTip
                                                                    items={[
                                                                        {
                                                                            label: "herunterladen",
                                                                            onClick: () => {
                                                                                downloadDeliveryNote(deliveryNote.id)
                                                                            }
                                                                        }
                                                                    ]}
                                                                ></ToolTip>
                                                            </td>
                                                        </tr>

                                                        return null;
                                                    })
                                                }
                                            </table>
                                            {deliveryNotes.length > 5 &&
                                                <div
                                                    onClick={() => setShowAllDeliveryNotes(prev => !prev)}
                                                    style={{ color: 'var(--green-color)', cursor: 'pointer', marginTop: 20 }}>
                                                    {showAllDeliveryNotes ? 'Weniger anzeigen' : 'Alle anzeigen'}
                                                </div>
                                            }
                                        </>
                                            : <>Keine Lieferscheine</>
                                        }
                                    </>
                                }
                            </Card>
                        }
                        <Card style={{ marginBottom: 40 }}>
                            <h4>Rechnungen</h4>
                            {invoiceLoading ? <Loading relative visible={true} containerStyle={{ height: 70 }} /> :
                                <>
                                    {
                                        invoices.length > 0 ? <>
                                            <table>
                                                <tr>
                                                    <td><strong>Datum</strong></td>
                                                    <td><strong>Nummer</strong></td>
                                                    <td><strong>Summe</strong></td>
                                                    <td><strong>Status</strong></td>
                                                    <td></td>
                                                </tr>
                                                {
                                                    invoices !== undefined && invoices.map((invoice, key) => {
                                                        if (!showAllInvoices && key >= 5) {
                                                            return null;
                                                        }
                                                        return <tr>
                                                            <td>{formatDbDate(invoice.invoiceDate)}</td>
                                                            <td>#{invoice.invoiceNumber}</td>
                                                            <td>{formatMoney(invoice.total)}</td>
                                                            <td>
                                                                <Select
                                                                    className={`statusSelect ${invoice.status}`}
                                                                    value={invoice.status}
                                                                    onChange={e => changeInvoiceStatus(invoice.id, e.target.value)}
                                                                    style={{ width: 200 }}
                                                                >
                                                                    <MenuItem value="issued">Offen</MenuItem>
                                                                    <MenuItem value="processing">Ausstehend</MenuItem>
                                                                    <MenuItem value="paid">Bezahlt</MenuItem>
                                                                    <MenuItem value="canceled">storniert</MenuItem>
                                                                    <MenuItem value="failed">Fehlgeschlagen</MenuItem>
                                                                </Select>
                                                            </td>
                                                            <td style={{ width: 40 }}>
                                                                <ToolTip
                                                                    items={[
                                                                        {
                                                                            label: "herunterladen",
                                                                            onClick: () => {
                                                                                downloadInvoice(invoice.id)
                                                                            }
                                                                        }
                                                                    ]}
                                                                ></ToolTip>
                                                            </td>
                                                        </tr>

                                                        return null;
                                                    })
                                                }
                                            </table>
                                            {invoices.length > 5 &&
                                                <div
                                                    onClick={() => setShowAllInvoices(prev => !prev)}
                                                    style={{ color: 'var(--green-color)', cursor: 'pointer', marginTop: 20 }}>
                                                    {showAllInvoices ? 'Weniger anzeigen' : 'Alle anzeigen'}
                                                </div>
                                            }
                                        </>
                                            : <>Keine Rechnungen</>
                                    }
                                </>
                            }
                        </Card>
                        <Card style={{ marginBottom: 40 }}>
                            <DeliveryList 
                                customerId={customerId}
                            />
                        </Card>
                        <Card style={{ marginBottom: 40 }}>
                            <h4>Rabatte</h4> {discounts.length > 0 ? <>
                                <table>
                                    <tr>
                                        <td><strong>Produkt</strong></td>
                                        <td><strong>Rabatt</strong></td>
                                        <td><strong>Typ</strong></td>
                                        <td><strong>Status</strong></td>
                                        <td></td>
                                    </tr>
                                    {
                                        discounts !== undefined && discounts.map(discount => {
                                            return <tr>
                                                <td>
                                                    <div>{discount.productTitle}</div>
                                                    <div style={{ fontSize: 10 }}>{discount.productSku}</div>
                                                </td>
                                                <td>{discount.amount}%</td>
                                                <td>{translateDiscountTypes(discount.type)}</td>
                                                <td><StatusLabel status={discount.status}>{translateDiscountStatus(discount.status)}</StatusLabel></td>
                                                <td style={{ width: 40 }}>
                                                    {discount.status === 'active' && <ToolTip
                                                        items={[
                                                            {
                                                                label: "Rabatt zurückziehen",
                                                                onClick: () => {
                                                                    deleteDiscount(discount.id)
                                                                }
                                                            }
                                                        ]}
                                                    ></ToolTip>}
                                                </td>
                                            </tr>

                                            return null;
                                        })
                                    }
                                </table>
                            </>
                                : <>Keine Rabatte</>
                            }
                        </Card>
                        <Card style={{ marginBottom: 40 }}>
                            <h4>Bewertungen</h4> {reviews.total > 0 ? <>({reviews.rating} Sterne)
                                <div style={{ maxHeight: 400, overflow: 'scroll' }}>
                                    {
                                        reviews.reviews !== undefined && reviews.reviews.map(review => {
                                            if (review.comment !== null) {
                                                return <>{review.comment}<br /><hr /><br /></>
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                            </>
                                : <>Keine Bewertung</>
                            }
                        </Card>
                        <Card style={{ marginBottom: 40 }}>
                            <h4>Bestellungen</h4> {orders.length > 0 ? <>
                                <div>
                                    <Table>
                                        {
                                            orders.map(order => {
                                                return <TableRow>
                                                    <TableCell>
                                                        {formatDbDateTime(order.created)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {order.status}
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: 'right' }}>
                                                        <div
                                                            onClick={() => showOrderDetails(order.id)}
                                                            style={{ color: 'var(--green-color)', cursor: 'pointer' }}>
                                                            Details anzeigen
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            })
                                        }
                                    </Table>
                                </div>
                            </>
                                : <>Keine Bestellungen</>
                            }
                        </Card>

                    </Col>
                    <Col md={4}>
                        <Card style={{ marginBottom: 40 }}>
                            <h4>Logs</h4>
                            <div style={{ maxHeight: 400, overflow: 'scroll' }}>
                                {addingLogEntry ?
                                    <>
                                        <LogNoteForm
                                            setShow={setAddingLogEntry}
                                            setLogEntries={setLogEntries}
                                            customerId={customerId}
                                        />
                                    </>
                                    :
                                    <>
                                        {hasPermission('customer', ['can_update']) &&
                                            <div
                                                onClick={() => setAddingLogEntry(true)}
                                                style={{ color: 'var(--green-color)', position: 'absolute', top: 20, right: 30, cursor: 'pointer' }}>Notiz hinzufügen</div>
                                        }
                                        {
                                            logEntries.map(logEntry => {
                                                return <LogEntry {...logEntry} />;
                                            })
                                        }
                                    </>
                                }
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Row style={{ marginBottom: 100 }}>
                    <Col md={5}>
                    </Col>
                    <Col md={7}>
                    </Col>
                </Row>
            </Container>
            <Popup
                close={() => setPausePopupOpen(false)}
                open={pausePopupOpen}
                style={{ width: 372, paddingTop: 50 }}
            >
                <DateRangePicker
                    ranges={[pauseRange]}
                    onChange={onChangeDate}
                    minDate={new Date()}
                    locale={de}
                    staticRanges={[]}
                    inputRanges={[]}
                    renderStaticRangeLabel={{ hasCustomRendering: false }}
                />
                <div
                    className="button"
                    onClick={setPause}
                >Pausieren</div>
            </Popup>
            <Popup
                close={() => setSwitchProductPopupOpen(false)}
                open={switchProductPopupOpen}
            // style={{ width: 372, paddingTop: 50 }}
            >
                <br />
                <br />
                <Autocomplete
                    id="tags-outlined"
                    options={products}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, newValue) => {
                        changeSwitchedProduct(newValue.id, selectedSubscription.id)
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Produkte"
                            className="filled"
                        />
                    )}
                />

                <br />
                <br />
                <div
                    className="button"
                    style={{ marginRight: 20 }}
                    onClick={() => switchProduct(false)}
                >Produkt ändern</div>
                <div
                    className="button secondary"
                    onClick={() => switchProduct(true)}
                >Produkt sofort ändern</div>
            </Popup>
            <Popup
                close={() => setAddSubscriptionPopupOpen(false)}
                open={addSubscriptionPopupOpen}
                style={{ paddingTop: 50 }}
            >
                {
                    customer.type === "b2b" && <>
                        <FormControlLabel
                            name={"businessproduct"}
                            label={'Businessprodukt?'} control={
                                <Checkbox
                                    onChange={e => setIsBusinessProduct(e.target.checked)}
                                    checked={isBusinessProduct}
                                />
                            } />
                        <br />
                        <br />

                    </>
                }
                {isBusinessProduct ? <>
                    <Container fluid style={{ padding: 0 }}>
                        <Row>
                            <Col>
                                <TextField
                                    value={businessProductTitle}
                                    onChange={e => setBusinessProductTitle(e.target.value)}
                                    label={'Titel'}
                                    className="filled"
                                    style={{ marginBottom: 10 }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField
                                    value={businessProductSku}
                                    onChange={e => setBusinessProductSku(e.target.value)}
                                    label={'Interner Titel (SKU)'}
                                    className="filled"
                                    style={{ marginBottom: 10 }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 10 }}>
                            <Col md={4}>
                                <TextField
                                    value={businessProductQuantity}
                                    onChange={e => setBusinessProductQuantity(e.target.value)}
                                    label={'Wie viele Kisten?'}
                                    className="filled"
                                    style={{ marginBottom: 10 }}
                                />
                            </Col>
                            <Col md={4}>
                                <FormControl
                                    className="selectFormControl"
                                    error={errors.intervalOfDelivery}
                                >
                                    <InputLabel id="type">Interval der Lieferung*</InputLabel>
                                    <Select
                                        onChange={e => setBusinessIntervalOfDelivery(e.target.value)}
                                        defaultValue={businessIntervalOfDelivery}
                                    >
                                        {intervalOfDeliveryArray.map((i) => <MenuItem value={i.value}>{i.label} </MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Col>

                            <Col md={4}>
                                <FormControl
                                    className="selectFormControl"
                                    style={{ marginBottom: 10 }}
                                >
                                    <InputLabel id="type">Liefertag</InputLabel>
                                    <Select
                                        className={``}
                                        onChange={e => setBusinessProductDeliveryDay(e.target.value)}
                                        value={businessProductDeliveryDay}
                                    >
                                        <MenuItem value="1">MO</MenuItem>
                                        <MenuItem value="2">DI</MenuItem>
                                        <MenuItem value="3">MI</MenuItem>
                                        <MenuItem value="4">DO</MenuItem>
                                        <MenuItem value="5">FR</MenuItem>
                                        <MenuItem value="6">SA</MenuItem>
                                        <MenuItem value="7">SO</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 10 }}>
                            <Col md={6}>
                                <TextField
                                    value={businessProductPrice}
                                    onChange={e => setBusinessProductPrice(e.target.value)}
                                    label={'Preis'}
                                    className="filled"
                                    style={{ marginBottom: 10 }}
                                />
                            </Col>
                            <Col md={6}>
                                <FormControl
                                    className="selectFormControl"
                                    style={{ marginBottom: 10 }}
                                >
                                    <InputLabel id="type">Art der Bepreisung</InputLabel>
                                    <Select
                                        className={``}
                                        onChange={e => setBusinessProductPriceType(e.target.value)}
                                        value={businessProductPriceType}
                                    >
                                        <MenuItem value="whole">Gesamt</MenuItem>
                                        <MenuItem value="kg">pro kg</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                    </Container>



                    <table width={'100%'} cellPadding="4">
                        <tbody>
                            {businessProductContentRow('divFruits1', 'Div Obst 1')}
                            {businessProductContentRow('divFruits2', 'Div Obst 2')}
                            {businessProductContentRow('divFruits3', 'Div Obst 3')}
                            {businessProductContentRow('divFruits4', 'Div Obst 4')}
                            {businessProductContentRow('divFruits5', 'Div Obst 5')}
                            {businessProductContentRow('divVegetable1', 'Div Gemüse 1')}
                            {businessProductContentRow('divVegetable2', 'Div Gemüse 2')}
                            {businessProductContentRow('divVegetable3', 'Div Gemüse 3')}
                            {businessProductContentRow('divVegetable4', 'Div Gemüse 4')}

                            {businessProductContent.filter(content => procurementProducts.findIndex(e => e.id === content.item_id) > -1).map(content => businessProductContentRow(content.item_id))}
                            {!businessProductContentShow ? <></> : businessProductContentRow()}
                        </tbody>
                    </table>

                </>
                    :
                    <Autocomplete
                        id="tags-outlined"
                        options={products}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, newValue) => {
                            setNewProduct(newValue.id);
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Produkte"
                                className="filled"
                            />
                        )}
                    />
                }
                <div
                    className="button"
                    style={{ marginTop: 20 }}
                    onClick={createSubscription}
                >Abonnement hinzufügen</div>
            </Popup>
            <Popup
                close={() => setAddDiscountPopupOpen(false)}
                open={addDiscountPopupOpen}
                style={{ paddingTop: 50 }}
            >
                <AddDiscountForm
                    reload={loadDiscounts}
                    subscriptionId={selectedSubscription.id}
                    setShow={setAddDiscountPopupOpen}
                />

            </Popup>
            <Popup
                close={() => setShowOrderDetailPopupOpen(false)}
                open={showOrderDetailPopupOpen}
                style={{ paddingTop: 50 }}
            >
                <OrderDetail
                    setShow={setShowOrderDetailPopupOpen}
                    setMessage={() => { }}
                    orderId={currentOrderId}
                />
            </Popup>
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                small={errorMessageSmall}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />

            <Message
                open={askDeleteMessageOpen}
                type={'success'}
                small={false}
                title={translate('sure_delete_title')}
                body={translate('sure_delete_body')}
                buttonText={translate('yes_delete')}
                buttonAction={() => deleteCustomer()}
                buttonTwoText={translate('cancel')}
                buttonTwoAction={() => setAskDeleteMessageOpen(false)}
                buttonTwoStyle={{ color: 'red' }}
            />

            <Loading visible={loading} />
        </>

    )

}

export default CustomerSingle;